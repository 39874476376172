import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import QuizResultFilter from "./core-components/QuizResultFilter";
import {
  checkAnswer,
  rawMarkup,
  saveUserInput,
  verifyAnswer,
} from "./core-components/helpers";
import InstantFeedback from "./core-components/InstantFeedback";
import Explanation from "./core-components/Explanation";
import JWPlayer from "../sharedComponents/JWPlayer/JWPlayer";
import moment from "moment";
import { QuizDataService } from "../services";
import { LessonPlanDataService } from "../services/lessonPlans.service";

const Core = ({
  officialID,
  quizID,
  quizHistoryID,
  questions,
  appLocale,
  showDefaultResult,
  onComplete,
  customResultPage,
  showInstantFeedback,
  continueTillCorrect,
  jwPlayersrc,
  jwPlayerkey,
  playExternalVideo,
  updateQuestion,
  correntAnswerIndex,
  inCorrentAnswerIndex,
  userAnswersInputs,
}) => {
  const [incorrectAnswer, setIncorrectAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [showNextQuestionButton, setShowNextQuestionButton] = useState(false);
  const [endQuiz, setEndQuiz] = useState(
    userAnswersInputs.length === questions.length ? true : false
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    userAnswersInputs.length === questions.length
      ? userAnswersInputs.length - 1
      : userAnswersInputs.length
  );
  const [buttons, setButtons] = useState({});
  const [buttonClasses, setButtonClasses] = useState({});
  const [correct, setCorrect] = useState(correntAnswerIndex);
  const [incorrect, setIncorrect] = useState(inCorrentAnswerIndex);
  const [userInput, setUserInput] = useState(userAnswersInputs);
  const [filteredValue, setFilteredValue] = useState("all");
  const [userAttempt, setUserAttempt] = useState(1);
  const [showDefaultResultState, setShowDefaultResult] = useState(true);
  const [answerSelectionTypeState, setAnswerSelectionType] = useState(
    undefined
  );
  const [totalPoints, setTotalPoints] = useState(0);
  const [correctPoints, setCorrectPoints] = useState(0);
  const [question, setQuestion] = useState(questions[currentQuestionIndex]);
  const [questionSummary, setQuestionSummary] = useState(undefined);
  const [questionTime, setQuestionTime] = useState();
  useEffect(() => {
    setShowDefaultResult(
      showDefaultResult !== undefined ? showDefaultResult : true
    );
  }, [showDefaultResult]);

  useEffect(() => {
    setQuestion(questions[currentQuestionIndex]);
    setQuestionTime(moment());
  }, [currentQuestionIndex]);

  useEffect(() => {
    let { answerSelectionType } = question;
    // Default single to avoid code breaking due to automatic version upgrade
    setAnswerSelectionType(answerSelectionType || "single");
  }, [question, currentQuestionIndex]);

  useEffect(() => {
    if (endQuiz) {
      let totalPointsTemp = 0;
      let correctPointsTemp = 0;
      for (let i = 0; i < questions.length; i++) {
        let point = questions[i].point || 0;
        if (typeof point === "string" || point instanceof String) {
          point = parseInt(point);
        }

        totalPointsTemp = totalPointsTemp + point;

        if (correct.includes(i)) {
          correctPointsTemp = correctPointsTemp + point;
        }
      }
      setTotalPoints(totalPointsTemp);
      setCorrectPoints(correctPointsTemp);
    }
  }, [endQuiz]);

  useEffect(() => {
    setQuestionSummary({
      numberOfQuestions: questions.length,
      numberOfCorrectAnswers: correct.length,
      numberOfIncorrectAnswers: incorrect.length,
      questions: questions,
      userInput: userInput,
      totalPoints: totalPoints,
      correctPoints: correctPoints,
    });
  }, [totalPoints, correctPoints]);

  useEffect(() => {
    if (endQuiz && onComplete !== undefined && questionSummary !== undefined) {
      if (
        questionSummary.numberOfCorrectAnswers !== 0 ||
        questionSummary.numberOfIncorrectAnswers !== 0
      )
        onComplete(questionSummary);
    }
  }, [endQuiz, questionSummary]);

  const nextQuestion = (currentQuestionIndex) => {
    setIncorrectAnswer(false);
    setCorrectAnswer(false);
    setShowNextQuestionButton(false);
    setButtons({});
    updateQuestion(question);
    if (currentQuestionIndex + 1 === questions.length) {
      setEndQuiz(true);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleChange = (event) => {
    setFilteredValue(event.target.value);
  };
  const saveToDB = (time) => {
    const { answers } = question;
    let usersanswers = [];
    //let type = typeof userInput[currentQuestionIndex];
    if (answerSelectionTypeState === "single") {
      usersanswers.push(answers[userInput[currentQuestionIndex] - 1]);
    } else {
      userInput[currentQuestionIndex].forEach((element) => {
        usersanswers.push(answers[element - 1]);
      });
    }
    let obj = {
      ...question,
      timeToAnswer: Math.round(time),
      userAnswers: usersanswers,
    };
    let reqData = {
      quizUID: quizID,
      quizHistoryID: quizHistoryID,
      lessonPlanID: quizID,
      userResponse: obj,
    };
    if (officialID !== null && officialID !== "") {
      reqData.officialUID = officialID;
    } else {
      reqData.officialUID = '00000000-0000-0000-0000-000000000000';
    }
    //debugger;
    return new Promise((resolve, reject) => {
      LessonPlanDataService.saveQuizAnswer(reqData)
        .then((response) => {
          setQuestion(response.userResponse);
          resolve(response.userResponse);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };
  const renderAnswerInResult = (question, userInputIndex) => {
    const {
      answers,
      result,
      questionType,
      correctAnswer,
      userAnswers,
    } = question;
    let { answerSelectionType } = question;
    let answerBtnCorrectClassName;
    let answerBtnIncorrectClassName;

    // Default single to avoid code breaking due to automatic version upgrade
    answerSelectionType = answerSelectionType || "single";

    return answers.map((answer, index) => {
      //if (answerSelectionType === "single") {
      // correctAnswer - is string
      answerBtnCorrectClassName = correctAnswer.includes(answer)
        ? "correct"
        : "";
      answerBtnIncorrectClassName =
        userAnswers.includes(answer) && !correctAnswer.includes(answer)
          ? "incorrect"
          : "";
      // } else {
      //   // correctAnswer - is array of numbers
      //   answerBtnCorrectClassName = correctAnswer.includes(answer)
      //     ? "correct"
      //     : "";
      //   answerBtnIncorrectClassName =
      //     !result.includes(index + 1) && userInputIndex.includes(index + 1)
      //       ? "incorrect"
      //       : "";
      // }

      return (
        <div key={index}>
          <button
            disabled={true}
            className={
              "answerBtn btn " +
              answerBtnCorrectClassName +
              answerBtnIncorrectClassName
            }
          >
            {questionType === "text" && <span>{answer}</span>}
            {questionType === "photo" && <img src={answer} alt="img" />}
            {/* {questionType === 'video' && <JWPlayer fileURL={fileURL} jwPlayersrc={jwPlayersrc} jwPlayerkey={jwPlayerkey} userName={userName} playExternalVideo={playExternalVideo}/>} */}
          </button>
        </div>
      );
    });
  };

  const renderQuizResultQuestions = useCallback(() => {
    let filteredQuestions;
    let filteredUserInput;

    if (filteredValue !== "all") {
      if (filteredValue === "correct") {
        filteredQuestions = questions.filter(
          (question, index) => correct.indexOf(index) !== -1
        );
        filteredUserInput = userInput.filter(
          (input, index) => correct.indexOf(index) !== -1
        );
      } else {
        filteredQuestions = questions.filter(
          (question, index) => incorrect.indexOf(index) !== -1
        );
        filteredUserInput = userInput.filter(
          (input, index) => incorrect.indexOf(index) !== -1
        );
      }
    }

    return (filteredQuestions ? filteredQuestions : questions).map(
      (question, index) => {
        const userInputIndex = filteredUserInput
          ? filteredUserInput[index]
          : userInput[index];

        // Default single to avoid code breaking due to automatic version upgrade
        let answerSelectionType = question.answerSelectionType || "single";

        return (
          <div className="result-answer-wrapper" key={index + 1}>
            <h3
              dangerouslySetInnerHTML={rawMarkup(
                `Q${index + 1}:${question.questionText}`
              )}
            />
            {question.questionPic !== "" && (
              <img src={question.questionPic} alt="img" />
            )}
            {question.questionVideo !== "" && (
              <JWPlayer
                id={`my-palyer_${index + 1}`}
                fileURL={question.questionVideo}
                jwPlayersrc={jwPlayersrc}
                jwPlayerkey={jwPlayerkey}
                playExternalVideo={playExternalVideo}
              />
            )}
            {renderTags(
              answerSelectionType,
              question.numberOfSelection,
              question.segment
            )}
            <div className="result-answer">
              {renderAnswerInResult(question, userInputIndex)}
            </div>
            <Explanation question={question} isResultPage={true} />
          </div>
        );
      }
    );
  }, [endQuiz, filteredValue]);
  const onAnswerClick = (index) => {
    const { numberOfSelection, answerSelectionType } = question;
    saveUserInput(index + 1, numberOfSelection, answerSelectionType, {
      userInput,
      currentQuestionIndex,
      setButtons,
      setUserInput,
    });
  };

  const verifyAnswerAndSubmit = () => {
    let { answers } = question;
    let disabledAll = { ...buttons };
    answers.forEach((element, index) => {
      if (disabledAll[index] === undefined) {
        disabledAll[index] = { disabled: true };
      } else {
        let obj = disabledAll[index];
        disabledAll[index] = { ...obj, disabled: true };
      }
    });
    setButtons((prevState) => ({
      ...prevState,
      ...disabledAll,
    }));
    let timeNow = moment();
    let timeTaken = (timeNow - moment(questionTime)) / 1000;
    saveToDB(timeTaken).then((data) => {
      if (data) {
        const {
          numberOfSelection,
          answerSelectionType,
          result,
          correctAnswer,
          answers,
          userAnswers,
        } = data;
        verifyAnswer(
          result,
          numberOfSelection,
          answerSelectionType,
          correctAnswer,
          answers,
          userAnswers,
          {
            userInput,
            userAttempt,
            currentQuestionIndex,
            continueTillCorrect,
            showNextQuestionButton,
            incorrect,
            correct,
            setButtons,
            setCorrectAnswer,
            setIncorrectAnswer,
            setCorrect,
            setIncorrect,
            setShowNextQuestionButton,
            setUserInput,
            setUserAttempt,
          }
        );
      }
    });
  };
  const renderAnswers = (question, buttons) => {
    const { answers, correctAnswer, questionType } = question;
    let { answerSelectionType } = question;
    const onClickAnswer = (index) =>
      checkAnswer(index + 1, correctAnswer, answerSelectionType, {
        userInput,
        userAttempt,
        currentQuestionIndex,
        continueTillCorrect,
        showNextQuestionButton,
        incorrect,
        correct,
        setButtons,
        setCorrectAnswer,
        setIncorrectAnswer,
        setCorrect,
        setIncorrect,
        setShowNextQuestionButton,
        setUserInput,
        setUserAttempt,
      });

    // Default single to avoid code breaking due to automatic version upgrade
    answerSelectionType = answerSelectionType || "single";

    return answers.map((answer, index) => (
      <Fragment key={index}>
        {buttons[index] !== undefined ? (
          <button
            disabled={buttons[index].disabled || false}
            className={`${buttons[index].className} answerBtn btn`}
            onClick={() => onAnswerClick(index)}
          >
            {questionType === "text" && <span>{answer}</span>}
            {questionType === "photo" && <img src={answer} alt="image" />}
          </button>
        ) : (
          <button
            onClick={() => onAnswerClick(index)}
            className="answerBtn btn"
          >
            {questionType === "text" && answer}
            {questionType === "photo" && <img src={answer} alt="image" />}
          </button>
        )}
      </Fragment>
    ));
  };

  const renderTags = (answerSelectionType, numberOfSelection, segment) => {
    const {
      singleSelectionTagText,
      multipleSelectionTagText,
      pickNumberOfSelection,
    } = appLocale;

    return (
      <div className="tag-container">
        {answerSelectionType === "single" && (
          <span className="single selection-tag">{singleSelectionTagText}</span>
        )}
        {answerSelectionType === "multiple" && (
          <span className="multiple selection-tag">
            {multipleSelectionTagText}
          </span>
        )}
        <span className="number-of-selection">
          {pickNumberOfSelection.replace(
            "<numberOfSelection>",
            numberOfSelection
          )}
        </span>
        {segment && <span className="selection-tag segment">{segment}</span>}
      </div>
    );
  };

  const renderResult = () => (
    <div className="card-body">
      <h2>
        {appLocale.resultPageHeaderText
          .replace("<correctIndexLength>", correct.length)
          .replace("<questionLength>", questions.length)}
      </h2>
      <h2>
        {appLocale.resultPagePoint
          .replace("<correctPoints>", correctPoints)
          .replace("<totalPoints>", totalPoints)}
      </h2>
      <br />
      <QuizResultFilter
        filteredValue={filteredValue}
        handleChange={handleChange}
        appLocale={appLocale}
      />
      {renderQuizResultQuestions()}
    </div>
  );

  return (
    <div className="questionWrapper">
      {!endQuiz && (
        <div className="questionWrapperBody">
          <div className="questionModal">
            <InstantFeedback
              question={question}
              showInstantFeedback={showInstantFeedback}
              correctAnswer={correctAnswer}
              incorrectAnswer={incorrectAnswer}
            />
          </div>
          <div>
            {appLocale.question} {currentQuestionIndex + 1}:
          </div>
          <h3
            dangerouslySetInnerHTML={rawMarkup(question && question.questionText)}
          />
          {question && question.questionPic && (
            <img src={question.questionPic} alt="img" />
          )}
          {question.questionVideo && (
            <JWPlayer
              fileURL={question.questionVideo}
              jwPlayersrc={jwPlayersrc}
              jwPlayerkey={jwPlayerkey}
              playExternalVideo={playExternalVideo}
            />
          )}
          {question &&
            renderTags(
              answerSelectionTypeState,
              question.numberOfSelection,
              question.segment
            )}
          {question && renderAnswers(question, buttons)}
          {showNextQuestionButton ? (
            <div>
              <button
                onClick={() => nextQuestion(currentQuestionIndex)}
                className="nextQuestionBtn btn"
              >
                {appLocale.nextQuestionBtn}
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={verifyAnswerAndSubmit}
                className="checkAnswerBtn btn"
                disabled={
                  userInput[currentQuestionIndex] !== undefined
                    ? question.answerSelectionType === "single"
                      ? false
                      : userInput[currentQuestionIndex].length !==
                        question.numberOfSelection
                    : true
                }
              >
                {appLocale.submitAnswer}
              </button>
            </div>
          )}
        </div>
      )}
      {endQuiz &&
        showDefaultResultState &&
        customResultPage === undefined &&
        renderResult()}
      {endQuiz &&
        !showDefaultResultState &&
        customResultPage !== undefined &&
        customResultPage(questionSummary)}
    </div>
  );
};

Core.propTypes = {
  questions: PropTypes.array,
  showDefaultResult: PropTypes.bool,
  onComplete: PropTypes.func,
  customResultPage: PropTypes.func,
  showInstantFeedback: PropTypes.bool,
  continueTillCorrect: PropTypes.bool,
  appLocale: PropTypes.object,
  jwPlayersrc: PropTypes.string,
  jwPlayerkey: PropTypes.string,
  userName: PropTypes.string,
  playExternalVideo: PropTypes.bool,
};

export default Core;
