import React, { useState, useEffect, useContext } from "react";
import { withRouter, useLocation, useParams } from "react-router-dom";
import { makeStyles, Typography, TextField, FormControl, InputLabel, MenuItem, Select, Button, Grid, FormHelperText, LinearProgress, Link, CircularProgress } from "@material-ui/core";
import zxcvbn from "zxcvbn";
import RouteConstants from "../../../common/RouteConstants";
import { userService } from "../../../services";
import Validations from "../../../common/Validations";
import AppDataContext from "../../../common/AppContext";
import StrengthIndicator from "../../../sharedComponents/indicators/StrengthIndicator";
import { Autocomplete } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
}));

const UserRegistrationComponent = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { addSnackMessages, countryList } = useContext(AppDataContext);

  const search = props.location.search;
  const params = new URLSearchParams(search);
  const eventIDEncrypted = params.get('e');
  const eventSlugEncrypted = params.get('s');
  const guestPofileRequestIDEncrypted = params.get('r');
  const [emailReadOnly, setEmailReadOnly] = useState(false);
  const [showRegForm, setShowRegForm] = useState(true);
  const [formData, setFormData] = useState({
    "firstName": "",
    "lastName": "",
    "email": "",
    "eventID": eventIDEncrypted !== undefined ? eventIDEncrypted : '',
    "confirmEmail": "",
    "userName": "",
    "password": "",
    "confirmPassword": "",
    "country": 0,
    "eventSlug": eventSlugEncrypted !== undefined ? eventSlugEncrypted : '',
    "requestID": null
  });
  const [formDataError, setFormDataError] = useState([]);



  useEffect(() => {
    //debugger
    if ((guestPofileRequestIDEncrypted !== null && guestPofileRequestIDEncrypted !== undefined && guestPofileRequestIDEncrypted !== '') && formData != null) {
      //setIsLoading(true);
      userService.getGuestProfileRequest(guestPofileRequestIDEncrypted)
        .then(result => {
          //setIsLoading(false);
          if (result != null && result != undefined && result != '' && result.data != null && result.data != undefined) {
            setEmailReadOnly(true);
            let _formData = formData;
            _formData.email = result.data.guestProfileEmail;
            _formData.eventID = result.data.eventID;
            _formData.eventSlug = result.data.eventSlug;
            _formData.requestID = result.data.requestID;
            setFormData(_formData);
            //  setFormData({ ...formData, email: result.data.guestProfileEmail })
            //  setFormData({ ...formData, eventID: result.data.eventID })
            //  setFormData({ ...formData, eventSlug: result.data.eventSlug })
          } else {
            setShowRegForm(false);
          }
        })
    }
  }, [guestPofileRequestIDEncrypted])

  // useEffect(() => {
  //   console.log(event)
  //   if (event !== undefined && event !== null) {
  //     setFormData({ ...formData, eventID: event })
  //   }
  // }, [event])

  useEffect(() => {
    if (Object.keys(formDataError).length) isFormDataValid();
  }, [formData]);

  const isFormDataValid = () => {
    let errors = [];


    Object.entries(formData).forEach(([key, value]) => {
      let field = false;
      switch (key) {
        case "firstName":
          field = Validations.Validate("First Name", value.trim(), { Required: true, AllowOnlyCharacters: true });
          break;
        case "lastName":
          field = Validations.Validate("Last Name", value.trim(), { Required: true, AllowOnlyCharacters: true });
          break;
        case "email":
          field = Validations.Validate("Email", value.trim(), { Required: true, Email: true });
          break;
        case "password":
          field = Validations.Validate("Password", value.trim(), { Required: true, MinLength: 8, MaxLength: 16, Password: true });
          break;
        case "userName":
          field = Validations.Validate("Username", value.trim(), { Required: true, UserName: true, MaxLength: 25, MinLength: 5 });
          break;
        // case "aboutusSource":
        //   field = Validations.Validate("Field", value.trim(), { Required: true });
        //   break;
        case "country":
          field = Validations.Validate("Country", value, { Min: 1 });
          if (field && !field.isValid) {
            field.validationMessage = "Country is required."
          }
          break;
        // case "aboutusDescription":
        //   if (formData.aboutusSource === "NBA Referee Scouting Group" || formData.aboutusSource === "Referee Assignor" || formData.aboutusSource === "NBA Employee" || formData.aboutusSource === "Other") {
        //     field = Validations.Validate("Details", value.trim(), { Required: true });
        //   }
      }

      if (field && !field.isValid) {
        errors[key] = field.validationMessage;
      }
    });

    // Password & Confirm Password equal check
    if (!errors.password && !errors.confirmPassword && formData.password !== formData.confirmPassword) {
      errors['confirmPassword'] = "Password & Confirm Password should be same.";
    }
    // Email & Confirm Email equal check
    if (!errors.email && !errors.confirmEmail && formData.email !== formData.confirmEmail) {
      errors['confirmEmail'] = "Email & Confirm Email should be same.";
    }

    if (Object.keys(errors).length) {
      setFormDataError(errors);
      return false;
    }
    setFormDataError([]);
    return true;
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isFormDataValid()) {
      setIsLoading(false);
      return false;
    }

    userService.registerUser(formData)
      .then(res => {
        if (res && res.data) {
          addSnackMessages([{
            open: true,
            severity: "success",
            message: res.message
          }]);
          setTimeout(() => {
            gotoLogin();
          }, 3000);
          setIsLoading(false);
        } else {
          addSnackMessages([{
            open: true,
            severity: "error",
            message: res.errorMessage ?? "Something went wrong"
          }]);
          setIsLoading(false);
        }
      }).catch(e => {
        setFormDataError(e.errors);
        addSnackMessages([{
          open: true,
          severity: "error",
          message: e.message ?? "Something went wrong. Please try again later"
        }]);
        setIsLoading(false);
      })
  }

  const passwordTest = zxcvbn(formData.password);

  const gotoLogin = (e) => {
    if (e) e.preventDefault();
    props.history.push(RouteConstants.OFFICIAL_LOGIN);
  }

  return (
    showRegForm == true ?
      <>
        <Grid container spacing={2} component="form" noValidate onSubmit={(e) => handleFormSubmit(e)}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" color="secondary">
              Sign Up
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="outlined"
              required
              fullWidth
              size="small"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formData.email}
              inputProps={{ readOnly: emailReadOnly }}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              error={formDataError.email && true || false}
              helperText={formDataError.email && formDataError.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="confirmEmail"
              id="confirmEmail"
              size="small"
              label="Confirm Email"
              fullWidth
              required
              value={formData.confirmEmail}
              onChange={(e) => setFormData({ ...formData, confirmEmail: e.target.value })}
              error={formDataError.confirmEmail && true || false}
              helperText={formDataError.confirmEmail && formDataError.confirmEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="userName"
              id="userName"
              size="small"
              label="Username"
              fullWidth
              required
              value={formData.userName}
              onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
              error={formDataError.userName && true || false}
              helperText={formDataError.userName && formDataError.userName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              id="coachPassword"
              name="password"
              variant="outlined"
              size="small"
              label="Password"
              fullWidth
              required
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              error={formDataError.password && true || false}
              helperText={formDataError.password && formDataError.password}
            />
            <StrengthIndicator value={passwordTest.score} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              id="coachConfirmPassword"
              variant="outlined"
              size="small"
              name="confirmPassword"
              label="Confirm Password"
              fullWidth
              required
              value={formData.confirmPassword}
              onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
              error={formDataError.confirmPassword && true || false}
              helperText={formDataError.confirmPassword && formDataError.confirmPassword}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              variant="outlined"
              id="coachFirstName"
              name="firstName"
              size="small"
              label="First Name"
              fullWidth
              required
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              error={formDataError.firstName && true || false}
              helperText={formDataError.firstName && formDataError.firstName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              variant="outlined"
              id="coachLastName"
              name="lastName"
              size="small"
              label="Last Name"
              fullWidth
              required
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              error={formDataError.lastName && true || false}
              helperText={formDataError.lastName && formDataError.lastName}
            />
          </Grid>
          <Grid item xs={12} >
            <Autocomplete
              id={`country`}
              size="small"
              disableClearable
              // getOptionSelected={(option, value) => option.numericValue === value.numericValue}
              value={countryList.find(x => x.numericValue === formData.eventCountry)}
              // defaultValue={countryList.find(x => x.numericValue === formData.eventCountry)}
              options={countryList}
              getOptionLabel={(option) => option.displayName ? option.displayName : ""}
              onChange={(event, newValue) => {
                let val = newValue !== null ? newValue.numericValue : 0;
                setFormData({ ...formData, country: val });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label={`Country`}
                  error={formDataError.country && true || false}
                  helperText={formDataError.country}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              type="submit"
              className="capitalize"
              disabled={(isLoading || Object.keys(formDataError).length > 0)}
              startIcon={isLoading && <CircularProgress color="secondary" size={24} />}
            >
              Sign Up
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" align="center">Already have an account.? <Link href={RouteConstants.OFFICIAL_LOGIN} onClick={gotoLogin}><strong>Login</strong></Link></Typography>
          </Grid>
        </Grid>
      </>
      :
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" color="secondary">
              Invalid URL.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="center">Already have an account.? <Link href={RouteConstants.OFFICIAL_LOGIN} onClick={gotoLogin}><strong>Login</strong></Link></Typography>
          </Grid>
        </Grid>
      </>
  )
};

export default withRouter(UserRegistrationComponent);
