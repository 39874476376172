import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

export const partnerService = {
    sendInvite,
    validateInviteToken,
    getEvents,
    shareEvent,
    saveEvent,
    deleteEventMedia,
    getAffiliationNetworks,
    getPartnerOrganization,
    getPartnerUserDetails
}

function sendInvite(payload) {
    return APIHelper.post(APIURLConstants.ORG_MEMBER_SEND_INVITE, payload, authHeader())
        //.then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}

function validateInviteToken(token) {
    return APIHelper.post(APIURLConstants.VALIDATE_INVITE_TOKEN, token)
        // .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getEvents(page, batchSize) {
    let data = {
        PageIndex: page || 1,
        PageSize: batchSize || 6
    };
    return APIHelper.post(APIURLConstants.ORG_MEMBER_GET_EVENTS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function shareEvent(data) {
    return APIHelper.post(APIURLConstants.ORG_MEMBER_YAO_SHARE_EVENT, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveEvent(data) {
    return APIHelper.post(APIURLConstants.ORG_MEMBER_YAO_SAVE_EVENT, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function deleteEventMedia(data) {
    return APIHelper.post(APIURLConstants.ORG_MEMBER_YAO_DELETE_EVENT_MEDIA, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getAffiliationNetworks() {
    return APIHelper.get(APIURLConstants.GET_AFFILIATION_NETWORK, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getPartnerOrganization(affiliationId) {
    let postdata = { "afflicationNetworkId": affiliationId }
    return APIHelper.post(APIURLConstants.GET_PARTNER_ORGANIZATION, postdata, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getPartnerUserDetails() {
    return APIHelper.get(APIURLConstants.GET_PERTNER_USER_DETAILS, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}