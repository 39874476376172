import { LogLevel } from '@azure/msal-browser';

import AzureConstant from './authConfigConstants';

let CLIENT_ID = AzureConstant.PROD_CLIENT;
let TENANT_ID = AzureConstant.TENANT_ID;

if (window.location.href.includes(AzureConstant.LOCAL_APP)) {
    CLIENT_ID = AzureConstant.DEV_CLIENT;
}
if (window.location.href.includes(AzureConstant.DEV_APP)) {
    CLIENT_ID = AzureConstant.DEV_CLIENT;
}
if (window.location.href.includes(AzureConstant.QA_APP)) {
    CLIENT_ID = AzureConstant.QA_CLIENT;
}

if (window.location.href.includes(AzureConstant.PROD_APP)) {
    CLIENT_ID = AzureConstant.PROD_CLIENT;
}
export const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        authority:
            `https://login.microsoftonline.com/${TENANT_ID}/`,
        redirectUri: `/signin-oidc`,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: [`api://${CLIENT_ID}/User.Login`],
};
