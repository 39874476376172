import React, { useState, useEffect, useContext } from "react";
import { generatePath, withRouter } from "react-router-dom";
import { CircularProgress, makeStyles, Typography, TextField, Button, Grid, Link } from "@material-ui/core";
import RouteConstants, { DefaultRoutes } from "../../../common/RouteConstants";
import { authenticationService, userService, referenceDataService } from "../../../services";
import Validations from "../../../common/Validations";
import AppDataContext from "../../../common/AppContext";
//import { Role } from "../../../helper";
//import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../config/authConfig';
import Helper from "../../../common/Helper";
import APIHelper from "../../../common/ApiHelper";
import APIURLConstants from "../../../common/ApiURLConstants";
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { BehaviorSubject } from 'rxjs';
import { AbilityContext } from '../../../common/Can'
import { defineRulesfor, defineDefaultRules } from "../../../common/Permission";
import jwt_decode from 'jwt-decode'

const useStyles = makeStyles((theme) => ({
}));

const LoginComponent = (props) => {
    // debugger;
    const classes = useStyles();
    const ability = useContext(AbilityContext)
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const redirectTo = new URLSearchParams(props.location.search).get("redirectTo");
    const { addSnackMessages, setUser, fetchDefaultData, users, setMasterRoles } = useContext(AppDataContext);
    const [formData, setFormData] = useState({ userName: "", password: "" });
    const [formDataError, setFormDataError] = useState([]);
    const [ssoLoading, setSSOLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(formDataError).length) isFormDataValid();
    }, [formData]);

    function returnToRedirect(user) {
        if ((props.location.state && props.location.state.redirectTo) || redirectTo) {
            const redirectPath = (props.location.state && props.location.state.redirectTo) ? props.location.state.redirectTo : redirectTo;
            props.history.push({
                pathname: redirectPath
            });
        }
        else if (userService.getUserRole() === 'Admin' || userService.getUserRole() === 'Scout' || userService.getUserRole() === 'AdminScout') {
            props.history.push({
                pathname: DefaultRoutes.ADMIN
            })
        }
        else if (userService.getUserRole() === 'Partner') {
            props.history.push({
                pathname: DefaultRoutes.PARTNER
            })
        }
        else if (userService.getUserRole() === 'User') {
            props.history.push({
                pathname: DefaultRoutes.PROSPECT
            });
        }else if(userService.getUserRole() === 'EventAdmin'){
            props.history.push({
                pathname: DefaultRoutes.EVENTADMIN
            });
        }else {
            props.history.push({
                pathname: '/'
            });
        }
        return;
    }

    const isFormDataValid = () => {
        let errors = [];
        Object.entries(formData).forEach(([key, value]) => {
            let field = false;
            switch (key) {
                case "userName":
                    if (value.includes('@')) {
                        field = Validations.Validate("Email", value.trim(), { Required: true, Email: true });
                    }
                    else {
                        field = Validations.Validate("Username / Email", value.trim(), { Required: true });
                    }
                    break;
                case "password":
                    field = Validations.Validate("Password", value.trim(), { Required: true });
                    break;
            }
            if (field && !field.isValid) {
                errors[key] = field.validationMessage[0].toUpperCase() + field.validationMessage.slice(1);
            }
        });

        if (Object.keys(errors).length) {
            setFormDataError(errors);
            return false;
        }
        setFormDataError([]);
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!isFormDataValid()) {
            setIsLoading(false);
            return false;
        }
        localStorage.clear();
        authenticationService.login(formData.userName, formData.password)
            .then(async (res) => {

                if (res.isAuthenticated) {
                   
                    let user = { ...res };
                    let roles = res.roles;
                    delete user.roles
                    Helper.setStorage('accessToken', user.token);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    Helper.setStorage('chk', 'jr');
                   
                    setUser(user);
                    let decoded = jwt_decode(res.token)
                    let permissions = await authenticationService.getPermissionByRole(decoded.roleid)
                    let rules = defineRulesfor(permissions)
                    //console.log(rules);
                    ability.update(rules)
                    //debugger
                    //setIsLoading(false);
                    setMasterRoles(roles)
                    addSnackMessages([{
                        open: true,
                        message: `Welcome back, ${user.firstName} ${user.lastName}`,
                        severity: 'success'
                    }], true);
                    if (!user.isEmailVerified) {
                        props.history.push(RouteConstants.EMAIL_VERIFY);
                    }
                    else {
                        //setTimeout(() => {
                            returnToRedirect(user);
                        //}, 1000);
                      
                    }
                }
                else {
                    addSnackMessages([{
                        open: true,
                        message: "Incorrect credentials. Please try again.",
                        severity: 'error'
                    }]);
                }
            }).catch(e => {
                
                setFormDataError(e.errors);
                addSnackMessages([{
                    open: true,
                    message: e.message,
                    severity: 'error'
                }]);
            }
            ).finally(()=>{
                setIsLoading(false);
            })
    }
    const handleLogin = () => {
        setSSOLoading(true);
        instance
            .handleRedirectPromise()
            .then(async (tokenResponse) => {
                if (!tokenResponse) {
                    const accounts = instance.getAllAccounts();
                    if (accounts.length === 0) {
                        // No user signed in
                        let check = await instance.loginPopup(loginRequest);
                        if (check.accessToken) {
                            //Helper.setStorage('accessToken', check.accessToken);
                            Helper.setStorage('chk', 'aad');
                            //props.setLogin(true);
                            APIHelper.get(APIURLConstants.GET_USER_DETAILS, { Authorization: `Bearer ${check.accessToken}` })
                                .then(res => res.data)
                                .then(async res => {
                                    if (res !== null) {
                                        let user = { ...res }
                                        let roles = res.roles;
                                        delete user.roles;
                                        const currentUserSubject = new BehaviorSubject();
                                        currentUserSubject.next(user);
                                        localStorage.setItem('currentUser', JSON.stringify(user));
                                        Helper.setStorage('accessToken', user.token);
                                        setSSOLoading(false)
                                        setMasterRoles(roles);
                                        let decoded = jwt_decode(res.token)
                                        let permissions = await authenticationService.getPermissionByRole(decoded.roleid)
                                        let rules = defineRulesfor(permissions)
                                        //console.log(rules);
                                        ability.update(rules)
                                        setUser(res)
                                        fetchDefaultData(res)
                                        addSnackMessages([{
                                            open: true,
                                            message: `Welcome back, ${user.firstName} ${user.lastName}`,
                                            severity: 'success'
                                        }], true);
                                        //setTimeout(() => {
                                            returnToRedirect(user);
                                        //}, 1000);
                                        console.log(res)

                                    } else {
                                        props.history.push(RouteConstants.UNAUTHORIZED)
                                        setSSOLoading(false)
                                    }
                                })
                                .catch(err => {
                                    setSSOLoading(false)
                                    console.log(err)
                                    if (err.response.status === 401) {
                                        props.history.push(RouteConstants.UNAUTHORIZED)
                                    }
                                })
                            console.log('success')
                        }
                    }
                } else {
                    // Do something with the tokenResponse
                    Helper.setStorage('chk', 'aad');
                    console.log('failure')
                    setSSOLoading(false);
                    //props.setLogin(false);
                }
            })
            .catch((err) => {
                // Handle error
                setSSOLoading(false)
                console.error(err);
            });
        // eslint-disable-next-line no-debugger
        // debugger
        // location.href = "/sso";
        //props.history.replace("/sso");
    };

    return (
        <>
            <Grid container spacing={2} component="form" className={classes.root} noValidate onSubmit={(e) => handleSubmit(e)}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" color="secondary">
                        Login
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        id="username-or-email"
                        size="small"
                        label="Username Or Email"
                        value={formData.userName}
                        name="userName"
                        onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
                        fullWidth
                        error={formDataError.userName && true || false}
                        helperText={formDataError.userName && formDataError.userName}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="password"
                        variant="outlined"
                        id="password"
                        size="small"
                        label="Password"
                        value={formData.password}
                        name="password"
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        fullWidth
                        error={(formDataError.password && true) || false}
                        helperText={formDataError.password && formDataError.password}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        className="capitalize"
                        disabled={(isLoading || ssoLoading || Object.keys(formDataError).length > 0)}
                        startIcon={isLoading && <CircularProgress color="secondary" size={24} />}
                    >
                        Login
                    </Button>
                </Grid>


                <Grid item xs>
                    <Typography variant="body2" align="left"><Link href={RouteConstants.FORGOT_PASSWORD} onClick={(e) => {
                        e.preventDefault();
                        props.history.push(RouteConstants.FORGOT_PASSWORD);
                    }}><strong>Forgot Password?</strong></Link></Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body2" align="right">New User? <Link href={RouteConstants.OFFICIAL_REGISTRATION} onClick={(e) => {
                        e.preventDefault();
                        props.history.push(RouteConstants.OFFICIAL_REGISTRATION);
                    }}><strong>Sign Up</strong></Link></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={() => handleLogin()}
                        // type="submit"
                        className="capitalize"
                        disabled={(ssoLoading || isLoading)}
                        startIcon={ssoLoading && <CircularProgress color="secondary" size={24} />}
                    >
                        SSO
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default withRouter(LoginComponent);