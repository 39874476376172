import React from 'react'
import './Style.css';
import {makeStyles} from '@material-ui/core/styles'
import { Box, Grid, Typography, Divider } from '@material-ui/core';

const useStyles=makeStyles(theme=>({
    root:{
        backgroundColor:'#ffffff'

    },
    refereePath:{
        fontSize:'2rem'
    },
    positionName:{
        fontSize:21,
        fontWeight:700,
        color:'#000000'
    },
    toggleTitle:{
        display:'flex',
        backgroundColor:'#124d80',
        color:'#ffffff',
        padding:'6px',
        lineHeight:1.4,
    },
    flatToggle:{
        border:'1px solid #cccccc',
        marginBottom:'10px'
    },
    toggleContent:{
        textAlign:'center',
        padding:'25px'
    }
}))
function HomePage() {
    const classes=useStyles();
    return (
        <div className={classes.root}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome">
                            <div className="pageHeadLine pull-left">Welcome to NBA Officials.com</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container marg25">
              <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>The NBA and WNBA are two of the world's premier sports and entertainment enterprises; a
                                diverse group of high performing individuals delivering joy and excitement to hundreds
                                of millions of basketball fans all over the world. It should be no surprise that much of
                                our success depends on the Referees who ensure the integrity of the game and a forum
                                where player's skills, team dynamics, passion and dedication determine game outcomes.
                            </p>
                            <p>It's our goal to recruit referees from around the globe to become part of our team, but
                                also to provide those who want to pursue this exciting career with insight into how they
                                can develop their skills to achieve professional-level officiating excellence.</p>
                            <p>Therefore the purpose of this website is two fold.</p>

                            <ol className="text-bold">
                                <li>We want you to better understand the officiating experience that will best instill
                                    knowledge and skills you need to be a professional referee.</li>
                                <li>We want to learn more about you and where you currently stand against those
                                    experiences.</li>
                            </ol>
                            <p>A solid officiating foundation and experience are necessary tools that help navigate the
                                path to becoming a professional NBA or WNBA referee. To this end, we have mapped out the
                                path of officiating experience that, combined with some innate skill and the dedication
                                to succeed, will provide a candidate with the best opportunity to move and advance
                                through the development and training process.</p>
                        </div>

                    </div>
                </div>
                <div className="col-lg-4 positionDivider">
                    <div className="row">
                        <div className="col-xs-5" style={{padding:'10px 10px 10px 20px'}}><img alt=""
                                src="/images/headshot_AdamSilver_FPO.png"/></div>
                        <div className="col-xs-7" style={{padding:"10px"}}>
                            <img alt="Adam Silver" src="/images/logo_nba.png" />
                            <p>
                                <span className="positionName">Adam Silver</span><br/>
                                <span className="positionHead">Commissioner - NBA</span>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-5" style={{padding:'10px 10px 10px 20px'}}><img alt=""
                                src="/images/Cathy_Engelbert.jpg"/></div>
                        <div className="col-xs-7" style={{padding:"10px"}}>
                            <img alt="Mark Tatum" src="/images/logo_wnba.png"/>
                            <p>
                                <span className="positionName">Cathy Engelbert</span><br/>
                                <span className="positionHead">Commissioner - WNBA</span>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-5" style={{padding:'10px 10px 10px 20px'}}><img alt=""
                                src="/images/headshot_ShareefAbdur-Rahim_FPO.png"/></div>
                        <div className="col-xs-7" style={{padding:"10px"}}>
                            <img alt="Malcolm Turner" src="/images/logo_d-league.png"/>
                            <p>
                                <span className="positionName">
                                    Shareef Abdur-Rahim
                                </span><br/>
                                <span className="positionHead"> President - NBA G League</span>
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome">
                            <div className="pageHeadLine pull-left">Path to Officiating in the NBA, WNBA and G League</div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="flat-accordion marg25">
                            <div className="flat-toggle">
                                <div className="toggle-title active"><span className="badgeHome">1</span><span
                                        className="titleText">START HERE</span> <span className="dateText">4/1 - 8/31</span></div>
                                <div className="toggle-content" style={{display: 'block'}}>
                                    <p className="text-center">We review candidates based on expertise of officiating in live
                                        events. Prospective officials should contact any of these organizations to begin
                                        their professional journey. Candidates are evaluated by the NBA Scouting Group</p>
                                    <p className="text-center hidden">You will need to demonstrate experience in officiating.
                                        Potential candidates are scouted from basketball leagues, programs and
                                        organizations.</p>
                                    <div className="text-center">
                                        <img alt="NBA" src="/images/StartHere_desktop.png"
                                            className="img-responsive"/>
                                    </div>
                                </div>
                            </div>
                            <div className="flat-toggle">
                                <div className="toggle-title active"><span className="badgeHome">2</span><span
                                        className="titleText">SCOUTING</span> <span className="dateText">10/2 - 3/31</span></div>
                                <div className="toggle-content" style={{display: 'block'}}>
                                    <p className="text-center">NBA Scouting Group determine the Top 100 candidates list for
                                        evaluation events and proceed to the next phase.</p>
                                    <div className="text-center">
                                        <img alt="NBA" src="/images/Scouting_desktop.png"
                                            className="img-responsive"/>
                                    </div>
                                </div>
                            </div>

                            <div className="flat-toggle">
                                <div className="toggle-title active"><span className="badgeHome">3</span><span
                                        className="titleText">TRAINING AND EVALUATION</span> <span className="dateText">4/15 -
                                        7/15</span></div>
                                <div className="toggle-content" style={{display: 'block'}}>
                                    <div className="row">
                                        <div className="col-lg-offset-1  col-lg-6">
                                            <p className="text-center">NBA Referee Operations Management Team uses the <a>5
                                                    performance standards</a> for advancement to the next phase.</p>
                                            <p>Some candidates that have been identified but do not advance remain on a
                                                monitoring list for the following year.</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="text-center">
                                                <img alt="NBA"
                                                    src="/images/TrainingAndEvaluation_desktop-Right.png"
                                                    className="img-responsive"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-center">
                                                <img alt="NBA"
                                                    src="/images/TrainingAndEvaluation_desktop-Base.png"
                                                    className="img-responsive"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flat-toggle">
                                <div className="toggle-title active"><span className="badgeHome">4</span><span
                                        className="titleText">HIRING G LEAGUE</span> <span className="dateText">6/15</span></div>
                                <div className="toggle-content" style={{display: 'block'}}>
                                    <p className="text-center">All G League new hires participate in Summer League Training
                                        and/or an additional New Hire Orientation Session.</p>
                                    <div className="text-center">
                                        <img alt="NBA"
                                            src="/images/HiringD-League_desktop.png"
                                            className="img-responsive"/>
                                    </div>
                                </div>
                            </div>
                            <div className="flat-toggle">
                                <div className="toggle-title active"><span className="badgeHome">5</span><span
                                        className="titleText">DEVELOPMENT</span> <span className="dateText">11/1 - 4/30</span></div>
                                <div className="toggle-content" style={{display: 'block'}}>
                                    <p className="text-center">G League referees attend Referee Preseason Meeting, work games
                                        (preseason - playoffs). NBA Officials Interactive Website memos and testing. Game
                                        Observations and management team interactions</p>
                                    <div className="text-center">
                                        <img alt="NBA" src="/images/Development_desktop.png"
                                            className="img-responsive"/>
                                    </div>
                                </div>
                            </div>
                            <div className="flat-toggle">
                                <div className="toggle-title active"><span className="badgeHome">6</span><span
                                        className="titleText">HIRING NBA &amp; WNBA</span> <span className="dateText">9/15</span>
                                </div>
                                <div className="toggle-content" style={{display: 'block'}}>
                                    <p className="text-center">G League referees are evaluated and recommended for hire into the
                                        NBA and WNBA.</p>
                                    <div className="text-center">
                                        <img alt="NBA"
                                            src="/images/HiringNBA-WNBA_desktop.png"
                                            className="img-responsive"/>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

            <div className="footer footer-bg">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-12 margb60">
                            <p>As you move through the process, you add experience Officiating faster, more skillful and
                                more sophisticated basketball games and learn more advanced skills. When invited to
                                participate in NBA Summer Leagues and/or the NBA G League, you receive direct instruction
                                from the NBA referee staff to help you master your skills.</p>
                            <p>With that in mind, please take the time to complete the questionnaire linked below. Please be
                                thorough in your responses. Not that this is not an application for employment, we want to
                                know more about your experience.</p>
                            <p>Thank you in advance for taking the time to complete this. We look forward to learning more
                                about you and, hopefully, seeing more of you in the years to come.</p>
                            <p>To get started, please <a id="hypRegister" data-target="#regmodal" href="/signup"
                                    data-toggle="modal">register</a> if you are first-time user or <a id="hypLoginIn"
                                    href="/login">log in</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-12 margb60">
                            <p> Please note that neither a completed questionnaire nor any information solicited or provided by the NBA in
                             connection with nbaofficials.com is -- or should be construed to be -- any of the following: an application 
                             for employment with the NBA or any of its affiliated entities; an offer, promise, commitment, or guarantee of employment 
                             with the NBA or any of its affiliated entities at any, or for any period of, time; or an offer or promise by the NBA 
                             or any of its affiliated entities to provide information, services, instruction, or any other thing of value at any time. 
                             By submitting a questionnaire, you accept and acknowledge your agreement with the foregoing terms.
                             </p>
                            <p> If you have any questions <a id="hypRegister" data-target="#regmodal" href="/contact-us"
                                    data-toggle="modal">click here</a> to contact a representative.</p>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage
