import React from 'react';
import { Grid, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    userCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '320px',
        '& .MuiPaper-root': {
            paddingTop: '20px',
            width: '280px',
            height: '290px',
            borderRadius: '4px 4px 50% 50%',
            textAlign: 'center',
            boxShadow: '0px 0px 15px #000000',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    userDetails: {
        justifyContent: 'center'
    },
    userInfo: {
        flex: '0 1 auto'
    },
    userImage: {
        width: 120,
        height: 120,
        borderRadius: '50%',
        border: '3px solid #FFFFFF',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))'
    },
    userOrgLogo: {
        minWidth: 'unset',
        marginRight: 16,
        '& img': {
            maxWidth: 48,
            maxHeight: 58,
        }
    },
    inline: {
        display: 'inline',
    },
}));

const UserCardsSection = ({ items }) => {
    const classes = useStyles();

    return items.map((item, index) =>
        <Grid item xs className={classes.userCard} key={index}>
            <Paper>
                <img src={item.image._url} className={classes.userImage} />
                <ListItem alignItems="flex-start" className={classes.userDetails} component={'div'}>
                    {item.organizationLogo && <ListItemAvatar className={classes.userOrgLogo}>
                        <img src={item.organizationLogo._url} />
                    </ListItemAvatar>}
                    <ListItemText
                        className={classes.userInfo}
                        primary={<Typography variant='h4'>{item.title}</Typography>}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {item.designation}
                                </Typography>
                                {` — ${item.organization}`}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </Paper>
        </Grid>)
}

export default UserCardsSection;