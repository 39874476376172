export default class Validations {

    //... Required Field Validation
    static Required(value) {
        //... Returns true when the value is empty.
        return /^\s*$/.test(value) || /null/.test(value);
    }

    //... Email Format Field Validation
    static Email(value) {
        return /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/.test(value);
    }

    //... UserName Format Validation
    static UserName(value){
        return /^[a-zA-Z0-9_.]+$/g.test(value)
        //return /^[a-zA-Z0-9._]+(?<![_.])$/g.test(value)
    }
    //... USA Zip Code Format (xxxxx or xxxxx-xxxx)
    static USAZip(value) {
        if (value === undefined || value === '')
            return true;

        return /^\d{5}(?:[-]\d{4})?$/.test(value);
    }

    //... Zip Code Format (xxxxxx)
    static Zip(value) {
        if (value === undefined || value === '')
            return true;

        return /^[1-9][0-9]{5}$/.test(value);
    }

    //... Numeric validation
    static Numeric(value) {
        if (value === undefined || value === '')
            return true;

        return /^[0-9]*$/.test(value);
    }

    //... Decimal validation
    static Decimal(value) {
        if (value === undefined || value === '')
            return true;

        return /^(([1-9]\d*)|0)(.0*[1-9](0*[1-9])*)?$/.test(value);
    }

    //... Currency validation (e.g. 100,000,000.00)
    static Currency(value) {
        if (value === undefined || value === '')
             return true;

        return /^([1-9]+\d{0,2}(\,\d{3})*|([1-9]+\d*))(\.\d{2})?$/.test(value);
    }

    static CurrencyWithZeroAllowed(value) {
        if (value === undefined || value === '')
             return true;
      
        return /^([0-9]+\d{0,2}(\,\d{3})*|([1-9]+\d*))(\.\d{2})?$/.test(value);
    }

    //Restrict all special characters Expept '
    static RestrictSpecialCharacters(value) {
        if (value === undefined || value === '')
            return true;
        return /^[a-zA-Z0-9\s]+$/.test(value);
    }

    //Allow only characters
    static AllowOnlyCharacters(value) {
        if (value === undefined || value === '')
            return true;
        return /^[a-zA-Z\s]+$/.test(value);
    }

    static AllowOnlyNumber(value) {
        if (value === undefined || value === '')
            return true;

        return /^[1-9][0-9]$/.test(value);
    }

    //Currency Code e.g $(USD)
    static CurrencyCode(value) {
        if (value === undefined || value === '')
            return true;

        return /\([a-zA-Z]{3}\)$/.test(value);
    }

    //String Length :: Minimum Charachters
    static MinLength(value, limit) {
        if (value === undefined || value === '')
            return true;

        return value.length >= limit;
    }

    //String Length :: Maximum Charachters
    static MaxLength(value, limit) {
        if (value === undefined || value === '')
            return true;

        return value.length <= limit;
    }
    //Numeric :: Minimum Value
    static Min(value, limit) {
        if (value === undefined || value === '')
            return true;

        return value >= limit;
    }

    //Numeric :: Maximum Value
    static Max(value, limit) {
        if (value === undefined || value === '')
            return true;

        return value <= limit;
    }

    //Password Match -- 1 uppercase, 1 lower case, 1 digit
    static Password(value) {
        if (value === undefined || value === '') return true;

        return /^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*\(\)\-\_]{8,50}$/.test(value);
    }

    // URL
    static URL(string) {
        let url;
        return /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(string);
    }

    //... etc.

    static Validate(control, value, rules) {

        let isValid = true;
        let validationResult = { isValid: true, validationMessage: null };

        if (!rules) {
            return validationResult;
        }

        //... Required Field Validation
        if (rules.Required && isValid) {
            isValid = !Validations.Required(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : control + ' ' + ' is required';
        }

        //... Email Format Field Validation
        if (rules.Email && isValid) {
            isValid = Validations.Email(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Email format is incorrect';
        }

        //... UserName Field Validation allow alphanumeric and special charecter ._-
        if (rules.UserName && isValid) {
            isValid = Validations.UserName(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Username can contain alphanumeric and special character . _ and no spaces';
        }

        //... USA Zip Code Format (xxxxx or xxxxx-xxxx)
        if (rules.USAZip && isValid) {
            isValid = Validations.USAZip(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Zip code format is incorrect';
        }

        //... Zip Code Format (xxxxxx)
        if (rules.Zip && isValid) {
            isValid = Validations.Zip(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Zip code format is incorrect';
        }

        //... Numeric validation
        if (rules.Numeric && isValid) {
            isValid = Validations.Numeric(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Only numbers are allowed';
        }

        if (rules.Decimal && isValid) {
            isValid = Validations.Decimal(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Value is incorrect';
        }

        //... Currency validation
        if (rules.Currency && isValid) {
            isValid = Validations.Currency(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Value is incorrect';
        }

        //... Currency validation
        if (rules.CurrencyWithZeroAllowed && isValid) {
            isValid = Validations.CurrencyWithZeroAllowed(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Value is incorrect';
        }

        //... special character validation
        if (rules.RestrictSpecialCharacters && isValid) {
            isValid = Validations.RestrictSpecialCharacters(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Special character is not allowed';
        }

        //... special character validation
        if (rules.AllowOnlyCharacters && isValid) {
            isValid = Validations.AllowOnlyCharacters(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : 'Only characters allowed';
        }

        //... minimum length validation
        if (rules.MinLength && isValid) {
            isValid = Validations.MinLength(value, rules.MinLength);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : control + ` length must be greater than or equal to ${rules.MinLength} characters`;
        }

        //... maximum length validation
        if (rules.MaxLength && isValid) {
            isValid = Validations.MaxLength(value, rules.MaxLength);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : `Maximum ${rules.MaxLength} characters allowed`;
        }

        //... numeric minimum value validation
        if (rules.Min && isValid) {
            isValid = Validations.Min(value, rules.Min);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : `Value should be greater than or equal to ${rules.Min}`;
        }

        //... numeric maximum value validation
        if (rules.Max && isValid) {
            isValid = Validations.Max(value, rules.Max);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : `Value should be less than or equal to ${rules.Max}`;
        }

        //Password
        if (rules.Password && isValid) {
            isValid = Validations.Password(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : control + ` should contain at least one uppercase, one lowercase & one digit without space(s).`;
        }

        // URL
        if (rules.URL && isValid) {
            isValid = Validations.URL(value);
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : control + ' ' + ` is not valid.`;
        }

        //Date if min
        if (rules.IsMinDate && isValid) {
            isValid = new Date(rules.minDate) <= new Date(value)
            validationResult.isValid = isValid;
            validationResult.validationMessage = (isValid) ? null : control + ' ' + ` should be greater than ${rules.minDateControl}.`;
        }

        return validationResult;
    }
}
