import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const scheduleDataService = {
   saveScheduleUpload,
   getScheduleAttachment,
   deleteScheduleAttachment,
   getattachmenturl,
};

function saveScheduleUpload(data) {

    return APIHelper.post(APIURLConstants.SAVE_SCHEDULE_ATTACHMENT,data, authHeader())      
        .then(response => {           
            return response.data;
        }).catch(handleError);
}

function getScheduleAttachment(data) {

    return APIHelper.post(APIURLConstants.GET_SCHEDULE_ATTACHMENTS,data, authHeader())      
        .then(response => {           
            return response.data;
        }).catch(handleError);
}

function deleteScheduleAttachment(data) {

    return APIHelper.post(APIURLConstants.DELETE_SCHEDULE_ATTACHMENT, data, authHeader())      
        .then(response => {           
            return response.data;
        }).catch(handleError);
}

function getattachmenturl(data) {

    return APIHelper.post(APIURLConstants.GET_DYNAMIC_URL_ATTACHMENT, data, authHeader())      
        .then(response => {           
            return response.data;
        }).catch(handleError);
}