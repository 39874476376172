import React, { useContext, useEffect, useState } from 'react';
import { Button, Drawer, IconButton, makeStyles, Typography } from '@material-ui/core';
import GooglePlayStoreLogo from '../images/google-play-badge.png';
import AppleAppStoreLogo from '../images/apple-store-icon.svg';
import AppDataContext from '../common/AppContext';

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: 240,
        borderRadius: '8px 8px 0px 0px'
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 3, 0)
    },
    actionArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(2)
    },
    skipBtn: {
        margin: '8px auto'
    },
    playstorelogo: {
        height: '100%',
        display: 'flex',
        maxWidth: 150,
        '& img': {
            height: '100%',
            width: '100%'
        }
    },
    appstorelogo: {
        display: "flex",
        maxWidth: 150,
        padding: '12px',
        '& img': {
            width: '100%',
            height: '100%'
        }
    },
    header: {
        textAlign: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        borderBottom: '1px solid #ccc',
        padding: '0px 16px',
        alignItems: 'center',
        color: theme.palette.primary.main,
        height: 56,
        // color: '#fff',
        // background: theme.palette.primary.main
    }
}));

const DownloadOurAppPopup = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [deviceType, setDeviceType] = useState(false);
    const { navigationMenu } = useContext(AppDataContext);
    const { appStoreURL, playStoreURL } = navigationMenu;

    useEffect(() => {
        setOpen(false);
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            setDeviceType("mobile");
            setOpen(true);
        }
        // if (/iPhone|iPad|iPod/i.test(navigator.userAgent) && appStoreURL) {
        //     setDeviceType("mobile_ios");
        //     setOpen(true);
        // }
        // else if (/Android/i.test(navigator.userAgent) && playStoreURL) {
        //     setDeviceType("mobile_android");
        //     setOpen(true);
        // } 
        else {
            setDeviceType(false);
        }
    }, []);

    return <Drawer anchor='bottom' open={open} onClose={() => { setOpen(false); }} PaperProps={{
        className: classes.paper
    }} >
        {/* <span className={classes.header}>
            <Typography variant='h4'>Download Our App</Typography>
            <IconButton color='inherit' size='small' onClick={() => { setOpen(false); }}>
                <CloseIcon />
            </IconButton>
        </span> */}
        <div className={classes.content}>
            <Typography variant='h6' component='p'>For quick and better experience download our app.</Typography>
            <div className={classes.actionArea}>
                {appStoreURL &&
                    <a href={appStoreURL} className={classes.appstorelogo}>
                        <img src={AppleAppStoreLogo} alt="Download on the App Store" />
                    </a>
                }
                {playStoreURL &&
                    <a href={playStoreURL} className={classes.playstorelogo}><img src={GooglePlayStoreLogo} /></a>
                }
            </div>
            <Button component='a' href="#" color="primary" className={classes.skipBtn} onClick={(e) => { e.preventDefault(); setOpen(false); }}>
                No, Thanks
            </Button>
        </div>

    </Drawer >
}

export default DownloadOurAppPopup;