import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

export const ContactUsFeedbackService = {
    submitFeedback,
    getFaqs
};

function submitFeedback(payload) {
    return APIHelper.post(APIURLConstants.CONTACTUS_SUBMIT, payload, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getFaqs() {
    return APIHelper.get(APIURLConstants.GET_FAQS, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}