import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import ChangePasswordFormComponent from '../../prospectProfile/components/edit/components/ChangePasswordFormComponent';

const useStyles = makeStyles((theme) => ({

}));

const ForceChangePasswordComponent = () => {
    const classes = useStyles();

    return <>
        <Grid container spacing={2} component="section">
            <Grid item xs={12}>
                <Typography variant="h4" align="center" color="secondary">
                    Change Password
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ChangePasswordFormComponent />
            </Grid>
        </Grid>
    </>
}

export default ForceChangePasswordComponent;