import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, TextField, Typography, Button, Grid, Link, CircularProgress } from "@material-ui/core";
import zxcvbn from "zxcvbn";
import RouteConstants from "../../../common/RouteConstants";
import Validations from "../../../common/Validations";
import AppDataContext from "../../../common/AppContext";
import { userService } from "../../../services";
import SectionLoader from '../../../sharedComponents/Loader/SectionLoader';
import PageNotFoundUI from '../../../sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import { partnerService } from "../../../services/partners.service";
import Helper from "../../../common/Helper";
import StrengthIndicator from "../../../sharedComponents/indicators/StrengthIndicator";
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  logoStrip: {
    display: 'flex',
    // flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '100%',
    height: '150px',
    // paddingTop: '7%'
  },
  logo: {
    [theme.breakpoints.up("sm")]: {
      width: "425px",
      height: "170px",
    },
    [theme.breakpoints.down("md")]: {
      height: '115px',
      width: '240px'
    },
  },
  registrationBox: {
    width: "340px",
    position: "fixed",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.up("lg")]: {
      right: "10%",
      alignSelf: "flex-end",
      height: "570px",
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: "786px",
      width: "280px",
      position: 'static',
      margin: theme.spacing(2),
    },
  },
  sectiondivider: {
    margin: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
    padding: "5px 25px",
  },
  passwordRule: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: "#fff",
  },
  sectionPopover: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  popoverInnerSection: {
    '&::before': {
      position: 'absolute',
      display: 'block',
      content: "''",
      borderColor: 'transparent',
      borderStyle: 'solid',
      top: '0',
      right: '10px',
      borderWidth: '0 0.5rem 0.5rem 0.5rem',
      borderBottomColor: 'rgba(0, 0, 0, 0.25)',
    },
    '&::after': {
      position: 'absolute',
      display: 'block',
      content: "''",
      borderColor: 'transparent',
      borderStyle: 'solid',
      top: '1px',
      right: '10px',
      borderWidth: '0 0.5rem 0.5rem 0.5rem',
      borderBottomColor: '#fff',
    },
    background: '#fff !important',
    boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(1),
    '& p': {
      color: '#c70e2d',
      fontSize: '.75rem',
      lineHeight: '1',
      padding: theme.spacing(.3, 0),
    },
  },
  nbaLogo: {
    width: 'auto',
    height: '90px',
    paddingLeft: '6%',
    paddingRight: '2.5rem',
  },
  userMeta: {
    background: '#112751',
    color: '#fff',
    justifyContent: 'space-between',
    padding: '8px 16px !important',
    borderRadius: 4,
    margin: '0 8px 8px',
  }
}));

const OrgMemberRegistrationComponent = ({ token, ...props }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const { addSnackMessages, countryList } = useContext(AppDataContext);

  const [formData, setFormData] = useState({
    "firstName": "",
    "lastName": "",
    "email": "",
    "confirmEmail": "",
    "userName": "",
    "password": "",
    "confirmPassword": "",
    "token": token,
    "role": "Partner",
    "country": 0
  });
  const [formDataError, setFormDataError] = useState([]);

  useEffect(() => {
    if (Object.keys(formDataError).length) isFormDataValid();
  }, [formData]);

  useEffect(() => {
    if (token) {
      getTokenData();
    }
  }, [token]);

  const getTokenData = () => {
    setIsLoading(true);

    partnerService.validateInviteToken(token).then(res => {
      const { data } = res;
      if (!data) {
        setTokenInvalid(true);
      }
      else {
        setFormData({
          ...formData,
          email: data.emailAddress,
          organization: data.organizationName
        });
      }
      setIsLoading(false);
    }).catch((e) => {
      setIsLoading(false);
      addSnackMessages([{
        open: true,
        message: "Something went wrong!",
        severity: "error"
      }]);
    });
  }

  const isFormDataValid = () => {
    let errors = [];
    Object.entries(formData).forEach(([key, value]) => {
      let field = false;
      switch (key) {
        case "firstName":
          field = Validations.Validate("First Name", value.trim(), { Required: true, AllowOnlyCharacters: true });
          break;
        case "lastName":
          field = Validations.Validate("Last Name", value.trim(), { Required: true, AllowOnlyCharacters: true });
          break;
        case "email":
          field = Validations.Validate("Email", value.trim(), { Required: true, Email: true });
          break;
        case "password":
          field = Validations.Validate("Password", value.trim(), { Required: true, MinLength: 8, MaxLength: 16, Password: true });
          break;
        case "userName":
          field = Validations.Validate("Username", value.trim(), { Required: true, UserName:true,MaxLength:25, MinLength:5 });
          break;
        case "country":
          field = Validations.Validate("Country", value, { Min: 1 });
          if (field && !field.isValid) {
            field.validationMessage = "Country is required."
          }
          break;
      }

      if (field && !field.isValid) {
        errors[key] = field.validationMessage;
      }
    });

    // Password & Confirm Password equal check
    if (!errors.password && !errors.confirmPassword && formData.password !== formData.confirmPassword) {
      errors['confirmPassword'] = "Password & Confirm Password should be same.";
    }
    // Email & Confirm Email equal check
    if (!errors.email && !errors.confirmEmail && formData.email !== formData.confirmEmail) {
      errors['confirmEmail'] = "Email & Confirm Email should be same.";
    }

    if (formData.role !== "Partner") {
      addSnackMessages([{
        open: true,
        severity: "error",
        message: "Something went wrong! Please refresh page."
      }]);
      return false;
    }

    if (Object.keys(errors).length) {
      setFormDataError(errors);
      return false;
    }
    setFormDataError([]);
    return true;
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitting(true);
    if (!isFormDataValid()) {
      setIsFormSubmitting(false);
      return false;
    }
    userService.registerUser(formData)
      .then(res => {
        if (res && res.data) {
          addSnackMessages([{
            open: true,
            severity: "success",
            message: "Profile created successfully."
          }]);
          setTimeout(() => {
            gotoLogin();
          }, 3000);
          setIsFormSubmitting(false);
        } else {
          addSnackMessages([{
            open: true,
            severity: "error",
            message: res.errorMessage && res.errorMessage || "Something went wrong"
          }]);
          setIsFormSubmitting(false);
        }
      }).catch(e => {
        setFormDataError(e.errors);
        setIsFormSubmitting(false);
        addSnackMessages([{
          open: true,
          severity: "error",
          message: e.message && e.message || "Something went wrong. Please try again later."
        }]);
      })
  }

  const passwordTest = zxcvbn(formData.password);

  const gotoLogin = (e) => {
    if (e) e.preventDefault();
    props.history.push(RouteConstants.OFFICIAL_LOGIN);
  }
  return (
    <>
      <Grid container spacing={2} component="form" noValidate onSubmit={(e) => handleFormSubmit(e)}>
        {isLoading ?
          <SectionLoader />
          : (tokenInvalid) ? <PageNotFoundUI
            title="Oops! Link Invalid or Expired"
            titleProps={{
              color: "secondary"
            }}
            subtitle="Please contact administrator to get new invitation link." /> : <>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" color="secondary">Sign Up</Typography>
              <Typography variant="subtitle2" align="center">For Partners</Typography>
            </Grid>
            <Grid item xs={12} className={classes.userMeta}>
              <Typography variant="body2">Email: <strong>{Helper.maskEmail(formData.email)}</strong></Typography>
              <Typography variant="body2">Organization: <strong>{formData.organization}</strong></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="confirmEmail"
                id="confirmEmail"
                size="small"
                label="Confirm Email"
                fullWidth
                required
                value={formData.confirmEmail}
                onChange={(e) => setFormData({ ...formData, confirmEmail: e.target.value })}
                error={formDataError.confirmEmail && true || false}
                helperText={formDataError.confirmEmail && formDataError.confirmEmail}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                id="coachFirstName"
                name="firstName"
                size="small"
                label="First Name"
                fullWidth
                required
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                error={formDataError.firstName && true || false}
                helperText={formDataError.firstName && formDataError.firstName}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                id="coachLastName"
                name="lastName"
                size="small"
                label="Last Name"
                fullWidth
                required
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                error={formDataError.lastName && true || false}
                helperText={formDataError.lastName && formDataError.lastName}
              />
            </Grid>
            <Grid item xs={12} >
              <Autocomplete
                id={`country`}
                size="small"
                disableClearable
                // getOptionSelected={(option, value) => option.numericValue === value.numericValue}
                value={countryList.find(x => x.numericValue === formData.eventCountry)}
                // defaultValue={countryList.find(x => x.numericValue === formData.eventCountry)}
                options={countryList}
                getOptionLabel={(option) => option.displayName ? option.displayName : ""}
                onChange={(event, newValue) => {
                  let val = newValue !== null ? newValue.numericValue : 0;
                  setFormData({ ...formData, country: val });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    label={`Country`}
                    error={formDataError.country && true || false}
                    helperText={formDataError.country}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="userName"
                id="userName"
                size="small"
                label="Username"
                fullWidth
                required
                value={formData.userName}
                onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
                error={formDataError.userName && true || false}
                helperText={formDataError.userName && formDataError.userName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                id="coachPassword"
                name="password"
                variant="outlined"
                size="small"
                label="Password"
                fullWidth
                required
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                error={formDataError.password && true || false}
                helperText={formDataError.password && formDataError.password}
              />
              <StrengthIndicator value={passwordTest.score} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                id="coachConfirmPassword"
                variant="outlined"
                size="small"
                name="confirmPassword"
                label="Confirm Password"
                fullWidth
                required
                value={formData.confirmPassword}
                onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                error={formDataError.confirmPassword && true || false}
                helperText={formDataError.confirmPassword && formDataError.confirmPassword}
              />
            </Grid>


            <Grid item xs={12}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
                className="capitalize"
                disabled={(isFormSubmitting || Object.keys(formDataError).length > 0)}
                startIcon={isFormSubmitting && <CircularProgress color="secondary" size={24} />}
              >
                Sign Up
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" align="center">Already have an account? <Link href={RouteConstants.OFFICIAL_LOGIN} onClick={gotoLogin}><strong>Login</strong></Link></Typography>
            </Grid>
          </>}
      </Grid>
    </>
  );
};

export default withRouter(OrgMemberRegistrationComponent);
