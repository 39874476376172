import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import PageTitle from '../../../sharedComponents/PageTitle/PageTitle';
import MediaTextBlock from '../sections/MediaTextBlock';
import QuoteSection from '../sections/QuoteSection';
import UserCardsSection from '../sections/UserCardsSection';
import FeatureBoxSection from '../sections/FeatureBoxSection';
import VideoOrMediaSection from '../sections/VideoOrMediaSection';
import Validations from '../../../common/Validations';
import { ContactUsFeedbackService } from '../../../services/contactus.service';
import AppDataContext from '../../../common/AppContext';
import Helper from '../../../common/Helper';
import { userService } from '../../../services';
import ReCAPTCHA from "react-google-recaptcha";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import FaqIcon from '../../../sharedComponents/customIcons/FaqIcon';



const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    bgDark: {
        background: '#112751'
    },
    bgLight: {
        background: '#6B8DCE'
    },
    quoteSection: {
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(7),
    },
    sectiondivider: {
        margin: theme.spacing(8),
    },
    expansionPanel: {
        border: '1px solid #D3D3D3',
        borderRadius: '8px',
        margin: theme.spacing(1),
    },
    expansionPanelSummary: {
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            padding: '0 16px 0 16px'
        },
    },
    expansionPanelDetails: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1)
        },
    },
}));

const ContactUsTemplate = ({ content }) => {
    const classes = useStyles();
    const { addSnackMessages, user, messageCenterCategoryList, sitekey, faqs } = useContext(AppDataContext);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        message: "",
        categoryId: 0,
        categoryName: '',
        captchaResponse: ''

    });
    const [formDataError, setFormDataError] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    //  const [sitekey, setSitekey] = useState("");
    const [isHuman, setIsHuman] = useState(false);
    let FAQHeader = 'FREQUENTLY ASKED QUESTIONS'

    let faqs1 = [
        {
            key: 'Is my username my email address?',
            val: ['No, your username is the separate identifier you used to first create your account']
        },
        {
            key: 'What if I forget my password?',
            val: ['Simply select the “forgot password” option on nbacoachesdatabase.com; you will receive an email with a link to reset your password']
        },
        {
            key: 'Do I have to complete 100% of my profile in order to submit it?',
            val: ['No, but we strongly encourage coaches to answer every question']
        },
        {
            key: 'How long does it take to create and submit a profile?',
            val: ['Time varies depending on the information you include; average time to finish is 30-40 minutes']
        },
        {
            key: 'Am I able to update my profile after my original submission?',
            val: ['Yes, and we strongly encourage coaches to update their information regularly (e.g. every three months) to ensure all key accomplishments and work experiences are up to date for front office users']
        },
    ]


    useEffect(() => {
        setIsUserLoggedIn(Helper.isUserLoggedIn())
    }, []);


    useEffect(() => {
        if (messageCenterCategoryList && messageCenterCategoryList.length > 0) {
            let defaultCategoryID = messageCenterCategoryList.find(cat => cat.displayName === 'General Questions')
            console.log(defaultCategoryID)
            if (defaultCategoryID) {
                setFormData({ ...formData, categoryId: defaultCategoryID.categoryID, categoryName: defaultCategoryID.displayName })
            }
        }
    }, [messageCenterCategoryList]);

    useEffect(() => {
        if (user) {
            setFormData({ ...formData, firstName: user.firstName, lastName: user.lastName, email: user.email })
        }

    }, [user]);

    useEffect(() => {
        if (Object.keys(formDataError).length) isFormDataValid();
    }, [formData]);

    const [expanded, setExpanded] = React.useState();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const verifyCallback = (response) => {
        if (response) {
            setFormData({ ...formData, captchaResponse: response })
            setIsHuman(true);
        } else {
            setFormData({ ...formData, captchaResponse: '' })
            setIsHuman(false);
        }
    };

    const onLoadCallback = () => {
        setIsHuman(false);
    };

    const isFormDataValid = () => {
        let errors = [];
        Object.entries(formData).forEach(([key, value]) => {
            let field = false;
            switch (key) {
                case "firstName":
                    field = Validations.Validate("First Name", value.trim(), { Required: true, AllowOnlyCharacters: true });
                    break;
                case "lastName":
                    field = Validations.Validate("Last Name", value.trim(), { Required: true, AllowOnlyCharacters: true });
                    break;
                case "email":
                    field = Validations.Validate("Email", value.trim(), { Required: true, Email: true });
                    break;
                case "subject":
                    field = Validations.Validate("Subject", value, { Required: true, MaxLength: 100 });
                    break;
                case "message":
                    field = Validations.Validate("Message", value.trim(), { Required: true });
                    break;
            }

            if (field && !field.isValid) {
                errors[key] = field.validationMessage;
            }
        });
        if (Object.keys(errors).length) {
            setFormDataError(errors);
            return false;
        }
        setFormDataError([]);
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!isFormDataValid()) {
            setIsLoading(false);
            return false;
        }

        // ToDo... call contact us api here
        if (isHuman === true || isUserLoggedIn) {
            ContactUsFeedbackService.submitFeedback(formData).then(res => {
                addSnackMessages([{
                    open: true,
                    severity: 'success',
                    message: res.message
                }], true);
                setFormData({
                    ...formData,
                    firstName: user ? user.firstName : "",
                    lastName: user ? user.lastName : "",
                    email: user ? user.email : "",
                    subject: "",
                    message: "",
                    captchaResponse: ''
                });
                setIsLoading(false);
                setIsHuman(false);
                if (!isUserLoggedIn) {
                    grecaptcha.reset();
                }

            }).catch(e => {
                addSnackMessages([{
                    open: true,
                    severity: 'error',
                    message: e.message
                }], true);
                setIsLoading(false);
                setIsHuman(false);
                if (!isUserLoggedIn) {
                    grecaptcha.reset();
                }
            })
        } else {
            addSnackMessages([{
                open: true,
                severity: 'error',
                message: 'Please validate the capcha'
            }], true);
            setIsLoading(false);

        }

    }

    const renderSections = (items, key) => {
        return items.map((item, index) => {
            switch (item.contentTypeAlias) {
                case "mediaContentBlock":
                    return <Container maxWidth="lg" component="section" className={classes.content} key={index}>
                        <MediaTextBlock
                            data={item}
                        />
                    </Container>
                    break;
                case "blockQuote":
                    return <Container maxWidth={false} component="section" className={`${classes.bgDark} ${classes.quoteSection}`} key={index}>
                        <QuoteSection
                            quote={item.pageContent}
                        />
                    </Container>
                    break;
                case "featureBoxItemGroup":
                    return <section className={`${classes.content} ${classes.bgLight}`} key={index}>
                        <Container maxWidth="lg">
                            <Grid container spacing={4} className={classes.featureBox} justifyContent="center">
                                <FeatureBoxSection
                                    items={item.pageContent}
                                />
                            </Grid>
                        </Container>
                    </section>
                    break;
                case "userCardItemGroup":
                    return <Container maxWidth={false} component="section" className={`${classes.content} ${classes.bgDark}`} key={index}>
                        <Grid container spacing={4} className={classes.userCards} justifyContent="center">
                            <UserCardsSection
                                items={item.pageContent}
                            />
                        </Grid>
                    </Container>
                    break;
                case "mediaCardGroup":
                    return <Container maxWidth={false} component="section" className={`${classes.content}`} key={index}>
                        <Grid container spacing={4} className={classes.videoMediaCards} justifyContent="center">
                            <VideoOrMediaSection items={item.pageContent} />
                        </Grid>
                    </Container>
                    break;
                case "descriptionBlock":
                    return <Container maxWidth="lg" component="section" className={classes.content} key={index}>
                        <div dangerouslySetInnerHTML={{ __html: item.pageContent }}></div>
                    </Container>
                    break;
            }

        });
    }

    return <>
        {content && content.showPageTitle && <PageTitle title={content.pageTitle} subtitle={content.subTitle} />}
        {content.description && <Container maxWidth="lg" component="section" className={classes.content}>
            <div dangerouslySetInnerHTML={{ __html: content.description }}></div>
        </Container>}
        <Container maxWidth="md" component="section" className={classes.content}>
            <Grid container spacing={2} component="form" className={classes.form} noValidate onSubmit={(e) => handleSubmit(e)}>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        // margin="normal"
                        required
                        fullWidth
                        autoFocus
                        name="firstName"
                        label="First Name"
                        id="first_name"
                        value={formData.firstName}
                        onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        error={formDataError.firstName ? true : false}
                        helperText={formDataError.firstName}
                        disabled={isUserLoggedIn}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        // margin="normal"
                        required
                        fullWidth
                        name="lastName"
                        label="Last Name"
                        id="last_name"
                        value={formData.lastName}
                        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        error={formDataError.lastName ? true : false}
                        helperText={formDataError.lastName}
                        disabled={isUserLoggedIn}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        // margin="normal"
                        required
                        fullWidth
                        name="email"
                        type="email"
                        label="Email"
                        id="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        error={formDataError.email ? true : false}
                        helperText={formDataError.email}
                        disabled={isUserLoggedIn}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        // margin="normal"
                        required
                        fullWidth
                        name="subject"
                        label="Subject"
                        id="subject"
                        value={formData.subject}
                        onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                        error={formDataError.subject ? true : false}
                        helperText={formDataError.subject}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        minRows={4}
                        variant="outlined"
                        // margin="normal"
                        required
                        fullWidth
                        name="message"
                        label="Message"
                        id="message"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                        error={formDataError.message ? true : false}
                        helperText={formDataError.message}
                    />
                </Grid>

                <Grid item xs={6} >
                    {sitekey !== "" && !isUserLoggedIn &&
                        <ReCAPTCHA
                            width="80%"
                            sitekey={sitekey}
                            render="explicit"
                            onChange={verifyCallback}
                            onloadCallback={onLoadCallback}
                            expiredCallback={onLoadCallback}
                        />
                    }
                </Grid>

                <Grid item xs={6} align="right">
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        className="capitalize"
                        disabled={(isLoading || Object.keys(formDataError).length > 0)}
                        startIcon={isLoading && <CircularProgress color="secondary" size={24} />}
                    >
                        SUBMIT
                    </Button>
                </Grid>
            </Grid>
            {faqs && faqs.questionAnswers && faqs.questionAnswers.length > 0 &&
                <>
                    <div className={classes.sectiondivider}></div>

                    <Typography variant="subtitle1" color="secondary">
                        {FAQHeader}
                    </Typography>
                    <br></br>
                    {faqs && faqs.questionAnswers && faqs.questionAnswers.map((option, index) => {
                        return (
                            <ExpansionPanel key={index} expanded={expanded === `panel1_` + (index + 1)} onChange={handleChange(`panel1_` + (index + 1))} className={classes.expansionPanel}>
                                <ExpansionPanelSummary className={classes.expansionPanelSummary}
                                    expandIcon={expanded === `panel1_` + (index + 1) ? <RemoveCircleOutlineIcon color='primary' /> : <AddCircleOutlineIcon color='primary' />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <FaqIcon style={{ marginRight: '4px' }} /><Typography className={classes.heading}>{option.questionTitle}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                    {
                                        (expanded === `panel1_` + (index + 1)) &&
                                        <div dangerouslySetInnerHTML={{ __html: option.answer }}></div>
                                        // <List>
                                        //     {option.val.map(value => {
                                        //         return (<ListItem style={{ alignItems: "self-start" }}>
                                        //             <>
                                        //                 <ArrowRightSharpIcon style={{ paddingRight: '5px' }} />{" "}

                                        //             </>
                                        //         </ListItem>)
                                        //     })}</List>
                                    }
                                </ExpansionPanelDetails>
                            </ExpansionPanel>)
                    })}
                    <br />



                </>
            }
        </Container>

        {content.pageSections.length ? renderSections(content.pageSections) : null}
    </>
}

export default ContactUsTemplate;