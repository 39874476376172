import React, { useContext, useEffect, useState } from 'react';
import AppDataContext from '../../common/AppContext';
import MenuItems from "./MenuItems";

import './multi-level-nav.css';

const Navbar = () => {
    const { navigationMenu } = useContext(AppDataContext);
    const [menuData, setMenuData] = useState(navigationMenu.main || []);
    useEffect(() => {
        setMenuData(navigationMenu.main || [])
    }, [navigationMenu]);
    
    return (menuData.length > 0) ? <nav>
        <ul className="menus">
            {menuData.map((menu, index) => {
                const depthLevel = 0;
                return <MenuItems item={menu} key={index} depthLevel={depthLevel} />;
            })}
        </ul>
    </nav> : null;
};

export default Navbar;