import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

export const coachProfileService = {
    getProfile,
    saveContactInfo,
    saveScheduleInfo,
    deleteScheduleInfo,
    saveEducationDetails,
    deleteEducationDetails,
    saveGeneralInfo,
    savePersonalInfo,
    saveEmploymentInfo,
    deleteEmploymentInfo,
    saveExperienceDetails,
    deleteExperienceDetails,
    saveSelfAssessmentInfo,
    saveOfficiatingEvents,
    uploadProfileAssets,
    deleteProfileAssets,
    getProfilePhotoUrl,
    getProfileVideoUrl,
    uploadProfileVideo,
    deleteProfileVideo,
    uploadFullShot,
    deleteFullShot,
    getUserSummary,
    updateNoExperience
};
function getProfile() {

    return APIHelper.post(APIURLConstants.GET_PROFILE_DETAILS, {}, authHeader())
        //.then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return user.data;
        }).catch(handleError);
}

function saveContactInfo(data) {
    return APIHelper.post(APIURLConstants.SAVE_CONTACT_INFO, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function getProfilePhotoUrl(data) {
    return APIHelper.post(APIURLConstants.GET_PROFILE_PICTURE_URL, data, authHeader())
        .then(data => {
            return data.data;
        }).catch(handleError)
}

function getProfileVideoUrl(data) {
    return APIHelper.post(APIURLConstants.GET_PROFILE_VIDEO_URL, data, authHeader())
        .then(data => {
            return data.data;
        }).catch(handleError)
}

function uploadProfileAssets(data) {
    return APIHelper.post(APIURLConstants.SAVE_PROFILE_PICTURE, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function uploadFullShot(data) {
    return APIHelper.post(APIURLConstants.SAVE_FULL_PICTURE, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function uploadProfileVideo(data) {
    return APIHelper.post(APIURLConstants.SAVE_PROFILE_VIDEO, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function deleteProfileAssets(data) {
    return APIHelper.post(APIURLConstants.DELETE_PROFILE_PICTURE, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function deleteFullShot(data) {
    return APIHelper.post(APIURLConstants.DELETE_FULL_PICTURE, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function deleteProfileVideo(data) {
    return APIHelper.post(APIURLConstants.DELETE_PROFILE_VIDEO, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function saveGeneralInfo(data) {
    return APIHelper.post(APIURLConstants.SAVE_GENERAL_INFO, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function savePersonalInfo(data) {
    return APIHelper.post(APIURLConstants.SAVE_PERSONAL_INFO, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}
function saveSelfAssessmentInfo(data) {
    return APIHelper.post(APIURLConstants.SAVE_SELF_ASSESSMENT, data, authHeader())
        .then(user => {
            return user;
        }).catch(handleError)
}

function saveScheduleInfo(data) {
    return APIHelper.post(APIURLConstants.SAVE_SCHEDULE_INFO, data, authHeader())
        .then(schedule => {
            return schedule;
        })
        .catch(handleError);
}

function deleteScheduleInfo(payload) {
    return APIHelper.post(APIURLConstants.DELETE_SCHEDULE_INFO, payload, authHeader())
        .then(schedule => {
            return schedule;
        })
        .catch(handleError);
}
function saveEducationDetails(data) {
    return APIHelper.post(APIURLConstants.SAVE_EDUCATION_DETAILS, data, authHeader())
        .then(education => {
            return education;
        })
        .catch(handleError);
}
function deleteEducationDetails(data) {
    return APIHelper.post(APIURLConstants.DELETE_EDUCATION_DETAILS, data, authHeader())
        .then(education => {
            return education;
        })
        .catch(handleError);
}
function saveEmploymentInfo(data) {
    return APIHelper.post(APIURLConstants.SAVE_EMPLOYMENT_HISTORY_DETAILS, data, authHeader())
        .then(employmentHistory => {
            return employmentHistory;
        })
        .catch(handleError);
}
function deleteEmploymentInfo(data) {
    return APIHelper.post(APIURLConstants.DELETE_EMPLOYMENT_HISTORY_DETAILS, data, authHeader())
        .then(employmentHistory => {
            return employmentHistory;
        })
        .catch(handleError);
}
function saveExperienceDetails(data) {
    return APIHelper.post(APIURLConstants.SAVE_EXPERIENCE_DETAILS, data, authHeader())
        .then(experience => {
            return experience;
        })
        .catch(handleError);
}
function deleteExperienceDetails(data) {
    return APIHelper.post(APIURLConstants.DELETE_EXPERIENCE_DETAILS, data, authHeader())
        .then(experience => {
            return experience;
        })
        .catch(handleError);
}

function saveOfficiatingEvents(data) {
    return APIHelper.post(APIURLConstants.SAVE_EVENTS_DETAILS, data, authHeader())
        .then(events => {
            return events;
        })
        .catch(handleError);
}

function getUserSummary() {

    return APIHelper.get(APIURLConstants.GET_USER_SUMMARY, authHeader())
        //.then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return user.data;
        }).catch(handleError);
}
function updateNoExperience(data) {
    return APIHelper.post(APIURLConstants.UPDATE_NO_EXPERIENCE, data, authHeader())
        .then(experience => {
            return experience;
        })
        .catch(handleError);
}


