import { BehaviorSubject } from 'rxjs';

import { handleResponse, handleError, authHeader } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return JSON.parse(localStorage.getItem('currentUser')) },
    checkEmailExists,
    isTokenValid,
    verifyUserAccount,
    resendEmailVerification,
    getPermissionByRole
};

function login(username, password) {
    let postData = { username, password };
    return APIHelper.post(APIURLConstants.AUTHENTICATE_USER, postData)
        .then(res => res.data)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user.isAuthenticated) {
                localStorage.setItem('currentUser', JSON.stringify(user));
            }
            currentUserSubject.next(user);
            return user;
        }).catch(handleError);
}

function forgotPassword(postdata) {
    return APIHelper.post(APIURLConstants.FORGOT_PASSWORD, postdata)
        .then(data => {
            return data;
        }).catch(handleError);
}

function resetPassword(payload) {
    return APIHelper.post(APIURLConstants.RESET_PASSWORD, payload)
        // .then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function logout() {
    // remove user from local storage to log user out

    localStorage.removeItem('currentUser');
    localStorage.clear();
}

function checkEmailExists(email) {
    let postdata = { 'emailAddress': email }
    return APIHelper.post(APIURLConstants.CHECK_EMAIL_EXISTS, postdata)
        .then(data => {
            return data;
        }).catch(handleError);
}

function isTokenValid(token) {
    return APIHelper.get(APIURLConstants.VALIDATE_PASSWORD_TOKEN(token))
        .then(data => {
            return data;
        }).catch(handleError);
}

function verifyUserAccount(token) {
    return APIHelper.post(APIURLConstants.VERIFY_EMAIL, { token: token })
        .then(data => {
            return data;
        }).catch(handleError);
}

function resendEmailVerification() {
    return APIHelper.post(APIURLConstants.RESEND_EMAIL_VERIFICATION, {}, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getPermissionByRole(id) {
    return APIHelper.post(APIURLConstants.GET_PERMISSIONS_BY_ROLE, { roleID: id }, authHeader())
        .then(data => {
            return data.data.permissionList;
        }).catch(handleError);
}



