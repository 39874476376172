import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { CircularProgress, makeStyles, Typography, Button, Grid } from "@material-ui/core";
import AppDataContext from "../../../common/AppContext";
import { authenticationService } from "../../../services";
import PageNotFoundUI from "../../../sharedComponents/emptyStateUIContainers/PageNotFoundUI";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    title: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginBottom: 12
    },
    subtitle: {
        maxWidth: 'auto'
    }
}));

const VerifyEmailComponent = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const redirectTo = new URLSearchParams(props.location.search).get("redirectTo");
    const { addSnackMessages, user } = useContext(AppDataContext);

    useEffect(() => {
        if (user && user.isAuthenticated) {
            returnToRedirect();
        }
    }, [user]);

    function returnToRedirect() {
        if ((props.location.state && props.location.state.redirectTo) || redirectTo) {
            const redirectPath = (props.location.state) ? props.location.state.redirectTo : redirectTo;
            props.history.push({
                pathname: redirectPath
            });
        }
    }

    const handleResendLinkClick = (e) => {
        if (e) e.preventDefault();
        setIsLoading(true);
        authenticationService.resendEmailVerification().then(res => {
            addSnackMessages([{
                open: true,
                severity: 'success',
                message: res.message
            }]);
            setIsLoading(false);
        }).catch(e => {
            addSnackMessages([{
                open: true,
                severity: 'error',
                message: e.message
            }]);
            setIsLoading(false);
        });
    }

    return (
        <>
            <Grid container spacing={2} className={classes.root}>
                <PageNotFoundUI
                    title="Your account is not verified."
                    subtitle="Please verify your account by clicking on the link in your provided email."
                    subtitleProps={{
                        align: "center",
                        color: "textSecondary",
                        variant: "h6",
                        className: classes.subtitle
                    }}
                    showPageIcon
                    pageIcon={<ErrorOutlineIcon />}
                />
                <Grid item xs={12} align="center">
                    <Button color="secondary"
                        onClick={() => handleResendLinkClick()}
                        startIcon={isLoading && <CircularProgress color="inherit" size={24} />}
                        variant="contained"
                        className="capitalize">{'Resend Verification Link'}</Button>
                    <Typography component={'p'} style={{paddingTop:'10px'}}>
                        <Typography component={'h6'}>Before you can log in, you need to verify your email address.</Typography>
                        <Typography component={'h6'}>Please check your email inbox for a message from us. Click on the verification link provided in the email to complete the registration process.</Typography>
                        <Typography component={'h6'}>If you haven't received the email, please check your spam or junk folder.</Typography>
                    </Typography>
                </Grid>
            </Grid>
        </>
    )

}

export default withRouter(VerifyEmailComponent);