import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Drawer, List, ListItem, ListItemText, Toolbar, Typography, Container, Hidden, SwipeableDrawer } from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import RouteConstants from '../common/RouteConstants';
import {
    UserProfileProvider,
    UserProfileConsumer,

} from "../common/ProfileContext";
import { useState } from 'react';
import AppBar from '../sharedComponents/appBar/AppHeaderBar';
import UserActionMenu from '../sharedComponents/appBar/UserActionMenu';
import NotificationMenu from '../sharedComponents/Notifications/NotificationMenu';
import Navbar from '../sharedComponents/multiLevelTopNav/NavBar';
import NavDrawer from '../sharedComponents/multiLevelTopNav/NavDrawer';



const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },

        // marginTop:
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            marginBottom: 10,
            marginTop: 64
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#0c214c",
        marginTop: 48,
        zIndex: 0,
        color: '#ffffff',
        // '& .MuiListItem-button': {
        //      backgroundColor: '#1d428a',
        //     color: '#ffffff',
        //  },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        fontWeight: 600,
    }
}));

const MasterLayout = ({ history, children }) => {
    const theme = useTheme();
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const toggleDrawer = (value) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(value);
    };

    const handleManageCommunicationCategory = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_COMMUNICATION_CATEGORY,
            state: undefined,
        })
    }
    const handleManageEvaluationCategory = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_EVALUATION_CATEGORY,
            state: undefined,
        })
    }

    const handleManageCompetencies = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_COMPETENCY,
            state: undefined,
        })
    }

    const handleManageQuizzes = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_QUIZ,
            state: undefined,
        })
    }

    const handleManageLessonPlans = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_LESSON_PLANS,
            state: undefined,
        })
    }

    const handleInvitePartners = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.ADMIN_INVITE_ORG_MEMBER,
            state: undefined,
        })
    }

    const handleManagePool = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.ADMIN_MANAGE_POOL,
            state: undefined,
        })
    }

    const handleManageQuestions = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_CMS_QUESTIONS,
            state: undefined,
        })
    }

    const handleManageEvents = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_EVALUATION_EVENTS,
            state: undefined,
        })
    }


    const handleManageTangibles = () => {

        setOpen(false)

        return history.push({

            pathname: RouteConstants.MANAGE_TANGIBLE,

            state: undefined,

        })

    }

    const handleManageClinicEventCategory = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_CLINICEVENT_CATEGORY,
            state: undefined,
        })
    }

    const handleManageClinician = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_CLINICIAN,
            state: undefined,
        })
    }

    const handleManageUserRole = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_USER_ROLE,
            state: undefined,
        })
    }

    const handleManagePermissions = () => {
        setOpen(false)
        return history.push({
            pathname: RouteConstants.MANAGE_PERMISSIONS,
            state: undefined,
        })
    }

    return (
        <>
            <AppBar
                mainNav={<Navbar />}
                mobileNav={<NavDrawer />}
                notificationMenu={<NotificationMenu />}
                userActionMenu={<UserActionMenu />}
            />
            <div className={classes.root}>
                <CssBaseline />
                <nav className={classes.drawer} aria-label="side nav">
                    <Hidden xsDown>
                        <Drawer

                            variant="permanent"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <List>
                                <ListItem button onClick={handleManageCommunicationCategory} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_COMMUNICATION_CATEGORY ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Communication Category' />
                                </ListItem>
                                <ListItem button onClick={handleManageEvaluationCategory} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_EVALUATION_CATEGORY ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Evaluation Category' />
                                </ListItem>
                                <ListItem divider button onClick={handleManageCompetencies} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_COMPETENCY ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Competencies' />
                                </ListItem>
                                <ListItem divider button onClick={handleManageTangibles} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_TANGIBLE ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Intangibles' />
                                </ListItem>
                                <ListItem button onClick={handleManageLessonPlans} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_LESSON_PLANS ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Lesson Plans' />
                                </ListItem>
                                <ListItem button onClick={handleManagePool} style={{ backgroundColor: history.location.pathname === RouteConstants.ADMIN_MANAGE_POOL ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Pool' />
                                </ListItem>
                                {/* <ListItem divider button onClick={handleManageQuestions} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_CMS_QUIZ_QUESTIONS ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Questions' />
                                </ListItem> */}
                                <ListItem button onClick={handleInvitePartners} style={{ backgroundColor: history.location.pathname === RouteConstants.ADMIN_INVITE_ORG_MEMBER ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Invite Partners' />
                                </ListItem>
                                <ListItem divider button onClick={handleManageEvents} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_EVALUATION_EVENTS ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Evaluation Event' />
                                </ListItem>
                                <ListItem button onClick={handleManageClinicEventCategory} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_CLINICEVENT_CATEGORY ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Clinic Event Category' />
                                </ListItem>
                                <ListItem button onClick={handleManageClinician} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_CLINICIAN ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Clinician' />
                                </ListItem>
                                <ListItem button onClick={handleManageUserRole} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_USER_ROLE ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage User Role' />
                                </ListItem>
                                <ListItem button onClick={handleManagePermissions} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_PERMISSIONS ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Permissions' />
                                </ListItem>
                            </List>
                        </Drawer>
                    </Hidden>
                    <Hidden mdUp>
                        <SwipeableDrawer
                            open={open}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <List>
                                <ListItem button onClick={handleManageCommunicationCategory} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_COMMUNICATION_CATEGORY ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Communication Category' />
                                </ListItem>
                                <ListItem button onClick={handleManageEvaluationCategory} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_EVALUATION_CATEGORY ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Evaluation Category' />
                                </ListItem>
                                <ListItem button onClick={handleManageCompetencies} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_COMPETENCY ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Competencies' />
                                </ListItem>
                                <ListItem button onClick={handleManageQuizzes} style={{ backgroundColor: history.location.pathname === RouteConstants.MANAGE_QUIZ ? '#1d428a' : undefined }}>
                                    <ListItemText primary='Manage Quizzes' />
                                </ListItem>
                            </List>
                        </SwipeableDrawer>
                    </Hidden>
                </nav>
                {children}
            </div>
        </>
    )
}


export default MasterLayout;