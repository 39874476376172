import React, { useContext, useState, useEffect } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
//import { UserContext } from '../../Context/userContext';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
//import APIHelper from 'ApiHelper';
import APIHelper from "../../common/ApiHelper";
import APIURLConstants from "../../common/ApiURLConstants";
//import RouteConstants from '../../Common/routeConstants';
import AppConstants from '../../common/AppConstants';
//import Helper from '../../Common/helper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import './NotificationMenu.css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { authHeader, handleResponse, handleError } from '../../helper';
import { AppDataContext } from '../../common/AppContext';
import { UserProfileContext } from '../../common/ProfileContext';
import Helper from '../../common/Helper';
import { messageCenterService, referenceDataService } from '../../services';
import { Close } from '@material-ui/icons';
import RouteConstants from '../../common/RouteConstants';
import parser from 'html-react-parser'
import * as signalR from '@microsoft/signalr';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        width: '500px',
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 10px)',
        },

    },
    initialSection: {
        flex: '0 0 auto',
        padding: theme.spacing(1.5),
        textAlign: 'center',
        marginRight: 0,
    },
    clearIcon: {
        color: theme.palette.grayOpacColor,
        cursor: 'pointer'
    },
    unreadIcon: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    listItem: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    bulletinItem: {
        backgroundColor: '#F4CED4',
        '&:hover': {
            backgroundColor: '#FAE6EA',
        },
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    notificationPopover: {
        zIndex: '1200',
        width: '100%',
    },
    notificationScroll: {
        maxHeight: '60vh',
        overflow: 'auto'
    },
    notificationText: {
        fontSize: '0.8rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
        },
    },
    font550: {
        fontWeight: '500'
    },
    notificationDate: {
        fontSize: '0.65rem'
    },
    badgeCount: {
        top: '-2px',
        right: '-2px',
    },
    notificationicon: {
        color: theme.palette.whiteColor,
    },
    progress: {
        marginLeft: '50%'
    },
    dialogClose: {
        marginLeft: theme.spacing(1)
    },
}));

let gNotificationsCountIntervalID = null;

let initialProfile = {

    count: 0,

};

export default function NotificationMenu(props) {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [notificationCount, setNotificationCount] = useState(0)
    const [notificationList, setNotificationList] = useState([])
    const { user, checkRole, updateNotificationCount, notificationCountChanged } = useContext(AppDataContext);
    const {officialUID} = useContext(UserProfileContext);
    
    //const [hubConnection, setHubConnection] = useState(new signalR.HubConnectionBuilder().withUrl(referenceDataService.getSignalRUrl(), signalR.HttpTransportType.ServerSentEvents).withAutomaticReconnect([30000, 30000, 30000, 30000]).build());
    //const [hubConnected, setHubConnected] = useState(false);

    //console.log(' officialUID ' + officialUID);

    useEffect(() => {
        if (checkRole("admin") || checkRole("user") || checkRole("scout") || checkRole("adminscout") ) {
            //if (notificationCountChanged) {
                //getNotificationCount();
                //gNotificationsCountIntervalID = setInterval(() => {
                getNotificationCount();
                //}, 60000);
            //}
        }
    }, []);

    // useEffect(() => {
    //     if (!hubConnected) {
    //         hubConnection
    //             .start()
    //             .then(() => {
    //                 //console.log('connection started');
    //                 setHubConnected(true);
    //             })
    //             .catch(err => console.log('Error while connection'));
    //     }
    // }, [hubConnection, !hubConnected]);

    // useEffect(() => {
    //     if (hubConnected) {
    //         hubConnection.on('ReceiveNotificationMessage', (messageObj) => {
    //             if (messageObj && messageObj.userId == officialUID ) {
    //                 console.log('ReceiveNotificationMessage ' + messageObj);
    //                 getNotificationCount();
    //             }
    //         });
    //     }
    // }, [hubConnection, hubConnected]);

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const getNotificationCount = () => {
        messageCenterService.getNotificationCount().
            then(data => {
                //console.log(data)
                setNotificationList(data.data.notificationList)
                if (notificationCount !== data.data.count) {
                    setNotificationCount(data.data.count);
                    // if(hubConnected){
                    //     hubConnection.stop().then(() => {
                    //         setHubConnected(false);
                    //     });
                        
                    // }
                }
            }).catch(err => {
                //... Check if user's session has expired
                // if (gNotificationsCountIntervalID) {
                //     clearInterval(gNotificationsCountIntervalID);
                //     gNotificationsCountIntervalID = null;
                // }
            });
    };

    const handleNotificationUpdate = (threadID) => {

        const thread = { threadID: threadID };
        messageCenterService.UpdateNotifications(thread)
            .then(data => {
                getNotificationCount()
            });
    }

    const handleToggle = () => {

        setOpen(prevOpen => !prevOpen);
        //if (!open)
        //   getNotifications();
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }


    const redirectToPage = (item, index) => {
        handleNotificationUpdate(item.threadID)
        setOpen(prevOpen => !prevOpen);
        history.push(generatePath(RouteConstants.MESSAGE_CENTER_THREAD, { id: item.threadID }));
    };

    const markAsRead = () => {
        handleNotificationUpdate(-1)
    }




    const renderNotificationText = (item) => {
        let threadTitle = item.title;
        let messageCount = item.messageCount + ' new messages';
        let notificationText = ''

        if (item.isRootPost) {

            notificationText = item.createdByName + ' started ' + parser(item.title) + ': ' + moment(item.createdOn).format("YYYY-MM-DD HH:mm")

        } else {

            notificationText = item.createdByName + ' responded to ' + parser(item.title) + ': ' + moment(item.createdOn).format("YYYY-MM-DD HH:mm")

        }


        return (
            <Box>
                <Typography component="p" className={` ${!item.status ? classes.font550 : ''} 
                ${classes.notificationText}`}>
                    {notificationText}
                </Typography>
                <Typography component="p" className={classes.notificationDate} noWrap>
                    {messageCount}
                </Typography>
            </Box>
        )
    }

    return (

        <>
            <IconButton
                className={classes.initialSection}
                //onClick={handleProfileClick}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge badgeContent={notificationCount} color="secondary" overlap='rectangular'
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <NotificationsIcon className={classes.notificationicon} style={{ color: '#fff' }} />
                </Badge>

            </IconButton>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition
                id="notificationPopover" className={classes.notificationPopover} >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            position: 'fixed', bottom: 'unset', right: 0, top: 5, left: 'unset',
                            zIndex: 9999
                        }}

                    >
                        <Paper className={`${classes.paper}`}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <>
                                    <List style={{ paddingTop: '4px', paddingBottom: '0px' }}>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText>
                                                <Grid container alignItems="center">
                                                    <Grid item xs>
                                                        <Typography variant="subtitle1" className="font550"> Notifications
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box component="span" pl={1} display="flex" justifyContent="flex-end">
                                                            {notificationList && notificationList.length > 0 &&
                                                                <Link variant="caption" component="button"
                                                                    onClick={() => { markAsRead(null, '', true) }}>
                                                                    Mark All as Read</Link>
                                                            }
                                                            <IconButton onClick={handleClose} className={classes.dialogClose} size="small">
                                                                <Close color="secondary" />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                    {/* {
                                            <React.Fragment>
                                                {
                                                    //... Show progress when loading data.
                                                    (!isLoaded) &&
                                                    <CircularProgress color="secondary" align="center" className={classes.progress} />
                                                }
                                            </React.Fragment>
                                        } */}
                                    <List component="nav" autofocusitem={open.toString()} id="menu-list-grow"
                                        onKeyDown={handleListKeyDown} className={classes.notificationScroll}
                                        classstyle={{ paddingLeft: '8px', paddingRight: '8px' }}>
                                        {
                                            notificationList && notificationList.length > 0 ?
                                                notificationList.map((item, index) => {

                                                    return (
                                                        <div key={index} onClick={() => { redirectToPage(item, index) }}>
                                                            {
                                                                index != 0 ? <Divider /> : null
                                                            }
                                                            <ListItem button className={classes.listItem}>
                                                                <ListItemText>
                                                                    <Grid container alignItems="center">

                                                                        <Grid item lg={10} md={10} sm={10} xs={9}>
                                                                            {

                                                                                renderNotificationText(item)
                                                                            }


                                                                        </Grid>

                                                                    </Grid>
                                                                </ListItemText>
                                                                <ListItemSecondaryAction style={{ right: '8px' }}>
                                                                    {
                                                                        // item.status ?
                                                                        //     <React.Fragment>
                                                                        //         <div style={{ float: 'left', marginRight: '5px' }}>
                                                                        //             <Tooltip title='Delete' enterDelay={200} leaveDelay={0}>
                                                                        //                 <HighlightOffIcon className={classes.clearIcon} fontSize="small" onClick={(e) => { deleteNotification(e, index, item.notificationID) }} />
                                                                        //             </Tooltip>
                                                                        //         </div>
                                                                        //         <div style={{ float: 'left' }}>
                                                                        //             <Tooltip title='Mark as unread' enterDelay={200} leaveDelay={0}>
                                                                        //                 <FiberManualRecordIcon className={classes.clearIcon} fontSize="small" onClick={(e) => { handleMarkAsReadUnread(e, item, index) }} />
                                                                        //             </Tooltip>
                                                                        //         </div>
                                                                        //     </React.Fragment> :
                                                                        // <React.Fragment>
                                                                        //     <div style={{ float: 'left', marginRight: '5px', visibility: 'hidden' }}>
                                                                        //         <Tooltip title='Delete' enterDelay={200} leaveDelay={0}>
                                                                        //             <HighlightOffIcon className={classes.clearIcon} fontSize="small" />
                                                                        //         </Tooltip>
                                                                        //     </div>
                                                                        //     <div style={{ float: 'left' }}>
                                                                        //         <Tooltip title='Mark as read' enterDelay={200} leaveDelay={0}>
                                                                        //             <FiberManualRecordIcon className={classes.unreadIcon} fontSize="small" onClick={(e) => { handleMarkAsReadUnread(e, item, index) }} />
                                                                        //         </Tooltip>
                                                                        //     </div>
                                                                        // </React.Fragment>
                                                                    }
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </div>
                                                    )
                                                }) :

                                                <Grid container alignItems="center" style={{ paddingBottom: '8px' }}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Typography component="p" className={classes.font550} align="center"> No new notifications</Typography>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </List>
                                </>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>

    );
}
