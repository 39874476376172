import React, { useState, useEffect, useContext, useMemo } from "react";
import { AppDataContext } from './AppContext';
import { authenticationService, userService } from "../services";
//import { Role } from "../helper";
import { useParams } from "react-router-dom";
/*
    This context provider contains data to fuel the multiple questions and allow us to maintain it at one place, 
    hence achieving flexibility in placing the questions anywhere in/and across multiple sections.
*/

//... This JSON should always match the structure of JSON retrieved from the API.
let initialProfile = {
  fullName: {
    firstName: "",
    lastName: "",
    middleInitial: "",
    nickName: ""
  },
  officialUID: '',
  userName: "",
  email: "",
  address1: "",
  address2: "",
  country: "",
  state: 0,
  stateOther: "",
  city: "",
  zip: "",
  primaryPhone: "",
  alternatePhone: "",
  socialMedia: [],
  mediaProfiles: [],
  role: "",
  token: "",
  resetToken: "",
  isActive: false,
  officiatingSchedule: null,
  officiatingEvents: null,
  education: null,
  employmentHistory: null,
  officiatingExperience: null,
  isCitizen: false,
  isAppliedNBA: false,
  appliedNBADescription: '',
  isAppliedNBATeam: false,
  appliedNBATeamDescription: '',
  isRelativesEmployed: false,
  relativesEmployedDescription: '',
  isMilitaryExperience: false,
  militaryExperienceDescription: '',
  currentCharitiesDescription: '',
  previousCharitiesDescription: '',
  personalInterestsDescription: '',
  otherNotesDescription: '',
  OfficiatingGoalsAspirations: '',
  currentStrengths: '',
  currentLimitations: '',
  headShot: '',
  profileVideoUrl: '',
  flagshipNetwork: 0,
  flagshipNetworkName: '',
  partnerOrganization: 0,
  partnerOrganizationName: '',
  // jwtTokenDetails: {},
  lastModified: '',
  nbaID: '',
  nbaEmail: '',
  fullShot: '',
  generalInformation: null,
  isProcessing: true,
  scheduleAttachments: [],
  hasNoExperience: false,
  officiatingVideos: [],
  aboutUsInfo: null
};

export const UserProfileContext = React.createContext(initialProfile);

export const UserProfileProvider = (props) => {
  const { user } = useContext(AppDataContext);
  //... State to maintain entire user profile.
  const [profile, setProfile] = useState(initialProfile);
  const [profileCompleteness, setProfileCompleteness] = useState(0);
  const [remainingSection, setRemainingSection] = useState([]);
  const [status, setStatus] = useState("");
  const { username } = useParams();
  const [snackMessages, setSnackMessages] = useState([]);
  const [hasNoExperience, setHasNoExperience] = useState(false);
  const [role, setRole] = useState()

  const getUserRole = () => {
    const fetchRole = async () => {
      let userRole = await userService.getUserRole();
      setRole(userRole);
    }
    fetchRole();
  }

  useEffect(() => {
    if (role === undefined) {
      getUserRole();
    }
    if (user && user.userName && role) {
      retrieveAndSetProfileContext();

      if (role === 'User') {
        getProfileCompleteness()
      }
    }
  }, [user, role]);

  // const getProfileCompleteness = () => {
  //   let percent = 0;
  //   let remainingSections = [];
  //   if (Object.keys(profile.fullName).length != 0) { percent += 15; }
  //   if (profile && profile.education !== null && profile.education !== undefined && Object.keys(profile.education).length !== 0) {
  //     if (profile.education.length > 0) {
  //       percent += 15;
  //     }
  //   }
  //   if (profile.officiatingSchedule !== null && profile.officiatingSchedule !== undefined && Object.keys(profile.officiatingSchedule).length !== 0) {
  //     if (profile.officiatingSchedule.length > 0) {
  //       percent += 15;
  //     }
  //   }
  //   if (profile.employmentHistory !== null && profile.employmentHistory !== undefined && Object.keys(profile.employmentHistory).length !== 0) {
  //     if (profile.employmentHistory.length > 0) {
  //       percent += 15;
  //     }
  //   }
  //   if (profile.officiatingExperience !== null && profile.officiatingExperience !== undefined && Object.keys(profile.officiatingExperience).length !== 0) {
  //     if (profile.officiatingExperience.length > 0) {
  //       percent += 15;
  //     }
  //   }

  //   if (profile.socialMedia !== null && profile.socialMedia !== undefined && Object.keys(profile.socialMedia).length !== 0) {
  //     if (profile.socialMedia.length > 0) {
  //       percent += 5;
  //     }
  //   }
  //   if (profile.headShot !== null && profile.headShot !== undefined && profile.headShot !== "") {
  //     percent += 5;
  //   }

  //   if (profile.isCitizen !== null || profile.isAppliedNBA !== null || profile.isAppliedNBATeam !== null || profile.isMilitaryExperience !== null || profile.isRelativesEmployed !== null) {
  //     percent += 5;
  //   }

  //   if (profile.officiatingGoalsAspirations !== null || profile.currentStrengths !== null || profile.currentLimitations !== null) {
  //     percent += 5;
  //   }

  //   if (profile.videoURL !== null && profile.videoURL !== "" && profile.videoURL !== undefined) {
  //     percent += 5;
  //   }

  //   setProfileCompleteness(percent);
  //   setremainingSection(remainingSections);
  // }
  const getProfileCompleteness = () => {
    userService.getProfileCompleteness()
      .then(data => {
        setRemainingSection(data.filter(x => x.profileStatus == 0))
      }).catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    // if (role === 'User') {
    //   getProfileCompleteness();
    // }
    setStatus(profile.status);
  }, [profile])

  const resetContext = () => {
    setProfile(initialProfile);
  }

  //... This provides values to the context consumers.
  const providerValue = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    middleInitial: profile.middleInitial,
    nickName: profile.nickName,
    fullName: profile.fullName,
    officialUID: profile.officialUID,
    userName: profile.userName,
    email: profile.email,
    address1: profile.address1,
    address2: profile.address2,
    country: profile.country,
    countryName: profile.countryName,
    state: profile.state,
    stateOther: profile.stateOther,
    stateName: profile.stateName,
    city: profile.city,
    zip: profile.zip,
    primaryPhone: profile.primaryPhone,
    alternatePhone: profile.alternatePhone,
    role: profile.role,
    token: profile.token,
    resetToken: profile.resetToken,
    isActive: profile.isActive,
    socialMedia: profile.socialMedia,
    mediaProfiles: profile.mediaProfiles,
    officiatingSchedule: profile.officiatingSchedule,
    officiatingEvents: profile.officiatingEvents,
    education: profile.education,
    employmentHistory: profile.employmentHistory,
    officiatingExperience: profile.officiatingExperience,
    isCitizen: profile.isCitizen,
    isAppliedNBA: profile.isAppliedNBA,
    appliedNBADescription: profile.appliedNBADescription,
    isAppliedNBATeam: profile.isAppliedNBATeam,
    appliedNBATeamDescription: profile.appliedNBATeamDescription,
    isRelativesEmployed: profile.isRelativesEmployed,
    relativesEmployedDescription: profile.relativesEmployedDescription,
    isMilitaryExperience: profile.isMilitaryExperience,
    militaryExperienceDescription: profile.militaryExperienceDescription,
    currentCharitiesDescription: profile.currentCharitiesDescription,
    previousCharitiesDescription: profile.previousCharitiesDescription,
    personalInterestsDescription: profile.personalInterestsDescription,
    otherNotesDescription: profile.otherNotesDescription,
    isProessionalPlayExperience: profile.isProessionalPlayExperience,
    officiatingGoalsAspirations: profile.officiatingGoalsAspirations,
    currentStrengths: profile.currentStrengths,
    currentLimitations: profile.currentLimitations,
    proessionalPlayDescription: profile.proessionalPlayDescription,
    headShot: profile.headShot,
    profileVideoUrl: profile.profileVideoUrl,
    profileCompleteness: profileCompleteness,
    remainingSection: remainingSection,
    status: status,
    lastModified: profile.lastModified,
    nbaID: profile.nbaID,
    nbaEmail: profile.nbaEmail,
    fullShot: profile.fullShot,
    isProcessing: profile.isProcessing,
    generalInformation: profile.generalInformation,
    selfAssessment: profile.selfAssessment,
    scheduleAttachments: profile.scheduleAttachments,
    hasNoExperience: hasNoExperience,
    updateProfile: (data, name) => {
      return handleUpdate(data, name);
    },
    updateContactDetails: (contactDetails) => {
      return handleContactDetailsUpdate(contactDetails);
    },
    updatePersonalDetails: (personalDetails) => {
      return handlePersonalDetailsUpdate(personalDetails);
    },
    updateGeneralDetails: (generalDetails) => {
      return handleGeneralDetailsUpdate(generalDetails);
    },
    updateSelfAssessmentDetails: (assessmentDetails) => {
      return handleSelfAssessmentDetailsUpdate(assessmentDetails);
    },
    updateOfficiatingExperienceDetails: (expDetails) => {
      return handleOfficiatingExperienceUpdate(expDetails);
    },
    updateHasNoExperience: (noExp) => {
      return setHasNoExperience(noExp)
    },
    updateOfficiatingEventDetails: (eventDetails) => {
      return handleOfficiatingEventUpdate(eventDetails);
    },
    updateLastModifiedDate: (details) => {
      return handleLastModifiedDateUpdate(details);
    },
    updateOfficiatingSchedule: (value) => updateOfficiatingSchedule(value),
    updateEducation: (value) => updateEducation(value),
    updateEmploymentHistory: (value) => updateEmploymentHistory(value),
    updateOfficiatingExperience: (value) => updateOfficiatingExperience(value),
    updateOfficiatingEvents: (value) => updateOfficiatingEvents(value),
    updateGeneralInfo: (value) => updateGeneralInfo(value),
    updateSelfAssessment: (value) => updateSelfAssessment(value),
    resetContext,
    reloadAttachments: (files) => {
      reloadAttachments(files)
    },
    getProfileCompleteness: getProfileCompleteness,
    remainingSection: remainingSection,
    officiatingVideos: profile.officiatingVideos,
    aboutUsInfo: profile.aboutUsInfo,
    updateAboutUsInfo: (value) => updateAboutUsInfo(value),
  };

  const updateOfficiatingSchedule = (officiatingSchedule) => {
    setProfile((prevState) => ({
      ...prevState,
      officiatingSchedule: officiatingSchedule
    }));
  }

  const reloadAttachments = (files) => {
    setProfile((prevState) => ({
      ...prevState,
      scheduleAttachments: files
    }));
  }
  const updateEducation = (education) => {
    setProfile((prevState) => ({
      ...prevState,
      education: education
    }));
    if (role === 'User') {
      getProfileCompleteness()
    }
  }

  const updateEmploymentHistory = (employmentHistory) => {
    setProfile((prevState) => ({
      ...prevState,
      employmentHistory: employmentHistory
    }));
    if (role === 'User') {
      getProfileCompleteness()
    }
  }

  const updateOfficiatingExperience = (officiatingExperience) => {
    setProfile((prevState) => ({
      ...prevState,
      officiatingExperience: officiatingExperience
    }));
    if (role === 'User') {
      getProfileCompleteness()
    }
  }

  const updateOfficiatingEvents = (officiatingEvents) => {
    setProfile((prevState) => ({
      ...prevState,
      officiatingEvents: officiatingEvents
    }));
  }

  const updateGeneralInfo = (generalInformation) => {
    setProfile((prevState) => ({
      ...prevState,
      generalInformation: generalInformation
    }));
    if (role === 'User') {
      getProfileCompleteness()
    }
  }

  const updateSelfAssessment = (selfAssessment) => {
    setProfile((prevState) => ({
      ...prevState,
      selfAssessment: selfAssessment
    }));
    if (role === 'User') {
      getProfileCompleteness()
    }
  }

  const handleContactDetailsUpdate = (contactDetails) => {
    let profileVideoUrl = '';

    setProfile((prevState) => ({
      ...prevState,
      fullName: { firstName: contactDetails.firstName, lastName: contactDetails.lastName, middleInitial: contactDetails.middleInitial, nickName: contactDetails.nickName },
      firstName: contactDetails.firstName,
      lastName: contactDetails.lastName,
      middleInitial: contactDetails.middleInitial,
      nickName: contactDetails.nickName,
      address1: contactDetails.address1,
      address2: contactDetails.address2,
      country: contactDetails.country,
      countryName: contactDetails.countryName,
      state: contactDetails.state,
      stateName: contactDetails.stateName,
      stateOther: contactDetails.stateOther,
      city: contactDetails.city,
      zip: contactDetails.zip,
      primaryPhone: contactDetails.primaryPhone,
      alternatePhone: contactDetails.alternatePhone,
      headShot: contactDetails.headShot,
      profileVideoUrl: contactDetails.profileVideoUrl,
      socialMedia: contactDetails.socialMedia,
      mediaProfiles: contactDetails.mediaProfiles,
      lastModified: contactDetails.lastModified,
      fullShot: contactDetails.fullShot,
      hasNoExperience: contactDetails.hasNoExperience,
      officiatingVideos: contactDetails.officiatingVideos

    }));
    getProfileCompleteness();
  };


  const handlePersonalDetailsUpdate = (personalDetails) => {
    setProfile((prevState) => ({
      ...prevState,
      currentCharitiesDescription: personalDetails.currentCharitiesDescription,
      previousCharitiesDescription: personalDetails.previousCharitiesDescription,
      personalInterestsDescription: personalDetails.personalInterestsDescription,
      otherNotesDescription: personalDetails.otherNotesDescription,
      lastModified: personalDetails.lastModified

    }));
  };

  const handleLastModifiedDateUpdate = (details) => {
    setProfile((prevState) => ({
      ...prevState,
      lastModified: details.lastModified

    }));
  };

  const handleOfficiatingExperienceUpdate = (details) => {
    setProfile((prevState) => ({
      ...prevState,
      officiatingExperience: details.expList,
      //jrNBAFlagshipNetwork: details.userInfo.jrNBAFlagshipNetwork,
      //jrNBAFlagshipNetworkName: details.userInfo.jrNBAFlagshipNetworkName
    }));
    if (role === 'User') {
      getProfileCompleteness()
    }
  };
  const handleOfficiatingEventUpdate = (events) => {
    setProfile((prevState) => ({
      ...prevState,
      officiatingEvents: events

    }));
  };

  const handleSelfAssessmentDetailsUpdate = (assessmentDetails) => {
    setProfile((prevState) => ({
      ...prevState,
      officiatingGoalsAspirations: assessmentDetails.officiatingGoalsAspirations,
      currentStrengths: assessmentDetails.currentStrengths,
      currentLimitations: assessmentDetails.currentLimitations,

    }));
    if (role === 'User') {
      getProfileCompleteness()
    }
  };

  const handleGeneralDetailsUpdate = (generalDetails) => {
    setProfile((prevState) => ({
      ...prevState,

      isCitizen: generalDetails.isCitizen,
      isAppliedNBA: generalDetails.isAppliedNBA,
      appliedNBADescription: generalDetails.appliedNBADescription,
      isAppliedNBATeam: generalDetails.isAppliedNBATeam,
      appliedNBATeamDescription: generalDetails.appliedNBATeamDescription,
      isRelativesEmployed: generalDetails.isRelativesEmployed,
      relativesEmployedDescription: generalDetails.relativesEmployedDescription,
      isMilitaryExperience: generalDetails.isMilitaryExperience,
      militaryExperienceDescription: generalDetails.militaryExperienceDescription,
      isProessionalPlayExperience: generalDetails.isProessionalPlayExperience,
      proessionalPlayDescription: generalDetails.proessionalPlayDescription


    }));
    if (role === 'User') {
      getProfileCompleteness()
    }
  };

  const updateAboutUsInfo = (aboutusInfo) => {
    setProfile((prevState) => ({
      ...prevState,
      aboutUsInfo: aboutusInfo
    }));
  }



  const handleUpdate = (data, name) => {
    let p = { ...profile };
    //... Update the profile object matching the keys.
    p[name] = data;
    //... Update provider's internal state.
    setProfile(p);

    return true;
  };

  const retrieveAndSetProfileContext = () => {
    let uname = username ?? user.userName;
    userService.getContactDetails(uname, props.fetchMedia)
      .then((data) => {
        let profileObject = {
          ...initialProfile,
          fullName: data && data.fullName ? data.fullName :
            {
              firstName: data.firstName,
              lastName: data.lastName,
              middleInitial: data.middleInitial,
              nickName: data.nickName
            },
          isLoading: false,
          isProcessing: false,
          ...data
        };
        setProfile({ ...profileObject });
        setHasNoExperience(data.hasNoExperience)
      })
  }

  return (
    <UserProfileContext.Provider value={providerValue}>
      {props.children}
    </UserProfileContext.Provider>
  );
};

export const UserProfileConsumer = UserProfileContext.Consumer;

export default UserProfileContext;
