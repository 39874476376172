import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

export const cmsDataservice = {
    getCMSCOntentById,
    getCMSContentBySlug
};

function getCMSCOntentById(obj) {
    return APIHelper.post(APIURLConstants.GET_CMS_CONTENT_BY_ID, obj, authHeader())
        .then(response => {
            return response;
        }).catch(handleError)
}

function getCMSContentBySlug(slug) {
    let postdata = { "slugName" : slug}
    return APIHelper.post(APIURLConstants.GET_CMS_CONTENT_BY_SLUG, postdata, authHeader())
        .then(response => {
            return response;
        }).catch(handleError)
}
