import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const permissionService = {
    getAllPermissions,
    SavePermissionsByRole
};


function getAllPermissions() {
    return APIHelper.get(APIURLConstants.GET_PERMISSION_LIST, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function SavePermissionsByRole(data) {
    return APIHelper.post(APIURLConstants.SAVE_PERMISSION_BY_ROLE, data, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}


