import React from 'react';
import { Grid, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Validations from '../../../common/Validations';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    featureItem: {
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 32,
        color: '#fff',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.7)',
        },
        '&.Square': {
            color: 'rgba(29, 66, 138, 1)',
            background: '#fff',
            borderRadius: 0,
            height: 80,
            textAlign: 'center',
            border: '2px solid #C9C9C9',
            boxShadow: 'inset 0px 0px 20px rgba(0, 0, 0, 0.35)',
            '&:hover': {
                boxShadow: '0px 0px 8px rgb(0 0 0 / 35%)'
            },
            '& h4': {
                fontSize: 22,
                fontFamily: `'Roboto' !important`
            }
        }
    },
    featureLogo: {
        minWidth: 'unset',
        marginRight: 16,
        '& img': {
            maxWidth: 80,
            maxHeight: 80,
        }
    }
}));

const FeatureBoxSection = ({ items, gridItemSize }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleLinkClick = (e, path) => {
        if (e) e.preventDefault();
        let isThirdParty = Validations.Validate("", path, { URL: true });
        if (isThirdParty.isValid) {
            window.open(path, '_blank');
            return false;
        }

        history.push({
            pathname: path
        });
        return;
    }
    return items.map((item, index) => <Grid item xs={12} sm={6} md={gridItemSize} key={index}>
        <ListItem button={item.redirectURL ? true : false} component={item.redirectURL ? 'a' : 'div'} href={item.redirectURL ? item.redirectURL : undefined} className={clsx([classes.featureItem, item.layout])} onClick={(e) => (item.redirectURL) ? handleLinkClick(e, item.redirectURL) : false}>
            {item.image && <ListItemAvatar className={classes.featureLogo}>
                <img src={item.image._url} />
            </ListItemAvatar>}
            <ListItemText
                primary={<Typography variant='h4'>{item.title}</Typography>}
                secondary={<Typography color='inherit'>{item.subtitle}</Typography>}
            />
        </ListItem>
    </Grid>)
}

export default FeatureBoxSection;