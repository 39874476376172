import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const messageCenterService = {
    getMessageThreads,
    addMessageThread,
    updateMessagePost,
    getThreadDetails,
    UpdateNotifications,
    getNotificationCount,
    updateMessageThreadStatus,
    getCategoryList,
    updateCategory,
    getActiveCategoryList,
    getSignalRUrl
};

function getSignalRUrl(data) {
    var url = APIHelper.signalRUrl();
}

function getMessageThreads(data) {
    return APIHelper.post(APIURLConstants.GET_ALL_THREADS, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function addMessageThread(data) {
    return APIHelper.post(APIURLConstants.ADD_NEW_THREAD, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function updateMessagePost(data) {
    return APIHelper.post(APIURLConstants.REPLY_THREAD, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function updateMessageThreadStatus(data) {
    return APIHelper.post(APIURLConstants.UpdateThreadStatus, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}




function getThreadDetails(id) {
    let postdata = {"threadID" : id}
    return APIHelper.post(APIURLConstants.GET_THREAD_DETAILS, postdata, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function UpdateNotifications(data) {
    return APIHelper.post(APIURLConstants.UPDATE_NOTIFICATIONS, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getNotificationCount() {
    return APIHelper.get(APIURLConstants.GET_UPDATED_THREADS, authHeader())
        .then(data => {
            return data
        }).catch(err => {
            //... Check if user's session has expired
            // if (gNotificationsCountIntervalID) {
            //     clearInterval(gNotificationsCountIntervalID);
            //     gNotificationsCountIntervalID = null;
            // }
        });
}

function getCategoryList() {
    return APIHelper.get(APIURLConstants.GET_CATEGORY_LIST, authHeader())
        .then(data => {
            return data
        })
        .catch(err => {
            handleError(err)
        });
}

function updateCategory(category) {
    return APIHelper.post(APIURLConstants.ADD_UPDATE_CATEGORY, category, authHeader())
        .then(data => {
            return data;
        })
        .catch(err => {
            handleError(err);
        })
}


function getActiveCategoryList() {
    return APIHelper.get(APIURLConstants.GET_ACTIVE_CATEGORY_LIST, authHeader())
        .then(data => {
            return data
        })
        .catch(err => {
            handleError(err)
        });
}




