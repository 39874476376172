import React from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import Helper from '../../common/Helper';

const useStyles = makeStyles((theme) => ({
    footer: {
        background: '#0F1C34',
        color: '#fff'
    }
}));

const FooterOrganizer = (props) => {
    const classes = useStyles();
    return <Container maxWidth="xl" className={classes.footer} component={`footer`}>
        <Typography align='right'>&copy; NBA Officials {Helper.GetYearFromDate(new Date())}</Typography>
    </Container>
}

export default FooterOrganizer;