import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const referenceDataService = {
    getMasterData,
    getGuestMasterdata,
    getAdminData,
    getAdminScoutData,
    getDashboardData,
    getAllWatchList,
    getAllWatchLists,
    getRefStatRefereeList,
    getMasterDataByName,
    getPoolList,
    getUsersList,
    getCountry,
    getState,
    savePool,
    getRoles,
    getSignalRUrl,
    savePoolDashboardStatus,
    getSeasonDates,
    SavetEventClinician,
    getAllEventClinicialList,
    getGeoJSONData,
    getRegionalOfficeList
};
function getSignalRUrl() {
    return APIHelper.signalRUrl();
}

function getRoles() {
    return APIHelper.get(APIURLConstants.GET_ROLES, authHeader())
        .then(data => {
            return data.data;
        }).catch(handleError);
}
function getRefStatRefereeList() {
    return APIHelper.get(APIURLConstants.GET_REFSTATS_DATA, authHeader())
        .then(data => {
            return data.data;
        }).catch(handleError);
}

function getMasterData() {
    return APIHelper.get(APIURLConstants.GET_MASTER_DATA, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data.data;
        }).catch(handleError);
}

function getGuestMasterdata() {
    return APIHelper.get(APIURLConstants.GET_GUEST_MASTER_DATA, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getMasterDataByName(name) {
    let postdata = {"masterGroupName" : name }
    return APIHelper.post(APIURLConstants.GET_MASTER_DATA_BY_NAME, postdata, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getPoolList() {
    let postdata = {"masterGroupName" : "Pool" }
    return APIHelper.post(APIURLConstants.GET_ALL_POOLS, postdata, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getUsersList() {
    return APIHelper.get(APIURLConstants.GET_ALL_USERS, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data.data;
        }).catch(handleError);
}

function getAdminData() {
    return APIHelper.get(APIURLConstants.GET_ADMIN_USERS, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data.data;
        }).catch(handleError);
}

function getAdminScoutData() {
    return APIHelper.get(APIURLConstants.GET_ADMIN_SCOUT_USERS, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data.data;
        }).catch(handleError);
}

function getDashboardData() {
    return APIHelper.get(APIURLConstants.GET_DASHBOARD_DATA, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getAllWatchList(scoutUID) {
    let postdata = { "scoutUID" : scoutUID}
    return APIHelper.post(APIURLConstants.GET_ALL_WATCHLIST, postdata, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getAllWatchLists() {
    return APIHelper.get(`${APIURLConstants.GET_ALL_WATCHLISTS}`, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getCountry() {
    let postdata = {"masterGroupName" : 'CountryCode' }
    return APIHelper.post(APIURLConstants.GET_MASTER_DATA_BY_NAME, postdata, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getState() {
    let postdata = {"masterGroupName" : 'State' }
    return APIHelper.post(APIURLConstants.GET_MASTER_DATA_BY_NAME, postdata, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function savePool(poolData) {
    return APIHelper.post(APIURLConstants.SAVE_POOL, poolData, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function savePoolDashboardStatus(poolData) {
    return APIHelper.post(APIURLConstants.SAVE_POOL_DASHBOARD_STATUS, poolData, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);

}

function getSeasonDates() {
    return APIHelper.get(APIURLConstants.GET_MASTER_DATA_SEASONDATES, authHeader()) 
    .then(data => {
        return data;
    }).catch(handleError);
}

function SavetEventClinician(data) {
    return APIHelper.post(APIURLConstants.SAVE_EVENT_CLINICIAL,data, authHeader())
        .then(result => {
            return result;
        }).catch(handleError);
}

function getAllEventClinicialList(data) {
    return APIHelper.post(APIURLConstants.GET_ALL_EVENT_CLINICIAL,data, authHeader())
        .then(result => {
            return result.data;
        }).catch(handleError);
}

function getGeoJSONData() {
    return APIHelper.get(APIURLConstants.GET_ALL_STATES, authHeader()) 
    .then(data => {
        return data;
    }).catch(handleError);
}

function getRegionalOfficeList(data) {
    return APIHelper.get(APIURLConstants.GET_REGIONAL_OFFICE, authHeader())
    .then(data => {
        return data;
    }).catch(handleError);
}
