import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

export const postEventAssessmentService = {
    getDecisionMaster,
    saveCandidateCampRanking
};

function getDecisionMaster(name) {
    let postdata = {"masterGroupName" : name}
    return APIHelper.post(APIURLConstants.GET_MASTER_DATA_BY_GROUP, postdata, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function saveCandidateCampRanking(data) {
    return APIHelper.post(APIURLConstants.SAVE_CANDIDATE_CAMP_POSTEVENT, data, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}