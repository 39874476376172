import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import HeadLine from '../umbraco/controls/headline';

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        background: '#112751',
        color: '#fff',
        minHeight: theme.spacing(8),
        alignItems: 'center',
        display: 'flex',
        '& h1': {
            fontSize: '3rem !important'
        },
        [theme.breakpoints.down('md')]: {
            minHeight: theme.spacing(5),
        }
    },
    pageSubTitle: {
        background: '#f2f2f2',
        color: '#3d3d3d',
        minHeight: theme.spacing(4.5),
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0px 1px 5px #aaa',
        '& h5':{
            fontSize: '1rem',
            fontFamily: `'Roboto' !important`
        }
    },
}));

export default function PageTitle({ title, subtitle }) {
    const classes = useStyles();
    return <>
        <Container maxWidth={false} component='section' className={classes.pageTitle}>
            <HeadLine variant="h1" data={title} />
        </Container>
        {subtitle && <Container maxWidth={false} component='section' className={classes.pageSubTitle}>
            <HeadLine variant="h5" data={subtitle} />
        </Container>}
    </>
}