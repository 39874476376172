import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

export const eventCalendarService = {
    getEvents,
    deleteEvent,
    exportEvents,
    importEvents,
    deleteEventMedia,
    shareEvent,
    getEventBySlug,
    saveEventUser,
    saveEventOfficials,
    saveCandidateCamp,
    getUsersByEvent,
    getYAOPublicEvents,
    getEventByID,
    saveEventTask,
    saveEventMeeting,
    saveEventAttendance,
    saveOfficiatingEvent,
    saveClinicEvent,
    savePostClinicEvent,
    getOrganizatonsByAffiliate,
    getCandidateCampRoaster,
    saveCandidateCampRoaster,
    saveCandidateCampInvitationResponse,
    getCandidateCampRosterStatus,
    getEventsByDate,
    updateEventIsCompleted,
    updateEventApprovalStatus,
    saveGuestUser,
    SendProfileCompletionRequestToGuest,
    sendEmailToRegister,
    getEventExportPDF
};


const webAPIURL = () => {
    //const { REACT_APP_PUBLIC_URL } = process.env;
    let url = `${new URL(window.location.origin)}api`;
    return url
};

function getEvents(data) {
    return APIHelper.post(APIURLConstants.GET_CANDIDATE_CAMPS, data, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getEventsByDate(data) {
    return APIHelper.post(APIURLConstants.GET_EVENTS_BY_DATE, data, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}



function importEvents(data) {
    return APIHelper.post(APIURLConstants.YAO_IMPORT_EVENTS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}


function exportEvents(data) {
    let headers = authHeader();
    headers.responseType = 'blob';
    let apiURL = webAPIURL();

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: apiURL + APIURLConstants.YAO_EXPORT_EVENTS,
            data: data,
            headers: headers
        }).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err)
        })
    })

}

// function saveEvent(data) {
//     return APIHelper.post(APIURLConstants.YAO_SAVE_EVENT, data, authHeader())
//         //.then(handleResponse)
//         .then(data => {
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             // localStorage.setItem('currentUser', JSON.stringify(user));
//             // currentUserSubject.next(user);
//             return data;
//         }).catch(handleError);
// }

function saveEventTask(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_EVENT_TASK, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveEventMeeting(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_EVENT_MEETING, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveEventAttendance(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_EVENT_ATTENDANCE, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveOfficiatingEvent(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_OFFICIATING_EVENT, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveClinicEvent(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_CLINIC_EVENT, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}

function savePostClinicEvent(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_POST_CLINIC_EVENT, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}


function saveCandidateCamp(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_CANDIDATE_CAMP_EVENT, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            return data;
        }).catch(handleError);
}


// function saveEventMedia(data) {
//     return APIHelper.post(APIURLConstants.YAO_SAVE_EVENT_MEDIA, data, authHeader())
//         //.then(handleResponse)
//         .then(data => {
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             // localStorage.setItem('currentUser', JSON.stringify(user));
//             // currentUserSubject.next(user);
//             return data;
//         }).catch(handleError);
// }


function deleteEventMedia(data) {
    return APIHelper.post(APIURLConstants.YAO_DELETE_EVENT_MEDIA, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function deleteEvent(data) {
    return APIHelper.post(APIURLConstants.YAO_DELETE_EVENT, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function shareEvent(data) {
    return APIHelper.post(APIURLConstants.SHARE_EVENT, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getEventBySlug(data) {
    return APIHelper.post(APIURLConstants.YAO_GET_EVENT_BY_SLUG, data)
        .then(data => {
            return data;
        }).catch(handleError);
}

function getEventByID(id) {
    return APIHelper.post(APIURLConstants.YAO_GET_EVENT_BY_ID, { eventID: id })
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveEventUser(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_EVENT_USER, data)
        .then(data => {
            return data;
        }).catch(handleError);
}


function saveGuestUser(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_GUEST_USER, data)
    .then(data => {
        return data;
    }).catch(handleError);
}

function saveEventOfficials(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_EVENT_OFFICIALS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getUsersByEvent(eventID) {
    return APIHelper.post(APIURLConstants.YAO_GET_USERS_BY_EVENT, { eventID: eventID }, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getYAOPublicEvents(page, batchSize, startDate, endDate, type) {
    let data = {
        PageIndex: page || 1,
        PageSize: batchSize || 6,
        eventStart: startDate || null,
        eventEnd: endDate || null
    };
    return APIHelper.post((type === 'past') ? APIURLConstants.YAO_GET_PUBLIC_EVENTS_PAST : APIURLConstants.YAO_GET_PUBLIC_EVENTS_UPCOMING, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getOrganizatonsByAffiliate(affiliateID) {
    let postdata = {"affiliateID" : affiliateID}
    return APIHelper.post(APIURLConstants.YAO_GET_ORGANIZATIONS_BY_AFFILIATION, postdata, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getCandidateCampRoaster(eventID) {
    let postdata = {"eventID" : eventID}
    return APIHelper.post(APIURLConstants.YAO_GET_CANDIDATE_CAMP_ROASTER_BY_CAMP_ID, postdata , authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveCandidateCampRoaster(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_CANDIDATE_CAMP_EVENT_ROASTER, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function saveCandidateCampInvitationResponse(data) {
    return APIHelper.post(APIURLConstants.YAO_SAVE_CANDIDATE_CAMP_EVENT_INVITATION_RESPONSE, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getCandidateCampRosterStatus(data) {
    let postdata = {"eventID" : data}
    return APIHelper.post(APIURLConstants.GET_CANDIDATE_CAMP_ROSTER_STATUS, postdata, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function updateEventIsCompleted(data) {
    return APIHelper.post(APIURLConstants.SAVE_EVENT_ISCOMPLETED, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}
function updateEventApprovalStatus(data) {
    return APIHelper.post(APIURLConstants.SAVE_EVENT_APPROVAL_STATUS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function SendProfileCompletionRequestToGuest(data) {
    return APIHelper.post(APIURLConstants.GUEST_PROFILE_REGISTRATION_REQUEST, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);

}

function sendEmailToRegister(data) {
    return APIHelper.post(APIURLConstants.GUEST_PROFILE_REGISTRATION_REQUEST_SINGLE, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);

}

function getEventExportPDF(eventID) {
    let headers = authHeader();
    headers.responseType = 'blob';
    let apiURL = webAPIURL();

    var postdata = { "eventID": eventID };

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: apiURL + APIURLConstants.GET_EXPORT_EVENT_PDF,
            data: postdata,
            headers: headers
        }).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err)
        })
    })
}
