import React from 'react';
import { Button, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core';
import ThumbUp from '@material-ui/icons/ThumbUp';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import RouteConstants from '../../common/RouteConstants';
import AppConstants from '../../common/AppConstants';
import moment from 'moment';
import Helper from '../../common/Helper';
import { generatePath, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        border: '1px solid #112751',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(0.5),
        background: '#FFFFFF',
        border: '2px solid #C9C9C9',
        boxShadow: 'inset 0px 0px 20px rgba(0, 0, 0, 0.35)'
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
        borderBottom: '1px solid #112751'
    },
    cardTitle: {
        textDecoration: 'none'
    },
    cardContent: {
        padding: theme.spacing(2, 1),
    },
    cardActions: {
        justifyContent: 'space-between'
    },
    cardListItemText: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        margin: theme.spacing(1),
        borderRadius: 15,
        textTransform: 'none'
    },
    doneBtn: {
        background: "green",
        color: "white",
        cursor: "not-allowed"
    },
}));

const LessonPlanCard = ({ item }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleOpenQuiz = (value, e) => {
        if (e) e.preventDefault()
        history.push({
            pathname: generatePath(RouteConstants.LESSON_PLAN_DETAIL, { id: value.id, title: Helper.convertToSlug(value.lessonPlanName) })
        });
    }

    const getQuizStatus = (value) => {

        //if (moment(Helper.FormatDate(item.dueDate)).isBefore(Helper.FormatDate(moment())) && !value.dueDate.startsWith('0001')) {
        //    return false;
        //}

        

        if (value && value.userHistoryStatus === 1) {
            return <Button
                variant="contained"
                className={`${classes.button} ${classes.doneBtn}`}
                endIcon={<ThumbUp />}
            >
                Done
            </Button>
        } 
        
        if((moment(Helper.FormatDate(item.dueDate)).isSameOrAfter(Helper.FormatDate(moment())) && !value.dueDate.startsWith('0001')) && (value && value.userHistoryStatus === 0)) {
            return <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<PlayCircleFilled />}
                onClick={() => handleOpenQuiz(value)}
            >
                {value.userHistoryStatusName === AppConstants.QUIZ_STATUS_NAME.IN_PROGRESS ? "Resume Lesson Plan" : "Take a Lesson Plan"}
            </Button>
        } else if (value.dueDate.startsWith('0001')) {
            return <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<PlayCircleFilled />}
                onClick={() => handleOpenQuiz(value)}
            >
                {value.userHistoryStatusName === AppConstants.QUIZ_STATUS_NAME.IN_PROGRESS ? "Resume Lesson Plan" : "Take a Lesson Plan"}
            </Button>
         }
    }
    return (
        <Card raised className={classes.cardRoot}>
            <CardContent className={classes.cardContent}>
                <Typography variant="h4" color='primary' className={classes.cardTitle}>{item.lessonPlanName}</Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {!item.isPublic &&
                        <>
                            <Typography variant="body2" color="primary">Due Date: </Typography>&nbsp;<Typography color="secondary" variant="subtitle2">{(item.dueDate === null || item.dueDate.startsWith('0001')) ? 'NA' : (moment(Helper.FormatDate(item.dueDate)).isSameOrAfter(Helper.FormatDate(moment())) ? Helper.FormatDate(item.dueDate) : 'Passed Due Date.')}</Typography>
                        </>
                    }
                </span>
                {getQuizStatus(item)}
            </CardActions>
        </Card>
    )
}

export default LessonPlanCard;