import React from 'react';
import { Button, Container, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 560,
        background: 'black',
        zIndex:0,
        [theme.breakpoints.only('sm')]: {
            height: 380
        }
    },
    slideBackground: {
        position: 'absolute',
        zIndex: 0,
        width: '100%',
        // opacity: '0.3',
        height: '100%',
        objectFit: 'cover'
    },
    slideContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '80px',
        width: '100%',
        flex: 1,
        height: '100%',
        alignItems: 'center',
        position: 'absolute',
        color: '#fff',
        background: 'rgba(0,0,0,0.5)',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '40px',
        },
        '& p': {
            lineHeight: '28px',
            fontSize: '16px'
        },
        '& a': {
            display: 'inline-block',
            color: '#fff',
            border: '1px solid #fff',
            padding: '8px 12px',
            fontSize: '1rem',
            fontWeight: 'bold',
            borderRadius: '4px',
            '&:hover': {
                textDecoration: 'none',
            }
        }
    },
    navButtonWrapper: {
        backgroundColor: 'transparent !important',
        '&:hover': {
            backgroundColor: 'transparent !important',
            color: '#fff',
            opacity: '1 !important'
        }
    },
    navButton: {
        backgroundColor: 'transparent !important',
        color: 'rgba(255, 255, 255, 0.5)',
        '&:hover': {
            backgroundColor: 'transparent !important',
            color: '#fff',
            opacity: '1 !important'
        }
    },
    navIcon: {
        width: 48,
        height: 48
    }
}));

const HeroSliderCorousel = ({ items }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleLinkClick = (event) => {
        event.preventDefault();
        history.push({
            pathname: event.target.pathname
        });
        return;
    }


    function Item({ item }) {
        return (
            <div className={classes.slide}>
                {item.image && <img src={item.image._url} className={classes.slideBackground} />}
                <div className={classes.slideContent}>
                    <Container maxWidth="md">
                        <Typography variant='h2'>{item.title}</Typography>
                        <p>{item.subtitle}</p>
                        {item.redirectURL && <Link href={item.redirectURL} onClick={handleLinkClick}>Read More</Link>}
                    </Container>
                </div>
            </div>
        )
    }

    return <Carousel
        fullHeightHover={false}
        stopAutoPlayOnHover
        interval={10000}
        duration={500}
        // autoPlay={false}
        // animation="slide"
        swipe={items.length > 1}
        navButtonsAlwaysVisible={items.length > 1}
        indicators={items.length > 1}
        navButtonsAlwaysInvisible={items.length === 1}
        NextIcon={<ChevronRightIcon className={classes.navIcon} />}
        PrevIcon={<ChevronLeftIcon className={classes.navIcon} />}
        className={classes.root}
        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            className: classes.navButton
        }}
        navButtonsWrapperProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            className: classes.navButtonWrapper
        }}
    >
        {
            items.map((item, i) => <Item key={i} item={item} />)
        }
    </Carousel>
}

export default HeroSliderCorousel;