import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import AuthLayoutContainer from "./AuthLayoutContainer";
import ForgotPasswordComponent from "./components/ForgotPasswordComponent";

const useStyles = makeStyles((theme) => ({

}));

const ForgotPassword = (props) => {
    const classes = useStyles();
    useEffect(() => {
    }, [])

    return <>
        <AuthLayoutContainer children={<ForgotPasswordComponent />} />
    </>

}

export default ForgotPassword;