export const cacheStorage = {
    saveProfilePhotoToLocalStorage,
    getProfilePhotoFromLocalStorage,
    removeProfilePhotoFromLocalStorage
};

function saveProfilePhotoToLocalStorage(username, signedUrl){
    sessionStorage.setItem(username, signedUrl);
}

function getProfilePhotoFromLocalStorage(username) {
    return sessionStorage.getItem(username);
}

function removeProfilePhotoFromLocalStorage(username) {
    sessionStorage.removeItem(username);
}