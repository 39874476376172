import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import AuthLayoutContainer from "./AuthLayoutContainer";
import ForceChangePasswordComponent from "./components/ForceChangePasswordComponent";

const useStyles = makeStyles((theme) => ({

}));

const ForceChangePassword = (props) => {
    const classes = useStyles();
    useEffect(() => {
    }, [])

    return <>
        <AuthLayoutContainer children={<ForceChangePasswordComponent />} />
    </>

}

export default ForceChangePassword;