import { Link } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { generatePath, useHistory } from 'react-router-dom';
import RouteConstants from '../../common/RouteConstants';
import Validations from "../../common/Validations";

import Dropdown from "./Dropdown";

const MenuItems = ({ item, depthLevel, navDrawerClose }) => {
    const [dropdown, setDropdown] = useState(false);
    const history = useHistory();

    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    const handleLinkClick = (event, url) => {
        event.preventDefault();
        let isThirdParty = Validations.Validate("", url, { URL: true });
        if (isThirdParty.isValid) {
            window.open(event.target.href, '_blank');
            return false;
        }
        history.push({
            pathname: event.target.pathname
        });
        if (navDrawerClose) {
            navDrawerClose();
        }
        return;
    }

    return item && <li
        className="menu-items"
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        {item.navChildren ? (
            <>
                <button
                    type="button"
                    aria-haspopup="menu"
                    aria-expanded={dropdown ? "true" : "false"}
                    onClick={() => setDropdown((prev) => !prev)}
                >
                    {item.title}{" "}
                    {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
                </button>
                <Dropdown
                    depthLevel={depthLevel}
                    submenus={item.navChildren}
                    dropdown={dropdown}
                    navDrawerClose={navDrawerClose}
                />
            </>
        ) : <Link href={item.navUrl} onClick={(e) => handleLinkClick(e, item.navUrl)}>{item.title}</Link>
        }
    </li>;
};

export default MenuItems;