import React, { useContext } from "react";
import { generatePath, useLocation, withRouter } from "react-router-dom";
import { AppBar, Hidden, makeStyles, Toolbar, Typography, Box } from "@material-ui/core";
import NBA_Officiate_logo from '../../images/nba-officials.png';
import Helper from "../../common/Helper";
import AppDataContext from "../../common/AppContext";
import RouteConstants, { DefaultRoutes } from "../../common/RouteConstants";
import { userService } from "../../services";


import "./AppHeaderBar.css";

const useStyles = makeStyles((theme) => ({
    appBar: {

        color: theme.palette.type === "dark" && "#fff",
        backgroundColor: theme.palette.type === "dark" && theme.palette.primary.dark,
        [theme.breakpoints.down('md')]: {
            // height: '60px',
            // fontSize: '.4rem'
        },
        '&::after': {
            content: '""',
            display: 'block',
            height: "auto",
            top: 0,
            right: 0,
            borderTop: "48px solid #112751",
            borderLeft: "30px solid transparent",
            position: "absolute",
            zIndex: -1,
            paddingLeft: 42
        }
    },
    offset: theme.mixins.toolbar,
    logo: {
        //flexGrow: 1,
        lineHeight: 1,
        cursor: 'pointer'
    },
}));
const AppHeaderBar = ({ mainNav, mobileNav, notificationMenu, userActionMenu, history, match, location, ...props }) => {
    const classes = useStyles();
    const { checkRole, roles } = useContext(AppDataContext);

    const redirectToHome = async () => {
        let userRole = await userService.getUserRole();
        console.log(userRole)
        let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUserLocalStorage != null || currentUserLocalStorage?.isAuthenticated) {
            // not logged in so redirect to login page with the return url
            if (userRole === 'Admin' || userRole === 'Scout' || userRole === 'AdminScout') {
                return history.push({
                    pathname: generatePath(DefaultRoutes.ADMIN, { 0: '/' }),
                    state: undefined,
                })
            } else if (userRole === 'EventAdmin') {
                return history.push({
                    pathname: generatePath(DefaultRoutes.EVENTADMIN, { 0: '/' }),
                    state: undefined,
                })
            } else if (userRole === 'User') {
                return history.push({
                    pathname: generatePath(DefaultRoutes.PROSPECT)
                })
            }
            else {
                return history.push({
                    pathname: '/',
                    state: undefined,
                })
            }
        }
        else {
            return history.push({
                pathname: '/',
                state: undefined,
            })
        }

    }


    const getAppBar = React.useMemo(() => {
        return <AppBar position="fixed" className={classes.appBar} >
            <Toolbar variant="dense">
                {/*<img alt="logo" src={NBA_Officiate_logo} className="appbar-nba-logo appbar-nba-logo-rightMargin" onClick={(e) => { e.preventDefault(); history.push({ pathname: '/' }) }} />
                <Typography variant="h6" className={`${classes.logo} headerTitle`} onClick={(e) => { e.preventDefault(); history.push({ pathname: '/' }) }}>
                    Officiating Opportunities
                </Typography>*/}
                <img alt="logo" src={NBA_Officiate_logo} className="appbar-nba-logo appbar-nba-logo-rightMargin" onClick={redirectToHome} />
                <Typography variant="h6" className={`${classes.logo} headerTitle`} onClick={redirectToHome}>
                    Officiating Opportunities
                </Typography>
                <Hidden smDown>{!Helper.compareURL(location.pathname, "admin", 1) && mainNav}</Hidden>
                <Hidden mdUp>{!Helper.compareURL(location.pathname, "admin", 1) && mobileNav}</Hidden>
                <Box component='div' position='absolute' right={8}>
                    {Helper.isUserLoggedIn() && !Helper.compareURL(location.pathname, "login", 1) && Object.keys(roles).length > 0 && !checkRole("partner") && !checkRole("eventadmin") && notificationMenu}
                    {userActionMenu}
                </Box>
            </Toolbar>
        </AppBar>;
    }, [mainNav, mobileNav, notificationMenu]);

    return getAppBar;
}

export default withRouter(AppHeaderBar);