import { marked } from 'marked';
import dompurify from 'dompurify';

export const rawMarkup = (data) => {
    const sanitizer = dompurify.sanitize;
    let rawMarkup = marked(sanitizer(data));
    return { __html: rawMarkup };
};

export const saveUserInput = (index, numberOfSelection, answerSelectionType, {
    userInput,
    currentQuestionIndex,
    setButtons,
    setUserInput,
}) => {

    if (answerSelectionType === 'single') {
        if (userInput[currentQuestionIndex] === undefined) {
            userInput.push(index)

            setUserInput(userInput)
        } else {
            userInput[currentQuestionIndex] = index
            setUserInput(userInput)
        }
        setButtons((prevState) => ({
            //...prevState,
            dissabled: true,
            [index - 1]: {
                className: "userInput"
            },
        })
        );
    } else {
        let maxNumberOfMultipleSelection = numberOfSelection;
        if (userInput[currentQuestionIndex] === undefined) {
            userInput[currentQuestionIndex] = []
        }
        let ansIndex = userInput[currentQuestionIndex].indexOf(index)
        if (ansIndex === -1) {
            if (userInput[currentQuestionIndex].length < maxNumberOfMultipleSelection) {
                userInput[currentQuestionIndex].push(index);
                setButtons((prevState) => ({
                    ...prevState,

                    [index - 1]: {
                        className: "userInput"
                    },
                })
                )
            }
        } else {

            userInput[currentQuestionIndex].splice(ansIndex, 1);
            setButtons((prevState) => ({
                ...prevState,

                [index - 1]: {
                    className: ""
                },
            })
            )
        }
        setUserInput(userInput)
    }
}

export const verifyAnswer = (result, numberOfSelection, answerSelectionType, correctAnswers, answers, userAnswers, {
    userInput,
    userAttempt,
    currentQuestionIndex,
    continueTillCorrect,
    showNextQuestionButton,
    incorrect,
    correct,
    setButtons,
    setCorrectAnswer,
    setIncorrectAnswer,
    setCorrect,
    setIncorrect,
    setShowNextQuestionButton,
    setUserInput,
    setUserAttempt
}) => {
    //const indexStr = `${index}`;
    const disabledAll = {
        0: { disabled: true },
        1: { disabled: true },
        2: { disabled: true },
        3: { disabled: true }
    };
    if (answerSelectionType === 'single') {

        if (result) {
            if (incorrect.indexOf(currentQuestionIndex) < 0 && correct.indexOf(currentQuestionIndex) < 0) {
                correct.push(currentQuestionIndex);
            }

            setButtons((prevState) => ({
                ...prevState,
                ...disabledAll,
                [userInput[currentQuestionIndex] - 1]: {
                    className: result ? "correct" : "incorrect",
                    disabled: true
                },
            })
            );

            setCorrectAnswer(true);
            setIncorrectAnswer(false);
            setCorrect(correct);
            setShowNextQuestionButton(true)

        } else {
            if (correct.indexOf(currentQuestionIndex) < 0 && incorrect.indexOf(currentQuestionIndex) < 0) {
                incorrect.push(currentQuestionIndex)
            }
            setButtons((prevState) => (
                Object.assign(
                    {},
                    prevState,
                    {
                        ...disabledAll,
                        [userInput[currentQuestionIndex] - 1]: {
                            className: result ? "correct" : "incorrect"
                        },
                    }
                )
            ));
            setShowNextQuestionButton(true)
            setIncorrectAnswer(true);
            setCorrectAnswer(false);
            setIncorrect(incorrect)
        }
    } else {

        let maxNumberOfMultipleSelection = numberOfSelection;
        const disabledAll = {
            0: { disabled: true },
            1: { disabled: true },
            2: { disabled: true },
            3: { disabled: true }
        };
        if (result) {
            userInput[currentQuestionIndex].forEach(element => {
                disabledAll[element - 1] = { disabled: true, className: 'correct' }
            });
            if (userInput[currentQuestionIndex].length <= maxNumberOfMultipleSelection) {

                setButtons((prevState) => ({
                    ...prevState,
                    ...disabledAll,
                })
                )
            }
        } else {
            userInput[currentQuestionIndex].forEach(element => {
                disabledAll[element - 1] = { disabled: true, className: correctAnswers.includes(answers[element - 1]) ? 'correct' : 'incorrect' }
            });
            if (userInput[currentQuestionIndex].length <= maxNumberOfMultipleSelection) {
                setButtons((prevState) => ({
                    ...prevState,
                    ...disabledAll
                })
                )
            }


        }
        if (maxNumberOfMultipleSelection === userAnswers.length) {
            let cnt = 0;
            for (let i = 0; i < correctAnswers.length; i++) {
                if (userAnswers.includes(correctAnswers[i])) {
                    cnt++;
                }
            }

            if (cnt === maxNumberOfMultipleSelection) {
                correct.push(currentQuestionIndex);

                setCorrectAnswer(true);
                setIncorrectAnswer(false);
                setCorrect(correct);
                setShowNextQuestionButton(true);
            } else {
                incorrect.push(currentQuestionIndex);
                setIncorrectAnswer(true);
                setCorrectAnswer(false);
                setIncorrect(incorrect);
                setShowNextQuestionButton(true);

            }
        } else {
            if (!showNextQuestionButton) {
                setUserInput(userInput);
            }
        }
    }
};

export const checkAnswer = (index, correctAnswer, answerSelectionType, {
    userInput,
    userAttempt,
    currentQuestionIndex,
    continueTillCorrect,
    showNextQuestionButton,
    incorrect,
    correct,
    setButtons,
    setCorrectAnswer,
    setIncorrectAnswer,
    setCorrect,
    setIncorrect,
    setShowNextQuestionButton,
    setUserInput,
    setUserAttempt
}) => {
    const indexStr = `${index}`;
    const disabledAll = {
        0: { disabled: true },
        1: { disabled: true },
        2: { disabled: true },
        3: { disabled: true }
    };
    if (answerSelectionType === 'single') {
        if (userInput[currentQuestionIndex] === undefined) {
            userInput.push(index)
        }

        if (indexStr === correctAnswer) {
            if (incorrect.indexOf(currentQuestionIndex) < 0 && correct.indexOf(currentQuestionIndex) < 0) {
                correct.push(currentQuestionIndex);
            }

            setButtons((prevState) => ({
                ...prevState,
                ...disabledAll,
                [index - 1]: {
                    className: (indexStr === correctAnswer) ? "correct" : "incorrect"
                },
            })
            );

            setCorrectAnswer(true);
            setIncorrectAnswer(false);
            setCorrect(correct);
            setShowNextQuestionButton(true)

        } else {
            if (correct.indexOf(currentQuestionIndex) < 0 && incorrect.indexOf(currentQuestionIndex) < 0) {
                incorrect.push(currentQuestionIndex)
            }

            if (continueTillCorrect) {
                setButtons((prevState) => (
                    Object.assign(
                        {},
                        prevState,
                        {
                            [index - 1]: {
                                disabled: !prevState[index - 1]
                            }
                        }
                    )
                ))
            } else {
                setButtons((prevState) => (
                    Object.assign(
                        {},
                        prevState,
                        {
                            ...disabledAll,
                            [index - 1]: {
                                className: (indexStr === correctAnswer) ? "correct" : "incorrect"
                            },
                        }
                    )
                ));

                setShowNextQuestionButton(true)
            }

            setIncorrectAnswer(true);
            setCorrectAnswer(false);
            setIncorrect(incorrect)
        }
    } else {

        let maxNumberOfMultipleSelection = correctAnswer.length;

        if (userInput[currentQuestionIndex] === undefined) {
            userInput[currentQuestionIndex] = []
        }

        if (userInput[currentQuestionIndex].length < maxNumberOfMultipleSelection) {
            userInput[currentQuestionIndex].push(index);

            if (correctAnswer.includes(index)) {
                if (userInput[currentQuestionIndex].length <= maxNumberOfMultipleSelection) {

                    setButtons((prevState) => ({
                        ...prevState,
                        [index - 1]: {
                            disabled: !prevState[index - 1],
                            className: (correctAnswer.includes(index)) ? "correct" : "incorrect"
                        },
                    })
                    )
                }
            } else {
                if (userInput[currentQuestionIndex].length <= maxNumberOfMultipleSelection) {
                    setButtons((prevState) => ({
                        ...prevState,
                        [index - 1]: {
                            className: (correctAnswer.includes(index)) ? "correct" : "incorrect"
                        },
                    })
                    )
                }
            }
        }

        if (maxNumberOfMultipleSelection === userAttempt) {
            let cnt = 0;
            for (let i = 0; i < correctAnswer.length; i++) {
                if (userInput[currentQuestionIndex].includes(correctAnswer[i])) {
                    cnt++;
                }
            }

            if (cnt === maxNumberOfMultipleSelection) {
                correct.push(currentQuestionIndex);

                setCorrectAnswer(true);
                setIncorrectAnswer(false);
                setCorrect(correct);
                setShowNextQuestionButton(true);
                setUserAttempt(1)
            } else {
                incorrect.push(currentQuestionIndex);

                setIncorrectAnswer(true);
                setCorrectAnswer(false);
                setIncorrect(incorrect);
                setShowNextQuestionButton(true);
                setUserAttempt(1)
            }
        } else {
            if (!showNextQuestionButton) {
                setUserInput(userInput);
                setUserAttempt(userAttempt + 1)
            }
        }
    }
};
