import React from 'react';
import { makeStyles } from '@material-ui/core';
import OpenDoubleQuoteIcon from '../../../sharedComponents/customIcons/OpenDoubleQuoteIcon';

const useStyles = makeStyles((theme) => ({
    quote: {
        maxWidth: 1066,
        margin: '0 auto',
        fontSize: '22px',
        color: '#fff',
        textAlign: 'center',
        '& .startQuote': {
            width: 56,
            height: 28
        },
        '& .endQuote': {
            width: 56,
            height: 28,
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            marginBottom: -3,
            '& path': {
                fill: 'rgba(255, 255, 255, 0.25)'
            }
        }
    },
}));

const QuoteSection = ({ quote }) => {
    const classes = useStyles();

    return <blockquote className={classes.quote}>
        {/* <OpenDoubleQuoteIcon className="startQuote" /> */}
        {quote}
        {/* <OpenDoubleQuoteIcon className="endQuote" /> */}
    </blockquote>
}

export default QuoteSection;