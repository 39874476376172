import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './ServiceWorker';
import TagManager from 'react-gtm-module';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/authConfig';
import { AbilityContext } from './common/Can'
import {createMongoAbility} from '@casl/ability'


const msalInstance = new PublicClientApplication(msalConfig);

const tagManagerArgs = {
    gtmId: 'GTM-T376RVT'
}

TagManager.initialize(tagManagerArgs);
const ability= createMongoAbility()

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <AbilityContext.Provider value={ability}>
            <App />
        </AbilityContext.Provider>
    </MsalProvider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();

