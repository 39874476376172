import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import BasketballHoopIcon from '../../../sharedComponents/customIcons/BasketballHoopIcon';
import EventCard from '../../events/EventCard';
import { eventCalendarService } from '../../../services';
import { useHistory } from 'react-router-dom';
import RouteConstants from '../../../common/RouteConstants';
import Helper from '../../../common/Helper';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(3.5),
        background: '#E4E4E4',
        '& .MuiListItemSecondaryAction-root': {
            right: 0
        }
    },
    icon: {
        minWidth: 'unset',
        marginRight: 12
    },
    title: {
        textTransform: 'uppercase',
        color: 'rgba(0,0,0,0.75)'
    }
}));

const EventsSection = () => {
    const classes = useStyles();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        let today = new Date();
        let startDate = Helper.FormatDateToLocalTimeZone(today);
        let endDate = Helper.FormatDateToLocalTimeZone(today.setDate(today.getDate() + 30));
        eventCalendarService.getYAOPublicEvents(1, 3, startDate, endDate).then((result) => {
            setData(result.data.eventCalendarList);
            setIsLoading(false);
        }).catch((e) => console.error(e));
    }, []);

    return (!isLoading && data && data.length) ? <section className={classes.content}>
        <Container component="div" maxWidth="lg">
            <ListItem role={undefined} ContainerComponent='div' disableGutters>
                <ListItemIcon className={classes.icon}>
                    <BasketballHoopIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Upcoming Events"
                    primaryTypographyProps={{
                        variant: "h5",
                        component: "h5",
                        className: classes.title
                    }}
                />
                <ListItemSecondaryAction>
                    <Button variant='outlined' color='primary' size='small'
                        onClick={() => {
                            history.push({
                                pathname: RouteConstants.YAO_EVENTS
                            });
                        }}>
                        View All
                    </Button>
                </ListItemSecondaryAction>
            </ListItem>

            <Grid container spacing={4}>
                {data && data.length ? data.map((e, i) =>
                    <Grid item xs={12} sm={6} md={4} key={i}><EventCard event={e} /></Grid>
                ) : null
                }
            </Grid>
        </Container>
    </section> : null
}

export default EventsSection;