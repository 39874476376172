import Moment from "moment";
import { authenticationService } from "../services";
import CultureInfo from "./CultureInfo";
import AppConstants from "./AppConstants";
import jwt_decode from 'jwt-decode'
//import { Role } from "../helper";

export default class Helper {
  static DateToTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  static FormatDate(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DateFormat)
      : "";
  }

  static FormatDateMMYYYY(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().MonthWithYearFormat)
      : "";
  }

  static FormatDateMMMYYYY(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().MonWithYearFormat)
      : "";
  }
  static FormatYearDate(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().YearDateFormat)
      : '';
  }

  static GetYearFromDate(date) {
    var d = new Date(date);
    try {
      return d.getFullYear();
    } catch (e) {
      return "";
    }
  }

  static FormatTime(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().TimeFormat)
      : "";
  }

  static FormatTime24(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().TimeFormat24)
      : "";
  }

  static FormatDayWithDate(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DayWithDateFormat)
      : "";
  }

  static FormatDateToLocalTimeZone(value) {
    return value
      ? Moment(value).local().format(CultureInfo.GetCultureInfo().DateFormat)
      : "";
  }

  static FormatDateTime(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DateTimeFormat)
      : "";
  }

  static FormatDateTime24(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DateTimeFormat24)
      : "";
  }

  static FormatDateTime24WithTimeZone(value) {
    return value
      ? Moment(value).format(CultureInfo.GetCultureInfo().DateTimeFormat24WithTimezone)
      : "";
  }

  static FormatDateTimeToLocalTimeZone(value) {
    return value
      ? Moment(value)
        .local()
        .format(CultureInfo.GetCultureInfo().DateTimeFormat24)
      : "";
  }

  static getTomorrowDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return `${tomorrow.getFullYear()}/${tomorrow.getMonth() + 1}/${tomorrow.getDate()}`;
  }

  static getTodayDate() {
    const today = new Date();
    today.setDate(today.getDate());
    return `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;
  }

  //... Returns a random Id string. Id length is based on the supplied length.
  static GetRandomId(length = 5) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  static IfDateIsNULL(inputDate) {
    var minDate = Moment.utc("01/01/0001"); // minimum value as per UTC

    var receiveDate = Moment(this.FormatDate(inputDate)); // replace with variable
    if (Moment.utc(receiveDate).isAfter(minDate)) {
      return false;
    } else {
      return true;
    }
  }

  static GetNameInitials(name) {
    var iChars = "";
    name && name.replace(/[a-z]{2,}/gi, function (c) {
      iChars += c.charAt(0);
    });
    return iChars.toUpperCase();
  }

  static MakeFirstCharCapital(value) {
    if (value && value.length > 0) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return "";
  }
  static GetIframeElement() {
    var iframe = document.querySelector('#downloadiframe');
    if (!iframe) {
      // if no existing iframe create a new one
      iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'downloadiframe');
      iframe.style.display = 'none';
      if (document.body) {
        document.body.appendChild(iframe);
      }
    }
    // Clean the iframe up
    iframe.contentDocument.write('<head></head><body></body>');
    return iframe;
  }

  static isUserLoggedIn() {
    let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUserLocalStorage === null || !currentUserLocalStorage.isAuthenticated) {
      // not logged in
      return false;
    }
    return true;
  }

  static isUserAccountVerified() {
    let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUserLocalStorage === null || !currentUserLocalStorage.isAuthenticated || !currentUserLocalStorage.isEmailVerified) {
      return false;
    }
    return true;
  }

  static getUserRoleId() {
    let token = localStorage.getItem('accessToken');
    if (token && token !== null) {
      let user = jwt_decode(token)
      // not logged in so redirect to login page with the return url
      return user?.roleid ?? 0
    }
    return undefined
  }

  static isUserAdmin() {
    let token = localStorage.getItem('accessToken');
    if (token && token !== null) {
      let user = jwt_decode(token)
      // not logged in so redirect to login page with the return url
      if (user.role === 'Admin' || user.role === 'Scout' || user.role === 'AdminScout' || user.role === 'EventAdmin') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    return true;
  }

  static getOfficialId() {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user && user !== null && user.token) {
      let userdetails = jwt_decode(user.token)
      // not logged in so redirect to login page with the return url
      if (userdetails.official_id) {
        return userdetails.official_id;
      }
      return ''
    }
  }

  static Logout(redirect) {
    authenticationService.logout();
    window.location.href = redirect && redirect || '/';
    return;
  }

  static ReLogin(redirect) {
    authenticationService.logout();
    window.location.href = redirect && redirect || '/login';
    return;
  }

  static updateLocalStorageData(lsKey, updatedProp) {
    let currentLSData = JSON.parse(localStorage.getItem(lsKey));
    if (currentLSData) {
      localStorage.setItem(lsKey,
        JSON.stringify({ ...currentLSData, ...updatedProp }));
    }
    return;
  }

  static maskEmail(email) {
    var maskedEmail = email.replace(/([^@\.])/g, "*").split('');
    var previous = "";
    for (let i = 0; i < maskedEmail.length; i++) {
      if (i <= 1 || previous == "." || previous == "@") {
        maskedEmail[i] = email[i];
      }
      previous = email[i];
    }
    return maskedEmail.join('');
  }

  static convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  }

  static compareURL(mainURL, compareString, segment) {
    if (segment) {
      let segmentString = mainURL.split('/')[segment];
      return segmentString === compareString;
    }
    return mainURL === compareString;
  }
  static isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  static StringExcerpt(string, count = 120) {
    if (string.length > count) {
      return string.substring(0, count) + "...";
    }
    return string.substring(0, count);
  }

  static getApprovalStatus(status) {
    var approvalStatus = AppConstants.APPROVAL_STATUS.filter((item, index) => { return item.value === status })
    if (approvalStatus && approvalStatus.length > 0)
      return approvalStatus[0].text;
    else return "";
  }

  static getStorage(key) {
    var now = Date.now(); //epoch time, lets deal only with integer
    // set expiration for storage
    var expiresIn = localStorage.getItem(key + '_expiresIn');
    if (expiresIn === undefined || expiresIn === null) {
      expiresIn = 0;
    }

    if (expiresIn < now) {
      // Expired
      localStorage.removeItem(key);
      return null;
    } else {
      try {
        if (key == 'accessToken' || key == 'chk') {
          var value = localStorage.getItem(key);
          return value;
        } else {
          return JSON.parse(localStorage.getItem(key));
        }
      } catch (e) {
        console.error(e);
        //console.log('getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e));
        return null;
      }
    }
  }
  /*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
  static setStorage(key, value, expires) {
    if (expires === undefined || expires === null) {
      expires = 24 * 60 * 60; // default: seconds for 1 day
    } else {
      expires = Math.abs(expires); //make sure it's positive
    }

    var now = Date.now(); //millisecs since epoch time, lets deal only with integer
    var schedule = now + expires * 1000;
    try {
      if (typeof value === "string" || value instanceof String) {
        localStorage.setItem(key, value);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }

      localStorage.setItem(key + "_expiresIn", schedule);
    } catch (e) {
      //console.log('setStorage: Error setting key [' + key + '] in localStorage: ' + JSON.stringify(e));
      return false;
    }
    return true;
  }

  static clearLocalStorage() {
    if (localStorage) {
      let app_version = localStorage.app_version_reps;
      localStorage.clear();
      if (app_version) {
        localStorage.setItem("app_version_dc", app_version);
      }
    }
  }
}
