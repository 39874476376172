export function handleResponse(response) {
    return new Promise((resolve, reject) => {
        const data = response.response && JSON.parse(response.response);
        if (response.ok == false) {
            const error = {
                errors: [],
                message: response && response.message || 'Something went wrong! Please try again later.'
            }
            reject(error);
        }

        resolve(data);
    });
}