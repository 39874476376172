export const defaultLocale = {
  landingHeaderText: "<questionLength> Questions",
  question: "Question",
  startQuizBtn: "Start",
  resumeQuizBtn: "Resume",
  resultFilterAll: "All",
  resultFilterCorrect: "Correct",
  resultFilterIncorrect: "Incorrect",
  nextQuestionBtn: "Next",
  resultPageHeaderText:
    "You have completed the lesson plan. You got <correctIndexLength> out of <questionLength> questions.",
  resultPagePoint: "You scored <correctPoints> out of <totalPoints>.",
  singleSelectionTagText: "Single Selection",
  multipleSelectionTagText: "Multiple Selection",
  pickNumberOfSelection: "Pick <numberOfSelection>",
  submitAnswer: "Submit",
};
