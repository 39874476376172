import React, { useContext, useEffect, useState } from 'react';
import { Collapse, IconButton, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import AppDataContext from '../../common/AppContext';

const useStyles = makeStyles((theme) => ({
    alertGM: {
        '& .MuiAlert-message': {
            width: '100%'
        }
    }
}));

const GlobalMessageSection = ({ message }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const { navigationMenu, updateNavigationMenu } = useContext(AppDataContext);

    return <Collapse in={open}>
        <Alert
            className={classes.alertGM}
            icon={false}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        updateNavigationMenu({ ...navigationMenu, globalMessage: "" });
                        setOpen(false);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            severity={`info`}
        ><span dangerouslySetInnerHTML={{ __html: message }}></span></Alert>
    </Collapse>
}

export default GlobalMessageSection;