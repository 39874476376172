import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import AppBar from '../sharedComponents/appBar/AppHeaderBar';
import UserActionMenu from '../sharedComponents/appBar/UserActionMenu';
import NotificationMenu from '../sharedComponents/Notifications/NotificationMenu';
import Navbar from '../sharedComponents/multiLevelTopNav/NavBar';
import NavDrawer from '../sharedComponents/multiLevelTopNav/NavDrawer';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        zIndex: 0,
        marginTop: theme.mixins.toolbar.minHeight
    }
}));

const AdminLayout = ({ history, children }) => {

    const classes = useStyles();
    return (
        <>
            <AppBar
                mainNav={<Navbar />}
                mobileNav={<NavDrawer />}
                notificationMenu={<NotificationMenu />}
                userActionMenu={<UserActionMenu />}
            />
            {/* <UserProfileProvider>
                <UserProfileConsumer>
                    {(props) => {
                        return ( */}
            <div className={classes.root}>
                {children}
            </div>
            {/* )
                    }}
                </UserProfileConsumer>
            </UserProfileProvider> */}
        </>
    );
}


export default AdminLayout;