import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, makeStyles, TextField, Dialog, DialogContent, DialogContentText, DialogTitle, } from '@material-ui/core';
import AppDataContext from '../../../../../common/AppContext';
import { userService } from '../../../../../services';
import Helper from '../../../../../common/Helper';
import RouteConstants from '../../../../../common/RouteConstants';
import Validations from '../../../../../common/Validations';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles((theme) => ({

}));

const ChangePasswordFormComponent = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const { addSnackMessages } = useContext(AppDataContext);
    const [redirectOpen, setRedirectOpen] = useState(false);
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });
    const [formDataError, setFormDataError] = useState([]);
    const history = useHistory();
    useEffect(() => {
        if (Object.keys(formDataError).length) isFormDataValid();
    }, [formData]);

    const isFormDataValid = () => {
        let errors = [];
        Object.entries(formData).forEach(([key, value]) => {
            let field = false;
            switch (key) {
                case "currentPassword":
                    field = Validations.Validate("Current Password", value.trim(), { Required: true });
                    break;
                case "newPassword":
                    field = Validations.Validate("New Password", value.trim(), { Required: true, Password: true, MinLength: 8 });
                    break;
                case "confirmNewPassword":
                    field = Validations.Validate("Confirm Password", value.trim(), { Required: true, Password: true, MinLength: 8 });
                    if (formData.newPassword !== formData.confirmNewPassword) {
                        field = {
                            isValid: false,
                            validationMessage: "New password & confirm password should be same."
                        };
                    }
                    break;
            }

            if (field && !field.isValid) {
                errors[key] = field.validationMessage;
            }
        });

        if (Object.keys(errors).length) {
            setFormDataError(errors);
            return false;
        }
        setFormDataError([]);
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!isFormDataValid()) {
            setIsLoading(false);
            return false;
        }

        userService.changePassword(formData.currentPassword, formData.newPassword, formData.confirmNewPassword).then((res) => {
            setIsLoading(false);
            addSnackMessages([{
                open: true,
                severity: "success",
                message: "Password changed successfully. Please login again."
            }]);
            //Helper.Logout(RouteConstants.OFFICIAL_LOGIN);
            setRedirectOpen(true);
            setTimeout(() => {
                setRedirectOpen(false);
                history.push({
                    pathname: RouteConstants.OFFICIAL_RELOGIN
                });
            }, 5000);
        }).catch((e) => {
            addSnackMessages([{
                open: true,
                autohideDuration: 6000,
                severity: "error",
                message: e.message
            }]);
            setFormDataError(e.errors);
            setIsLoading(false);
        });
        return false;
    }

    return (
        <>
            <Grid container className={classes.root} spacing={2} component="form" noValidate onSubmit={(e) => handleSubmit(e)} autoComplete="off">
                <Grid item xs={12}>
                    <TextField
                        type="password"
                        variant="outlined"
                        size="small"
                        name="currentPassword"
                        autoComplete='password'
                        label="Current Password"
                        fullWidth
                        onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                        error={formDataError.currentPassword ? true : false}
                        helperText={formDataError.currentPassword}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="password"
                        id="coachPassword"
                        name="password"
                        variant="outlined"
                        size="small"
                        autoComplete={`new_password`}
                        label="New Password"
                        fullWidth
                        onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                        error={formDataError.newPassword ? true : false}
                        helperText={formDataError.newPassword}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="password"
                        id="coachConfirmPassword"
                        variant="outlined"
                        size="small"
                        autoComplete="confirm_password"
                        name="confirmPassword"
                        label="Confirm Password"
                        fullWidth
                        onChange={(e) => setFormData({ ...formData, confirmNewPassword: e.target.value })}
                        error={formDataError.confirmNewPassword ? true : false}
                        helperText={formDataError.confirmNewPassword}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button color="secondary"
                        disabled={(isLoading || Object.keys(formDataError).length > 0)}
                        startIcon={isLoading && <CircularProgress color="secondary" size={24} />}
                        type="submit" variant="contained" className="capitalize" fullWidth>{'Update'}</Button>
                </Grid>
            </Grid>
            <Dialog
                open={redirectOpen}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Password changed successfully!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>You need to login again to continue.</p>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>)
}

export default ChangePasswordFormComponent;