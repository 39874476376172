import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import clsx from 'clsx';
import HeadLine from "../../../sharedComponents/umbraco/controls/headline";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  imgItem: {
  },
  img: {
    maxWidth: '100%',
    minHeight: 400,
    objectFit: 'cover',
    height: '100%'
  },
  textItem: {},
  paperItemText: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    background: 'transparent',
    textAlign: 'justify'
  },
  standard_img_right: {
    '& $paperItemText': {
      marginRight: theme.spacing(1.5)
    }
  },
  standard_img_left: {
    flexDirection: 'row-reverse',
    '& $imgItem': {
    },
    '& $paperItemText': {
      marginLeft: theme.spacing(1.5)
    }
  },
  modern_img_right: {
    '& $imgItem': {
      marginLeft: '-5%',
      '& img': {
        height: 'calc(100% + 80px)',
        borderRadius: 4,
        boxShadow: '-1px 0 4px #000',
        transform: 'translate(0px, -40px)',
      }
    },
    '& $textItem': {
      marginLeft: '2.5%',
      // alignItems: "center",
      // display: 'flex'
    },
    '& $paperItemText': {
      padding: 16,
      paddingRight: 'calc(7.5% + 16px)',
      border: '1px solid',
      // height: 'auto'
    }
  },
  modern_img_left: {
    flexDirection: 'row-reverse',
    '& $imgItem': {
      marginRight: '-5%',
      '& img': {
        height: 'calc(100% + 80px)',
        borderRadius: 4,
        boxShadow: '-1px 0 4px #000',
        transform: 'translate(0px, -40px)',
      }
    },
    '& $textItem': {
      marginRight: '2.5%',
    },
    '& $paperItemText': {
      padding: 16,
      paddingLeft: 'calc(7.5% + 16px)',
      border: '1px solid',
    }
  }
}));

export default function MediaTextBlock(props) {
  const classes = useStyles();
  const { data, defaultData } = props;
  const { layout, article, title, image } = { ...defaultData, ...data };
  return (
    <Grid component="section" container spacing={layout.includes('standard') ? 2 : 0} className={clsx(classes.container, {
      [classes.standard_img_right]: layout === "Standard Image Right",
      [classes.standard_img_left]: layout === "Standard Image Left",
      [classes.modern_img_right]: layout === "Modern Image Right",
      [classes.modern_img_left]: layout === "Modern Image Left",
    })}>
      <Grid item xs={12} sm={8} md={8} lg={8} className={classes.textItem}>
        <Paper className={clsx(classes.paperItemText)} elevation={0}>
          {title && <HeadLine data={title} variant="h5" className={classes.title} paragraph />}
          <div dangerouslySetInnerHTML={{ __html: article }}></div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} className={classes.imgItem}>
        <img
          className={clsx(classes.img)}
          alt={title}
          src={image._url} />
      </Grid>
    </Grid>
  )
}

MediaTextBlock.defaultProps = {
  defaultData: {
    layout: 'standard-img-left',
    contentPosition: 'center', // 'start', 'center', 'end'
  }
};