import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import RouteConstants from '../../../common/RouteConstants';
import { useHistory, withRouter } from 'react-router-dom';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiTypography-root': {
            color: '#fff',
            fontWeight: 600
        },
        '& svg.MuiSvgIcon-root': {
            color: "#fff"
        }
    }
}));
const OrgNavigationDrawerComponent = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const navigation = [
        {
            label: "Dashboard",
            path: RouteConstants.ORG_MEMBER_DASHBOARD,
            icon: <DashboardIcon />
        },
        {
            label: "Create Event",
            path: RouteConstants.ORG_MEMBER_EVENT_CREATE,
            icon: <EventIcon />
        },
        {
            label: "Profile",
            path: RouteConstants.ORG_MEMBER_PROFILE,
            icon: <AccountBoxIcon />
        },
        {
            label: "Logout",
            path: RouteConstants.OFFICIAL_LOGOUT,
            icon: <ExitToAppIcon />
        },
    ];

    const routeTo = (e, path) => {
        if (e) e.preventDefault();

        history.push({
            pathname: path
        });
    };

    return <>
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Toolbar />
            <div className={classes.drawerContainer}>
                <List>
                    {navigation.map((item, index) => (
                        <ListItem button component="a" href={item.path} key={index} className={props.match.path === item.path ? classes.active : ''} onClick={(e) => routeTo(e, item.path)}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    ))}
                </List>
            </div>
        </Drawer>
    </>
}

export default withRouter(OrgNavigationDrawerComponent);