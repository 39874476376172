import { Avatar, Button, Chip, CircularProgress, Container, CssBaseline, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography, Link, Divider, Paper, Box } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import Helper from '../../common/Helper';
import HeadLine from '../../sharedComponents/umbraco/controls/headline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventIcon from '@material-ui/icons/Event';
import TodayIcon from '@material-ui/icons/Today';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import EventRegistrationDialog from './EventRegistrationDialog';
import { eventCalendarService, ClinicEventCategoryData } from '../../services';
import { Alert } from '@material-ui/lab';
import './events.css';
import AppDataContext from '../../common/AppContext';
//import { Role } from '../../helper';
import RouteConstants from '../../common/RouteConstants';
import SectionLoader from '../../sharedComponents/Loader/SectionLoader';
import PageNotFoundUI from '../../sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import EditProfileValidationDialog from '../../sharedComponents/dialog/EditProfileValidationDialog';
import { referenceDataService } from '../../services';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 1280,
        width: '100%',
        margin: '0 auto'
    },
    content: {
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(2),
        background: '#fff',
        border: '1px solid #ff4a1a'

    },
    pageTitleContainer: {
        background: '#fff',
        backgroundRepeat: `no-repeat`,
        backgroundSize: 'cover',
        padding: theme.spacing(2, 2),
        textAlign: 'center',
        //  boxShadow: 'inset 0px 0px 1000px 1000px rgb(0 0 0 / 50%)',
        color: '#fff',
        '& .MuiTypography-root': {
            textAlign: 'left'
        }
    },
    pageTitle: {
        textTransform: 'capitalize',
        color: '#1d428a'
    },
    pageContent: {
        maxWidth: 1280,
        margin: '0 auto'
    },
    metaData: {
        background: 'lightyellow',
        border: '2px solid #ff4a1a',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        boxShadow: '0 0 4px 0px #a1a1a1',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            transform: 'translate(0px, -50%)',
            marginBottom: -16
        }
    },
    metaChip: {
        background: 'transparent',
        marginBottom: theme.spacing(0.5),
        justifyContent: 'flex-start',
        width: '100%',
        '& .MuiChip-icon': {
            color: '#1d428a',
        }
    },
    metaIcon: {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
    },
    article: {
        flexDirection: 'column-reverse',
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(0),
            flexDirection: 'row'
        },
        '& .MuiGrid-item': {
            padding: 12
        }
    },
    mediaImg: {
        maxWidth: '600px',
        maxHeight: '500px',
    },
    eventMedia: {
        border: '0px solid #ccc'
    },
    eventMediaFeaturedImage: {
        width: '100%',
        height: '100%'
    },
    socialIcon: {
        border: '1px solid',
        fontSize: '36px',
        padding: '6px',
        margin: '8px'
    },
    eventDetails: {
        [theme.breakpoints.up('md')]: {
            borderRadius: 4,
            //border: '1px solid #ddd'
        },
        padding: theme.spacing(2),
        marginTop: theme.spacing(4)
    },
    loginOrSignupArea: {
        // display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    registerBtn: {
        '&.Mui-disabled': {
            backgroundColor: '#e0e0e0',
        }
    },
    linkFonts: {
        fontSize: '1rem'
    },
    header: {
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    eventDetailheaders: {
        color: '#1d428a',
        fontWeight: 'bold'
    },
}));

const EventDetails = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams();
    const [content, setContent] = useState(false);
    const [loader, setLoader] = useState(true);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [openRegDialog, setOpenRegDialog] = useState(false);
    const { addSnackMessages, checkRole, user, roles } = useContext(AppDataContext);
    const [categorydList, setcategorydList] = useState([]);

    useEffect(() => {
        getCategoryList();
    }, []);


    useEffect(() => {
        if (!Helper.isUserLoggedIn()) {
            setLoader(true);
            eventCalendarService.getEventBySlug({
                eventSlug: params.slug
            }).then((result) => {
                setContent(result.data);
                setLoader(false);
            }).catch((e) => console.error(e));
        }
        else if (Helper.isUserLoggedIn() && user) {
            setLoader(true);
            eventCalendarService.getEventBySlug({
                eventSlug: params.slug,
                officialUID: Helper.getOfficialId()
            }).then((result) => {
                setContent(result.data);
                setLoader(false);
            }).catch((e) => console.error(e));
        }
    }, [user, params.slug]);

    const getCategoryList = () => {
        var data = { "ParentId": -1 };
        ClinicEventCategoryData.getAllClinicalEventCategoryList(data).then(result => {
            if (result) {
                let categories = result.filter((category) => {
                    if (category.parentID === 0)
                        return category;
                });
                setcategorydList(categories);

                // let subcategories = [];
                // subcategories = result.filter((subcategory) => {
                //     if (subcategory.parentID > 0)
                //         return subcategory;
                // });
                // setAllsubcategoryList(subcategories);
            }
        });
        // referenceDataService.getMasterDataByName('ClinicEventCategory').then(res => {
        //     setcategorydList(res.data);
        // });
    }

    const handleRegisterClick = (e) => {
        if (e) e.preventDefault();
        if (Helper.isUserLoggedIn() && content && content.eventID) {
            setFormSubmitting(true);
            let data = {
                eventID: content.eventID
            };
            eventCalendarService.saveEventOfficials(data).then((res) => {
                if (res.data) {
                    setContent({ ...content, registered: true });
                    addSnackMessages([{
                        open: true,
                        severity: "success",
                        message: res.message ?? "You are successfully registered for event"
                    }]);
                }
                else {
                    addSnackMessages([{
                        open: true,
                        severity: "error",
                        message: res.message
                    }]);
                }
                setFormSubmitting(false);
            }).catch(e => {
                addSnackMessages([{
                    open: true,
                    severity: "error",
                    message: e.message && e.message || "Something went wrong. Please try again later"
                }]);
                setFormSubmitting(false);
            });
        }
        else {
            setOpenRegDialog(true);
        }
        return;
    }

    const routeTo = (e, path) => {
        if (e) e.preventDefault();
        if (path === RouteConstants.OFFICIAL_REGISTRATION) {
            history.push({
                pathname: path,
                search: "?e=" + content.eventIDEncrypted + "&s=" + content.eventSlugEncrypted
            });
        }
        else if (path === RouteConstants.EVENT_REGISTRATION) {
            history.push({
                pathname: path,
                search: "?redirectTo=" + generatePath(RouteConstants.YAO_EVENT_DETAILS, { slug: content.eventNameSlug }) + "&e=" + content.eventIDEncrypted + "&s=" + content.eventSlugEncrypted
            });
        } else {
            history.push({
                pathname: path,
                search: "?redirectTo=" + generatePath(RouteConstants.YAO_EVENT_DETAILS, { slug: content.eventNameSlug }),
                state: {
                    eventID:
                        content.eventID

                }
            });

        }

        return;
    }

    function renderCategory(item) {
        let categoryName = "";
        //let subCategoryName = "";
        if (categorydList.length != 0) {
            if (item.eventCategoryID != 0) {
                //subCategoryName = item.eventSubCategoryName ? ' (' + item.eventSubCategoryName + ')' : '' ;
                categoryName = item.eventCategoryName; //categorydList.find(x => x.numericValue === item.eventCategoryID);
                categoryName = categoryName ? categoryName : '';

            }
        }

        return categoryName;
    }
    function renderSubCategory(item) {
        //let categoryName = "";
        let subCategoryName = "";
        if (categorydList.length != 0) {
            if (item.eventCategoryID != 0) {
                subCategoryName = item.eventSubCategoryName ? item.eventSubCategoryName : '';
                //categoryName = item.eventCategoryName; //categorydList.find(x => x.numericValue === item.eventCategoryID);
                //categoryName = categoryName ? categoryName + subCategoryName : '';

            }
        }

        return subCategoryName;
    }

    const renderEventDateAndTime = (content) => {
        if (Helper.FormatDayWithDate(content.eventStart) === Helper.FormatDayWithDate(content.eventEnd)) {
            return <>
                <ListItem dense alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar className={classes.metaIcon}>
                            <EventIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography color='primary' className={classes.header} variant="caption">Date</Typography>}
                        secondary={<Typography variant='subtitle2' color='primary'>{Helper.FormatDayWithDate(content.eventStart)}</Typography>} />
                </ListItem>
                {/**<ListItem dense alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar className={classes.metaIcon}>
                            <AccessTimeIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="caption">Time</Typography>} secondary={<Typography variant='subtitle2'>{`${Helper.FormatTime(content.startTime)} - ${Helper.FormatTime(content.endTime)}`} </Typography>} />
                    </ListItem>*/}
            </>
        }
        else {
            return <>
                <ListItem dense alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar className={classes.metaIcon}>
                            <TodayIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography color='primary' className={classes.header} variant="caption">Start From</Typography>}
                        secondary={<Typography color='primary' variant='subtitle2'>{Helper.FormatDayWithDate(content.eventStart)} </Typography>} />
                </ListItem>
                <ListItem dense alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar className={classes.metaIcon}>
                            <EventIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="caption" color='primary' className={classes.header} >End On</Typography>}
                        secondary={<Typography color='primary' variant='subtitle2'>{Helper.FormatDayWithDate(content.eventEnd)}</Typography>} />
                </ListItem>
            </>
        }
    }

    const renderEventActions = (content) => {
        if (content.isRegistered) {
            return <Alert severity='success' style={{
                width: 'fit-content',
                margin: '0 auto'
            }}>You already registered for this event.</Alert>
        } else if (content.registered) {
            return <Alert severity='success' style={{
                width: 'fit-content',
                margin: '0 auto'
            }}>You are registered for this event.</Alert>
        }
        else if (Helper.FormatDate(content.eventEnd) < Helper.FormatDate(new Date())) {
            return <Alert severity='error' style={{
                width: 'fit-content',
                margin: '0 auto'
            }}>Registration is closed for this event..</Alert>
        }
        else if (checkRole(roles.user) && Helper.isUserAccountVerified()) {
            return <>
                {/* <Typography variant='body2'></Typography> */}
                <Typography style={{ paddingLeft: '115px', fontSize: '1rem' }} variant='body2'> <Link href="" onClick={(e) => handleRegisterClick(e)}><strong>Register for this event</strong></Link></Typography>
            </>
        }
        else if (!Helper.isUserLoggedIn()) {
            return <div className={classes.loginOrSignupArea}>
                <div style={{ marginBottom: 20 }}>
                    <Typography className={classes.linkFonts} variant='body2'>Already have an account? <Link href={RouteConstants.OFFICIAL_LOGIN + "?redirectTo=" + generatePath(RouteConstants.YAO_EVENT_DETAILS, { slug: content.eventNameSlug })} onClick={(e) => routeTo(e, RouteConstants.OFFICIAL_LOGIN)}><strong>Login</strong></Link></Typography>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <Typography className={classes.linkFonts} variant='body2'>If you want to create a Profile with us? <Link href={RouteConstants.OFFICIAL_REGISTRATION + "?e=" + content.eventIDEncrypted + "&s=" + content.eventSlugEncrypted} onClick={(e) => routeTo(e, RouteConstants.OFFICIAL_REGISTRATION)}><strong>Sign Up</strong></Link></Typography>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <Typography className={classes.linkFonts} variant='body2'>Don't want to create a Profile?  <Link href={RouteConstants.EVENT_REGISTRATION + "?redirectTo=" + generatePath(RouteConstants.YAO_EVENT_DETAILS, { slug: content.eventNameSlug }) + "&e=" + content.eventIDEncrypted + "&s=" + content.eventSlugEncrypted} onClick={(e) => routeTo(e, RouteConstants.EVENT_REGISTRATION)}><strong>Register as Guest</strong></Link></Typography>
                </div>
            </div>
        }
    }

    return (
        <>
            {loader ? <SectionLoader /> : <>
                {(user && !user.isProfileComplete) ? <EditProfileValidationDialog /> :
                    !content ?
                        <PageNotFoundUI
                            showPageIcon
                            pageIcon={<EventIcon />}
                            title="Event not found."
                            subtitle="We couldn't found event data for you. This event is moved or removed."
                        /> : !content.isPublic || content.approvalStatus !== 1 ?
                            <PageNotFoundUI
                                showPageIcon
                                pageIcon={<EventIcon />}
                                title="Event not found."
                                subtitle="We couldn't found event data for you."
                            /> :
                            <>
                                <Container maxWidth='lg' className={classes.pageTitleContainer}>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <HeadLine data={content.eventName} className={classes.pageTitle} variant="h2" />
                                            <Divider />
                                        </Grid>
                                        <Grid item md={8}>
                                            <div class={classes.eventMedia}>
                                                {(content.eventMedia && content.eventMedia.length > 0) ?
                                                    <img className={classes.mediaImg} alt={``} src={content.eventMedia[0].attachedFileUrl} />
                                                    : null}
                                            </div>
                                            <Divider />
                                            <Typography paragraph variant='h5' component={'div'} color='primary' style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 0', marginTop: 10, marginBottom: 10 }}>Description</Typography>
                                            <section dangerouslySetInnerHTML={{ __html: content.eventDescription }} style={{ color: 'black', textAlign: 'left' }}></section>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Typography paragraph variant='h5' component={'div'} color='primary' style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px', marginBottom: 10 }}>Date</Typography>
                                            <Typography variant='subtitle2' color='primary' style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px' }}>{Helper.FormatDayWithDate(content.eventStart) + ' - ' + Helper.FormatDayWithDate(content.eventEnd)}</Typography>
                                            <Divider />
                                            <Typography paragraph variant='h5' component={'div'} color='primary' style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px', marginBottom: 10 }}>Address</Typography>
                                            <Typography style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px' }} component={'div'}>
                                                {content.eventMode === "Virtual" ? <Typography color='primary' style={{ padding: '0 0 0 10px' }} component={'div'} variant='subtitle2'>{'Online'}</Typography>:<><Typography color='primary' variant='subtitle2' component={'div'} style={{ padding: '0 10px 0 0' }}>{`${content.eventCity}, ${(content.eventStateName === null || content.eventStateName === undefined) ? (content.eventStateOther === null || content.eventStateOther === undefined || content.eventStateOther === '') ? '' : content.eventStateOther + ',' : content.eventStateName + ','} ${content.eventCountryName}`}</Typography></>}
                                            </Typography>
                                            <Divider />
                                            <Typography paragraph variant='h5' component={'div'} color='primary' style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px', marginTop: 10, marginBottom: 10 }}>Organizer Contact Info</Typography>
                                            <Typography color='primary' variant='subtitle2' component={'div'} style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px' }}> {content.personName}</Typography>
                                            <Divider />
                                            <Typography paragraph variant='h5' component={'div'} color='primary' style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px', marginTop: 10, marginBottom: 10 }}>Category</Typography>
                                            <Box component='div' display='flex' flexDirection='column' padding='0 10px 0 10px'>
                                                <Typography color='primary' variant='subtitle2' component={'div'} style={{ display: 'flex', alignSelf: 'left', }}> {renderCategory(content)}</Typography>
                                                <Typography color='primary' variant='caption' component={'div'} style={{ display: 'flex', alignSelf: 'left', }}> {renderSubCategory(content)}</Typography>
                                            </Box>
                                            <Divider />
                                            <Typography paragraph variant='h5' component={'div'} color='primary' style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px', marginTop: 10, marginBottom: 10 }}>Clinician</Typography>
                                            <Typography color='primary' variant='subtitle2' component={'div'} style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px' }}> {content.eventClinician}</Typography>
                                            <Divider />
                                            <Typography paragraph variant='h5' component={'div'} color='primary' style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px', marginTop: 10, marginBottom: 10 }}>Ref Ops.</Typography>
                                            <Typography color='primary' variant='subtitle2' component={'div'} style={{ display: 'flex', alignSelf: 'left', padding: '0 10px 0 10px' }}> {content.refOpsStaff}</Typography>
                                            <Divider />
                                            {content && content.isPublic && <Paper elevation={3} className={classes.eventDetails}>
                                                <Typography variant='h5' color='primary' style={{ paddingBottom: '10px' }}>To Register an Event</Typography>
                                                {renderEventActions(content)}
                                            </Paper>
                                            }
                                        </Grid>

                                    </Grid>
                                </Container>

                                {openRegDialog && <EventRegistrationDialog eventID={content.eventID} open={openRegDialog} onClose={(res) => {
                                    if (res === "success") {
                                        addSnackMessages([{
                                            open: true,
                                            message: "Registration Successfully",
                                            severity: "success"
                                        }]);
                                    }
                                    else if (res === "error") {
                                        addSnackMessages([{
                                            open: true,
                                            message: "Something is wrong! Please try again later.",
                                            severity: "error"
                                        }]);
                                    }
                                    setOpenRegDialog(false);
                                }
                                } />}
                            </>}
            </>
            }
        </>
    );

}

export default EventDetails;