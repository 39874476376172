import Helper from "../common/Helper";
import { authenticationService } from "../services";

export function handleError(error) {
    const buildErrorObject = ({ data, status }) => {
        let { errors, message } = data;
        let errArr = [];
        if (errors && errors.length) {
            errors.forEach(ele => {
                errArr[ele.key] = ele.description && ele.description[0] || false;
            });
        }
        let resErr = {
            errors: errArr,
            status: status,
            message: message && message || 'Something went wrong! Please try again later.'
        };
        return resErr;
    }

    return new Promise((resolve, reject) => {
        const errorState = error.response;
        //debugger
        if (errorState) {
            if (errorState.data.errors) {
                reject(buildErrorObject(errorState));
            } else if (errorState.status === 401) {
                let exp = Helper.getStorage('chk')
                if (new Date(exp) < new Date()) {
                    authenticationService.logout()
                }
            } else {
                let error = {
                    errors: [],
                    status: errorState.status,
                    message: errorState.data && errorState.data.message || 'Something went wrong! Please try again later.'
                }
                reject(error);
            }
        }

        resolve(errorState);
    });
}