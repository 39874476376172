import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';


export const dashboardService = {
    getDashboardStates,
    getDashboardExperienceList,
    getProfileCountByRegion,
    getProfileCountByScout,
    getProfileCountByNP,
    getProfileCountByYear,
    getProfileCountByEvaluations,
    getStateWiseAttendanceByScouts,
    getEventCountByScouts,
    excelExport,
    getDashboardPDF
};

const webAPIURL = () => {
    //const { REACT_APP_PUBLIC_URL } = process.env;
    let url = `${new URL(window.location.origin)}api`;
    return url
};

function getDashboardStates(data) {
    return APIHelper.post(APIURLConstants.GET_DASHBOARD_STATES, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getDashboardExperienceList(data) {
    return APIHelper.post(APIURLConstants.GET_DASHBOARD_EXPERIENCE, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getProfileCountByRegion(data) {
    return APIHelper.post(APIURLConstants.GET_DASHBOARD_PROFILE_COUNT_BY_REGION, data, authHeader())
    .then(data => {
        return data;
    }).catch(handleError);
}

function getProfileCountByScout() {
    return APIHelper.post(APIURLConstants.GET_DASHBOARD_PROFILE_COUNT_BY_SCOUT, {}, authHeader())
    .then(data => {
        return data;
    }).catch(handleError);
}

function getProfileCountByNP(data) {
    return APIHelper.post(APIURLConstants.GET_DASHBOARD_PROFILE_COUNT_BY_NP, data, authHeader())
    .then(data => {
        return data;
    }).catch(handleError); 
}

function getProfileCountByYear() {
    return APIHelper.get(APIURLConstants.GET_DASHBOARD_PROFILE_COUNT_BY_YEAR, authHeader())
    .then(data => {
        return data;
    }).catch(handleError); 
}

function getProfileCountByEvaluations(data) {
    return APIHelper.post(APIURLConstants.GET_DASHBOARD_PROFILE_COUNT_BY_EVALUATIONS, data, authHeader())
    .then(data => {
        return data;
    }).catch(handleError);
}

//
function getStateWiseAttendanceByScouts(data) {
    return APIHelper.post(APIURLConstants.GET_DASHBOARD_STATEWISE_ATTENDANCE_BY_SCOUTS, data, authHeader())
    .then(data => {
        return data;
    }).catch(handleError);
}

function getEventCountByScouts(data) {
    return APIHelper.post(APIURLConstants.GET_DASHBOARD_EVENT_COUNT_BY_SCOUTS, data, authHeader())
    .then(data => {
        return data;
    }).catch(handleError);
}

function excelExport(data) {
    let headers = authHeader();
    headers.responseType = 'blob';
    let apiURL = webAPIURL();

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: apiURL + APIURLConstants.SEASON_CYCLE_REPORT_EXCEL,
            data: data,
            headers: headers
        }).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err)
        })
    })

}

function getDashboardPDF(data) { 


    let headers = authHeader();
    headers.responseType = 'blob';
    let apiURL = webAPIURL();

    return new Promise((resolve, reject) => {       
        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: apiURL + APIURLConstants.SEASON_CYCLE_REPORT_PDF,
            data: data,
            headers: headers
        }).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err)
        })
    })
}

















