import React from 'react';
import Typography from '@material-ui/core/Typography';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import Fab from '@material-ui/core/Fab';

import './ErrorUI.css';

const ErrorUI = (props) => {

    const reload = () => {
        //... Reload the screen.
        window.location.reload();
    }

    return (
        <div className="error-ui-container">
            <ErrorTwoToneIcon className="error-ui-icon" />

            <Typography variant="h6">Something went wrong</Typography>
            <Typography variant="body1" className="error-ui-subtext">We couldn't complete your request. Please try again.</Typography>

            <br /><br />
            <Fab size="medium" variant="extended" color="primary" aria-label="reload" className="error-ui-fab-reload"
                onClick={() => reload()}>
                Try Again
                </Fab>
            <br />
        </div>
    )
}

export default ErrorUI;