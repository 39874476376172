import { Button, Grid, Link, makeStyles, TextField, Typography, Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import AppDataContext from "../../../common/AppContext";
import Helper from "../../../common/Helper";
import RouteConstants from "../../../common/RouteConstants";
import Validations from "../../../common/Validations";
import { authenticationService } from "../../../services";
import SectionLoader from '../../../sharedComponents/Loader/SectionLoader';
import PageNotFoundUI from '../../../sharedComponents/emptyStateUIContainers/PageNotFoundUI';

const useStyles = makeStyles((theme) => ({
}));

const ResetPasswordComponent = ({ token, ...props }) => {
    const classes = useStyles();
    let { state } = useLocation();
    const { addSnackMessages } = useContext(AppDataContext);
    const [isLoading, setIsLoading] = useState(false);
    const [tokenInvalid, setTokenInvalid] = useState(true);
    const [redirectOpen, setRedirectOpen] = useState(false);
    const history = useHistory();
    const [formData, setFormData] = useState({
        newPassword: "",
        confirmNewPassword: "",
        resetToken: "",
    });
    const [formDataError, setFormDataError] = useState([]);

    // useEffect(() => {
    //     if (state && state.formData) {
    //         setFormData({
    //             ...formData,
    //             ...state.formData
    //         });
    //     }
    //     else {
    //         props.history.push({
    //             pathname: RouteConstants.FORGOT_PASSWORD
    //         });
    //     }
    // }, [state]);

    useEffect(() => {
        if (Object.keys(formDataError).length) isFormDataValid();
    }, [formData]);

    useEffect(() => {
        if (token) {
            getTokenData();
        }
    }, [token]);

    const getTokenData = () => {
        setIsLoading(true);

        authenticationService.isTokenValid(token).then(res => {
            const { data } = res;
            if (!data) {
                setTokenInvalid(true);
                addSnackMessages([{
                    open: true,
                    message: "Invalid Token! Please try again",
                    severity: "error"
                }]);
            }
            else {
                setFormData({ ...formData, resetToken: token });
                setTokenInvalid(false);
            }
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false);
            addSnackMessages([{
                open: true,
                message: "Something went wrong!",
                severity: "error"
            }]);
        });
    }
    const isFormDataValid = () => {

        let errors = [];
        Object.entries(formData).forEach(([key, value]) => {
            let field = false;
            switch (key) {
                case "newPassword":
                    field = Validations.Validate("New Password", value.trim(), { Required: true, Password: true, MinLength: 8 });
                    break;
                case "confirmNewPassword":
                    field = Validations.Validate("Confirm Password", value.trim(), { Required: true });
                    break;
            }

            if (field && !field.isValid) {
                errors[key] = field.validationMessage;
            }
        });

        // Password & Confirm Password equal check
        if (!errors.newPassword && !errors.confirmNewPassword && formData.newPassword !== formData.confirmNewPassword) {
            errors['confirmNewPassword'] = "New password & confirm password should be same.";
        }

        if (Object.keys(errors).length) {
            setFormDataError(errors);
            return false;
        }
        setFormDataError([]);

        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!isFormDataValid()) {
            setIsLoading(false);
            return false;
        }
        authenticationService.resetPassword(formData).then((res) => {
            setIsLoading(false);
            if (res.data === 1) {
                addSnackMessages([{
                    open: true,
                    message: "Your password has been changed successfully. Please login with new password.",
                    severity: "success"
                }]);
                setRedirectOpen(true);
                setTimeout(() => {
                    history.push({
                        pathname: RouteConstants.OFFICIAL_RELOGIN
                    });
                }, 5000);
                //props.history.push({
                //    pathname: RouteConstants.OFFICIAL_LOGIN
                //});
            } else {
                addSnackMessages([{
                    open: true,
                    message: res.message,
                    severity: "error"
                }]);
            }
        }).catch(e => {
            setIsLoading(false);
            setFormDataError(e.errors);
            addSnackMessages([{
                open: true,
                message: e.message,
                severity: "error"
            }]);
        }
        );
        return;
    }

    return <>
        <Grid container spacing={2} component="form" noValidate onSubmit={(e) => handleSubmit(e)} autoComplete="off">
            {isLoading ?
                <SectionLoader />
                : (tokenInvalid) ?
                    <>
                        <PageNotFoundUI
                            title="Oops! Link Invalid or Expired"
                            titleProps={{
                                color: "secondary"
                            }}
                        />
                        <Grid item xs={12}>
                            <Typography variant="body2" align="center">Link Expired? <Link href={RouteConstants.FORGOT_PASSWORD} onClick={(e) => {
                                e.preventDefault();
                                props.history.push({ pathname: RouteConstants.FORGOT_PASSWORD })
                            }}><strong>Try again</strong></Link></Typography>
                        </Grid>
                    </> : <>

                        <Grid item xs={12}>
                            <Typography variant="h4" align="center" color="secondary">Reset Password</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="password"
                                variant="outlined"
                                id="newPassword"
                                size="small"
                                label="New Password"
                                value={formData.newPassword}
                                name="newPassword"
                                onChange={(e) => setFormData({ ...formData, newPassword: e.target.value, confirmNewPassword: "" })}
                                fullWidth
                                error={formDataError.newPassword && true || false}
                                helperText={formDataError.newPassword && formDataError.newPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="password"
                                variant="outlined"
                                id="confirmNewPassword"
                                size="small"
                                label="Confirm Password"
                                value={formData.confirmNewPassword}
                                name="confirmNewPassword"
                                onChange={(e) => setFormData({ ...formData, confirmNewPassword: e.target.value })}
                                fullWidth
                                error={formDataError.confirmNewPassword && true || false}
                                helperText={formDataError.confirmNewPassword && formDataError.confirmNewPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="secondary" type="submit" variant="contained" className="capitalize" fullWidth onClick={(e) => handleSubmit(e)}>Update</Button>
                        </Grid>

                        {/* <Grid item xs={12}>
                <Typography variant="body2" align="center">OTP Expired? <Link href={RouteConstants.FORGOT_PASSWORD} onClick={(e) => {
                    e.preventDefault();
                    props.history.push({ pathname: RouteConstants.FORGOT_PASSWORD })
                }}><strong>Forgot Password</strong></Link></Typography>
            </Grid> */}
                    </>}
        </Grid>
        <Dialog
            open={redirectOpen}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Password changed successfully!</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <p>You need to login again to continue.</p>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    </>
}

export default withRouter(ResetPasswordComponent);