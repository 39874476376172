import React from 'react';
import MenuItems from "./MenuItems";
const Dropdown = ({ submenus, dropdown, depthLevel, navDrawerClose }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
    return (
        <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
            {submenus.map((submenu, index) => (
                <MenuItems item={submenu} key={index} depthLevel={depthLevel} navDrawerClose={navDrawerClose} />
            ))}
        </ul>
    );
};

export default Dropdown;