import React, { Fragment, useContext } from 'react';
import { Route } from 'react-router-dom';
import { CssBaseline, makeStyles } from '@material-ui/core';
import AppBar from '../sharedComponents/appBar/AppHeaderBar';
import UserActionMenu from '../sharedComponents/appBar/UserActionMenu';
import Navbar from '../sharedComponents/multiLevelTopNav/NavBar';
import NotificationMenu from '../sharedComponents/Notifications/NotificationMenu';
import FooterPublic from '../sharedComponents/footer/FooterPublic';
import NavDrawer from '../sharedComponents/multiLevelTopNav/NavDrawer';
import GlobalMessageSection from '../sharedComponents/appBar/GlobalMessageSection';
import AppDataContext from '../common/AppContext';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        zIndex: 0,
        marginTop: theme.mixins.toolbar.minHeight
    }
}));

const PublicLayout = (props) => {
    const classes = useStyles();
    const { navigationMenu } = useContext(AppDataContext);

    return (
        <Fragment>
            <AppBar
                mainNav={<Navbar />}
                mobileNav={<NavDrawer />}
                notificationMenu={<NotificationMenu />}
                userActionMenu={<UserActionMenu />}
            />
            <main className={classes.main}>
                <CssBaseline />
                {(navigationMenu && navigationMenu.globalMessage) ? <GlobalMessageSection message={navigationMenu.globalMessage} /> : null}
                <Route {...props} />
            </main>
            {!props.hideFooter && <FooterPublic />}
        </Fragment>
    );
}

PublicLayout.defaultProps = {
    hideFooter: true
}

export default PublicLayout;