import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import AuthLayoutContainer from "./AuthLayoutContainer";
import LoginComponent from "./components/LoginComponent";

const useStyles = makeStyles((theme) => ({

}));

const Login = (props) => {
    const classes = useStyles();
    useEffect(() => {
    }, [])

    return <>
        <AuthLayoutContainer children={<LoginComponent />} />
    </>

}

export default Login;