import { authenticationService } from '../services';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;

    if ((currentUser && currentUser.token)) {
        return { Authorization: `Bearer ${currentUser?.token}` };
    } else {
        return {};
    }
}

// export function authHeader() {
//     // return authorization header with jwt token
//     const accessToken = localStorage.getItem("accessToken");
//     if (accessToken && accessToken !== "") {
//         //let accssTokenString = JSON.parse(JSON.stringify(accessToken)); //.replace(/"/g, "");
//         return { Authorization: "Bearer " + accessToken };
//     } else {
//         return {};
//     }
// }