import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '400px'
    },
    notesHeader: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1.5),
    },
    dialogWidth: {
        overflow: 'hidden',
        minWidth: '400px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '300px'
        }
    },
    ErrorName: {
        color: 'red',
    },
    nameContainer: {
        margin: 15,
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column'
    },
    formLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
    userLabel: {
        paddingLeft: '10px',
    },
    addButton: {
        position: 'relative',
        top: theme.spacing(-4),
        paddingRight: theme.spacing(1)
    },
    progress: {
        marginLeft: '50%',

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className="font500" variant="subtitle1" noWrap>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: '15px 15px',
        [theme.breakpoints.down(281)]: {
            width: '218px',
        },
        [theme.breakpoints.between(281, 322)]: {
            width: '258px',
        },
        [theme.breakpoints.between(321, 556)]: {
            width: '275px'
        },
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddEditEventClinician = (props) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        name: "",
        isActive: true,
        id: 0,
    });

    const [ClinicianError, setClinicianError] = useState('');

    useEffect(() => {
        setFormData({
            ...formData, name: props.Clinician.name, 
            isActive: props.Clinician.isActive == 1 ? true : false, id: props.Clinician.id
        })

    }, [props.Clinician]);

    const handleClose = () => {
        props.handleCloseClick();
    };

    const isFormDataValid = () => {
        let isValid = true;
        Object.entries(formData).forEach(([key, value]) => {
            let field = false;
            switch (key) {
                case "name":
                    if(value === undefined || value == null || (value && value.trim() == '') || value == '') {
                        setClinicianError('Clinician is required');
                        isValid = false;
                    } else {
                        setClinicianError(null);
                    }
                    break;
            }
        });
        return isValid;
    }

    const handleSubmit = () => {
        if (isFormDataValid()){
            props.onSubmit(formData);
        } else {
            return;
        }
    };
    const setClinician = (value) => {
        if(value === undefined || value == null || (value && value.trim() === '') || value == '') 
        {
            setClinicianError('Clinician is required');
        } else {
            setClinicianError(null);
        }
        setFormData({ ...formData, name: value })
    } 


    return (
        <div>
            <Dialog maxWidth="sm" fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {formData.id > 0 ? 'Edit Clinician' : 'Add Clinician'}
                </DialogTitle>
                <DialogContent dividers className={classes.dialogWidth}>
                    <Grid container style={{ paddingTop: '8px' }}>
                        <TextField id="DataField" variant="outlined" fullWidth={true}
                            label={<Typography variant="body2">Name</Typography>}
                            size="small"
                            value={formData.name}
                            name="name"
                            onChange={(e) => setClinician(e.target.value)} 
                            error={ClinicianError ? true : false}
                            helperText={ClinicianError}
                            inputProps={{ maxLength: 128 }}
                        />
                    </Grid>
                    <Grid container style={{ paddingTop: '8px' }}>
                        <FormControlLabel
                            label="Active"
                            labelPlacement='start'
                            control={
                                <Checkbox
                                    checked={formData.isActive}
                                    onChange={(e) => setFormData({ ...formData, isActive: e.target.checked ? true : false })}
                                    color="primary"
                                    name="isActive"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary" disabled={props.isLoading}>
                        {formData.id > 0 ? 'Update' : 'Save'}</Button>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        autoFocus>Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default AddEditEventClinician;