import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const LessonPlanDataService = {
    listAll,
    listAllPublic,
    create,
    update,
    updateStatus,
    getUserAccess,
    updateUserAccess,
    deleteUserAccess,
    getDetails,
    getQuizContent,
    saveQuizAnswer,
    saveQuizStatus,
    getMyLessons,
    getUserAndScore,
    getDashboardData,
    getFilterUsers,
    addUserAccess,
    updateUserAccessLevel,
    // syncQuizList,
    // getQuizContentByID,
    // getPublicQuizes,
    // getMyQuizes,
    // updateQuizAccess,
    // saveQuizStatus,
    // saveQuizQuestionAnswer,
    // getQuizUserAndScore,
    // getUserScores,
    // getQuizAccess,
    // deleteQuizAccess
}

function getDashboardData() {
    return APIHelper.get(APIURLConstants.GET_LESSON_PLAN_DASHBOARD_DATA, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function listAll() {
    return APIHelper.get(APIURLConstants.GET_LESSON_PLAN_LIST, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function listAllPublic() {
    return APIHelper.get(APIURLConstants.GET_PUBLIC_LESSON_PLAN_LIST, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getDetails(id) {
    let postData = {"lessonPlanID" : id}
    return APIHelper.post(APIURLConstants.GET_LESSON_PLAN_DETAILS, postData, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function create(postData) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_CREATE, postData, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function update(postData) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_UPDATE, postData, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function updateStatus(postData) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_UPDATE_STATUS, postData, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getMyLessons(page, batchSize) {
    let data = {
        PageIndex: page || 1,
        PageSize: batchSize || 6
    };
    return APIHelper.post(APIURLConstants.GET_MY_LESSONS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getUserAndScore(data) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_USERS_AND_SCORES_GET, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}

function getUserAccess(id) {
    let postdata = { "lessonPlanID" : id}
    return APIHelper.post(APIURLConstants.LESSON_PLAN_USER_ACCESS, postdata, authHeader())
        .then(data => {
            return data;
        })
        .catch(handleError)
}


function updateUserAccess(data) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_UPDATE_USER_ACCESS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}


function updateUserAccessLevel(data) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_UPDATE_ACCESS_LEVEL, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}



function deleteUserAccess(data) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_DELETE_USER_ACCESS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}

function addUserAccess(data) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_UPDATE_USER_ACCESS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}

function getQuizContent(data) {
    return APIHelper.post(APIURLConstants.GET_QUIZ_CONTENT, data, authHeader())
        .then(data => {
            return data;
        })
        .catch(handleError)
}

function saveQuizAnswer(data) {
    return APIHelper.post(APIURLConstants.SAVE_QUIZ_ANSWER, data, authHeader())
        .then(response => {
            return response.data;
        }).catch(handleError)
}

function saveQuizStatus(data) {
    return APIHelper.post(APIURLConstants.SAVE_QUIZ, data, authHeader())
        .then(response => {
            return response;
        }).catch(handleError)
}

function getFilterUsers(data) {
    return APIHelper.post(APIURLConstants.LESSON_PLAN_FILTER_USERS, data, authHeader())
    .then(response => {
        return response;
    }).catch(handleError)
}