import React from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, makeStyles, Typography } from '@material-ui/core';
import { generatePath, useHistory } from 'react-router-dom';
import Helper from '../../common/Helper';
import RouteConstants from '../../common/RouteConstants';
import AccessTime from '@material-ui/icons/AccessTime';
import Event from '@material-ui/icons/Event';
import DateRange from '@material-ui/icons/DateRange';
import DefaultImagePath from '../../images/NBA_DEFAULT.png';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        overflow: 'inherit',
        borderRadius: theme.spacing(1),
        height: '100%',
        maxHeight: '700px',
        zIndex: 0
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        borderRadius: '8px 8px 0px 0px',
        objectFit: 'cover',
        position: 'relative',
        boxShadow: '0px 0px 8px #ccc inset',
    },
    dateSection: {
        background: '#ff4a1a',
        color: 'white',
        padding: '0 8px',
        '& p': {
            color: 'white !important',
        }
    },
    metaChip: {
        border: 'none',
        fontWeight: 'bold',
        color: '#000',
        '& .MuiChip-icon': {
            color: theme.palette.primary.main
        },
        '& .MuiTypography-subtitle2': {
            fontSize: '0.8rem',
            lineHeight: 1.3
        }
        // marginBottom: theme.spacing(1)
    },
    content: {
        padding: theme.spacing(1, 1.5, 0),
        minHeight: 102,
        '& h3': {
            textTransform: 'capitalize'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    eventDays: {
        content: `''`,
        fontWeight: 'bold',
        top: '12px',
        right: '-10px',
        padding: '0.5rem',
        width: '56px',
        background: '#3949ab',
        color: 'white',
        textAlign: 'center',
        fontFamily: `'Roboto', sans-serif`,
        boxShadow: '4px 4px 15px rgba(26, 35, 126, 0.2)',
        position: 'absolute',
        '&::after': {
            position: 'absolute',
            content: `''`,
            background: '#68768f',
            height: '28px',
            width: '28px',
            top: '50px',
            right: '6px',
            transform: 'rotate(45deg)',
            zIndex: -1,
        },
    },
    remainingDays: {
        position: 'absolute',
        background: '#ff4a1a',
        bottom: 0,
        color: '#fff',
        padding: theme.spacing(0, 1.5),
        borderRadius: theme.spacing(0, 1, 0),
        '&.ongoing': {
            background: 'green',
        }
    },
    shortDescription: {
        height: 42,
        overflow: 'hidden'
    }
}));

const EventCard = ({ event }) => {
    const classes = useStyles();
    const history = useHistory();

    const routeTo = (e, path) => {
        if (e) e.preventDefault();

        history.push({
            pathname: path
        });
    }

    const countEventDays = (startDate, endDate, addDiff = 0) => {
        // if addDiff use to display days with addition
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);
        if (date1 > date2) {
            const diffTime = Math.abs(date1 - date2);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const eventDays = diffDays;
            return -eventDays;
        }
        else {
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const eventDays = diffDays + addDiff;
            if (eventDays === 1) {
                return eventDays + ' Day';
            }
            else {
                return eventDays + ' Days';
            }
        }
    }

    const renderEventDateAndTime = (event) => {
        if (Helper.FormatDayWithDate(event.eventStart) === Helper.FormatDayWithDate(event.eventEnd)) {
            return <>
                <Chip variant='outlined' className={classes.metaChip} label={<Typography variant='subtitle2' component="p">{Helper.FormatDayWithDate(event.eventStart)}</Typography>} icon={<Event />} />
                {/*<Chip variant='outlined' className={classes.metaChip} label={<Typography variant='subtitle2' component="p">{Helper.FormatTime(event.startTime)} - {Helper.FormatTime(event.endTime)}</Typography>} icon={<AccessTime />} />*/}
            </>
        }
        else {
            return <>
                <Chip
                    variant='outlined'
                    className={classes.metaChip}
                    label={<>
                        <Typography variant='subtitle2' component="p">
                            {Helper.FormatDayWithDate(event.eventStart)}
                        </Typography>
                        <Typography variant='subtitle2' component="p">
                            {Helper.FormatDayWithDate(event.eventEnd)}
                        </Typography>
                    </>}
                    icon={<DateRange />} />
            </>
        }
    }

    const getImageUrl = (media) => {
        if(media) {
            let _evtMedia = media.attachment;
            let _ext = _evtMedia.split(".").pop();
            if(_ext == "bmp" || _ext == "gif" || _ext == "jpeg" || _ext == "jpg" || _ext == "png" || _ext == "svg" || _ext == "tif" || _ext == "tiff" ) {
                return media.attachedFileUrl;
            } else {
                return DefaultImagePath;
            }
        } else {
            return DefaultImagePath;
        }
    }

    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={event.eventMedia && event.eventMedia.length > 0 ? getImageUrl(event.eventMedia[0]) : DefaultImagePath}
                title={event.eventName}
            />
            <Typography className={classes.eventDays}>{countEventDays(event.eventStart, event.eventEnd, 1)}</Typography>
            <Box
                display={`flex`}
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems={`center`}
                py={0.5}
                bgcolor="#eee">
                {(parseInt(countEventDays(new Date(), event.eventStart)) > 0) ? <Typography className={classes.remainingDays}>{countEventDays(new Date(), event.eventStart)} to go</Typography> : <Typography className={`${classes.remainingDays} ongoing`}>Ongoing</Typography>}
                {renderEventDateAndTime(event)}
            </Box>
            <CardContent className={classes.content}>
                <Typography gutterBottom variant="h4" color='primary' component="h3">
                    {event.eventName}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className={classes.shortDescription}>
                    {Helper.StringExcerpt(event.eventDescription, 80)}
                </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
                <Button style={{backgroundColor:'lightyellow'}} href={generatePath(RouteConstants.YAO_EVENT_DETAILS, { slug: event.eventNameSlug })} variant='outlined' size='small' color='primary' onClick={(e) => routeTo(e, generatePath(RouteConstants.YAO_EVENT_DETAILS, { slug: event.eventNameSlug }))}>Learn More</Button>
            </CardActions>
        </Card>
    )
}

export default EventCard;