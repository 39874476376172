import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Container, makeStyles } from '@material-ui/core';
import AppDataContext from '../../common/AppContext';
import PageTitle from '../../sharedComponents/PageTitle/PageTitle';
import SectionLoader from '../../sharedComponents/Loader/SectionLoader';
import VerifyEmailComponent from './components/VerifyEmailComponent';
import RouteConstants from '../../common/RouteConstants';
import { authenticationService } from '../../services';
import Helper from '../../common/Helper';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    }
}))

const VerifyEmail = (props) => {
    const classes = useStyles();
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");
    const { addSnackMessages, setUser } = useContext(AppDataContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        authenticationService.currentUser.subscribe(currentUser => {
            setUser(currentUser);
            if (!currentUser) {
                props.history.push(RouteConstants.OFFICIAL_LOGIN);
            }
            if (currentUser && currentUser.isEmailVerified) {
                props.history.push(RouteConstants.OFFICIAL_LOGIN);
            }
        });
        if (token) {
            authenticationService.verifyUserAccount(token).then(res => {
                if (res.isEmailVerified) {
                    addSnackMessages([{
                        open: true,
                        severity: 'success',
                        message: res.message
                    }]);
                    let lsData = JSON.parse(localStorage.getItem("currentUser"));
                    if (lsData) {
                        //setTimeout(function () {
                            Helper.updateLocalStorageData('currentUser', { isEmailVerified: res.isEmailVerified });
                            //props.history.push(RouteConstants.OFFICIAL_LOGOUT);
                            setIsLoading(false);
                            window.location.reload();
                        //}, 2000);
                    }
                    else {
                        setTimeout(function () {
                            Helper.Logout(RouteConstants.OFFICIAL_LOGIN);
                            setIsLoading(false);
                        }, 3000);
                    }
                }
            }).catch(e => {
                addSnackMessages([{
                    open: true,
                    severity: 'error',
                    message: e.message ?? "Something went wrong. Please try again later."
                }]);
                setTimeout(function () {
                    Helper.Logout(RouteConstants.OFFICIAL_LOGIN);
                    // link expired message to show on page
                }, 3000);
            })
        }
        else {
            setIsLoading(false);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{`Verify Account`}</title>
                <meta name="description" content="Verify User Account" />
                <link rel="canonical" href={encodeURI(`${new URL(window.location.origin)}${RouteConstants.EMAIL_VERIFY}`)} />
            </Helmet>

            <PageTitle title={`Account Verification`} />

            {isLoading ? <SectionLoader />
                :
                <Container maxWidth="lg" component="section" className={classes.content}>
                    <VerifyEmailComponent />
                </Container>
            }

        </>
    );
}

export default VerifyEmail;
