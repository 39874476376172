import { LinearProgress, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 6,
        borderRadius: 4,
        marginTop: 2,
        marginBottom: 2
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 4,
        '&.weak': {
            backgroundColor: '#f25f5c'
        },
        '&.fair': {
            backgroundColor: '#ffe066'
        },
        '&.good': {
            backgroundColor: '#247ba0'
        },
        '&.strong': {
            backgroundColor: '#70c1b3'
        },
    },
}))(LinearProgress);

const StrengthIndicator = ({ value }) => {
    const createPasswordLabel = (value) => {
        switch (value) {
            case 0:
                return "weak";
            case 1:
                return "weak";
            case 2:
                return "fair";
            case 3:
                return "good";
            case 4:
                return "strong";
            default:
                return "weak";
        }
    };
    return <BorderLinearProgress variant="determinate" value={value * 25} classes={{
        bar: createPasswordLabel(value)
    }} />
}

export default StrengthIndicator;