import { Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import { Helmet } from 'react-helmet';
import RouteConstants from '../../common/RouteConstants';
import Helper from '../../common/Helper';
import SectionLoader from '../../sharedComponents/Loader/SectionLoader';
import { eventCalendarService } from '../../services';
import PageTitle from '../../sharedComponents/PageTitle/PageTitle';
import PageNotFoundUI from '../../sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import EventIcon from '@material-ui/icons/Event';


import './events.css';
import EventCard from './EventCard';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 1280,
        width: '100%',
        margin: '0 auto'
    },
    pageContent: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paginationContainer: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'center',
    }
}));



const Events = (props) => {
    const classes = useStyles();   
    const [page, setPage] =  useState(1)
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        totalRecords: 2
    });
    const [isLoading, setIsLoading] = useState(true);
  

    useEffect(() => {
        getEvents(parseInt(page));
    }, [page]);

    

   


    const changePage = (page) => {
        setPage(page)        
        getEvents(parseInt(page))
        return;
    }

    function getEvents(page) {
        setIsLoading(true);
        let today = new Date();
        let startDate = Helper.FormatDateToLocalTimeZone(today);
        eventCalendarService.getYAOPublicEvents(page, 6, startDate).then((result) => {
            setData(result.data.eventCalendarList);
            setPagination({
                currentPage: result.data.currentPage,
                lastPage: result.data.lastPage,
                totalRecords: result.data.totalRecords
            });
            setIsLoading(false);
        }).catch((e) => console.error(e));
        return;
    }

    return (
        <>
            <Helmet>
                <title>{`Events`}</title>
                <meta name="description" content="NBA Officials Events" />
                <link rel="canonical" href={encodeURI(`${new URL(window.location.origin)}${RouteConstants.YAO_EVENTS}`)} />
            </Helmet>

            <PageTitle title="Events" />
            <Container maxWidth="lg" className={classes.pageContent}>
                {isLoading ? <SectionLoader /> :
                    <Grid container spacing={3}>
                        {data && data.length ? data.map((e, i) =>
                            <Grid item xs={12} sm={6} md={4} key={i}><EventCard event={e} /></Grid>
                        ) : <PageNotFoundUI
                            showPageIcon
                            pageIcon={<EventIcon />}
                            title="Events not available."
                            subtitle="We couldn't found events for you right now. Please come back later."
                        />
                        }

                        {(data && data.length && pagination.lastPage > 1) ? <Grid item xs={12} className={classes.paginationContainer}>
                            <Pagination
                                page={pagination.currentPage}
                                count={pagination.lastPage}
                                onChange={(e, page) => changePage(page)}
                                color="primary"
                                size='large'
                                showFirstButton
                                showLastButton />
                        </Grid> : null}
                    </Grid>}
            </Container>           
        </>
    );

}

export default Events;