import React, { useState, useContext, useEffect } from "react";
import { generatePath, useParams, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { AccountCircle } from "@material-ui/icons";
import AppBar from '../sharedComponents/appBar/AppHeaderBar';
import UserActionMenu from '../sharedComponents/appBar/UserActionMenu';
import Navbar from '../sharedComponents/multiLevelTopNav/NavBar';
import NotificationMenu from '../sharedComponents/Notifications/NotificationMenu';
import SectionLoader from "../sharedComponents/Loader/SectionLoader";
import PageNotFoundUI from "../sharedComponents/emptyStateUIContainers/PageNotFoundUI";
import AppDataContext from "../common/AppContext";
import { UserProfileProvider, UserProfileConsumer } from "../common/ProfileContext";
import RouteConstants from "../common/RouteConstants";
import NavDrawer from "../sharedComponents/multiLevelTopNav/NavDrawer";
import GlobalMessageSection from "../sharedComponents/appBar/GlobalMessageSection";
import EditProfileValidationDialog from "../sharedComponents/dialog/EditProfileValidationDialog";
//import { Role } from "../helper";

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        zIndex: 0,
        marginTop: theme.mixins.toolbar.minHeight
    },
    profileSummary: {
        paddingBottom: theme.spacing(2),
        minHeight: "300px",
        boxShadow: '0px 0px 5px rgba(6, 37, 91, .2)',
        borderRadius: '0',
        backgroundColor: '#06255b',
    },
    submitButton: {
        alignContent: "center",
        textAlign: "center",
        paddingBottom: 10,
        paddingRight: 10,
    },
}));

const OfficialProfileLayout = ({ children, ...props }) => {
    const classes = useStyles();
    const params = useParams();
    const { username, section } = params;
    const [accessDenied, setAccessDenied] = useState(false);
    const { user, checkRole, navigationMenu } = useContext(AppDataContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user) {
            if (username) {
                // admin
                //debugger
                if (!(checkRole('admin') || checkRole('scout') || checkRole('adminscout') ||checkRole("eventadmin"))) setAccessDenied(true);
                else setAccessDenied(false);
            } else if (!user.isEmailVerified) {
                props.history.push(RouteConstants.EMAIL_VERIFY)
            }
            else {
                // owner/user/my profile
                if (!checkRole('user')) setAccessDenied(true);
                else setAccessDenied(false);
            }
            setIsLoading(false);
        }
    }, [user, username]);

    const fabRoute = () => {
        if (checkRole("admin") || checkRole("scout") || checkRole("adminscout") ||checkRole("eventadmin")) {
            return generatePath(RouteConstants.ADMIN_PROSPECTS_HOME);
        }
        else if (checkRole("partner")) {
            return generatePath(RouteConstants.ORG_MEMBER_DASHBOARD);
        }
        else if (checkRole("user")) {
            return generatePath(RouteConstants.PROSPECT_PROFILE, { username: user.userName, section: section })
        }
    }

    return (
        <>
            <AppBar
                mainNav={<Navbar />}
                mobileNav={<NavDrawer />}
                notificationMenu={<NotificationMenu />}
                userActionMenu={<UserActionMenu />}
            />
            <main className={classes.main}>
                <CssBaseline />
                {(navigationMenu && navigationMenu.globalMessage) ? <GlobalMessageSection message={navigationMenu.globalMessage} /> : null}
                {isLoading && <SectionLoader /> ||
                    accessDenied && <PageNotFoundUI
                        title="Not Authorised"
                        subtitle="You're not authorized to view this page. Please contact administration."
                        showFabIcon
                        fabIcon={<AccountCircle />}
                        fabRoute={fabRoute()}
                    /> ||
                    <UserProfileProvider fetchMedia={true}>
                        <UserProfileConsumer>
                            {(props) => (!user.isProfileComplete) ? <EditProfileValidationDialog /> : children}
                        </UserProfileConsumer>
                    </UserProfileProvider>
                }
            </main>
        </>
    );
}

export default withRouter(OfficialProfileLayout);