import { generatePath } from "react-router-dom";

const RouteConstants = {
  // Start... Public Routes
  UNAUTHORIZED: '/unauthorized',
  NBAOFFICIAL_COMMON_PAGE: '/:slug?/:segmenttwo?',
  OFFICIAL_LOGIN: "/login",
  OFFICIAL_REGISTRATION: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  NEW_USER_CHANGE_PASSWORD: "/change-password",
  OFFICIAL_LOGOUT: "/logout",
  OFFICIAL_RELOGIN: "/relogin",
  EMAIL_VERIFY: "/account-verify",
  EVENT_REGISTRATION: "/event-registration",
  // Start... User Profile Route
  PROSPECT_MY_PROFILE: "/me/:section",
  PROSPECT_MY_PROFILE_EDIT: "/me/edit/:section", //change email 
  PROSPECT_PROFILE: "/profile/:username/:section?",
  // PROSPECT_PROFILE_EDIT: "/profile/:username/edit/:section",


  MESSAGE_CENTER_HOME: "/message-center",
  MESSAGE_CENTER_THREAD: "/message-center/thread/:id",
  LESSON_PLANS: '/lesson-plans',
  OFFICIATING_GAME_VIDEOS: 'officiating-game-videos',
  LESSON_PLAN_DETAIL: '/lesson-plan/:id/:title?',
  NBA_QUIZ_USER_SCORE_LIST: '/quiz/userscore',
  NBA_QUIZ_USER_ACCESS: '/quiz/useraccess',
  YAO_EVENTS: '/events',
  YAO_EVENT_DETAILS: '/event/:slug',
  // End... Public Routes

  PARTICIPANT_MEMBER_DASHBOARD: '/participant/dashboard',
  PARTICIPANT_MEMBER_PROFILE: '/participant/profile/view',
  PARTICIPANT_MEMBER_EVENTS: '/my-events',

  // Start... Organizer Routes
  ORG_MEMBER_DASHBOARD: '/organizer/dashboard',
  ORG_MEMBER_PROFILE: '/organizer/profile/view',
  ORG_MEMBER_EVENTS_LIST: '/organizer/events',
  ORG_MEMBER_EVENT_CREATE: '/organizer/event/create',
  ORG_MEMBER_EVENT_EDIT: '/organizer/event/:id/edit',
  // End... Organizer Routes

  // Start... Admin Routes
  DASHBOARD: "/admin/dashboard",
  ADMIN_HOME: "/admin",
  ADMIN_YAO_EVENT_CALENDAR_HOME: "/admin/eventcalendarhome",
  ADMIN_YAO_EVENT_CALENDAR: "/admin/eventcalendar/detail/:date",
  MANAGE_ASSIGNORS: "/admin/assignors",
  ADMIN_PROSPECTS_HOME: '/admin/prospects/home',
  PROSPECT_PROFILE_VIEW: '/admin/prospect/view/:userName',
  EVALUATE_PROSPECT: '/admin/evaluation',
  MANAGE_COMMUNICATION_CATEGORY: '/admin/master/message-category',
  MANAGE_EVALUATION_CATEGORY: '/admin/master/evaluation-category',
  MANAGE_COMPETENCY: '/admin/master/manage-competencies',
  MANAGE_TANGIBLE: '/admin/master/manage-tangibles',
  ADD_EVALUATION_EVENT: '/admin/master/add-event',
  MANAGE_QUIZ: '/admin/master/manage-quiz',
  MANAGE_LESSON_PLANS: '/admin/master/lesson-plans',
  CREATE_LESSON_PLANS: '/admin/master/lesson-plans/create',
  EDIT_LESSON_PLANS: '/admin/master/lesson-plans/:id/edit',
  LESSON_PLANS_USER_ACCESS: '/admin/master/lesson-plans/:id/user-access',
  LESSON_PLAN_QUIZ_USER_SCORE_LIST: '/admin/master/lesson-plans/:id/user-score',
  LESSON_PLANS_DASHBOARD: '/admin/dashboard/lesson-plans',
  MANAGE_CMS_QUESTIONS: '/admin/master/questions',
  CREATE_CMS_QUESTION: '/admin/master/create',
  EDIT_CMS_QUESTION: '/admin/master/questions/:id/edit',
  ADMIN_INVITE_ORG_MEMBER: '/admin/organizer/invite-member',
  ADMIN_CANDIDATE_CAMPS: "/admin/candidate-camps",
  ADMIN_CANDIDATE_CAMP_CREATE: "/admin/candidate-camp/create",
  ADMIN_CANDIDATE_CAMP_EDIT: "/admin/candidate-camp/:id/edit",
  ADMIN_CANDIDATE_CAMP_ADD_EDIT_PARTICIPANTS: "/admin/candidate-camp/:id/participants",
  ADMIN_EVENT_NOTIFICATION: "/admin/:id/event-notifications",
  ADMIN_ADD_EDIT_NOTIFICATION: "/admin/:id/notification",
  ADMIN_CANDIDATE_CAMP_ASSESSMENT: "/admin/:id/candidate-camp-assessment",
  ADMIN_MANAGE_POOL: "/admin/pool",
  NO_PROSPECT: "/admin/noprospect",
  ADMIN_SEND_REMINDER_EMAIL: '/admin/send-reminder',
  MANAGE_EVALUATION_EVENTS: '/admin/master/evaluation-events',
  EVENT_DASHBOARD: '/admin/event-dashboard',
  MANAGE_CLINICEVENT_CATEGORY: '/admin/master/clinicevent-category',
  MANAGE_CLINICIAN: '/admin/master/clinician',
  MANAGE_USER_ROLE: '/admin/master/user-role',
  MANAGE_PERMISSIONS: '/admin/master/permissions',
  // End... Admin Routes
};

export const CMSRouteSlug = {
  MENU: 'nba-officials-navigation',
  HOME: 'home',
  ABOUT_US: 'about-us',
}

export const DefaultRoutes = {
  ADMIN: RouteConstants.DASHBOARD,
  PROSPECT: generatePath(RouteConstants.PROSPECT_MY_PROFILE, { section: 'schedule' }),
  PARTNER: RouteConstants.ORG_MEMBER_DASHBOARD,
  PARTICIPANT: RouteConstants.PARTICIPANT_MEMBER_EVENTS,
  EVENTADMIN: RouteConstants.EVENT_DASHBOARD
}

export const PERMISSIONS_CONSTANTS = {
  SCOUTING_DASHBOARD: {
    subject: 'scoutingDashboard'
  },
  EVENT_DASHBOARD: {
    subject: 'eventDashboard'
  },
  LESSON_PLAN_DASHBOARD: {
    subject: 'lessonPlanDashboard'
  },
  MANAGE_PROSPECT: {
    subject: 'manageProspects'
  },
  PROSPECT_PROFILE: {
    subject: 'prospectProfile'
  },
  CANDIDATE_CAMP: {
    subject: 'candidateCamps'
  },
  EVENTS_CALENDAR: {
    subject: 'eventsCalendar'
  },
  MY_PROFILE: {
    subject: 'myProfile'
  },
  EDIT_PROFILE: {
    subject: 'editProfile'
  },
  COMMUNICATION_CENTER: {
    subject: 'communicationCenter'
  },
  ORGANIZER_DASHBOARD: {
    subject: 'organizerDashboard'
  },
  ORGANIZER_PROFILE: {
    subject: 'organizerProfile'
  },
  ORGANIZER_MEMBER_EVENTS: {
    subject: 'organizerEvent'
  },
  MASTER_COMMUNICATION_CATEGORY: {
    subject: 'settings'
  },
  NO_PROSPECT: {
    subject: 'noProspect'
  },
  SEND_REMINDER: {
    subject: 'sendReminder'
  },
  ADMIN_EVENT_NOTIFICATION: {
    subject: 'eventNotification'
  },
  CHANGE_PASSWORD: {
    subject: 'changePassword'
  },
  LOGIN: {
    subject: 'login'
  },
  SIGN_UP: {
    subject: 'signup'
  },
  LOGOUT: {
    subject: 'logout'
  }
}

export const PERMISSION_VERBS = {
  READ: 'Read',
  CREATE: 'Create',
  DELETE: 'Delete',
  UPDATE: 'Update'
}

export default RouteConstants;