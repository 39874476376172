import React, { useContext, useEffect, useState } from 'react';
import { Divider, Drawer, IconButton, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { clsx } from 'clsx';
import AppDataContext from '../../common/AppContext';
import MenuItems from './MenuItems';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    hide: {
        display: 'none',
    },
    drawer: {
        // width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        minWidth: drawerWidth,
        maxWidth: '100%',
        marginTop: theme.mixins.toolbar.minHeight,
        background: '#203663',
        color: '#fff'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        justifyContent: 'flex-end',
    },
}));

const NavDrawer = () => {
    const classes = useStyles();
    const { navigationMenu } = useContext(AppDataContext);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [menuData, setMenuData] = useState(navigationMenu.main || []);
    useEffect(() => {
        setMenuData(navigationMenu.main || [])
    }, [navigationMenu]);

    return (menuData.length > 0) ? <>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpenDrawer(!openDrawer)}
        >
            {openDrawer ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        {openDrawer && <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={openDrawer}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <List>
                {menuData.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems item={menu} key={index} depthLevel={depthLevel} navDrawerClose={() => setOpenDrawer(false)} />;
                })}
            </List>
        </Drawer>}
    </> : null
}

export default NavDrawer;