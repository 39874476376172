import React, { useContext, useEffect, useState } from "react";
import { Avatar, IconButton, ListItemAvatar, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import AppDataContext from "../../common/AppContext";
import { EmailOutlined, ExitToApp, VpnKeyOutlined, PersonAdd, Lock, AccountCircle, Forum, Dashboard, Event, SettingsOutlined, Edit, SportsBasketball, EmojiEvents, EmailRounded } from "@material-ui/icons";
import RouteConstants,{PERMISSIONS_CONSTANTS, PERMISSION_VERBS} from "../../common/RouteConstants";
import Helper from "../../common/Helper";
import { generatePath, useHistory } from "react-router-dom";
import { userService } from '../../services'
import PersonIcon from '@material-ui/icons/Person';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
//import { Role } from "../../helper";
import { Can,AbilityContext } from '../../common/Can'

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    item: {
        '&:hover': {
            background: '#0c214c',
            color: '#fff',
            '& svg': {
                color: '#fff'
            }
        }
    }
}));

const UserActionMenu = () => {
    const ability=useContext(AbilityContext)

    const classes = useStyles();
    const history = useHistory();
    const { checkRole, user, roles } = useContext(AppDataContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionMenuData, setActionMenuData] = useState([]);
    const open = Boolean(anchorEl);

    useEffect(() => {
        reCheckPermissions();
    }, [user, roles]);

    const reCheckPermissions = () => {
        setActionMenuData([
            {
                path: RouteConstants.DASHBOARD,
                icon: <Dashboard />,
                text: 'Scouting Dashboard',
                subject: PERMISSIONS_CONSTANTS.SCOUTING_DASHBOARD.subject,
                //role: [roles.admin, roles.scout, roles.adminscout]
            },
            {
                path: RouteConstants.EVENT_DASHBOARD,
                icon: <EmojiEvents />,
                text: 'Events Dashboard',
                subject:PERMISSIONS_CONSTANTS.EVENT_DASHBOARD.subject,
                //role: [roles.admin, roles.scout, roles.adminscout]
            },
            {
                path: RouteConstants.ADMIN_PROSPECTS_HOME,
                icon: <AccountCircle />,
                text: 'Manage Prospects',
                subject:PERMISSIONS_CONSTANTS.MANAGE_PROSPECT.subject,
                //role: [roles.admin, roles.scout, roles.adminscout]
            },
            {
                path: RouteConstants.ADMIN_CANDIDATE_CAMPS,
                icon: <SportsBasketball />,
                text: 'Candidate Camps',
                subject:PERMISSIONS_CONSTANTS.CANDIDATE_CAMP.subject,
                //role: [roles.admin, roles.scout, roles.adminscout]
            },
            {
                path: RouteConstants.ADMIN_YAO_EVENT_CALENDAR_HOME,
                icon: <Event />,
                text: 'Events Calendar',
                subject:PERMISSIONS_CONSTANTS.EVENTS_CALENDAR.subject,
                //role: [roles.admin, roles.scout, roles.adminscout]
            },
            {
                path: RouteConstants.ADMIN_SEND_REMINDER_EMAIL,
                icon: <EmailOutlined />,
                text: 'Send Reminder Email',
                role: [roles.admin, roles.adminscout]
            },
            {
                path: generatePath(RouteConstants.PROSPECT_MY_PROFILE, { section: 'schedule' }),
                icon: <AccountCircle />,
                text: 'My Profile',
                subject:PERMISSIONS_CONSTANTS.MY_PROFILE.subject,
                //role: [roles.user]
            },
            {
                path: generatePath(RouteConstants.PROSPECT_MY_PROFILE_EDIT, { section: 'contact-details' }),
                icon: <Edit />,
                text: 'Edit Profile',
                subject:PERMISSIONS_CONSTANTS.EDIT_PROFILE.subject,
                //role: [roles.user]
            },
            {
                path: RouteConstants.MESSAGE_CENTER_HOME,
                icon: <Forum />,
                text: 'Communication Center',
                subject:PERMISSIONS_CONSTANTS.COMMUNICATION_CENTER.subject,
                //role: [roles.user, roles.admin, roles.scout, roles.adminscout]
            },
            {
                path: RouteConstants.ORG_MEMBER_DASHBOARD,
                icon: <Dashboard />,
                text: 'Dashboard',
                subject:PERMISSIONS_CONSTANTS.ORGANIZER_DASHBOARD.subject,
                //role: [roles.partner]
            },
            {
                path: RouteConstants.ORG_MEMBER_PROFILE,
                icon: <AccountCircle />,
                text: 'My Profile',
                subject:PERMISSIONS_CONSTANTS.ORGANIZER_PROFILE.subject,
                //role: [roles.partner]
            },
            {
                path: RouteConstants.PARTICIPANT_MEMBER_EVENTS,
                icon: <Event />,
                text: 'My Events',
                subject:PERMISSIONS_CONSTANTS.ORGANIZER_MEMBER_EVENTS.subject,
                //role: [roles.participant]
            },
            {
                path: RouteConstants.ADMIN_SEND_REMINDER_EMAIL,
                icon: <EmailRounded />,
                text: 'Send Reminder Email',
                subject:PERMISSIONS_CONSTANTS.SEND_REMINDER.subject,
                //role: [roles.participant]
            },
            // {
            //     path: RouteConstants.PARTICIAPANT_MEMBER_PROFILE,
            //     icon: <AccountCircle />,
            //     text: 'My Profile',
            //     role: [roles.participant]
            // },
            {
                path: RouteConstants.MANAGE_COMMUNICATION_CATEGORY,
                icon: <SettingsOutlined />,
                text: 'Settings',
                subject:PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject,
                //role: [roles.admin, roles.adminscout]
            },
            {
                path: RouteConstants.NO_PROSPECT,
                icon: <PermContactCalendarIcon />,
                text: 'No Prospect',
                subject:PERMISSIONS_CONSTANTS.NO_PROSPECT.subject,
                //role: [roles.scout, roles.adminscout]
            },
            // common for all
            {
                path: RouteConstants.OFFICIAL_LOGIN,
                icon: <Lock />,
                text: 'Login',
                subject:PERMISSIONS_CONSTANTS.LOGIN.subject
            },
            {
                path: RouteConstants.OFFICIAL_REGISTRATION,
                icon: <Lock />,
                text: 'Sign Up',
                subject:PERMISSIONS_CONSTANTS.SIGN_UP.subject
            },
            {
                path: RouteConstants.OFFICIAL_LOGOUT,
                icon: <ExitToApp />,
                text: 'Log out',
                //role: '*',
                subject:PERMISSIONS_CONSTANTS.LOGOUT.subject
            }
        ]);
    }

    const toggleMenu = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget)
    }

    const routeTo = (e, path) => {
        if (e) e.preventDefault();
        toggleMenu();
        history.push({
            pathname: path
        })
    }

    return <>
        <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleMenu}
            color="inherit"
        >
            {user ? <PersonIcon /> : <PersonAdd />}
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={open}
            onClose={toggleMenu}
            className={classes.mainMenu}
        >
            {user && <MenuItem divider>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>{user && Helper.GetNameInitials(`${user.firstName} ${user.lastName}`)}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={userService.getUserRole()} />
            </MenuItem>}


            {actionMenuData.map((item, index) => {
                return <Can I={PERMISSION_VERBS.READ} a={item.subject}>
                    <MenuItem onClick={(e) => routeTo(e, item.path)} key={index} className={classes.item} component="a" href={item.path}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </MenuItem>
                </Can>
            })}
        </Menu>
    </>
}

export default UserActionMenu;