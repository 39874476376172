import React, { useState, useContext, useEffect } from 'react';
import {
    makeStyles, Typography, Toolbar, AppBar, IconButton, Switch, Tooltip,
    Paper, Hidden, Button, Fab, FormControlLabel
} from '@material-ui/core';
import { NoRecordsOverlay } from '../../../common/NoRecordsOverlay';
import PageNotFoundUI from '../../../sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import { Add, Assessment } from '@material-ui/icons';
import EditIcon from "@material-ui/icons/Edit";
import { DataGrid } from '@material-ui/data-grid';
import Loader from '../../../sharedComponents/Loader/Loader';
import { AppDataContext } from '../../../common/AppContext'
import AddEditClinician from './AddEditEventClinician';
import { referenceDataService } from '../../../services';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: theme.spacing(1),
        zIndex: 0,
        marginTop: theme.mixins.toolbar.minHeight,
        '& .super-app-theme--header': {
            backgroundColor: '#1d428a',
            color: '#ffffff'
        },
    },
    appbarBackground: {
        backgroundColor: "#fff",
        color: "red"
    },
    title: {
        flexGrow: 1,
    },
    container: {
        flexGrow: 1,
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '45px'
        }
    },
    cursorPointer: {
        cursor: 'pointer',
        float: 'right'
    },
    listContainer: {
        display: 'flex',
        // flexGrow: 1,
        paddingRight: '130px'
    },
    buttonAlign: {

        [theme.breakpoints.up('sm')]: {
            paddingLeft: '20px',
            alignSelf: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '10px'
        }
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'end'
    },
}))
const InitialClinician = { id: 0, name: '', isActive: 1, hasError: false };
const EventClinicianContainer = (props) => {
    const { addSnackMessages } = useContext(AppDataContext);
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [editItem, setEditItem] = useState({ ...InitialClinician });
    const [open, setOpen] = useState(false);
    const [eventclinician, setEventclinician] = useState([]);

    useEffect (() => {
        getEventClinician();
    },[])

    const getEventClinician = () => {
        setIsLoading(true);
        referenceDataService.getAllEventClinicialList()
            .then(result => {
                if (result) {
                    setEventclinician(result);
                    setIsLoading(false);
                }
            });
    }

    const handleUpdateStatus = (status, row) => {
        let data = eventclinician.find(x => x.id === row.id);
        if (data) {
            data['isActive'] = status == 1 ? true : false;
            handleSubmitClinician(data)
        }
    }

    const handleSubmitClinician = (Clinician) => {
        if (validateClinician(Clinician)) {
            setIsLoading(true);
            let msg = Clinician.id === 0 ? 'Clinician saved successfully' : 'Clinician updated successfully';
            Clinician.name = Clinician.name;

            referenceDataService.SavetEventClinician(Clinician)
                .then(result => {
                    if (result && result.data == 0) {
                        addSnackMessages([{
                            open: true,
                            severity: 'error',
                            message: "Duplicate Clinician!"
                        }]);
                        setIsLoading(false);
                        return;
                    }
                    setEditItem({ ...InitialClinician });
                    addSnackMessages([{
                        open: true,
                        severity: 'success',
                        message: msg
                    }]);
                    getEventClinician();
                    setIsLoading(false);
                    setOpen(false);
                })
                .catch(err => {
                    addSnackMessages([{
                        open: true,
                        severity: 'error',
                        message: err.message
                    }]);
                    setIsLoading(false);
                    setOpen(false);
                })
        } else {
            addSnackMessages([{
                open: true,
                severity: 'error',
                message: "Duplicate Clinician!"
            }]);
        }
    }

    const validateClinician = (Clinician) => {
        let data = { ...Clinician };
        let isvalid = true;
        if (data.name === '') {
            data.hasError = true;
            isvalid = false;
        }
        setEditItem(data);

        return isvalid;
    }

    const handleAddClick = () => {
        setOpen(true);
        setEditItem({ ...InitialClinician })
    }

    const EditRow = ({ index }) => {
        const handleEditClick = () => {
            setEditItem(index);
            setOpen(true);
        }

        return (
            <FormControlLabel
                control={
                    <Tooltip title='Edit'>
                        <IconButton
                            color="primary"
                            aria-label="Edit"
                            onClick={handleEditClick}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                }
            />
        )
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Clinician',
            width: 150,
            headerAlign: 'left',
            align: 'left',
            disableColumnMenu: true,
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={params.row.name} placement="right">
                    <span className="table-cell-trucate">{params.row.name}</span>
                </Tooltip>
            ),
        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <div className='d-flex justify-content-between align-items-center' style={{ cursor: "pointer" }}>
                        <FormControlLabel
                            control={
                                <Tooltip title={(params.row.isActive) ? 'Active' : 'Inactive'}>
                                    <Switch
                                        checked={(params.row.isActive)}
                                        onChange={(e) => handleUpdateStatus((e.target.checked ? 1 : 0), params.row)}
                                        color="primary"
                                        name="Active"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Tooltip>
                            }
                            label={(params.row.isActive) ? 'Active' : 'Inactive'}
                        />
                    </div>
                )
            }
        },
        {
            field: "id",
            headerName: "Actions",
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'right',
            disableColumnMenu: true,
            headerClassName: 'super-app-theme--header',
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div className='d-flex justify-content-between align-items-center' style={{ cursor: "pointer" }}>
                        <EditRow index={params.row}></EditRow>
                    </div >
                )
            }
        }
    ]


    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appbarBackground}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Manage Clinician
                    </Typography>
                    <Hidden smDown>
                        <Button edge="end" size='small' color='primary' variant='contained' className={classes.cursorPointer} onClick={handleAddClick}>
                            <Add ></Add>Add New
                        </Button>
                    </Hidden>
                </Toolbar>
            </AppBar>
            <Hidden mdUp>
                <Fab color="primary" aria-label="add" style={{ position: 'absolute', bottom: 10, right: 10 }} onClick={handleAddClick}>
                    <Add />
                </Fab>
            </Hidden>
            <Paper p={0} elevation={0} className={classes.container} >

                {eventclinician && eventclinician.length ? <div style={{ width: '100%' }} >
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                rows={[...eventclinician]}
                                columns={columns}
                                autoHeight
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                hideFooterSelectedRowCount={true}
                                components={{
                                    NoRowsOverlay: NoRecordsOverlay,
                                }}
                            />  </div>
                    </div>
                </div> : (!isLoading ? <PageNotFoundUI
                    showPageIcon
                    pageIcon={<Assessment />}
                    title="Clinician not available."
                    subtitle=""
                /> : null)
                }
                {/* </Paper> */}
            </Paper >
            {open && <AddEditClinician open={open}
                Clinician={editItem}
                handleCloseClick={() => setOpen(false)}
                onSubmit={handleSubmitClinician}
                isLoading={isLoading}
            />}
            <Loader isLoading={isLoading} />
        </div >
    );
}

export default EventClinicianContainer;