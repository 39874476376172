import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

const webAPIURL = () => {
    //const { REACT_APP_PUBLIC_URL } = process.env;
    let url = `${new URL(window.location.origin)}api`;
    return url
};


export const EvaluationCategoryData = {
    getAllEvaluationCategoryList,
    insertEvaluationCategory,
    updateEvaluationCategory,
    updateEvaluationCategoryStatus,
    insertEvaluationSubCategory,
    updateEvaluationSubCategory,
    updateEvaluationSubCategoryStatus,
    insertEvaluationCompetency,
    updateEvaluationCompetency,
    getCompetenciesList,
    updateCompetencyStatus,
    getEvaluationEvents,
    saveEvaluationRatings,
    getEvaluationList,
    deleteEvaluation,
    getSeasonType,
    insertEvent,
    getCognitiveServiceToken,
    updateEvent,
    excelExportEvaluationEvents,
    getTangibleList,
    updateTangible,
    insertTangible
}

function getAllEvaluationCategoryList() {
    return APIHelper.get(APIURLConstants.GET_EVALUATION_CATEGORY_LIST, authHeader())
        //.then(handleResponse)
        .then(result => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return result.data;
        }).catch(handleError);
}
function insertEvaluationCategory(data) {
    return APIHelper.post(APIURLConstants.INSERT_EVALUATION_CATEGORY, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function updateEvaluationCategory(data) {
    return APIHelper.post(APIURLConstants.UPDATE_EVALUATION_CATEGORY, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function updateEvaluationCategoryStatus(data) {
    return APIHelper.post(APIURLConstants.UPDATE_EVALUATION_CATEGORY_STATUS, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function insertEvaluationSubCategory(data) {
    return APIHelper.post(APIURLConstants.INSERT_EVALUATION_SUBCATEGORY, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function updateEvaluationSubCategory(data) {
    return APIHelper.post(APIURLConstants.UPDATE_EVALUATION_SUBCATEGORY, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function updateEvaluationSubCategoryStatus(data) {
    return APIHelper.post(APIURLConstants.UPDATE_EVALUATION_SUBCATEGORY_STATUS, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}


function insertEvaluationCompetency(data) {
    return APIHelper.post(APIURLConstants.INSERT_EVALUATION_COMPETENCY, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function updateEvaluationCompetency(data) {
    return APIHelper.post(APIURLConstants.UPDATE_EVALUATION_COMPETENCY, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getCompetenciesList() {
    return APIHelper.get(APIURLConstants.GET_EVALUATION_COMPETENCY, authHeader())
        //.then(handleResponse)
        .then(result => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return result.data;
        }).catch(handleError);
}

function updateCompetencyStatus(data) {
    return APIHelper.post(APIURLConstants.UPDATE_COMPETENCY_STATUS, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getEvaluationList(data) {
    return APIHelper.post(APIURLConstants.EVALUATION_LIST, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function getEvaluationEvents(data) {
    return APIHelper.post(APIURLConstants.GET_EVALUATION_EVENTS, data, authHeader())
        //.then(handleResponse)
        .then(result => {
            return result.data;
        }).catch(handleError);
}

function saveEvaluationRatings(ratings) {
    return APIHelper.post(APIURLConstants.SAVE_EVALUATION_RATINGS, ratings, authHeader())
        //.then(handleResponse)
        .then(result => {
            return result.data;
        }).catch(handleError);
}

function deleteEvaluation(evaluation) {
    return APIHelper.post(APIURLConstants.DELETE_EVALUATION, evaluation, authHeader())
        //.then(handleResponse)
        .then(result => {
            return result.data;
        }).catch(handleError);
}

function getSeasonType(groupName) {
    let postdata = { "masterGroupName": groupName }
    return APIHelper.post(`${APIURLConstants.GET_MASTER_DATA_BY_GROUP}`, postdata, authHeader())

        //.then(handleResponse)
        .then(result => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return result.data;
        }).catch(handleError);
}

function insertEvent(data) {
    return APIHelper.post(APIURLConstants.INSERT_EVALUATION_EVENT, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function updateEvent(data) {
    return APIHelper.post(APIURLConstants.UPDATE_EVALUATION_EVENT, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getCognitiveServiceToken() {
    return APIHelper.get(APIURLConstants.GET_MS_COGNITIVE_SERVICE_TOKEN, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data.data;
        }).catch(handleError);
}

function excelExportEvaluationEvents(data) {
    let headers = authHeader();
    headers.responseType = 'blob';
    let apiURL = webAPIURL();

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: apiURL + APIURLConstants.EXCEL_EXPORT_EVENT_LIST,
            data: data,
            headers: headers
        }).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err)
        })
    })

}

function getTangibleList() {
    return APIHelper.get(APIURLConstants.GET_EVALUATION_TANGIBLES, authHeader())
        //.then(handleResponse)
        .then(result => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return result.data;
        }).catch(handleError);
}


function updateTangible(data) {
    return APIHelper.post(APIURLConstants.UPDATE_EVALUATION_TANGIBLE, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function insertTangible(data) {
    return APIHelper.post(APIURLConstants.INSERT_EVALUATION_TANGIBLE, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

