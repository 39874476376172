import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { useHistory, useLocation, useParams } from "react-router-dom";
import Quiz from '../../extraLib/Quiz';
import { LessonPlanDataService } from '../../services/lessonPlans.service';
import SectionLoader from '../../sharedComponents/Loader/SectionLoader';
import PageNotFoundUI from '../../sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import RouteConstants from '../../common/RouteConstants';
import { AppDataContext } from '../../common/AppContext';
import Helper from '../../common/Helper';
import EditProfileValidationDialog from '../../sharedComponents/dialog/EditProfileValidationDialog';
//import { Role } from '../../helper';

const LessonPlanDetail = (props) => {
    let location = useLocation();
    const params = useParams();
    const history = useHistory();
    const { user, checkRole, roles, jwtTokenDetails } = useContext(AppDataContext)
    // const [selectedQuiz, setSelectedQuiz] = useState(location.state !== undefined ? location.state.selectedQuiz : location.state.selectedQuiz);
    const [questionJSON, setQuestionJSON] = useState([]);
    const [jwToken, setJwTokenDetails] = useState({});
    const [result, setResult] = useState();
    const [correntAnswerIndex, setCorrectAnswerIndex] = useState([])
    const [inCorrentAnswerIndex, setInCorrectAnswerIndex] = useState([])
    const [userAnswersInput, setUserAnswersInput] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNotFound, setPageNotFound] = useState(false);
    const [notAuthorized, setNotAuthorized] = useState(false);
    useEffect(() => {
        setJwTokenDetails(jwtTokenDetails)
    }, [jwtTokenDetails])
 

    useEffect(() => {
        if (result) {
            let officialUID = Helper.getOfficialId()
            if (officialUID !== "" && checkRole(roles.user)) {
                let saveResult = { id: 0, officialUID: officialUID, lessonPlanID: params.id, lessonPlanStatus: 1 };
                LessonPlanDataService.saveQuizStatus(saveResult)
                    .then(result => {
                        console.log(result);
                    })
            }
        }
    }, [result])

    useEffect(() => {
        let officialUID = Helper.getOfficialId()
        if (Helper.isUserLoggedIn()) {

            if (user && !user.isEmailVerified) {
                history.push(RouteConstants.EMAIL_VERIFY);
            }
            else if (user && params.id) {
                setIsLoading(true);

                let data = { lessonPlanID: params.id }
                if (officialUID !== "") {
                    data.officialUID = officialUID
                }
                getLessonPlan(data);
            }
        }
        else if (params.id) {
            setIsLoading(true);
            let data = { lessonPlanID: params.id }
            if (officialUID !== "") {
                data.officialUID = officialUID
            }
            getLessonPlan(data);
        }


    }, [params.id, user]);

    const getLessonPlan = (data) => {
        LessonPlanDataService.getQuizContent(data)
            .then(res => {
                if (res !== null && res !== "") {
                    setUserResponse(res);
                } else {
                    setPageNotFound(true);
                }
                setIsLoading(false);
            })
            .catch(err => {
                if (err.status === 404) {
                    setPageNotFound(true);
                } else if (err.status === 401) {
                    setNotAuthorized(true);
                } else {
                    setPageNotFound(true);
                }
            });
        return;
    }

    const setUserResponse = (quizJson) => {
        let jsonQuiz = { ...quizJson };
        let questions = jsonQuiz.questions;
        let correctAnswer = [];
        let incorrectAnswer = [];
        let userInputs = []
        questions.forEach((question, index) => {
            if (question.userAnswers.length > 0) {
                if (question.result) {
                    correctAnswer.push(index);
                } else {
                    incorrectAnswer.push(index);
                }
                if (userInputs[index] === undefined) {
                    userInputs[index] = []
                }
                if (question.answerSelectionType === 'single') {
                    let ans = question.userAnswers[0]
                    userInputs[index] = question.answers.indexOf(ans) + 1;
                } else {
                    question.userAnswers.forEach((userAnswer) => {
                        userInputs[index].push(question.answers.indexOf(userAnswer) + 1)
                    })
                }
            }
        });

        setCorrectAnswerIndex(correctAnswer);
        setInCorrectAnswerIndex(incorrectAnswer);
        setUserAnswersInput(userInputs);
        setQuestionJSON(jsonQuiz)
    }
    const handleShuffledQuestions = (questions) => {
        console.log(questions)
    }
    const handleComplete = (result) => {
        setResult(result);
    }
    const handleQuizStart = (start) => {
        if (start && checkRole(roles.user)) {
            let saveResult = { id: 0, officialUID: Helper.getOfficialId(), lessonPlanID: params.id, lessonPlanStatus: 0 };
            LessonPlanDataService.saveQuizStatus(saveResult)
                .then(result => {
                    console.log(result);
                })
        }
    }
    return (
        <React.Fragment>
            {pageNotFound ? <PageNotFoundUI
                title="Oops! 404 Page Not Found"
                subtitle="We can't seem to find the lesson plan you're looking for. It might be removed/deleted."
                showFabIcon
                fabIcon={<LibraryBooksIcon />}
                fabRoute={RouteConstants.LESSON_PLANS}
            /> :
                notAuthorized ?
                    <PageNotFoundUI
                        title="Ohh! You are not authorized."
                        subtitle="You can not see the lesson plan, because you are not authorised for."
                        showFabIcon
                        fabIcon={<LibraryBooksIcon />}
                        fabRoute={RouteConstants.LESSON_PLANS}
                    /> :
                    <Box component='div' display='flex' flexGrow={1} justifyContent='center'>
                        {isLoading ? <SectionLoader /> :
                            (user && !user.isProfileComplete) ? <EditProfileValidationDialog /> :
                                Object.keys(questionJSON).length > 0 &&
                                <Quiz quizID={params.id} quiz={questionJSON} showInstantFeedback={true} onComplete={handleComplete}
                                    onQuizStart={handleQuizStart} officialID={Helper.getOfficialId()} correntAnswerIndex={correntAnswerIndex} inCorrentAnswerIndex={inCorrentAnswerIndex} userAnswersInputs={userAnswersInput}
                                    jwPlayersrc={jwToken.jwPlayerSource} jwPlayerkey={jwToken.jwPlayerKey} playExternalVideo={true} shuffledQuestions={handleShuffledQuestions} />
                        }
                    </Box>}
        </React.Fragment>
    )
}

export default LessonPlanDetail;