import React, { useState, useEffect, useContext } from "react";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import { makeStyles, Typography, TextField, FormControl, InputLabel, MenuItem, Select, Button, Grid, FormHelperText, LinearProgress, Link, CircularProgress } from "@material-ui/core";
import RouteConstants from "../../../common/RouteConstants";
import Validations from "../../../common/Validations";
import AppDataContext from "../../../common/AppContext";
import { Autocomplete } from '@material-ui/lab';
import { eventCalendarService } from '../../../services';



const useStyles = makeStyles((theme) => ({
}));

const EventRegistrationComponent = (props) => {
    let location = useLocation();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const { addSnackMessages, countryList, experienceLevelList } = useContext(AppDataContext);
    const redirectTo = new URLSearchParams(props.location.search).get("redirectTo");
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const eventIDEncrypted = params.get('e');
    const eventSlugEncrypted = params.get('s');
    const [formData, setFormData] = useState({
        "eventID": eventIDEncrypted !== undefined ? eventIDEncrypted : '',
        "firstName": "",
        "lastName": "",
        "email": "",
        "confirmEmail": "",
        "userName": "",
        "password": "",
        "confirmPassword": "",
        "country": 0,
        "experienceLevel": 0,
        "eventSlug": eventSlugEncrypted !== undefined ? eventSlugEncrypted : '',
    });
    const [formDataError, setFormDataError] = useState([]);


    useEffect(() => {
        if (Object.keys(formDataError).length) isFormDataValid();
    }, [formData]);

    const isFormDataValid = () => {
        let errors = [];

        Object.entries(formData).forEach(([key, value]) => {
            let field = false;
            switch (key) {
                case "firstName":
                    field = Validations.Validate("First Name", value.trim(), { Required: true, AllowOnlyCharacters: true });
                    break;
                case "lastName":
                    field = Validations.Validate("Last Name", value.trim(), { Required: true, AllowOnlyCharacters: true });
                    break;
                case "email":
                    field = Validations.Validate("Email", value.trim(), { Required: true, Email: true });
                    break;
                case "email":
                    field = Validations.Validate("confirmEmail", value.trim(), { Required: true, Email: true });
                    break;
                case "aboutusSource":
                    field = Validations.Validate("Field", value.trim(), { Required: true });
                    break;
                case "country":
                    field = Validations.Validate("Country", value, { Min: 1 });
                    if (field && !field.isValid) {
                        field.validationMessage = "Country is required."
                    }
                    break;
                case "experienceLevel":
                    field = Validations.Validate("Experience Level", value, { Min: 1 });
                    if (field && !field.isValid) {
                        field.validationMessage = "Experience Level is required."
                    }
                    break;
            }

            if (field && !field.isValid) {
                errors[key] = field.validationMessage;
            }
        });


        // Email & Confirm Email equal check
        if (!errors.email && !errors.confirmEmail && formData.email !== formData.confirmEmail) {
            errors['confirmEmail'] = "Email & Confirm Email should be same.";
        }

        if (Object.keys(errors).length) {
            setFormDataError(errors);
            return false;
        }
        setFormDataError([]);
        return true;
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!isFormDataValid()) {
            setIsLoading(false);
            return false;
        }
        eventCalendarService.saveGuestUser(formData)
            .then(res => {
                if (res && res.data) {
                    addSnackMessages([{
                        open: true,
                        severity: "success",
                        message: res.message
                    }]);
                    setTimeout(() => {
                        gotoEventPage();
                    }, 3000);
                    setIsLoading(false);
                } else {
                    addSnackMessages([{
                        open: true,
                        severity: "error",
                        message: res.errorMessage ?? "Something went wrong"
                    }]);
                    setIsLoading(false);
                }
            }).catch(e => {
                setFormDataError(e.errors);
                addSnackMessages([{
                    open: true,
                    severity: "error",
                    message: e.message ?? "Something went wrong. Please try again later"
                }]);
                setIsLoading(false);
            })
    }

    const gotoLogin = (e) => {
        if (e) e.preventDefault();
        props.history.push({
            pathname: RouteConstants.OFFICIAL_LOGIN,
            search: "?redirectTo=" + redirectTo
        });
        return;
    }

    const gotoSignup = (e) => {
        if (e) e.preventDefault();
        props.history.push({
            pathname: RouteConstants.OFFICIAL_REGISTRATION,
            search: "?s=" + eventIDEncrypted + "&e=" + eventSlugEncrypted
        });
        return;
    }

    const gotoEventPage = (e) => {
        // if (e) e.preventDefault();
        // props.history.push({
        //     pathname: RouteConstants.YAO_EVENTS,
        // });
        if ((props.location.state && props.location.state.redirectTo) || redirectTo) {
            const redirectPath = (props.location.state && props.location.state.redirectTo) ? props.location.state.redirectTo : redirectTo;
            props.history.push({
                pathname: redirectPath
            });
        }
        return;
    }

    return (
        <>
            <Grid container spacing={2} component="form" noValidate onSubmit={(e) => handleFormSubmit(e)}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" color="secondary">
                        Register for Event
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        variant="outlined"
                        required
                        fullWidth
                        size="small"
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        error={formDataError.email && true || false}
                        helperText={formDataError.email && formDataError.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        name="confirmEmail"
                        id="confirmEmail"
                        size="small"
                        label="Confirm Email"
                        fullWidth
                        required
                        value={formData.confirmEmail}
                        onChange={(e) => setFormData({ ...formData, confirmEmail: e.target.value })}
                        error={formDataError.confirmEmail && true || false}
                        helperText={formDataError.confirmEmail && formDataError.confirmEmail}
                    />
                </Grid>



                <Grid item md={6} xs={12}>
                    <TextField
                        variant="outlined"
                        id="coachFirstName"
                        name="firstName"
                        size="small"
                        label="First Name"
                        fullWidth
                        required
                        value={formData.firstName}
                        onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        error={formDataError.firstName && true || false}
                        helperText={formDataError.firstName && formDataError.firstName}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        variant="outlined"
                        id="coachLastName"
                        name="lastName"
                        size="small"
                        label="Last Name"
                        fullWidth
                        required
                        value={formData.lastName}
                        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        error={formDataError.lastName && true || false}
                        helperText={formDataError.lastName && formDataError.lastName}
                    />
                </Grid>





                <Grid item xs={12} >
                    <Autocomplete
                        id={`country`}
                        size="small"
                        disableClearable
                        // getOptionSelected={(option, value) => option.numericValue === value.numericValue}
                        value={countryList.find(x => x.numericValue === formData.eventCountry)}
                        // defaultValue={countryList.find(x => x.numericValue === formData.eventCountry)}
                        options={countryList}
                        getOptionLabel={(option) => option.displayName ? option.displayName : ""}
                        onChange={(event, newValue) => {
                            let val = newValue !== null ? newValue.numericValue : 0;
                            setFormData({ ...formData, country: val });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label={`Country`}
                                error={formDataError.country && true || false}
                                helperText={formDataError.country}
                                required
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} >
                    <Autocomplete
                        id={`experienceLevel`}
                        size="small"
                        disableClearable
                        // getOptionSelected={(option, value) => option.numericValue === value.numericValue}
                        value={experienceLevelList.find(x => x.numericValue === formData.experienceLevel)}
                        // defaultValue={countryList.find(x => x.numericValue === formData.eventCountry)}
                        options={experienceLevelList}
                        getOptionLabel={(option) => option.displayName ? option.displayName : ""}
                        onChange={(event, newValue) => {
                            let val = newValue !== null ? newValue.numericValue : 0;
                            setFormData({ ...formData, experienceLevel: val });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label={`Experience Level`}
                                error={formDataError.experienceLevel && true || false}
                                helperText={formDataError.experienceLevel}
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        className="capitalize"
                        disabled={(isLoading || Object.keys(formDataError).length > 0)}
                        startIcon={isLoading && <CircularProgress color="secondary" size={24} />}
                    >
                        Register
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body2" align="center"> If you want to create a profile with us. <Link href={RouteConstants.OFFICIAL_REGISTRATION} onClick={gotoSignup}><strong>Click here</strong></Link></Typography>
                    <Typography variant="body2" align="center">Already have an account.? <Link href={RouteConstants.OFFICIAL_LOGIN} onClick={gotoLogin}><strong>Login</strong></Link></Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(EventRegistrationComponent);
