import React, { Fragment, useContext } from 'react';
import { Route } from 'react-router-dom';
import { CssBaseline, makeStyles } from '@material-ui/core';
import OrgNavigationDrawer from '../screens/organizers/components/OrgNavigationDrawerComponent';
import FooterOrganizer from '../sharedComponents/footer/FooterOrganizer';
import AppBar from '../sharedComponents/appBar/AppHeaderBar';
import UserActionMenu from '../sharedComponents/appBar/UserActionMenu';
import Navbar from '../sharedComponents/multiLevelTopNav/NavBar';
import NotificationMenu from '../sharedComponents/Notifications/NotificationMenu';
import NavDrawer from '../sharedComponents/multiLevelTopNav/NavDrawer';
import GlobalMessageSection from '../sharedComponents/appBar/GlobalMessageSection';
import AppDataContext from '../common/AppContext';

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    wrapper: {
        display: 'flex',
        zIndex: 0,
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight + 24}px)`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: theme.mixins.toolbar.minHeight
    }
}));

const OrganizerLayout = (props) => {
    const classes = useStyles();
    const { navigationMenu } = useContext(AppDataContext);
    return (
        <Fragment>
            <AppBar
                mainNav={<Navbar />}
                mobileNav={<NavDrawer />}
                notificationMenu={<NotificationMenu />}
                userActionMenu={<UserActionMenu />}
            />
            <div className={classes.wrapper}>
                <OrgNavigationDrawer />

                <div className={classes.pageContainer}>
                    <main className={classes.main}>
                        <CssBaseline />
                        {(navigationMenu && navigationMenu.globalMessage) ? <GlobalMessageSection message={navigationMenu.globalMessage} /> : null}
                        <Route {...props} />
                    </main>
                    {!props.hideFooter && <FooterOrganizer />}
                </div>

            </div>
        </Fragment>
    );
}

OrganizerLayout.defaultProps = {
    hideFooter: false
}

export default OrganizerLayout;