import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
    root:{
        height: '100%'
    },
    media: {
        height: 240,
        background: '#000',
        '&.video': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '& iframe': {
                maxHeight: 240
            },
            '& div': {
                maxHeight: 240
            }
        }
    }
}));

const VideoOrMediaSection = ({ items }) => {
    const classes = useStyles();
    const [mediaData, setMediaData] = useState(items);

    const renderMedia = (item, index) => {
        if (item.media) {
            switch (item.media.mediaTypeAlias) {
                case "Image":
                    if (item.mediaURL.length > 0) {
                        return <CardMedia
                            controls
                            className={`${classes.media} video`}
                            poster={item.media._url}
                        >
                            <ReactPlayer controls url={item.mediaURL} />
                        </CardMedia>
                    }
                    return <CardMedia
                        className={classes.media}
                        component={"img"}
                        src={item.media._url}
                    />
                    break;
                case "video":
                    return <CardMedia
                        controls
                        className={`${classes.media} video`}
                        component={"video"}
                        src={item.media._url}
                    />
                    break;
                default:
                    break;
            }
        }
        else {
            if (item.mediaURL.length > 0) {
                return <CardMedia
                    controls
                    className={`${classes.media} video`}
                >
                    <ReactPlayer controls url={item.mediaURL} />
                </CardMedia>
            }
        }
    }


    return mediaData.map((item, index) => <Grid item xs={12} sm={6} md={4} key={index}>
        <Card className={classes.root}>
            {renderMedia(item, index)}
            <CardContent>
                <Typography gutterBottom variant="h4" component="h2" color='primary'>{item.title}</Typography>
                {item.subtitle && <Typography variant="body2" color="textSecondary" component="p">{item.subtitle}</Typography>}
            </CardContent>
        </Card>
    </Grid>
    )
}

export default VideoOrMediaSection;