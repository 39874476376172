
import React, { useState, useEffect } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    passwordRule: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        color: "#fff",
    },
    sectionPopover: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "left",
    },
    popoverInnerSection: {
        '&::before': {
            position: 'absolute',
            display: 'block',
            //content: "''",
            borderColor: 'transparent',
            borderStyle: 'solid',
            top: '0',
            right: '10px',
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            borderBottomColor: 'rgba(0, 0, 0, 0.25)',
        },
        '&::after': {
            position: 'absolute',
            display: 'block',
            //content: "''",
            borderColor: 'transparent',
            borderStyle: 'solid',
            top: '1px',
            right: '10px',
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            borderBottomColor: '#fff',
        },
        background: '#fff !important',
        boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(1),
        '& p': {
            color: '#c70e2d',
            fontSize: '.75rem',
            lineHeight: '1',
            padding: theme.spacing(.3, 0),
        },
    },
}));

export default function ErrorPopover(props) {
    const classes = useStyles();
    let ruleArray = [];

    if (props.for === "Password")
        ruleArray = ["Password should be minimum 8 and maximum 50 characters.", "Password should contain at least one uppercase, one lowercase, one digit.",
            "Password should not contain space(s)."];
    if (props.for === "Username")
        ruleArray = ["Username should be minimum 8 characters", "& Username should not contain space."];
    if (props.for == "Email")
        ruleArray = ["Email is not valid"];
    if (props.for == "EmailEmpty")
        ruleArray = ["Email is mandatory"];    
    if (props.for == "ConfirmEmail")
        ruleArray = ["Email and confirm email is not matched"];
    if (props.for == "maxEmail")
        ruleArray = ["You have already added 15 Email"];
    if (props.for == "ConfirmPassword")
        ruleArray = ["Password and confirm password is not matched"];
    if (props.for == "FirstName")
        ruleArray = ["First Name is mandatory"];
    if (props.for == "LastName")
        ruleArray = ["Last Name is mandatory"];
    if (props.for == "AboutusSource")
        ruleArray = ["How Did You Hear About Us is mandatory"];
    if (props.for == "AboutusDescriptionPerson")
        ruleArray = ["Please fill in the person name"];
    if (props.for == "AboutusDescriptionOther")
        ruleArray = ["Please fill in the detail"];


    return (<Popover placement="left" isOpen={props.isOpen} target={props.target} >
        <PopoverBody><div className={classes.sectionPopover}>
            <Card style={{ backgroundColor: '#7986cb' }} className={classes.popoverInnerSection}>
                {ruleArray.map((value, index) => {
                    return (<Typography key={index} className={classes.passwordRule}>{value}</Typography>)
                })}
            </Card>
        </div></PopoverBody>

    </Popover>)

}