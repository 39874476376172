import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import PageTitle from '../../../sharedComponents/PageTitle/PageTitle';
import HeroSliderCorousel from '../sections/HeroSliderCorousel';
import UserCardsSection from '../sections/UserCardsSection';
import FeatureBoxSection from '../sections/FeatureBoxSection';
import QuoteSection from '../sections/QuoteSection';
import MediaTextBlock from '../sections/MediaTextBlock';
import LessonPlanSection from '../sections/LessonPlanSection';
import EventsSection from '../sections/EventsSection';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    heroSection: {
        position: 'relative',
        '&:after': {
            content: `''`,
            background: 'radial-gradient(100% 150% at top, transparent 60%, #203663 61%)',
            width: '100%',
            position: 'absolute',
            height: '180px',
            bottom: 0,
            left: 0,
            right: 0
        }
    },
    highlights: {
        background: 'linear-gradient(180deg, #203663 0%, #6B8DCE 100%)',
        '& blockquote': {
            paddingTop: theme.spacing(7.5),
            paddingBottom: theme.spacing(3),
        }
    },
    userCards: {
        position: 'relative',
        marginTop: '-220px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
        }
    },
    featureBox: {
        paddingTop: 32,
        paddingBottom: 24
    },
}));

const HomeTemplate = ({ content }) => {
    const classes = useStyles();
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        generatePageSectionData(content.pageSections);
    }, [content])

    const generatePageSectionData = (pageSections) => {
        let tempPageData = [];
        pageSections.map((item) => {
            tempPageData[item.contentTypeAlias] = item.pageContent ? item.pageContent : item;
        });
        setPageData(tempPageData);
        return false;
    }

    return <>
        {content && content.showPageTitle && <PageTitle title={content.pageTitle} subtitle={content.subTitle} />}
        {pageData.heroCarouselSlider && <section className={classes.heroSection}>
            <HeroSliderCorousel items={pageData.heroCarouselSlider} />
        </section>}

        <section className={classes.highlights}>
            <Container maxWidth="lg" className={classes.content}>
                <>
                    {/* user cards section */}
                    {pageData.userCardItemGroup &&
                        <Grid container spacing={4} className={classes.userCards} justifyContent="center">
                            <UserCardsSection items={pageData.userCardItemGroup} />
                        </Grid>
                    }

                    {/* blockquote */}
                    {pageData.blockQuote && <QuoteSection quote={pageData.blockQuote} />}

                    {/* feature box section */}
                    {pageData.featureBoxItemGroup &&
                        <Grid container spacing={4} className={classes.featureBox} justifyContent="center">
                            <FeatureBoxSection
                                items={pageData.featureBoxItemGroup}
                                gridItemSize={4}
                            />
                        </Grid>
                    }
                </>
            </Container>
        </section>

        {content.showLessonPlans && <LessonPlanSection />}
        {content.showUpcomingEvents && <EventsSection />}

        {content.description && <Container maxWidth="lg" className={classes.content} component="section">
            <div dangerouslySetInnerHTML={{ __html: content.description }}></div>
        </Container>}

        {pageData.descriptionBlock && <Container maxWidth="lg" component="section" className={classes.content}>
            <div dangerouslySetInnerHTML={{ __html: pageData.descriptionBlock }}></div>
        </Container>}
        {pageData.mediaContentBlock &&
            <Container maxWidth="lg" className={classes.content} component="section">
                <MediaTextBlock data={pageData.mediaContentBlock} />
            </Container>
        }
    </>
}



export default HomeTemplate;