import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

export const prospectService = {
    getProfileList,
    getProfilePDF,
    saveWatchList,
    updateWatchList,
    deleteWatchList,
    getallWatchList,
    getProspectInvitation,
    getProfileHeadShots,
    getProfileHeadshot,
    getNoProspectList,
    deleteNoProspectList,
    insertNoProspectList,
    excelExportNoProspect,
    deleteProspectProfile,
    restoreProspectProfile
};
const webAPIURL = () => {
    //const { REACT_APP_PUBLIC_URL } = process.env;
    let url = `${new URL(window.location.origin)}api`;
    return url
};
function getProfileList(post) {

    return APIHelper.post(APIURLConstants.GET_ALL_PROSPECTS, post, authHeader())
        //.then(handleResponse)
        .then(propspects => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return propspects.data;
        }).catch(handleError);
}


function getProfileHeadShots(post) {

    return APIHelper.post(APIURLConstants.GET_ALL_PROSPECTS_HEADSHOTS, post, authHeader())
        //.then(handleResponse)
        .then(propspects => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return propspects.data;
        }).catch(handleError);
}

function getProfileHeadshot(obj) {

    return APIHelper.post(APIURLConstants.GET_HEAD_SHOT, obj, authHeader())
        //.then(handleResponse)
        .then(res => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return res.data.data;
        }).catch(handleError);
}

function getProspectInvitation(post) {

    return APIHelper.post(APIURLConstants.GET_PROSPECT_LIST_INVITATION, post, authHeader())
        //.then(handleResponse)
        .then(propspects => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return propspects.data;
        }).catch(handleError);
}

function getProfilePDF(data) {

    return APIHelper.post(APIURLConstants.GET_PROSPECT_PDF, data, authHeader())
        //.then(handleResponse)
        .then(propspects => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return propspects;
        }).catch(handleError);
}

function saveWatchList(data) {
    return APIHelper.post(APIURLConstants.SAVE_WATCHLIST, data, authHeader())
        .then(response => {
            return response;
        }).catch(handleError);
}
function updateWatchList(data) {
    return APIHelper.post(APIURLConstants.UPDATE_WATCHLIST, data, authHeader())
        .then(response => {
            return response;
        }).catch(handleError);
}

function deleteWatchList(data) {
    return APIHelper.post(APIURLConstants.DELETE_WATCHLIST, data, authHeader())
        .then(response => {
            return response;
        }).catch(handleError);
}

function getNoProspectList(data) {
    return APIHelper.post(APIURLConstants.GET_LIST_NOPROSPECT, data, authHeader())
        .then(response => {
            return response.data;
        }).catch(handleError);
}

function deleteNoProspectList(data) {
    return APIHelper.post(APIURLConstants.DELETE_NOPROSPECT, data, authHeader())
        .then(response => {
            return response.data;
        }).catch(handleError);
}

function insertNoProspectList(data) {
    return APIHelper.post(APIURLConstants.INSERT_PROSPECTS_LIST, data, authHeader())
        .then(response => {
            return response;
        }).catch(handleError);
}
function deleteProspectProfile(data) {
    return APIHelper.post(APIURLConstants.DELETE_PROFILE, data, authHeader())
        .then(response => {
            return response;
        }).catch(handleError);
}

function restoreProspectProfile(data) {
    return APIHelper.post(APIURLConstants.RESTORE_PROFILE, data, authHeader())
        .then(response => {
            return response;
        }).catch(handleError);
}

function excelExportNoProspect(data) {
    let headers = authHeader();
    headers.responseType = 'blob';
    let apiURL = webAPIURL();

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: apiURL + APIURLConstants.EXCEL_EXPORT_NOPROSPECT_LIST,
            data: data,
            headers: headers
        }).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err)
        })
    })

}

function getallWatchList () {
    
}




















