import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory, withRouter, Prompt } from "react-router-dom";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import RouteConstants, { PERMISSIONS_CONSTANTS } from './common/RouteConstants';
import { AppDataProvider, AppDataConsumer } from './common/AppContext';
import { UserProfileProvider, UserProfileConsumer } from './common/ProfileContext';
import ErrorBoundary from './sharedComponents/errorBoundary/ErrorBoundary';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Logout from './screens/authentication/Logout';
import { history } from './helper';
import { PrivateRoute } from './sharedComponents/PrivateRoute';
import './App.css'
import MasterLayout from './Layout/masterLayout'
import OfficialProfileLayout from './Layout/officialProfileLayout'
import MessageCenterLayout from './Layout/messageCenterLayout'
import AdminLayout from './Layout/adminLayout';

import LessonPlans from './screens/lessonPlans/LessonPlans';


import { defineDefaultRules, defineRulesfor } from './common/Permission';
import { AbilityContext } from './common/Can';




import EventDetails from './screens/events/EventDetails';

import SnackNotifier from './common/SnackNotifier';
import Events from './screens/events/Events';
import PublicLayout from './Layout/publicLayout';
import OrganizerLayout from './Layout/organizerLayout';

import UserRegistration from './screens/authentication/UserRegistration';
import ForgotPassword from './screens/authentication/ForgotPassword';
import ResetPassword from './screens/authentication/ResetPassword';
import Login from './screens/authentication/Login';
import LessonPlanDetail from './screens/lessonPlans/LessonPlanDetail';
import Page from './screens/pages/Page';
import ForceChangePassword from './screens/authentication/ForceChangePassword';
import Helper from './common/Helper';
import UnauthorizedUI from './sharedComponents/EmptyContainer/UnauthorizedUI';
import { loginRequest } from './config/authConfig';
import APIHelper from './common/ApiHelper';
import APIURLConstants from './common/ApiURLConstants';
import VerifyEmail from './screens/authentication/VerifyEmail';
import DownloadOurAppPopup from './sharedComponents/DownloadOurAppPopup';
import { userService, authenticationService } from './services'
import ReLogin from './screens/authentication/ReLogin';
import EventRegistration from './screens/authentication/EventRegistration';
import EventClinicianContainer from './screens/master/manageEventClinician/EventClinicanContainer';

const QuizUserAccess = React.lazy(() => import('./screens/master/cmsManageQuiz/QuizUserAccess'));
const QuizUsersAndScoreDetail = React.lazy(() => import('./screens/master/cmsManageQuiz/QuizUsersAndScoreDetail'));
const LessonPlanUserAccess = React.lazy(() => import('./screens/master/manageLessonPlans/LessonPlanUserAccessContainer'));
const CreateEditLessonPlanContainer = React.lazy(() => import('./screens/master/manageLessonPlans/CreateEditLessonPlanContainer'));
const ManageLessonPlansContainer = React.lazy(() => import('./screens/master/manageLessonPlans/ManageLessonPlansContainer'));
const CmsManagerQuizContainer = React.lazy(() => import('./screens/master/cmsManageQuiz/CmsManageQuizContainer'));
const CompetencyContainer = React.lazy(() => import('./screens/master/refereeCompetencies/CompetencyContainer'));
const TangibleContainer = React.lazy(() => import('./screens/master/tangibles/TangibleContainer'));
const EvaluationCategoryContainer = React.lazy(() => import('./screens/master/evaluationCategory/EvaluationCategoryContainer'));
const CommunicationCategoryContainer = React.lazy(() => import('./screens/master/communicationCategory/CommunicationCategoryContainer'));
const InviteOrgMember = React.lazy(() => import('./screens/admin/Organizers/InviteOrgMember'));
const CampAssessmentContainer = React.lazy(() => import('./screens/admin/CandidateCampAssessment/CampAssessmentContainer'));
const AddEditNotification = React.lazy(() => import('./screens/admin/Notification/AddEditNotification'));
const NotificationContainer = React.lazy(() => import('./screens/admin/Notification/NotificationContainer'));
const CandidateCampParticipants = React.lazy(() => import('./screens/admin/CandidateCamp/CandidateCampParticipants'));
const CreateEditCandidateCamp = React.lazy(() => import('./screens/admin/CandidateCamp/CreateEditCandidateCamp'));
const CandidateCamps = React.lazy(() => import('./screens/admin/CandidateCamp/CandidateCamps'));
const ManageLessonPlansDashboard = React.lazy(() => import('./screens/master/manageLessonPlans/ManageLessonPlansDashboard'));
const EvaluationContainer = React.lazy(() => import('./screens/admin/ProspectEvaluation/EvaluationContainer'));
const ProspectsContainer = React.lazy(() => import('./screens/admin/Prospects/prospectsContainer'));
const EventCalendar = React.lazy(() => import('./screens/admin/EventCalendar/EventCalendar'));
const EventCalendarHome = React.lazy(() => import('./screens/admin/EventCalendarHome/EventCalendarHome'));
const ParticipantsMyEvents = React.lazy(() => import('./screens/participants/ParticipantsMyEvents'));
const OrgCreateEditEvent = React.lazy(() => import('./screens/organizers/OrgCreateEditEvent'));
const OrganizerProfile = React.lazy(() => import('./screens/organizers/OrganizerProfile'));
const OrganizerDashboard = React.lazy(() => import('./screens/organizers/OrganizerDashboard'));
const MessageCenterThread = React.lazy(() => import('./screens/messageCenter/MessageCenterThread'));
const MessageCenterHome = React.lazy(() => import('./screens/messageCenter/MessageCenterHome'));
const EditProfileProspect = React.lazy(() => import('./screens/prospectProfile/EditProfileProspect'));

const ViewProfileProspect = React.lazy(() => import('./screens/prospectProfile/ViewProfileProspect'));
const DashboardContainer = React.lazy(() => import('./screens/admin/Dashboard/DashboardContainer'));
const PoolContainer = React.lazy(() => import('./screens/master/Pool/PoolContainer'));
const CreateEditCMSQuestionContainer = React.lazy(() => import('./screens/master/manageCMSQuestions/CreateEditCMSQuestionContainer'));
const ManageCMSQuestionsContainer = React.lazy(() => import('./screens/master/manageCMSQuestions/ManageCMSQuestionsContainer'));
const NoProspectContainer = React.lazy(() => import('./screens/admin/NoProspect/NoProspectContainer'))
const ReminderEmail = React.lazy(() => import('./screens/admin/reminderEmail/ReminderEmail'));
const EvaluationEventContainer = React.lazy(() => import('./screens/master/evaluationEvent/EvaluationEventContainer'));
const EventDashboardContainer = React.lazy(() => import('./screens/admin/EventDashboard/EventDashboardContainer'))
const ClinicEventCategoryContainer = React.lazy(() => import('./screens/master/clinicEventCategory/ClinicEventCategoryContainer'));
const UserRoleContainer = React.lazy(() => import('./screens/master/manageUserRole/UserRoleContainer'));
const RolePermissionContainer = React.lazy(() => import('./screens/master/manageRolePermissions/RolePermissionContainer'));



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    }
}));

function App(props) {
    const ability = useContext(AbilityContext)
    const { instance, inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [user, setUser] = useState(false);
    const classes = useStyles();
    const brHistory = useHistory()
    //const {}=useLocation();
    const [themeType, setThemeType] = useState('light');
    //... See the theme colors at the bottom of this control.
    const theme = getTheme({
        paletteType: themeType,
    });
    useEffect(() => {
        if (isAuthenticated) {
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    console.log(response);


                    Helper.setStorage("chk", "aad");
                    APIHelper.get(APIURLConstants.GET_USER_DETAILS, { Authorization: `Bearer ${response.accessToken}` })
                        .then(res => res.data)
                        .then(async res => {
                            if (res !== null) {
                                delete res.roles
                                localStorage.setItem('currentUser', JSON.stringify(res));
                                Helper.setStorage("accessToken", res.token);
                                setUser(res)
                                let decoded = jwt_decode(res.token)
                                let permissions = await authenticationService.getPermissionByRole(decoded.roleid)
                                let rules = defineRulesfor(permissions)
                                //console.log(rules);
                                ability.update(rules)
                                console.log(res)
                            }
                        })
                        .catch(err => {
                            console.log(err)

                        })
                });
        }
        else if (localStorage.getItem('accessToken') == undefined) {
            let rules = defineDefaultRules()
            ability.update(rules)
        }
    }, []);

    useEffect(() => {
        if (instance && accounts[0]) {
            const accessTokenRequest = {
                ...loginRequest,
                account: accounts[0],
            };
            if (inProgress === InteractionStatus.None) {
                instance
                    .acquireTokenSilent(accessTokenRequest)
                    .then(async (response) => {
                        console.log(response);

                        // eslint-disable-next-line no-debugger
                        // debugger
                        //setShowLogin(false);
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect(accessTokenRequest);
                        }
                    });
            }
        }
    }, [instance, accounts, inProgress]);
    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <Router>
                    <AppDataProvider user={user}>
                        <AppDataConsumer>
                            {props => {
                                setUser(props.user)
                                return (<>
                                    {(props.user && userService.getUserRole() === 'User' && !props.user.isEmailVerified) ? <Redirect to={RouteConstants.EMAIL_VERIFY} /> : null}
                                    {(props.user && userService.getUserRole() === 'User' && props.user.lastLoginDate === null) ? <Redirect to={RouteConstants.NEW_USER_CHANGE_PASSWORD} /> : null}
                                    <UserProfileProvider>
                                        <UserProfileConsumer>
                                            {props1 => {
                                                return <>
                                                    <div className={classes.root} >
                                                        <Switch>
                                                            <Route exact path={RouteConstants.UNAUTHORIZED} component={UnauthorizedUI} hideFooter />
                                                            {/* Authentication Routes */}
                                                            <PublicLayout exact path={RouteConstants.OFFICIAL_LOGIN} component={Login} hideFooter />
                                                            <PublicLayout exact path={RouteConstants.FORGOT_PASSWORD} component={ForgotPassword} hideFooter />
                                                            <PublicLayout exact path={RouteConstants.RESET_PASSWORD} component={ResetPassword} hideFooter />
                                                            <PublicLayout exact path={RouteConstants.OFFICIAL_REGISTRATION} component={UserRegistration} hideFooter />
                                                            <Route exact path={RouteConstants.OFFICIAL_LOGOUT} component={Logout} />
                                                            <Route exact path={RouteConstants.OFFICIAL_RELOGIN} component={ReLogin} />
                                                            {/* Public Routes */}
                                                            <PublicLayout exact path={RouteConstants.LESSON_PLAN_DETAIL} component={LessonPlanDetail} />
                                                            <PublicLayout exact path={RouteConstants.LESSON_PLANS} component={LessonPlans} />
                                                            <PublicLayout exact path={RouteConstants.YAO_EVENTS} component={Events} />
                                                            <PublicLayout exact path={RouteConstants.YAO_EVENT_DETAILS} component={EventDetails} />
                                                            <PublicLayout exact path={RouteConstants.EVENT_REGISTRATION} component={EventRegistration} hideFooter />

                                                            {/* User Routes */}
                                                            <PublicLayout exact path={RouteConstants.EMAIL_VERIFY} component={VerifyEmail} />
                                                            <PrivateRoute exact path={RouteConstants.NEW_USER_CHANGE_PASSWORD} component={ForceChangePassword} subject={PERMISSIONS_CONSTANTS.CHANGE_PASSWORD.subject} />
                                                            {/* roles={['User', 'Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute path={RouteConstants.PROSPECT_MY_PROFILE} exact component={ViewProfileProspect} subject={PERMISSIONS_CONSTANTS.MY_PROFILE.subject} layout={OfficialProfileLayout} />
                                                            <PrivateRoute path={RouteConstants.PROSPECT_PROFILE} exact component={ViewProfileProspect} subject={PERMISSIONS_CONSTANTS.PROSPECT_PROFILE.subject} layout={OfficialProfileLayout} />
                                                            {/* roles={['User']} */}
                                                            <PrivateRoute path={RouteConstants.PROSPECT_MY_PROFILE_EDIT} exact component={EditProfileProspect} layout={OfficialProfileLayout} subject={PERMISSIONS_CONSTANTS.EDIT_PROFILE.subject} />
                                                            {/* roles={['User', 'Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.MESSAGE_CENTER_HOME} component={MessageCenterHome} layout={MessageCenterLayout} subject={PERMISSIONS_CONSTANTS.COMMUNICATION_CENTER.subject} />
                                                            {/* roles={['User', 'Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.MESSAGE_CENTER_THREAD} component={MessageCenterThread} layout={MessageCenterLayout} subject={PERMISSIONS_CONSTANTS.COMMUNICATION_CENTER.subject} />
                                                            {/* Organizer Routes */}
                                                            {/* roles={['Partner']}  */}
                                                            <PrivateRoute exact path={[RouteConstants.ORG_MEMBER_DASHBOARD, RouteConstants.ORG_MEMBER_EVENTS_LIST]} component={OrganizerDashboard} layout={OrganizerLayout} subject={PERMISSIONS_CONSTANTS.ORGANIZER_DASHBOARD.subject} />
                                                            <PrivateRoute exact path={RouteConstants.ORG_MEMBER_PROFILE} component={OrganizerProfile} layout={OrganizerLayout} subject={PERMISSIONS_CONSTANTS.ORGANIZER_PROFILE.subject} />
                                                            <PrivateRoute exact path={[RouteConstants.ORG_MEMBER_EVENT_CREATE, RouteConstants.ORG_MEMBER_EVENT_EDIT]} component={OrgCreateEditEvent} layout={OrganizerLayout} subject={PERMISSIONS_CONSTANTS.ORGANIZER_MEMBER_EVENTS.subject} />

                                                            {/****Admin Routes*****/}
                                                            {/* Layout:: Userroles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.ADMIN_YAO_EVENT_CALENDAR_HOME} component={EventCalendarHome} subject={PERMISSIONS_CONSTANTS.EVENTS_CALENDAR.subject} layout={AdminLayout} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.ADMIN_YAO_EVENT_CALENDAR} component={EventCalendar} subject={PERMISSIONS_CONSTANTS.EVENTS_CALENDAR.subject} layout={AdminLayout} />
                                                            {/* Layout:: Admin */}
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']}  */}
                                                            <PrivateRoute exact path={RouteConstants.ADMIN_PROSPECTS_HOME} component={ProspectsContainer} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.MANAGE_PROSPECT.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']}  */}
                                                            <PrivateRoute exact path={RouteConstants.EVALUATE_PROSPECT} component={EvaluationContainer} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.MANAGE_PROSPECT.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.DASHBOARD} component={DashboardContainer} subject={PERMISSIONS_CONSTANTS.SCOUTING_DASHBOARD.subject} layout={AdminLayout} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.EVENT_DASHBOARD} component={EventDashboardContainer} subject={PERMISSIONS_CONSTANTS.EVENT_DASHBOARD.subject} layout={AdminLayout} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.LESSON_PLANS_DASHBOARD} component={ManageLessonPlansDashboard} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.LESSON_PLAN_DASHBOARD.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.ADMIN_CANDIDATE_CAMPS} component={CandidateCamps} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.CANDIDATE_CAMP.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={[RouteConstants.ADMIN_CANDIDATE_CAMP_CREATE, RouteConstants.ADMIN_CANDIDATE_CAMP_EDIT]} component={CreateEditCandidateCamp} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.CANDIDATE_CAMP.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.ADMIN_CANDIDATE_CAMP_ADD_EDIT_PARTICIPANTS} component={CandidateCampParticipants} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.CANDIDATE_CAMP.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={[RouteConstants.ADMIN_EVENT_NOTIFICATION]} component={NotificationContainer} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.ADMIN_EVENT_NOTIFICATION.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={[RouteConstants.ADMIN_ADD_EDIT_NOTIFICATION]} component={AddEditNotification} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.ADMIN_EVENT_NOTIFICATION.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={[RouteConstants.ADMIN_CANDIDATE_CAMP_ASSESSMENT]} component={CampAssessmentContainer} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.CANDIDATE_CAMP.subject} />
                                                            {/* roles={['Admin', 'Scout', 'AdminScout']} */}
                                                            <PrivateRoute exact path={[RouteConstants.NO_PROSPECT]} component={NoProspectContainer} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.NO_PROSPECT.subject} />
                                                            {/* roles={['Admin', , 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.ADMIN_SEND_REMINDER_EMAIL} component={ReminderEmail} layout={AdminLayout} subject={PERMISSIONS_CONSTANTS.SEND_REMINDER.subject} />
                                                            {/* Layout:: Master/Settings */}
                                                            {/* roles={['Admin', , 'AdminScout']} */}
                                                            <PrivateRoute exact path={RouteConstants.ADMIN_INVITE_ORG_MEMBER} component={InviteOrgMember} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_COMMUNICATION_CATEGORY} component={CommunicationCategoryContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_EVALUATION_CATEGORY} component={EvaluationCategoryContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_COMPETENCY} component={CompetencyContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_TANGIBLE} component={TangibleContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_QUIZ} component={CmsManagerQuizContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_LESSON_PLANS} component={ManageLessonPlansContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={[RouteConstants.CREATE_LESSON_PLANS, RouteConstants.EDIT_LESSON_PLANS]} component={CreateEditLessonPlanContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.LESSON_PLANS_USER_ACCESS} component={LessonPlanUserAccess} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.LESSON_PLAN_QUIZ_USER_SCORE_LIST} component={QuizUsersAndScoreDetail} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.NBA_QUIZ_USER_ACCESS} component={QuizUserAccess} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_CMS_QUESTIONS} component={ManageCMSQuestionsContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={[RouteConstants.CREATE_CMS_QUESTION, RouteConstants.EDIT_CMS_QUESTION]} component={CreateEditCMSQuestionContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.ADMIN_MANAGE_POOL} component={PoolContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_EVALUATION_EVENTS} component={EvaluationEventContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_CLINICEVENT_CATEGORY} component={ClinicEventCategoryContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_CLINICIAN} component={EventClinicianContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_USER_ROLE} component={UserRoleContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />
                                                            <PrivateRoute exact path={RouteConstants.MANAGE_PERMISSIONS} component={RolePermissionContainer} layout={MasterLayout} subject={PERMISSIONS_CONSTANTS.MASTER_COMMUNICATION_CATEGORY.subject} />

                                                            {/* RolePermissionContainer */}
                                                            {/* Route for all other path(*) */}
                                                            <PublicLayout exact path={RouteConstants.NBAOFFICIAL_COMMON_PAGE} component={Page} />

                                                        </Switch>
                                                    </div>
                                                    {(props.snackMessages && props.snackMessages.length) ? <SnackNotifier /> : null}
                                                    {(props.promptData && props.promptData.isRouteBlocked) && <Prompt
                                                        when={true} // <-- blocking condition
                                                        message={props.promptData.message ?? "Do you want to leave this page? You have some unsaved data. Click CANCEL to continue or OK to discard unsaved data."} />}
                                                    {props.navigationMenu && props.navigationMenu.downloadAppDialog && <DownloadOurAppPopup />}
                                                </>
                                            }}

                                        </UserProfileConsumer>
                                    </UserProfileProvider>

                                </>
                                )
                            }}
                        </AppDataConsumer>
                    </AppDataProvider>
                </Router>
            </ErrorBoundary>
        </ThemeProvider>
    );
}
export default App;

//... Setting colors for the light and dark theme. 
function getTheme(theme) {
    return createTheme({
        palette: {
            type: theme.paletteType,
            primary: {
                main: (theme.paletteType === 'light') ? '#1d428a' : '#90caf9',
                //light: (theme.paletteType === 'light') ? '#8797bf' : '#a6d4fa',
                dark: (theme.paletteType === 'light') ? "#172b5b" : "#648dae"
            },
            secondary: {
                main: (theme.paletteType === 'light') ? '#c8102e' : '#f48fb1',
            },
        },

        mixins: {
            toolbar: {
                minHeight: 48
            }
        },
        overrides: {

            MuiTypography: {
                h1: {
                    fontFamily: `"Action NBA  Web" !important`
                },
                h2: {
                    fontFamily: `"Action NBA  Web" !important`
                },
                h3: {
                    fontFamily: `"Action NBA  Web" !important`
                },
                h4: {
                    fontFamily: `"Action NBA  Web" !important`
                },
                h5: {
                    fontFamily: `"Action NBA  Web" !important`,
                    fontWeight: 600
                },
                h6: {
                    // fontFamily: `"Action NBA  Web" !important`
                    fontWeight: 400
                },
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: ".75rem",
                    fontWeight: 'normal',
                }
            },
            MuiToolbar: {
                gutters: {
                    paddingLeft: 8,
                    paddingRight: 8,
                    ["@media (min-width:600px)"]: {
                        paddingLeft: 8,
                        paddingRight: 8,
                    },
                },
                regular: {
                    minHeight: 48,
                    ["@media (min-width:600px)"]: {
                        minHeight: 48
                    }
                }
            }
        }
    });
}