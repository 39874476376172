import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography, FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import AppDataContext from '../../common/AppContext';
import UserProfileContext from '../../common/ProfileContext';
import Validations from '../../common/Validations';
import { userService } from '../../services';
import Helper from '../../common/Helper';
import { eventCalendarService, referenceDataService } from '../../services';
import AppConstants from "../../common/AppConstants";
import Loader from "../../sharedComponents/Loader/Loader";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        background: theme.palette.warning.main,
        color: "#fff",
        padding: theme.spacing(1, 2)
    },
    form: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    }
}));

export default function EditProfileValidationDialog() {
    const classes = useStyles();
    const { user, setUser, addSnackMessages, countryList, clinicEvents, eventCategoryList } = useContext(AppDataContext);
    const { country } = useContext(UserProfileContext);
    const [open, setOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [formDataError, setFormDataError] = useState([]);
    const [events, setEvents] = useState([]);
    const [incompletedFields, setIncompletedFields] = useState([]);
    const [currentIncompleteField, setCurrentIncompleteField] = useState();
    const [isLoadingEvents, setIsLoadingEvents] = useState(false);
    const [categoryList, setcategoryList] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        if (user && user.incompletedFields && user.incompletedFields.length > 0) {
            setIncompletedFields(user.incompletedFields)
            let data = {}
            if (user.incompletedFields.includes('country')) {
                data['country'] = 0
            }
            if (user.incompletedFields.includes('aboutUsSource')) {
                data['aboutUsSourceID'] = null
                data['eventID'] = 0
                data['aboutUsDescription'] = ''


            }
            setFormData({ ...data })
        }
    }, []);





    useEffect(() => {
        if (incompletedFields.length > 0) {
            let data = [...incompletedFields]
            let current = data.pop()
            setCurrentIncompleteField(current)
        } else {
            //  setUser({ ...user, isProfileComplete: true });
        }
    }, [incompletedFields]);


    useEffect(() => {
        if (Object.keys(formDataError).length) isFormDataValid();
    }, [formData]);



    const updateIncompletedFields = () => {
        let data = [...incompletedFields]
        data.pop()
        if (data.length === 0) {
            Helper.updateLocalStorageData('currentUser', { isProfileComplete: true });
            setUser({ ...user, isProfileComplete: true });
        } else {
            setIncompletedFields(data)
        }

    }

    const generateEventMenu = (option) => {
        return `${option.eventName ? option.eventName + " " : ""} ${Helper.FormatDate(option.eventStart) + ' - ' + Helper.FormatDate(option.eventEnd) + " "} ${option.eventMode && option.eventMode.toLowerCase() === 'virtual' ? 'Online' : 'at ' + option.eventCity}`
    }



    const isFormDataValid = () => {
        let errors = [];
        Object.entries(formData).forEach(([key, value]) => {
            let field = false;
            switch (key) {
                case "country":
                    if (currentIncompleteField === AppConstants.IncompletedFields.Country) {
                        field = Validations.Validate("Country", value, { Min: 1 });
                        if (field && !field.isValid) {
                            field.validationMessage = "Country is required."
                        }
                    }
                    break;
                case "aboutUsSourceID":
                    field = Validations.Validate("Field", value, { Required: true });
                    break;
                case "eventID":
                    if (selectedCategory.aboutUsDescriptionInputType === 'eventDropdown' && selectedCategory.isAboutUsDescriptionMandatory)
                        field = Validations.Validate("Event", value, { Min: 1 });
                    if (field && !field.isValid) {
                        field.validationMessage = "Event is required."
                    }
                    break;
                case "aboutUsDescription":
                    if (selectedCategory.aboutUsDescriptionInputType === 'freeTextbox' && selectedCategory.isAboutUsDescriptionMandatory) {
                        field = Validations.Validate("Detail", value, { Required: true });
                    }
                    break;
            }
            if (field && !field.isValid) {
                errors[key] = field.validationMessage[0].toUpperCase() + field.validationMessage.slice(1);
            }
        });

        if (Object.keys(errors).length) {
            setFormDataError(errors);
            return false;
        }
        setFormDataError([]);
        return true;
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log(formData)
        setIsLoading(true);
        if (!isFormDataValid()) {
            setIsLoading(false);
            return false;
        }

        //api service for updating country         
        if (currentIncompleteField === AppConstants.IncompletedFields.Country) {
            userService.saveMandatoryContactInfo(formData).then(res => {
                //  Helper.updateLocalStorageData('currentUser', { isProfileComplete: true });
                updateIncompletedFields()
                addSnackMessages([{
                    open: true,
                    message: res.message,
                    severity: 'success'
                }], true);
                setIsLoading(false);
            }).catch(e => {
                addSnackMessages([{
                    open: true,
                    message: e.message,
                    severity: 'error'
                }], true);
                setIsLoading(false);
            })
        } else if (currentIncompleteField === AppConstants.IncompletedFields.AboutUsSource) {
            userService.saveAboutUsInfo(formData).then(res => {
                updateIncompletedFields()
                addSnackMessages([{
                    open: true,
                    message: res.message,
                    severity: 'success'
                }], true);
                setIsLoading(false);
            }).catch(e => {
                addSnackMessages([{
                    open: true,
                    message: e.message,
                    severity: 'error'
                }], true);
                setIsLoading(false);
            })

        }

    }

    return <Dialog PaperProps={{
        component: "form",
        noValidate: true,
        onSubmit: (e) => handleFormSubmit(e)
    }} open={open} disableEscapeKeyDown >
        <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant='h4'>Attention Required!</Typography>
        </DialogTitle>
        <DialogContent dividers>
            <Typography gutterBottom>
                Welcome back, we found that following fields are missing in your profile.
            </Typography>
            <Typography gutterBottom>
                Please update these to continue.
            </Typography>


            {currentIncompleteField === AppConstants.IncompletedFields.Country &&
                <Grid container spacing={2} className={classes.form}>
                    <Grid item xs={12}>
                        <Autocomplete
                            id={`country`}
                            size="small"
                            disableClearable
                            value={countryList.find(x => x.numericValue === formData.eventCountry)}
                            options={countryList}
                            getOptionLabel={(option) => option.displayName ? option.displayName : ""}
                            onChange={(event, newValue) => {
                                let val = newValue !== null ? newValue.numericValue : 0;
                                setFormData({ ...formData, country: val });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label={`Country`}
                                    placeholder="Please select country"
                                    error={formDataError.country && true || false}
                                    helperText={formDataError.country}
                                    required
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            }
            {currentIncompleteField === AppConstants.IncompletedFields.AboutUsSource &&
                <Grid container spacing={2} className={classes.form}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined"
                            className={classes.formControl}
                            fullWidth
                            size="small"
                            required
                            error={formDataError.aboutUsSource && true || false}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">How Did You Hear About Us?</InputLabel>
                            <Select id="aboutUsSource"
                                name="aboutUsSource"
                                required
                                value={formData.aboutUsSourceID}
                                onChange={(e) => {
                                    setFormData({ ...formData, aboutUsSourceID: e.target.value, eventID: 0, aboutUsDescription: '' })
                                    setFormDataError([])
                                    setFilteredEvents(clinicEvents.filter(x => x.eventCategoryID === e.target.value))
                                    let cat = eventCategoryList.find(x => x.id === e.target.value)
                                    setSelectedCategory(cat)
                                    setFormDataError([])
                                }}
                                fullWidth
                                label="How Did You Hear About Us?"
                            >
                                <MenuItem value=""></MenuItem >
                                {eventCategoryList.filter(item => item.isActive && item.showOnAboutUs).map((option, index) => (
                                    <MenuItem disabled={clinicEvents.filter(x => x.eventCategoryID === option.id).length === 0 && option.aboutUsDescriptionInputType === 'eventDropdown'} key={index} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formDataError.aboutUsSourceID && <FormHelperText>{formDataError.aboutUsSourceID}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {selectedCategory && selectedCategory.aboutUsDescriptionInputType && selectedCategory.aboutUsDescriptionInputType === 'eventDropdown' &&
                            <Autocomplete
                                id={`event`}
                                size="small"
                                disableClearable
                                value={clinicEvents.find(x => x.eventID === formData.eventID)}
                                options={filteredEvents}
                                getOptionLabel={(option) => generateEventMenu(option)}
                                onChange={(event, newValue) => {
                                    let val = newValue !== null ? newValue.eventID : 0;
                                    setFormData({ ...formData, eventID: val });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        label={`Event`}
                                        placeholder="Please select Event"
                                        error={formDataError.eventID && true || false}
                                        helperText={formDataError.eventID}
                                    />
                                )}
                            />}
                        {selectedCategory && selectedCategory.aboutUsDescriptionInputType && selectedCategory.aboutUsDescriptionInputType === 'freeTextbox' &&
                            <TextField
                                variant="outlined"
                                id="aboutusDescription"
                                name="aboutusDescription"
                                size="small"
                                label="Details"
                                fullWidth
                                value={formData.aboutUsDescription}
                                onChange={(e) => setFormData({ ...formData, aboutUsDescription: e.target.value })}
                                error={formDataError.aboutUsDescription && true || false}
                                helperText={formDataError.aboutUsDescription}

                            ></TextField>
                        }
                    </Grid>

                </Grid>

            }
            {/* {formData.aboutUsSource === 'Other' &&
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        id="aboutusDescription"
                        name="aboutusDescription"
                        size="small"
                        label="Details"
                        fullWidth
                        required
                        value={formData.aboutUsDescription}
                        onChange={(e) => setFormData({ ...formData, aboutUsDescription: e.target.value })}
                        error={formDataError.aboutUsDescription && true || false}
                        helperText={formDataError.aboutUsDescription}

                    ></TextField>
                </Grid>
            } */}
        </DialogContent>
        <DialogActions>
            <Button
                color="primary"
                variant="contained"
                type="submit"
                className="capitalize"
                disabled={(isLoading || Object.keys(formDataError).length > 0)}
                startIcon={isLoading && <CircularProgress color="secondary" size={24} />}
            >
                Save & Next
            </Button>
        </DialogActions>
        <Loader isLoading={isLoadingEvents} />
    </Dialog >;
}
