import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import PageTitle from '../../../sharedComponents/PageTitle/PageTitle';
import MediaTextBlock from '../sections/MediaTextBlock';
import QuoteSection from '../sections/QuoteSection';
import UserCardsSection from '../sections/UserCardsSection';
import FeatureBoxSection from '../sections/FeatureBoxSection';
import VideoOrMediaSection from '../sections/VideoOrMediaSection';
import HeadLine from '../../../sharedComponents/umbraco/controls/headline';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        '& .pageTitle': {
            fontFamily: `'Roboto' !important`,
            fontSize: 22,
            fontWeight: 600,
            color: '#C8102E'
        },
        '&.Dark.Blue': {
            background: '#112751',
            color: '#fff',
            '& .pageTitle': {
                color: '#FFF'
            }
        },
        '&.Light.Grey': {
            background: '#e6e6e6',
            '& .pageTitle': {
                color: '#1d428a'
            }
        }
    },
    bgDark: {
        background: '#112751'
    },
    bgLight: {
        background: '#6B8DCE'
    },
    quoteSection: {
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(7),
    }
}));

const DefaultTemplate = ({ content }) => {
    const classes = useStyles();

    const renderSections = (items, key) => {
        return items.map((item, index) => {
            switch (item.contentTypeAlias) {
                case "mediaContentBlock":
                    return <Container maxWidth="lg" component="section" className={`${classes.content} mediaContentBlock`} key={index}>
                        <MediaTextBlock
                            data={item}
                        />
                    </Container>
                    break;
                case "blockQuote":
                    return <Container maxWidth={false} component="section" className={`${classes.bgDark} ${classes.quoteSection} blockQuote`} key={index}>
                        <QuoteSection
                            quote={item.pageContent}
                        />
                    </Container>
                    break;
                case "featureBoxItemGroup":
                    return <section className={`${classes.content} ${item.pageTheme} featureBoxItemGroup`} key={index}>
                        <Container maxWidth="lg">
                            {item.pageTitle && <HeadLine variant="h4" data={item.pageTitle} className={`pageTitle`} />}
                            {item.pageDescription && <div dangerouslySetInnerHTML={{ __html: item.pageDescription }}></div>}
                            <Grid container spacing={3} className={classes.featureBox} justifyContent="center">
                                <FeatureBoxSection
                                    items={item.pageContent}
                                    gridItemSize={item.pageGridItems ? (12 / parseInt(item.pageGridItems)) : 4}
                                />
                            </Grid>
                        </Container>
                    </section>
                    break;
                case "userCardItemGroup":
                    return <Container maxWidth={false} component="section" className={`${classes.content} ${classes.bgDark} userCardItemGroup`} key={index}>
                        <Grid container spacing={4} className={classes.userCards} justifyContent="center">
                            <UserCardsSection
                                items={item.pageContent}
                            />
                        </Grid>
                    </Container>
                    break;
                case "mediaCardGroup":
                    return <Container maxWidth={false} component="section" className={`${classes.content} mediaCardGroup`} key={index}>
                        <Grid container spacing={4} className={classes.videoMediaCards} justifyContent="center">
                            <VideoOrMediaSection items={item.pageContent} />
                        </Grid>
                    </Container>
                    break;
                case "descriptionBlock":
                    return <section className={`${classes.content} ${item.pageTheme} descriptionBlock`} key={index}>
                        <Container maxWidth="lg">
                            {item.pageTitle && <HeadLine variant="h4" data={item.pageTitle} className={`pageTitle`} />}
                            {item.pageDescription && <div dangerouslySetInnerHTML={{ __html: item.pageDescription }}></div>}
                            <div dangerouslySetInnerHTML={{ __html: item.pageContent }}></div>
                        </Container>
                    </section>
                    break;
            }

        });
    }

    return <>
        {content && content?.showPageTitle && <PageTitle title={content?.pageTitle} subtitle={content?.subTitle} />}
        {content?.description && <Container maxWidth="lg" component="section" className={classes.content}>
            <div dangerouslySetInnerHTML={{ __html: content?.description }}></div>
        </Container>}
        {content?.pageSections?.length ? renderSections(content?.pageSections) : null}
    </>
}

export default DefaultTemplate;