import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    UserProfileContext
} from "../common/ProfileContext";
import AppBar from '../sharedComponents/appBar/AppHeaderBar';
import UserActionMenu from '../sharedComponents/appBar/UserActionMenu';
import NotificationMenu from '../sharedComponents/Notifications/NotificationMenu';
import Navbar from '../sharedComponents/multiLevelTopNav/NavBar';
import NavDrawer from '../sharedComponents/multiLevelTopNav/NavDrawer';
import AppDataContext from '../common/AppContext';
import GlobalMessageSection from '../sharedComponents/appBar/GlobalMessageSection';
import EditProfileValidationDialog from '../sharedComponents/dialog/EditProfileValidationDialog';
import SectionLoader from '../sharedComponents/Loader/SectionLoader';
import RouteConstants from '../common/RouteConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        zIndex: 0,
        marginTop: theme.mixins.toolbar.minHeight
    },
    grid: {
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap",
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: 0,
            flexGrow: 0,
        },
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "left",
        padding: "5px 25px",
    },
    gridItem: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            paddingBottom: theme.spacing(2),
        },
    },
    messageCenter: {
        paddingBottom: theme.spacing(2),
        minHeight: "300px",
        boxShadow: '0px 0px 5px rgba(6, 37, 91, .2)',
        borderRadius: '0',
        backgroundColor: '#06255b',
    },
}));

const MessageCenterLayout = ({ children, ...props }) => {
    let isViewMode = false;
    const { isProcessing } = useContext(UserProfileContext)
    const { navigationMenu, user } = useContext(AppDataContext);
    useEffect(() => {
        if (user && !user.isEmailVerified) {
            props.history.push(RouteConstants.EMAIL_VERIFY)
        }
    }, [user])
    const getIsViewMode = () => {
        if (children.props.location.pathname == "/view") {
            isViewMode = true;
        }
    }

    const classes = useStyles();
    return (
        <>
            <AppBar
                mainNav={<Navbar />}
                mobileNav={<NavDrawer />}
                notificationMenu={<NotificationMenu />}
                userActionMenu={<UserActionMenu />}
            />
            {/* <UserProfileProvider>
                <UserProfileConsumer>
                    {(props) => {
                        return ( */}
            {
                isProcessing ? <SectionLoader /> :
                    (user && !user.isProfileComplete) ? <EditProfileValidationDialog /> :
                        <div className={classes.root}>
                            {(navigationMenu && navigationMenu.globalMessage) ? <GlobalMessageSection message={navigationMenu.globalMessage} /> : null}
                            {children}
                        </div>
            }
            {/* )
                    }}
                </UserProfileConsumer>
            </UserProfileProvider> */}
        </>
    );
}


export default withRouter(MessageCenterLayout);