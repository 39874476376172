const AzureConstant = {
    LOCAL_APP: 'https://localhost:44321/',
    DEV_APP: 'app-nbaofficials-dev-001.azurewebsites.net',
    QA_APP: 'app-nbaofficials-qa-001.azurewebsites.net',
    PROD_APP: 'nbaofficials.com',
    TENANT_ID: 'e898ff4a-4b69-45ee-a3ae-1cd6f239feb2',
    DEV_CLIENT: '60c199f9-602e-4b0e-8248-fd0c0defc5ee',
    QA_CLIENT: 'a9d33c14-360e-442a-979c-a2e65f47dddf',
    PROD_CLIENT: '8e805acb-8a18-462d-b15c-d9162b9e7e21',

};

export default AzureConstant;