import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles, Grid, Container } from '@material-ui/core';
import RouteConstants from "../../common/RouteConstants";
import AssessmentIcon from '@material-ui/icons/Assessment';
import Helper from '../../common/Helper';
import { LessonPlanDataService } from '../../services/lessonPlans.service';
import SectionLoader from '../../sharedComponents/Loader/SectionLoader';
import PageNotFoundUI from '../../sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import PageTitle from '../../sharedComponents/PageTitle/PageTitle';
import LessonPlanCard from './LessonPlanCard';
//import { Role } from '../../helper';
import AppDataContext from '../../common/AppContext';
import { useHistory, useLocation } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    appbarBackground: {
        backgroundColor: "#fff",
        color: "red"
    },
    title: {
        flexGrow: 1,
    },
    container: {
        flexGrow: 1,
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '45px'
        }
    },
    cursorPointer: {
        cursor: 'pointer',
        float: 'right'
    },
    listContainer: {
        display: 'flex',
        flexGrow: 1
    },
    categoryInput: {
        paddingTop: '10px'
    },
    content: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    paginationContainer: {
        display: 'flex',
        marginTop: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'end',
        flex: 1
    }
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LessonPlans = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const page = useQuery().get("page");
    const { checkRole, roles } = useContext(AppDataContext);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        totalRecords: 2
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getLessonPlans(parseInt(page));
    }, [page]);

    const changePage = (page) => {
        history.push({
            pathname: RouteConstants.LESSON_PLANS,
            search: `?page=${page}`
        });
        return;
    }

    function getLessonPlans(page) {
        setIsLoading(true);
        LessonPlanDataService.getMyLessons(page, 6).then(result => {
            setData(result.data.data ??[]);
            setPagination({
                currentPage: result.data.currentPage,
                lastPage: result.data.lastPage,
                totalRecords: result.data.totalRecords
            });
            setIsLoading(false);
        })
            .catch(error => {
                setIsLoading(false);
            });
    }

    return (
        <>
            <Helmet>
                <title>{`${(Helper.isUserLoggedIn() && checkRole(roles.user)) ? 'My ' : ''}Lesson Plans`}</title>
                <meta name="description" content="Lesson Plans list" />
                <link rel="canonical" href={encodeURI(`${new URL(window.location.origin)}${RouteConstants.LESSON_PLANS}`)} />
            </Helmet>

            <PageTitle title={`${(Helper.isUserLoggedIn() && checkRole(roles.user)) ? 'My ' : ''}Lesson Plans`} />

            {isLoading ? <SectionLoader />
                :
                (data === null || data.length === 0 ? <PageNotFoundUI
                    showPageIcon
                    pageIcon={<AssessmentIcon />}
                    title="Lesson Plans not available."
                    subtitle="We couldn't found lesson plans for you right now. Please come back later."
                /> :
                    <Container maxWidth="lg" component="section" className={classes.content}>
                        <Grid container spacing={2}>
                            {data.map((value, index) =>
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <LessonPlanCard item={value} />
                                </Grid>
                            )}

                        </Grid>
                        {(data && data.length && pagination.lastPage > 1) ? <div className={classes.paginationContainer}>
                            <Pagination
                                page={pagination.currentPage}
                                count={pagination.lastPage}
                                onChange={(e, page) => changePage(page)}
                                color="primary"
                                size='large'
                                showFirstButton
                                showLastButton />
                        </div> : null}

                    </Container>
                )
            }

        </>
    );
}

export default LessonPlans;
