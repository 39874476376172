import { Box, Container, Hidden, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import RouteConstants, { DefaultRoutes } from "../../common/RouteConstants";
import { authenticationService, userService } from "../../services";
import nbaOfficialsLogo from '../../images/nba-officials.png';
import clsx from "clsx";
import { AppDataContext } from '../../common/AppContext'
import { withRouter } from "react-router-dom";
import './authentication.css';
//import { Role } from "../../helper";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    logoStrip: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'start',
        width: '100%',
        height: '150px',
        '& .appTitle': {
            marginTop: '0 !important',
            marginBottom: '0 !important'
        },
        [theme.breakpoints.down("md")]: {
            '& .appTitle': {
                fontSize: '4rem'
            }
        },
        [theme.breakpoints.down("sm")]: {
            '&.login-strip-bg': {
                background: 'none'
            }
        },
    },
    nbaLogo: {
        width: 'auto',
        height: '90px',
        marginRight: theme.spacing(2)
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        }
    },
    childrenContainer: {
        width: '100%',
        maxWidth: 360,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1.5),
        height: 'auto',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 70px)',
        display: 'flex',
        alignItems: 'flex-start',
        minHeight: 280
    },
}));

const AuthLayoutContainer = ({ children, ...props }) => {
    const classes = useStyles();
    const { roles } = useContext(AppDataContext);
    const redirectTo = new URLSearchParams(props.location.search).get("redirectTo");

    function returnToRedirect(user) {
        if ((props.location.state && props.location.state.redirectTo) || redirectTo) {
            const redirectPath = (props.location.state) ? props.location.state.redirectTo : redirectTo;
            props.history.push({
                pathname: redirectPath
            });
        }
        else if (userService.getUserRole() === roles.admin || userService.getUserRole() === roles.adminscout) {
            props.history.push({
                pathname: DefaultRoutes.ADMIN
            })
        }
        else if (userService.getUserRole() === roles.partner) {
            props.history.push({
                pathname: DefaultRoutes.PARTNER
            })
        }
        else if (userService.getUserRole() === roles.participant) {
            props.history.push({
                pathname: DefaultRoutes.PARTICIPANT
            })
        }
        else if (userService.getUserRole() === roles.user) {
            props.history.push({
                pathname: DefaultRoutes.PROSPECT
            });
        }
        else {
            props.history.push({
                pathname: '/'
            });
        }
        return;
    }

    useEffect(() => {
        if (authenticationService.currentUserValue && authenticationService.currentUserValue.isAuthenticated) {
            if (!authenticationService.currentUserValue.isEmailVerified) {
                props.history.push({
                    pathname: RouteConstants.EMAIL_VERIFY
                });
            }
            else if (authenticationService.currentUserValue.lastLoginDate) {
                returnToRedirect(authenticationService.currentUserValue);
            }
        }
    }, []);

    return <div className={clsx("login-bg", classes.root)}>
        <div className={clsx("login-strip-bg", classes.logoStrip)}>
            <Container className={classes.container}>
                <Hidden smDown>
                    <Box display={'flex'} alignItems="center" flex={1}>
                        <img src={nbaOfficialsLogo} className={classes.nbaLogo} alt="nba officials logo" />
                        <p className={clsx("appTitle")}>Officiating Opportunities</p>
                    </Box>
                </Hidden>
                <Paper component="section" className={classes.childrenContainer} elevation={10}>
                    {children}
                </Paper>
            </Container>
        </div>
    </div>
}

export default withRouter(AuthLayoutContainer);