import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import Validations from '../../common/Validations';
import { authenticationService, eventCalendarService } from '../../services';
import AppDataContext from '../../common/AppContext';
import { Alert, Autocomplete } from '@material-ui/lab';
import RouteConstants from '../../common/RouteConstants';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        background: theme.palette.primary.main,
        color: "#fff"
    },
    dialogClose: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#fff'
    },
    form: {
        background: '#fff'
    }
}));

export default function EventRegistrationDialog(props) {
    const classes = useStyles();
    const { open, onClose, eventID } = props;
    const { setUser, stateList, countryList, addSnackMessages, experienceLevelList } = useContext(AppDataContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailExists, setIsEmailExists] = useState(null);
    const [validatingEmail, setValidatingEmail] = useState(false); //loader

    const [formData, setFormData] = useState({
        "eventID": eventID,
        "firstName": "",
        "lastName": "",
        "address1": "",
        "address2": "",
        "country": 1,
        "state": 0,
        "stateOther": "",
        "city": "",
        "zip": "",
        "phoneHome": "",
        "email": "",
        "experience_Level": undefined,
        "isActive": true
    });
    const [formDataError, setFormDataError] = useState([]);

    useEffect(() => {
        setFormData({ ...formData, state: 0, city: "", zip: "", stateOther: "" });
    }, [formData.country])
    useEffect(() => {
        if (Object.keys(formDataError).length) isFormDataValid();
    }, [formData]);


    const handleClose = (e, reason) => {
        if (reason === 'backdropClick' || reason == 'escapeKeyDown') { return false; }
        props.onClose(); return;
    };

    const handleReset = () => {
        setFormData({
            "eventID": eventID,
            "firstName": "",
            "lastName": "",
            "address1": "",
            "address2": "",
            "country": 1,
            "state": 0,
            "stateOther": "",
            "city": "",
            "zip": "",
            "phoneHome": "",
            "email": "",
            "experience_Level": undefined,
            "isActive": true
        });
        setIsEmailExists(null);
    }

    const isFormDataValid = () => {
        let errors = [];
        Object.entries(formData).forEach(([key, value]) => {
            let field = false;
            switch (key) {
                case "firstName":
                    field = Validations.Validate("First Name", value.trim(), { Required: true, AllowOnlyCharacters : true });
                    break;
                case "lastName":
                    field = Validations.Validate("Last Name", value.trim(), { Required: true, AllowOnlyCharacters : true });
                    break;
                case "email":
                    field = Validations.Validate("Email", value.trim(), { Required: true, Email: true });
                    break;
                case "phoneHome":
                    field = Validations.Validate("Phone", value, { Required: true, Numeric: true });
                    break;
                case "address1":
                    field = Validations.Validate("Address 1", value.trim(), { Required: true });
                    break;
                case "country":
                    field = Validations.Validate("Country", value, { Required: true, Numeric: true });
                    break;
                case "state":
                    if (formData.country === 1) {
                        field = Validations.Validate("State", value, { Required: true, Numeric: true });
                        if (field.isValid && value === 0) {
                            field = {
                                isValid: false,
                                validationMessage: "State is required"
                            }
                        }
                    }
                    break;
                case "stateOther":
                    if (formData.country !== 1) {
                        field = Validations.Validate("State", value.trim(), { Required: true, AllowOnlyCharacters: true });
                    }
                    break;
                case "city":
                    field = Validations.Validate("City", value.trim(), { Required: true, AllowOnlyCharacters: true });
                    break;
                case "zip":
                    field = Validations.Validate("Zip", value.trim(), { Required: true });
                    break;
            }

            if (field && !field.isValid) {
                errors[key] = field.validationMessage;
            }
        });
        if (Object.keys(errors).length) {
            setFormDataError(errors);
            return false;
        }
        setFormDataError([]);
        return true;
    }

    const doCheckEmail = () => {
        let field = Validations.Validate("Email", formData.email.trim(), { Required: true, Email: true });
        if (field && !field.isValid) {
            setFormDataError({
                email: field.validationMessage
            });
            return false;
        }
        setFormDataError([]);
        setValidatingEmail(true);
        setIsEmailExists(null);
        authenticationService.checkEmailExists(formData.email).then((res) => {
            // debugger;
            if (res.data) {
                setIsEmailExists(true);
            } else {
                setIsEmailExists(false);
            }
            console.log(res);
            setValidatingEmail(false);
        })
    }

    const handleFormSubmit = (e, type) => {
        if (e) e.preventDefault();
        if (type === 'emailCheck') {
            doCheckEmail();
            return;
        }
        if (type === 'doRegister') {
            doRegistration();
            return;
        }
    }

    const doRegistration = () => {
        setIsLoading(true);
        if (!isFormDataValid()) {
            setIsLoading(false);
            return false;
        }
        eventCalendarService.saveEventUser(formData).then((res) => {
            setIsLoading(false);
            props.onClose("success");
        }).catch((e) => {
            setIsLoading(false);
            if (e.email) {
                setFormDataError({ ...formDataError, email: e.email[0] });
                document.getElementById('event-registration-dialog-description').scrollIntoView();
                return false;
            }
            props.onClose("error");
        });
        return;
    }
    const handleLogin = () => {
        setIsLoading(true);
        authenticationService.login(formData.email, formData.password)
            .then(res => {
                if (res.isAuthenticated) {
                    let user = { ...res };
                    setUser(user);
                    addSnackMessages([{
                        open: true,
                        message: 'Login Successfully.',
                        severity: 'success'
                    }]);
                } else {
                    addSnackMessages([{
                        open: true,
                        message: 'Incorrect credentials. Please try again.',
                        severity: 'error'
                    }]);
                }
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                if (err == "inactive") {
                    addSnackMessages([{
                        open: true,
                        message: 'Your account is inactive.',
                        severity: 'error'
                    }]);
                }
                else {
                    addSnackMessages([{
                        open: true,
                        message: 'Incorrect credentials. Please try again.',
                        severity: 'error'
                    }]);
                }
            })
    }
    return <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        scroll={'paper'}
        PaperComponent={'form'}
        PaperProps={{
            noValidate: true,
            className: classes.form,
            onSubmit: (e) => handleFormSubmit(e, (isEmailExists !== null) ? "doRegister" : "emailCheck")
        }}
        aria-labelledby="event-registration-dialog-title"
        aria-describedby="event-registration-dialog-description"
    >
        {/* <form noValidate onSubmit={(e) => handleFormSubmit(e, (isEmailExists !== null) ? "doRegister" : "emailCheck")}> */}
        <DialogTitle disableTypography id="event-registration-dialog-title" className={classes.dialogTitle} onClose={handleClose}>
            <Typography variant='h6'>Event Registration</Typography>
            <IconButton className={classes.dialogClose} onClick={handleClose}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
            {isEmailExists && <Alert severity='error'>
                Your email already registered with us. <Link to={RouteConstants.OFFICIAL_LOGIN}>Click here</Link> to login.
            </Alert>}
            <TextField
                autoFocus
                disabled={(isEmailExists !== null)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                error={formDataError.email ? true : false}
                helperText={formDataError.email}
                InputProps={{
                    readOnly: (isEmailExists !== null),
                    endAdornment: (validatingEmail) && <InputAdornment position="end"><CircularProgress size={22} /></InputAdornment>,
                }}
            />
            {/* {(isEmailExists) ? <TextField
                            autoFocus
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            id="password"
                            label="Password"
                            name="password"
                            autoComplete="password"
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                            error={formDataError.password}
                            helperText={formDataError.password && formDataError.password}
                        /> : null} */}
            {(!isEmailExists && isEmailExists !== null) ? <>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            autoFocus
                            name="firstName"
                            label="First Name"
                            id="first_name"
                            value={formData.firstName}
                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                            error={formDataError.firstName ? true : false}
                            helperText={formDataError.firstName}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="lastName"
                            label="Last Name"
                            id="last_name"
                            value={formData.lastName}
                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                            error={formDataError.lastName ? true : false}
                            helperText={formDataError.lastName}
                        />
                    </Grid>
                </Grid>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="phoneHome"
                    label="Phone"
                    name="phoneHome"
                    autoComplete="phoneHome"
                    value={formData.phoneHome}
                    onChange={(e) => setFormData({ ...formData, phoneHome: e.target.value })}
                    error={formDataError.phoneHome ? true : false}
                    helperText={formDataError.phoneHome}
                />
                <FormControl margin="normal" fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel id="level-of-exp-select-outlined-label">Level of Experience</InputLabel>
                    <Select
                        labelId="level-of-exp-select-outlined-label"
                        id="level_of_exp"
                        value={formData.experience_Level}
                        onChange={(e) => setFormData({ ...formData, experience_Level: e.target.value })}
                        label="Level of Experience"
                    >
                        {experienceLevelList.map((item, index) =>
                            <MenuItem key={index} value={item.numericValue}>{item.displayName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="address1"
                    label="Address 1"
                    name="address1"
                    autoComplete="address1"
                    value={formData.address1}
                    onChange={(e) => setFormData({ ...formData, address1: e.target.value })}
                    error={formDataError.address1 ? true : false}
                    helperText={formDataError.address1}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    // required
                    fullWidth
                    id="address2"
                    label="Address 2"
                    name="address2"
                    autoComplete="address2"
                    value={formData.address2}
                    onChange={(e) => setFormData({ ...formData, address2: e.target.value })}
                    error={formDataError.address2 ? true : false}
                    helperText={formDataError.address2}
                />

                {/* START... country, state, stateOther, city, zip */}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Autocomplete
                            id={`country`}
                            disableClearable
                            value={countryList.find(x => x.numericValue === formData.country)}
                            defaultValue={countryList.find(x => x.numericValue === formData.country)}
                            options={countryList}
                            getOptionLabel={(option) => option.displayName ? option.displayName : ""}
                            onChange={(event, newValue) => {
                                let val = newValue !== null ? newValue.numericValue : 0;
                                setFormData({ ...formData, country: val });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    margin="normal"
                                    fullWidth
                                    variant="outlined"
                                    label={`Country`}
                                    error={formDataError.country ? true : false}
                                    helperText={formDataError.country}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        {formData.country === 1 ?
                            <Autocomplete
                                id={`state`}
                                disableClearable
                                options={stateList}
                                getOptionLabel={(option) => option.displayName ? option.displayName : ""}
                                onChange={(event, newValue) => {
                                    let val = newValue !== null ? newValue.id : 0;
                                    setFormData({ ...formData, state: val });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label={`State`}
                                        error={formDataError.state ? true : false}
                                        helperText={formDataError.state}
                                    />
                                )}
                            />
                            :
                            <TextField variant="outlined"
                                value={formData.stateOther}
                                label={`State`}
                                required
                                name="other_state"
                                id="other_state"
                                margin="normal"
                                fullWidth
                                error={formDataError.stateOther ? true : false}
                                helperText={formDataError.stateOther}
                                onChange={(e) => setFormData({ ...formData, stateOther: e.target.value })}
                                inputProps={{ maxLength: 180 }}
                            />
                        }
                    </Grid>
                </Grid>

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                    autoComplete="city"
                    value={formData.city}
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    error={formDataError.city ? true : false}
                    helperText={formDataError.city}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="zip"
                    label="Zip"
                    name="zip"
                    autoComplete="zip"
                    value={formData.zip}
                    onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
                    error={formDataError.zip ? true : false}
                    helperText={formDataError.zip}
                />
                {/* END... country, state, stateOther, city, zip */}

                <FormControlLabel
                    control={<Checkbox checked={formData.isActive} value={`isActive`} onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })} color="primary" />}
                    label="I want to create Profile at NBA Officials website as well!"
                />
            </>
                : null}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="secondary">
                Cancel
            </Button>
            {(isEmailExists !== null) && <Button onClick={handleReset}>
                Reset
            </Button>}
            {isLoading ? <CircularProgress size={28} /> :
                <>
                    {/* {(isEmailExists) && <Button type='submit' disabled={(Object.keys(formDataError).length > 0)} onClick={handleLogin} color="primary">
                                Login
                            </Button>} */}
                    {(!isEmailExists && isEmailExists === null) && <Button variant='contained' type='submit' color='primary'>Next</Button>}
                    {(!isEmailExists && isEmailExists !== null) && <Button variant='contained' type='submit' disabled={(Object.keys(formDataError).length > 0)} color="primary">
                        Register
                    </Button>}
                </>
            }
        </DialogActions>
        {/* </form> */}
    </Dialog >;
}
