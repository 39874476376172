import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, Typography, TextField, FormControl, InputLabel, MenuItem, Select, Button, Grid, FormHelperText, LinearProgress, Link, CircularProgress } from "@material-ui/core";
import zxcvbn from "zxcvbn";
import RouteConstants from "../../../common/RouteConstants";
import ErrorPopover from "../../../sharedComponents/dialog/ErrorPopover";
import { authenticationService, userService } from "../../../services";
import Validations from "../../../common/Validations";
import AppDataContext from "../../../common/AppContext";


const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        height: '100%'
    }
}));

const ForgotPasswordComponent = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const { addSnackMessages } = useContext(AppDataContext);

    const [formData, setFormData] = useState({ userName: "" });
    const [formDataError, setFormDataError] = useState([]);

    useEffect(() => {
        if (Object.keys(formDataError).length) isFormDataValid();
    }, [formData]);

    const isFormDataValid = () => {
        setFormDataError([]);
        let field = {};
        if (formData.userName.includes('@')) {
            field = Validations.Validate("Email", formData.userName.trim(), { Required: true, Email: true });
        }
        else {
            field = Validations.Validate("Username / Email", formData.userName.trim(), { Required: true });
        }

        if (field && !field.isValid) {
            setFormDataError({ userName: field.validationMessage[0].toUpperCase() + field.validationMessage.slice(1) });
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!isFormDataValid()) {
            setIsLoading(false);
            return false;
        }

        authenticationService.forgotPassword({
            username: formData.userName
        })
            .then((res) => {
                setIsLoading(false);
                if (res.data) {
                    addSnackMessages([{
                        open: true,
                        message: "We'll email you password reset instructions on email. If you don't receive the email, please try again.",
                        severity: 'success'
                    }]);
                    props.history.push({
                        pathname: RouteConstants.OFFICIAL_LOGIN
                        // state: {
                        //     formData: res.data
                        // }
                    })
                }
            },
                e => {
                    setIsLoading(false);
                    setFormDataError(e.errors);
                    addSnackMessages([{
                        open: true,
                        message: e.message,
                        severity: 'error'
                    }]);
                }
            );
    }

    return (
        <>
            <Grid container spacing={2} component="form" className={classes.root} noValidate onSubmit={(e) => handleSubmit(e)}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" color="secondary">
                        Forgot Password
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        id="username-or-email"
                        size="small"
                        label="Username Or Email"
                        value={formData.userName}
                        name="username"
                        onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
                        fullWidth
                        error={formDataError.userName && true || false}
                        helperText={formDataError.userName && formDataError.userName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        className="capitalize"
                        disabled={(isLoading || Object.keys(formDataError).length > 0)}
                        startIcon={isLoading && <CircularProgress color="secondary" size={24} />}
                    >
                        Submit
                    </Button>
                </Grid>


                <Grid item xs={12}>
                    <Typography variant="body2" align="center">Remember Password? <Link href={RouteConstants.OFFICIAL_LOGIN} onClick={(e) => {
                        e.preventDefault();
                        props.history.push(RouteConstants.OFFICIAL_LOGIN);
                    }}><strong>Login</strong></Link></Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default withRouter(ForgotPasswordComponent);