import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Fab, Link } from '@material-ui/core';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { useMsal } from '@azure/msal-react';
import RouteConstants from '../../common/RouteConstants';
import { unregister } from '../../registerServiceWorker';
import Helper from '../../common/Helper';
import './unauthorizedUI.css';

const UnauthorizedUI = (props) => {
    const { instance } = useMsal();
    // const context = useContext(UserContext);
    const gotoHome = () => {
        props.history.push({
            pathname: '/',
            state: {},
        });
    }

    const handleLogout = async () => {
        if (Helper.getStorage('chk') == 'aad') {
            unregister();
            await instance.loginRedirect();
            localStorage.clear();
        }
        else {
            unregister();
            localStorage.clear();
            window.location.href = '/';
        }
    };

    return (
        <div className="unauthorized-ui-container">
            <LockTwoToneIcon className="unauthorized-ui-icon" />
            <Typography variant="h6">Access denied</Typography>
            {props.showLogout != undefined && props.showLogout ?
                <>
                    <Typography variant="body1" className="unauthorized-ui-subtext">You do not have access to website.</Typography>
                    <Typography variant="body1" className="unauthorized-ui-subtext">Please contact administrator</Typography>
                </>
                :
                <Typography variant="body1" className="unauthorized-ui-subtext">You do not have access to this section.</Typography>
            }
            {/* <Link className="unauthorized-ui-subtext" to={''} onClick={() => logout()}>Sign out</Link> */}
            <br /><br />
            {props.showLogout != undefined && props.showLogout ?
                <Link onClick={handleLogout} sx={{ cursor: 'pointer' }}>Logout</Link>
                :
                <Fab size="medium" color="primary" aria-label="search"
                    className="unauthorized-ui-fab-home" onClick={() => gotoHome()}>
                    <HomeOutlinedIcon />
                </Fab>
            }
            <br />
        </div>
    )
}

export default UnauthorizedUI;