import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { referenceDataService, authenticationService, EvaluationCategoryData, cmsDataservice, userService, jwplayerService, contactus, ClinicEventCategoryData, eventCalendarService, permissionService } from "../services";
import { ContactUsFeedbackService } from '../services/contactus.service';
import Helper from './Helper';
import { CMSRouteSlug } from './RouteConstants';
import * as signalR from '@microsoft/signalr';
import moment from "moment";
import { AbilityContext } from '../common/Can';
import { defineRulesfor } from "./Permission";

export const AppDataContext = React.createContext();
export const AppDataProvider = (props) => {
  const ability = useContext(AbilityContext)


  const [user, setUser] = useState(null);

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [gameTypeList, setGameTypeList] = useState([]);
  const [schoolLevelList, setSchoolLevelList] = useState([]);
  const [experienceTypeList, setExperienceTypeList] = useState([]);
  const [gameCountList, setGameCountList] = useState([]);
  const [experienceSubTypeList, setExperienceSubTypeList] = useState([]);
  const [experienceSubType2List, setExperienceSubType2List] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [messageCenterCategoryList, setMessageCenterCategoryList] = useState([]);
  const [affiliationNetworkList, setAffiliationNetworkList] = useState([]);
  const [partnerOrganizationList, setPartnerOrganizationList] = useState([]);
  const [experienceLevelList, setExperienceLevelList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [allUsersList, setAllUsersList] = useState([]);
  const [eventTypeList, setEventTypeList] = useState([]);
  const [eventSubType1List, setEventSubType1List] = useState([]);
  const [eventSubType2List, setEventSubType2List] = useState([]);
  const [adminDataList, setAdminData] = useState([]);
  const [adminScoutDataList, setAdminScoutData] = useState([]);
  const [poolDataList, setPoolDataList] = useState([]);
  const [stateWiseProfiles, setStateWiseProfiles] = useState([]);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const [scoutObservations, setScoutObservations] = useState({});
  const [userActivities, setUserActivities] = useState({});
  const [hireActivities, setHireActivites] = useState([]);
  const [isLoadingDashboardData, setIsLoadingDashboardData] = useState(false);
  const [totalHireInfo, setTotalHireInfo] = useState([]);
  const [rpsmCount, setRpsmCount] = useState(0);
  const [nbaCandidateCampsList, setNBACandidateCampsList] = useState([]);
  const [primaryScoutList, setPrimaryScoutList] = useState([]);
  const [initiativeList, setInitiativeList] = useState([]);
  const [aboutSourceList, setAboutSourceList] = useState([]);
  const [mostActiveProspectCount, setMostActiveProspectCount] = useState([]);
  const [leastActiveProspectCount, setLeaseActiveProspectCount] = useState([]);
  const [scoutEventCount, setScoutEventCount] = useState([]);
  const [eventCount, setEventCount] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setsubCategory] = useState([])
  const [evaluationEvents, setevaluationEvents] = useState([]);
  const [competency, setCompitancy] = useState([])
  const [refereeList, setRefereeList] = useState([])
  const [snackMessages, setSnackMessages] = useState([])
  const [navigationMenu, setNavigationMenu] = useState([]);
  const [jwtTokenDetails, setJwtTokenDetails] = useState(null);

  const [seasonDates, setSeasonDates] = useState(null);
  const [savedFromDate, setSavedFromDate] = useState(null);
  const [savedToDate, setSavedToDate] = useState(null);
  const [savedSeasonYear, setSavedSeasonYear] = useState('');

  const [savedEventFromDate, setSavedEventFromDate] = useState(null);
  const [savedEventToDate, setSavedEventToDate] = useState(null);
  const [savedEventSeasonYear, setSavedEventSeasonYear] = useState('');


  const [promptData, setPromptData] = useState({
    isRouteBlocked: false,
    message: ""
  });
  const location = useLocation();
  const [roles, setRoles] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([])
  const [availableRoleData, setAvailableRoleData] = useState([])
  const [notificationCountChanged, setNotificationCountChanged] = useState(true);
  //const [hubConnection, setHubConnection] = useState(new signalR.HubConnectionBuilder().withUrl(referenceDataService.getSignalRUrl()).withAutomaticReconnect().configureLogging(signalR.LogLevel.Information).build());
  //const [hubConnected, setHubConnected] = useState(false);
  const [prospectDetails, setProspectDetails] = useState(null);
  const [poolProspect, setPoolProspect] = useState([]);
  const [seasonTypeList, setSeasonTypeList] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [sitekey, setSitekey] = useState("");
  const [faqs, setFaqs] = useState();
  const [tangibles, setTangibles] = useState([]);
  const [eventCategoryList, setEventCategoryList] = useState([])
  const [clinicEvents, setClinicEvents] = useState([])
  const [allPermissions, setAllPermissions] = useState([])
  const [permissionVerbList, setPermissionVerbList] = useState([])
  const [leagues, setLeagues] = useState([{ id: '00', displayName: 'NBA' }, { id: '10', displayName: 'WNBA' }, { id: '20', displayName: 'G-League' }])

  useEffect(() => {
    if (!navigationMenu.length) {
      getNavigationMenu();
    }
    if (!jwtTokenDetails) {
      getJWToken();
    }

    authenticationService.currentUser.subscribe(async currentUser => {
      setUser(currentUser);
      if (currentUser && currentUser.lastLoginDate !== null) {
        let roleId = Helper.getUserRoleId();
        if (roleId && roleId !== '') {
          let permissions = await authenticationService.getPermissionByRole(roleId)
          let rules = defineRulesfor(permissions)
          ability.update(rules)
        }
        if (checkRole('Admin', currentUser) || checkRole('Scout', currentUser) || checkRole('AdminScout', currentUser) || checkRole('EventAdmin', currentUser)) {
          getMasterDataForAdminRole();
          getCategoryData();
          getCompetencyData();
          getSeasonData();
          getTangibleData();
          getPermissions();
          getVerbsList();
          getCategoryList()
          getRoles()
        }
        if (checkRole('Partner', currentUser)) {
          getMasterDataForUserRole();
        }
        if (checkRole('User', currentUser)) {
          getMasterDataForUserRole();
          getEvents();
          getCategoryList()
          getRoles()
        }
      }
      else {
        getMasterDataForGuestRole();
      }
      getFaqsData();

    });
  }, []);

  const fetchDefaultData = async (user) => {
    if (!navigationMenu.length) {
      getNavigationMenu();
    }
    if (!jwtTokenDetails) {
      getJWToken();
    }


    setUser(user);
    if (user && user.lastLoginDate !== null) {
      let roleId = Helper.getUserRoleId();
      if (roleId && roleId !== '') {
        let permissions = await authenticationService.getPermissionByRole(roleId)
        let rules = defineRulesfor(permissions)
        ability.update(rules)
      }
      if (checkRole('Admin', user) || checkRole('Scout', user) || checkRole('AdminScout', user) || checkRole('EventAdmin', user)) {
        getMasterDataForAdminRole();
        getCategoryData();
        getCompetencyData();
        getSeasonData();
        getTangibleData();
        getCategoryList()
        getRoles()
        getPermissions()
        getVerbsList()
      }
      if (checkRole('Partner', user)) {
        getMasterDataForUserRole();
      }
      if (checkRole('User', user)) {
        getMasterDataForUserRole();
        getEvents();
        getCategoryList()
        getRoles()
      }
    }
    else {
      getMasterDataForGuestRole();
    }

  }

  useEffect(() => {
    // runs on location, i.e. route, change
    setPromptData({
      isRouteBlocked: false
    });
    // console.log('handle route change here', location)
  }, [location]);

  const getJWToken = () => {
    jwplayerService.getToken()
      .then(token => {
        setJwtTokenDetails(token.data);
        //updatejwTokenDetails(token);
      })
  }
  const updateNotificationCount = (notificationFlag) => {
    setNotificationCountChanged(notificationFlag);
  }

  const updateMessageCategoryList = (categoryList) => {
    setMessageCenterCategoryList(categoryList);
  }

  const updateAllUsersList = (data) => {
    setAllUsersList(data);
  }

  const updateDashboardStateList = (stateList) => {
    setStateWiseProfiles(stateList)
  }

  const updateDashboardExperienceList = (experienceList) => {
    setExperienceLevels(experienceList)
  }

  const getUpdatedEvaluationEvents = (postData) => {
    if (postData === undefined || postData === null) {
      postData = {
        eventName: '',
        gameType: 0,
        eventFrom: null,
        eventTo: null,

      }
    }

    setIsLoadingEvents(true)
    EvaluationCategoryData.getEvaluationEvents(postData)
      .then(result => {
        let dataEvents = result.map((item) => {


          return {
            ...item,
            eventFrom: Helper.FormatDate(item.eventFrom),
            eventTo: Helper.FormatDate(item.eventTo),

          }
        });
        setevaluationEvents(dataEvents)
        setIsLoadingEvents(false)
      })

  }

  const getCategoryData = () => {

    EvaluationCategoryData.getAllEvaluationCategoryList()
      .then(result => {
        let categories = result.map(category => { return { id: category.id, categoryName: category.categoryName, categoryActive: category.categoryActive } })
        let subcategories = [];
        result.forEach(category => {
          let subdata = category.subCategories
          subdata && subdata.length > 0 && subdata.forEach(sub => {
            sub['categoryName'] = category.categoryName;
            subcategories.push(sub);
          })
          setCategory(categories);
          setsubCategory(subcategories);
        });

      })

  }

  const getCompetencyData = () => {

    EvaluationCategoryData.getCompetenciesList()
      .then(result => {
        setCompitancy(result);
      })

  }

  const getTangibleData = () => {

    EvaluationCategoryData.getTangibleList()
      .then(result => {
        setTangibles(result);
      })

  }

  const getSeasonData = () => {

    EvaluationCategoryData.getSeasonType('Season')
      .then(result => {
        setSeasonTypeList(result)
      })
  }

  const getDashBoardData = () => {

    setIsLoadingDashboardData(true)
    referenceDataService.getDashboardData().then(response => {
      if (response.data) {
        if (response.data.stateWiseProfiles) {
          setStateWiseProfiles(response.data.stateWiseProfiles)
        }
        if (response.data.experienceLevels) {
          setExperienceLevels(response.data.experienceLevels)
        }
        // if (response.data.scoutObservations) {
        //   setScoutObservations(response.data.scoutObservations)
        // }
        if (response.data.userActivities) {
          setUserActivities(response.data.userActivities)
        }
        if (response.data.hireActivities) {
          setHireActivites(response.data.hireActivities)
        }
        if (response.data.totalHiredInfo) {
          setTotalHireInfo(response.data.totalHiredInfo);
        }
        if (response.data.rpsmCount) {
          setRpsmCount(response.data.rpsmCount);
        }
        if (response.data.mostActiveProspectCounts) {
          setMostActiveProspectCount(response.data.mostActiveProspectCounts)
        }
        if (response.data.leastActiveProspectCounts) {
          setLeaseActiveProspectCount(response.data.leastActiveProspectCounts);
        }
        if (response.data.scoutEventCounts) {
          setScoutEventCount(response.data.scoutEventCounts)
        }
        if (response.data.eventCounts) {
          setEventCount(response.data.eventCounts);
        }
        if (response.data.prospectDetails) {
          setProspectDetails(response.data.prospectDetails);
        }
        if (response.data.poolProspect) {
          setPoolProspect(response.data.poolProspect);
        }
      }
      setIsLoadingDashboardData(false)
    })

  }

  const checkRole = (role, userData) => {
    let userRole = userService.getUserRole();
    if (!role) return false;
    if (role === '*') return true;

    if (role) {
      if (Array.isArray(role)) {
        return role.find(r => r && r.toLowerCase() === userRole.toLowerCase());
      }

      return role.toLowerCase() === userRole.toLowerCase();
    }

    return false;
  }

  const getMasterDataForGuestRole = () => {
    // To Do... add api to get location, experience level data 
    referenceDataService.getGuestMasterdata().then(res => {
      if (res.data) {
        if (res.data.state) {
          setStateList(res.data.state)
        }
        if (res.data.country) {
          setCountryList(res.data.country)
        }
        if (res.data.experienceLevel) {
          setExperienceLevelList(res.data.experienceLevel)
        }
        if (res.data.messageCenterCategory) {
          // console.log(res.data.messageCenterCategory)
          setMessageCenterCategoryList(res.data.messageCenterCategory)
        }
        if (res.data.aboutUsSource) {
          // console.log(res.data.messageCenterCategory)
          setAboutSourceList(res.data.aboutUsSource)
        }





      }
    })

    userService.getSitekeyForRecaptcha()
      .then(data => {
        setSitekey(data.data);
      })
  }
  const getCategoryList = () => {
    var data = { "ParentId": 0 };
    ClinicEventCategoryData.getAllClinicalEventCategoryList(data).then(res => {
      setEventCategoryList(res && res?.length > 0 ? res.filter(x => x.parentID == 0) : []);
    });
  }
  const getFaqsData = () => {
    ContactUsFeedbackService.getFaqs()
      .then(data => {
        setFaqs(data.data)
      })
  }

  const getMasterDataForUserRole = () => {
    userService.getProfileMasterData().then(data => {
      if (data.state) {
        setStateList(data.state)
      }
      if (data.country) {
        setCountryList(data.country)
      }
      if (data.timeZone) {
        setTimeZoneList(data.timeZone)
      }
      if (data.gameType) {
        setGameTypeList(data.gameType)
      }
      if (data.schoolLevel) {
        setSchoolLevelList(data.schoolLevel)
      }
      if (data.experienceType) {
        setExperienceTypeList(data.experienceType)
      }
      if (data.gameCount) {
        setGameCountList(data.gameCount)
      }
      if (data.experienceSubType) {
        setExperienceSubTypeList(data.experienceSubType)
      }
      if (data.experienceSubType2) {
        setExperienceSubType2List(data.experienceSubType2);
      }
      if (data.socialMedia) {
        setSocialMediaList(data.socialMedia);
      }
      if (data.affiliationNetwork) {
        setAffiliationNetworkList(data.affiliationNetwork);
      }
      if (data.partnerOrganization) {
        setPartnerOrganizationList(data.partnerOrganization);
      }
      if (data.experienceLevel) {
        setExperienceLevelList(data.experienceLevel);
      }
      if (data.officiatingEventsType) {
        setEventTypeList(data.officiatingEventsType);
      }
      if (data.officiatingEventsSubType1) {
        setEventSubType1List(data.officiatingEventsSubType1);
      }
      if (data.officiatingEventsSubType2) {
        setEventSubType2List(data.officiatingEventsSubType2);
      }
      if (data.aboutUsSource) {
        setAboutSourceList(data.aboutUsSource)
      }
      if (data.messageCenterCategory) {
        setMessageCenterCategoryList(data.messageCenterCategory)
      }
    }).catch((e) => {
      Helper.Logout();

    });
  }
  const setMasterRoles = (roles) => {
    setAvailableRoleData(roles)
    if (roles && roles?.length > 0) {
      let roleList = roles && roles?.length > 0 ? roles?.map(item => item.roleCode) : [];
      let roleArr = {};
      roleList.forEach(role => {
        roleArr = { ...roleArr, [role.toLowerCase()]: role }
      })

      setAvailableRoles(roleList);
      setRoles(roleArr);
    }
  }
  const getRoles = () => {
    referenceDataService.getRoles()
      .then(result => {
        setMasterRoles(result)
      })
  }

  const getPermissions = () => {
    permissionService.getAllPermissions()
      .then(result => {
        setAllPermissions(result.data)
      })
  }

  const getVerbsList = () => {
    referenceDataService.getMasterDataByName('PermissionVerb').then(res => {
      setPermissionVerbList(res.data);
    });
  }



  const getMasterDataForAdminRole = () => {

    referenceDataService.getMasterData()
      .then(data => {
        if (data) {
          // if (data.state) {
          //   setStateList(data.state)
          // }
          // if (data.country) {
          //   setCountryList(data.country)
          // }
          if (data.timeZone) {
            setTimeZoneList(data.timeZone)
          }
          if (data.gameType) {
            setGameTypeList(data.gameType)
          }
          if (data.schoolLevel) {
            setSchoolLevelList(data.schoolLevel)
          }
          if (data.experienceType) {
            setExperienceTypeList(data.experienceType)
          }
          if (data.gameCount) {
            setGameCountList(data.gameCount)
          }
          if (data.experienceSubType) {
            setExperienceSubTypeList(data.experienceSubType)
          }
          if (data.experienceSubType2) {
            setExperienceSubType2List(data.experienceSubType2);
          }
          if (data.socialMedia) {
            setSocialMediaList(data.socialMedia);
          }
          if (data.messageCenterCategory) {
            setMessageCenterCategoryList(data.messageCenterCategory);
          }
          if (data.affiliationNetwork) {
            setAffiliationNetworkList(data.affiliationNetwork);
          }
          if (data.partnerOrganization) {
            setPartnerOrganizationList(data.partnerOrganization);
          }
          if (data.experienceLevel) {
            setExperienceLevelList(data.experienceLevel);
          }
          // if (data.userData) {
          //   setUserList(data.userData);
          // }
          if (data.allUsersData) {
            setAllUsersList(data.allUsersData)
          }
          if (data.officiatingEventsType) {
            setEventTypeList(data.officiatingEventsType);
          }
          if (data.officiatingEventsSubType1) {
            setEventSubType1List(data.officiatingEventsSubType1);
          }
          if (data.officiatingEventsSubType2) {
            setEventSubType2List(data.officiatingEventsSubType2);
          }
          if (data.candidateCamps) {
            setNBACandidateCampsList(data.candidateCamps);
          }
          if (data.poolData) {
            setPoolDataList(data.poolData)
          }
          if (data.primaryScouts) {
            let x = data.primaryScouts;
            x.push({ primaryScoutID: "none", primaryScoutName: "None" })
            setPrimaryScoutList(x);
          }
          if (data.initiatives) {
            setInitiativeList(data.initiatives);
          }
          if (data.aboutUsSource) {
            setAboutSourceList(data.aboutUsSource);
          }
        }
      }).catch((e) => {
        Helper.Logout();
      });

    referenceDataService.getSeasonDates().then(response => {
      if (response.data) {
        setSeasonDates(response.data);

      }
    })


    referenceDataService.getCountry().then(response => {
      if (response.data) {
        setCountryList(response.data)
      }
    })

    referenceDataService.getState().then(response => {
      if (response.data) {
        setStateList(response.data)
      }
    })
    referenceDataService.getUsersList().then(data => {
      if (data) {
        setUserList(data)
      }
    })

    referenceDataService.getPoolList().then(response => {
      if (response) {
        setPoolDataList(response.data)
      }
    })


    referenceDataService.getAdminData().then(data => {
      if (data) {
        if (data) {
          setAdminData(data)
        }
      }
    })

    referenceDataService.getAdminScoutData().then(data => {
      if (data) {
        setAdminScoutData(data)
      }
    })

    // getDashBoardData();

    // getCategoryData();

    // getCompetencyData();

    referenceDataService.getRefStatRefereeList()
      .then(result => {
        setRefereeList(result);
      })


  }

  const getNavigationMenu = () => {
    cmsDataservice.getCMSContentBySlug(CMSRouteSlug.MENU).then(res => {
      setNavigationMenu(res);
    });
  }

  const refreshPoolData = () => {
    referenceDataService.getPoolList().then(response => {
      if (response) {
        setPoolDataList(response.data)
      }
    })
  }

  const updateEvaluationCateAndSubCate = (list) => {
    setCategory(list.categoryList)
    setsubCategory(list.subCategoryList)
  }
  const updateEvalCompitancy = (compitancyList) => {
    setCompitancy(compitancyList)
  }
  // const updateHubConnectedStatus = (status) => {
  //   setHubConnected(status);
  // }

  const updatedDashboardFromDateState = (fromDate) => {
    setSavedFromDate(fromDate)
  }

  const updatedDashboardToDateState = (toDate) => {
    setSavedToDate(toDate)
  }

  const updatedDashboardSeasonYearState = (seasonYear) => {
    setSavedSeasonYear(seasonYear)
  }

  const refreshDashboardDatesState = () => {
    setSavedFromDate(null)
    setSavedToDate(null)
    setSavedSeasonYear('')
  }

  const updateDashboardDatesState = (fromDate, toDate, seasonYear) => {
    setSavedFromDate(fromDate)
    setSavedToDate(toDate)
    setSavedSeasonYear(seasonYear)
  }
  //
  const updatedEventDashboardFromDateState = (fromDate) => {
    setSavedEventFromDate(fromDate)
  }

  const updatedEventDashboardToDateState = (toDate) => {
    setSavedEventToDate(toDate)
  }

  const updatedEventDashboardSeasonYearState = (seasonYear) => {
    setSavedEventSeasonYear(seasonYear)
  }

  const refreshEventDashboardDatesState = () => {
    setSavedEventFromDate(null)
    setSavedEventToDate(null)
    setSavedEventSeasonYear('')
  }

  const updateEventDashboardDatesState = (fromDate, toDate, seasonYear) => {
    setSavedEventFromDate(fromDate)
    setSavedEventToDate(toDate)
    setSavedEventSeasonYear(seasonYear)
  }
  //

  //get past 3month and upcoming 1 months events
  const getEvents = () => {
    let endDate = Helper.FormatDateToLocalTimeZone(moment().add(1, 'months'));
    let startDate = Helper.FormatDateToLocalTimeZone(moment().subtract(3, 'months'));
    let type = 'past'
    // setIsLoadingEvents(true);
    eventCalendarService.getYAOPublicEvents(1, 999, startDate, endDate, type).then((result) => {
      setClinicEvents(result.data.eventCalendarList);
      // setIsLoadingEvents(false);
    }).catch((e) => console.error(e));

  }



  const providerValue = {
    user,
    stateList,
    countryList,
    gameTypeList,
    timeZoneList,
    schoolLevelList,
    experienceTypeList,
    gameCountList,
    experienceSubTypeList,
    experienceSubType2List,
    socialMediaList,
    updateSocialMediaList: (newValue) => setSocialMediaList(newValue),
    messageCenterCategoryList,
    affiliationNetworkList,
    partnerOrganizationList,
    experienceLevelList,
    eventTypeList,
    eventSubType1List,
    eventSubType2List,
    poolDataList,
    nbaCandidateCampsList,
    primaryScoutList,
    initiativeList,
    aboutSourceList,
    //graphQLClient,
    setUser,
    userList,
    allUsersList,
    adminDataList,
    adminScoutDataList,
    updateMessageCategoryList,
    updateAllUsersList,
    scoutObservations,
    stateWiseProfiles,
    experienceLevels,
    userActivities,
    hireActivities,
    updateDashboardStateList,
    updateDashboardExperienceList,
    mostActiveProspectCount,
    leastActiveProspectCount,
    scoutEventCount,
    eventCount,
    category,
    subCategory,
    evaluationEvents,
    competency,
    getUpdatedEvaluationEvents,
    refereeList,
    getDashBoardData,
    getCategoryData,
    getCompetencyData,
    snackMessages,
    navigationMenu,
    updateNavigationMenu: (newValue) => setNavigationMenu(newValue),
    jwtTokenDetails,
    addSnackMessages: (alerts, reset) => {
      if (reset) { setSnackMessages(alerts); }
      else {
        setSnackMessages([...snackMessages, ...alerts]);
      }
    },
    removeSnackMessages: (index) => { let tempAlerts = Array.from(snackMessages); tempAlerts.splice(index, 1); setSnackMessages(tempAlerts); },
    checkRole: (role) => checkRole(role),
    refreshPoolData,
    promptData,
    setPromptData: (newValue) => setPromptData(newValue),
    fetchDefaultData: fetchDefaultData,
    updateEvaluationCateAndSubCate: updateEvaluationCateAndSubCate,
    //updateEvaluationSubCategory: updateEvaluationSubCategory,
    updateEvalCompitancy: updateEvalCompitancy,
    roles,
    availableRoles,
    setMasterRoles,
    updateNotificationCount,
    notificationCountChanged,
    //hubConnection,
    //hubConnected,
    //updateHubConnectedStatus 
    prospectDetails,
    poolProspect,
    seasonDates,
    savedFromDate,
    savedToDate,
    savedSeasonYear,
    updatedDashboardToDateState,
    updatedDashboardFromDateState,
    updatedDashboardSeasonYearState,
    refreshDashboardDatesState,
    updateDashboardDatesState,
    seasonTypeList,
    isLoadingEvents,
    sitekey,
    faqs,
    tangibles,
    getTangibleData,
    savedEventFromDate,
    savedEventToDate,
    savedEventSeasonYear,
    updatedEventDashboardToDateState,
    updatedEventDashboardFromDateState,
    updatedEventDashboardSeasonYearState,
    refreshEventDashboardDatesState,
    updateEventDashboardDatesState,
    eventCategoryList,
    clinicEvents,
    availableRoleData,
    allPermissions,
    permissionVerbList,
    leagues,
    rpsmCount,
    totalHireInfo,
    isLoadingDashboardData
  };

  return (
    <AppDataContext.Provider value={providerValue}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export const AppDataConsumer = AppDataContext.Consumer;

export default AppDataContext;
