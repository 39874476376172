import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function AlertRhombus(props) {
    return (
        <SvgIcon {...props}>
            <path color='#FA0000' d="M12 2C11.5 2 11 2.19 10.59 2.59L2.59 10.59C1.8 11.37 1.8 12.63 2.59 13.41L10.59 21.41C11.37 22.2 12.63 22.2 13.41 21.41L21.41 13.41C22.2 12.63 22.2 11.37 21.41 10.59L13.41 2.59C13 2.19 12.5 2 12 2M11 7H13V13H11V7M11 15H13V17H11V15Z" />
        </SvgIcon>
    );
}

export default AlertRhombus;