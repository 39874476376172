import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles, Button, Typography } from '@material-ui/core';
import BrokenImageTwoToneIcon from '@material-ui/icons/BrokenImageTwoTone';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        alignSelf: 'center',
        width: '100%',
        backgroundColor: theme.palette.background.default
    },
    icon: {
        '& svg': {
            color: theme.palette.primary.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '10rem !important',
            marginBottom: theme.spacing(3),
        }
    },
    subtitle: {
        marginTop: theme.spacing(1),
        maxWidth: '320px',
        textAlign: 'center'
    },
    fabIcon: {
        margin: theme.spacing(1)
    },
    onlyFabIcon: {
        borderRadius: '50%',
        height: '48px',
        minWidth: '48px!important',
        width: '48px',
        '& .MuiButton-startIcon': {
            margin: '0 !important'
        }
    }
}));

const PageNotFoundUI = ({ titleProps, subtitleProps, ...props }) => {

    const classes = useStyles();

    const routeTo = (path) => {
        props.history.push({
            pathname: path,
            state: undefined
        });
    }

    return (
        <div className={`${classes.root} ${props.classes}`}>
            {props.showPageIcon &&
                <div className={classes.icon}>
                    {props.pageIcon}
                </div>
            }

            <Typography variant="h4" color="primary" {...titleProps}>{props.title}</Typography>
            {props.subtitle && <Typography paragraph variant="body1" color='textSecondary' className={classes.subtitle} {...subtitleProps}>{props.subtitle}</Typography>}

            {props.showFabIcon &&
                <Button size="medium" color="primary" variant='contained'
                    className={clsx(classes.fabIcon, {
                        [classes.onlyFabIcon]: !props.fabTitle
                    })}
                    onClick={() => routeTo(props.fabRoute)}
                    startIcon={props.fabIcon}
                >
                    {props.fabTitle}
                </Button>
            }

        </div>
    )
}

PageNotFoundUI.defaultProps = {
    title: 'Oops!',
    subtitle: 'We can\'t seem to find the page you\'re looking for.',
    fabRoute: '/',
    fabIcon: <HomeOutlinedIcon />,
    fabTitle: false,
    showFabIcon: false,
    pageIcon: <BrokenImageTwoToneIcon />,
    showPageIcon: false,
    classes: ''
}

export default withRouter(PageNotFoundUI);