import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

const webAPIURL = () => {
    //const { REACT_APP_PUBLIC_URL } = process.env;
    let url = `${new URL(window.location.origin)}api`;
    return url
};


export const ClinicEventCategoryData = {
    getAllClinicalEventCategoryList: getAllClinicalEventCategoryList,
    saveClinicalEventCategory: saveClinicalEventCategory,
    getEventClinicialList : getEventClinicialList
}

function getAllClinicalEventCategoryList(data) {
    return APIHelper.post(APIURLConstants.GET_ALL_CLINIC_EVENT_CATEGORY,data, authHeader())
        .then(result => {
            return result.data;
        }).catch(handleError);
}
function saveClinicalEventCategory(data) {
    return APIHelper.post(APIURLConstants.SAVE_CLINIC_EVENT_CATEGORY, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getEventClinicialList(data) {
    return APIHelper.post(APIURLConstants.GET_EVENT_CLINICIAL,data, authHeader())
        .then(result => {
            return result.data;
        }).catch(handleError);
}