import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { Container, makeStyles } from '@material-ui/core'
import { cmsDataservice } from '../../services'
import SectionLoader from '../../sharedComponents/Loader/SectionLoader';
import PageNotFoundUI from '../../sharedComponents/emptyStateUIContainers/PageNotFoundUI';
import DefaultTemplate from './templates/DefaultTemplate';
import HomeTemplate from './templates/HomeTemplate';
import ContactUsTemplate from './templates/ContactUsTemplate';
import HomePage from './HomePage';
import AlertRhombus from '../../sharedComponents/customIcons/alert-rhombus';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        height: '100%',
        display: 'flex'
    },

}));

function Page() {
    const classes = useStyles();
    const params = useParams();
    const { slug, segmenttwo } = params;
    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let currentSlug = slug || 'home';
        if (currentSlug) {
            if (segmenttwo) {
                currentSlug = currentSlug + '/' + segmenttwo;
            }
            setIsLoading(true);
            cmsDataservice.getCMSContentBySlug(currentSlug).then(res => {
                setContent(res);
                setIsLoading(false);
            }).catch(e => {
                setContent(null);
                setIsLoading(false);
            })
        }
    }, [slug, segmenttwo]);

    const renderPageByTemplate = ({ pageTemplate, ...content }) => {
        if (pageTemplate === "Home Template") {
            //return <HomeTemplate content={content} />;
            return <HomePage />;
        }
        
        if (pageTemplate === "Contact Us Template") {
            return <ContactUsTemplate content={content} />;
        }
        return <DefaultTemplate content={content} />;
    }

    return isLoading ?
        <Container maxWidth="xl" className={classes.content}>
            <SectionLoader />
        </Container>
        : !content ? slug === undefined?<>
            <Helmet>
                <title>Page Not Found</title>
                <meta name="description" content="Page Not Found" />
                <link rel="canonical" href={`${new URL(window.location.origin)}${slug ?? ""}`} />
            </Helmet>
            <PageNotFoundUI
                showPageIcon
                title="Oops! Unable to fetch content"
                titleProps={{
                    variant: "h3"
                }}
                pageIcon={<AlertRhombus/>}
                subtitle='There seems to be some config/network issue, unable to load content for home page. You can login and continue.'
            />
        </>:<>
        <Helmet>
                <title>Page Not Found</title>
                <meta name="description" content="Page Not Found" />
                <link rel="canonical" href={`${new URL(window.location.origin)}${slug ?? ""}`} />
            </Helmet>
            <PageNotFoundUI
                showPageIcon
                title="Oops! 404 Page Not Found"
                titleProps={{
                    variant: "h3"
                }}
            />
        </> : <>
            <Helmet>
                <title>{content.metaTitle ?? content.pageTitle}</title>
                <meta name="description" content={content.metaDescription} />
                <link rel="canonical" href={`${new URL(window.location.origin)}${slug ?? ""}`} />
                {content.pageStyle && <style>{content.pageStyle}</style>}
                {content.pageScript && <script>{content.pageScript}</script>}
            </Helmet>
            {renderPageByTemplate(content)}
        </>
}

export default Page;