import React, { useContext } from 'react';
import { Slide, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AppDataContext from './AppContext';

export default function SnackNotifier() {
    const { snackMessages, removeSnackMessages } = useContext(AppDataContext);

    function getBottom(index) {
        return (index) ? { bottom: index * (24 * 3), marginBottom: 8 } : {};
    }

    const handleClose = (event, reason, index) => {
        if (reason === 'clickaway') {
            return;
        }
        removeSnackMessages(index);
    }

    return snackMessages.map((alert, index) =>
        <Snackbar key={index} open={alert.open}
            autoHideDuration={(alert.hideDuration) ? alert.hideDuration : 4000}
            anchorOrigin={(alert.anchorOrigin) ? alert.anchorOrigin : { vertical: 'bottom', horizontal: 'right' }}
            onClose={(e, r) => handleClose(e, r, index)}
            style={getBottom(index)}
            TransitionComponent={Slide}
        >
            <Alert icon={alert.icon} onClose={(e, r) => handleClose(e, r, index)} severity={alert.severity}>{alert.message}</Alert>
        </Snackbar>
    )
}