import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Divider, Grid, Link, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import AppDataContext from '../../common/AppContext';
import NBA_Officiate_logo from '../../images/nba-officials.png';
import GooglePlayStoreLogo from '../../images/google-play-badge.png';
import Validations from '../../common/Validations';

const useStyles = makeStyles((theme) => ({
    footer: {
        background: '#0F1C34',
        color: '#e4e4e4'
    },
    containerMain: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(3)
    },
    containerCopyright: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column'
        }
    },
    divider: {
        backgroundColor: '#2c5199'
    },
    logoColumn: {
        '& img.logo-img': {
            maxWidth: 120,
            marginBottom: theme.spacing(1.5)
        },
        '& p': {
            maxWidth: '80%'
        }
    },
    copyrightLinks: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        '& a': {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            color: '#748fc6',
            '&:not(:last-child):after': {
                content: `'|'`,
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1)
            }
        }
    },
    columnHeader: {
        color: '#ffffff',
        marginBottom: theme.spacing(1)
    },
    playstorelogo: {
        width: 160,
        height: 67,
        display: 'inline-block',
        '& img': {
            display: 'block',
            maxWidth: 160,
            height: 67
        }
    },
    appDownloadLinks: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up("md")]: {
            position: 'absolute',
            right: 12
        }
    },
    appstorelogo: {
        display: 'flex',
        alignItems: 'center',
        height: 67,
        width: 160,
        '& img': {
            width: '100%',
            height: 45
        }
    },
}));

const FooterPublic = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { navigationMenu } = useContext(AppDataContext);
    const handleLinkClick = (event, url) => {
        event.preventDefault();
        let isThirdParty = Validations.Validate("", url, { URL: true });
        if (isThirdParty.isValid) {
            window.open(event.target.href, '_blank');
            return false;
        }
        history.push({
            pathname: event.target.pathname
        });
        return;
    }

    return <footer className={classes.footer}>
        <Container maxWidth="xl" className={classes.containerMain}>
            <Grid container spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} className={classes.logoColumn}>
                        <img alt="logo" src={NBA_Officiate_logo} className="logo-img" />
                        {navigationMenu.footerAboutUs && <Typography variant='body2'>{navigationMenu.footerAboutUs}</Typography>}
                    </Grid>
                    {(navigationMenu.columnOneTitle || navigationMenu.columnOne) && <Grid item xs={12} sm={6} md={3}>
                        {navigationMenu.columnOneTitle && <Typography variant='h4' className={classes.columnHeader}>{navigationMenu.columnOneTitle}</Typography>}
                        {navigationMenu.columnOne &&
                            <List dense disablePadding>
                                {navigationMenu.columnOne.map((item, index) =>
                                    <ListItem disableGutters key={index}><Link href={item.navUrl} onClick={(e) => handleLinkClick(e, item.navUrl)} color='inherit'>{item.title}</Link></ListItem>
                                )}
                            </List>}
                    </Grid>}
                    {(navigationMenu.columnTwoTitle || navigationMenu.columnTwo) && <Grid item xs={12} sm={6} md={3}>
                        {navigationMenu.columnTwoTitle && <Typography variant='h4' className={classes.columnHeader}>{navigationMenu.columnTwoTitle}</Typography>}
                        {navigationMenu.columnTwo &&
                            <List dense disablePadding>
                                {navigationMenu.columnTwo.map((item, index) =>
                                    <ListItem disableGutters key={index}><Link href={item.navUrl} onClick={(e) => handleLinkClick(e, item.navUrl)} color='inherit'>{item.title}</Link></ListItem>
                                )}
                            </List>}
                    </Grid>}
                    {(navigationMenu.columnThreeTitle || navigationMenu.columnThree) && <Grid item xs={12} sm={6} md={3}>
                        {navigationMenu.columnThreeTitle && <Typography variant='h4' className={classes.columnHeader}>{navigationMenu.columnThreeTitle}</Typography>}
                        {navigationMenu.columnThree &&
                            <List dense disablePadding>
                                {navigationMenu.columnThree.map((item, index) =>
                                    <ListItem disableGutters key={index}><Link href={item.navUrl} onClick={(e) => handleLinkClick(e, item.navUrl)} color='inherit'>{item.title}</Link></ListItem>
                                )}
                            </List>}
                    </Grid>}
                </Grid>
            </Grid>
        </Container>
        <Container maxWidth="xl">
            <Divider className={classes.divider} />
        </Container>
        <Container maxWidth="xl" className={classes.containerCopyright}>
            <div>
                {navigationMenu.copyrightLinks && <Typography align='center' variant='subtitle2' className={classes.copyrightLinks}>
                    {navigationMenu.copyrightLinks.map((item, index) =>
                        <Link key={index} href={item.navUrl} onClick={(e) => handleLinkClick(e, item.navUrl)}>{item.title}</Link>
                    )}
                </Typography>}
                <Typography align='center' variant='caption' display='block'>&copy; 2022 NBA Officials. All rights reserved.</Typography>
            </div>
            {(navigationMenu.playStoreURL || navigationMenu.appStoreURL) &&
                <div className={classes.appDownloadLinks}>
                    {navigationMenu.playStoreURL && <a href={navigationMenu.playStoreURL} className={classes.playstorelogo}><img src={GooglePlayStoreLogo} alt="Download on Google Play Store" /></a>}
                    {navigationMenu.appStoreURL && <a
                        href={navigationMenu.appStoreURL}
                        className={classes.appstorelogo}
                    >
                        <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=170x77&amp;releaseDate=1603756800&h=fde7b94c76097c41cdd8282ec0159dce" alt="Download on the App Store" />
                    </a>}
                </div>
            }
        </Container>
    </footer>
}

export default FooterPublic;