import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import {  useParams } from 'react-router-dom';
import AuthLayoutContainer from "./AuthLayoutContainer";
import ResetPasswordComponent from "./components/ResetPasswordComponent";

const useStyles = makeStyles((theme) => ({

}));

const ResetPassword = (props) => {
    const classes = useStyles();
    const params = new URLSearchParams(props.location.search);
    const { token } = useParams();
    useEffect(() => {
    }, [])

    return <>
        <AuthLayoutContainer children={<ResetPasswordComponent token={token} />} />
    </>

}

export default ResetPassword;