import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Helper from '../../common/Helper';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import APIURLConstants from '../../common/ApiURLConstants';

const Logout = (props) => {
    const { instance } = useMsal();
    useEffect(() => {
        const logout = async () => {
            var accesstoken = localStorage.getItem('accessToken')
            if (accesstoken && accesstoken !== "") {
                axios.get(APIURLConstants.SIGNOUT, { headers: { 'Authorization': `Bearer ${accesstoken}` } })
                    .then(async res => {
                        if (Helper.getStorage('chk') == 'aad') {
                            localStorage.clear()
                            await instance.logoutRedirect();

                        }

                        Helper.Logout();

                    })
            } else {
                window.location.href = '/';
            }



        }
        logout();
    }, [])

    return (
        <></>
    );
}

export default withRouter(Logout);