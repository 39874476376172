import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import DiaryIcon from '../../../sharedComponents/customIcons/DiaryIcon';
import { LessonPlanDataService } from '../../../services/lessonPlans.service';
import LessonPlanCard from '../../lessonPlans/LessonPlanCard';
import { useHistory } from 'react-router-dom';
import RouteConstants from '../../../common/RouteConstants';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(3.5),
        borderBottom: '1px solid #E4E4E4',
        '& .MuiListItemSecondaryAction-root': {
            right: 0
        }
    },
    icon: {
        minWidth: 'unset',
        marginRight: 12
    },
    title: {
        textTransform: 'uppercase'
    }
}));

const LessonPlanSection = () => {
    const classes = useStyles();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        LessonPlanDataService.getMyLessons(1, 3).then(result => {
            setData(result.data.data);
            setIsLoading(false);
        })
            .catch(error => {
                setIsLoading(false);
            });
    }, []);

    return (!isLoading && data.length) ? <Container maxWidth="lg" component="section" className={classes.content}>
        <ListItem role={undefined} ContainerComponent='div' disableGutters>
            <ListItemIcon className={classes.icon}>
                <DiaryIcon />
            </ListItemIcon>
            <ListItemText
                primary="Lesson Plans"
                primaryTypographyProps={{
                    variant: "h5",
                    component: "h5",
                    color: "secondary",
                    className: classes.title
                }}
            />
            <ListItemSecondaryAction>
                <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => {
                        history.push({
                            pathname: RouteConstants.LESSON_PLANS
                        });
                    }}
                >
                    View All
                </Button>
            </ListItemSecondaryAction>
        </ListItem>

        <Grid container spacing={4}>
            {data.slice(0, 3).map((value, index) =>
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <LessonPlanCard item={value} />
                </Grid>
            )}
        </Grid>

    </Container> : null
}

export default LessonPlanSection;