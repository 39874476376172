import { AbilityBuilder, Ability } from '@casl/ability';
import React, { useState, useContext } from 'react';
import { AbilityContext } from './Can';
import { PERMISSIONS_CONSTANTS, PERMISSION_VERBS } from './RouteConstants';


export function defineRulesfor(permissions) {
    const { can, rules } = new AbilityBuilder(Ability);
    if (permissions.length > 0) {
        permissions.forEach((permission) => {
            can(permission.verbName, permission.subjectName)
        })
    }
    // can('view', 'scoutingDashboard');
    // can('view','logout');
    // can('view', 'officiatingEvent');
    // can('view','clinicEventDashboard');

    return rules;
}

export function defineDefaultRules(permissions) {
    const { can, rules } = new AbilityBuilder(Ability);


    can(PERMISSION_VERBS.READ, PERMISSIONS_CONSTANTS.LOGIN.subject);
    can(PERMISSION_VERBS.READ, PERMISSIONS_CONSTANTS.SIGN_UP.subject);

    return rules;
}




