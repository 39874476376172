import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";


export const QuizDataService = {
    getQuizList,
    syncQuizList,
    getQuizContentByID,
    getPublicQuizes,
    getMyQuizes,
    updateQuizAccess,
    saveQuizStatus,
    saveQuizQuestionAnswer,
    getQuizUserAndScore,
    getUserScores,
    getQuizAccess,
    deleteQuizAccess
}


function getQuizList() {
    return APIHelper.get(APIURLConstants.GET_QUIZ_LIST, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}


function getMyQuizes() {
    return APIHelper.get(APIURLConstants.GET_MY_QUIZES, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function syncQuizList(postData) {
    return APIHelper.post(APIURLConstants.SYNC_QUIZ_LIST, postData, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getQuizContentByID(data) {
    return APIHelper.post(APIURLConstants.GET_QUIZ_CONTENT_BY_ID, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}

function getPublicQuizes() {
    return APIHelper.get(APIURLConstants.GET_PUBLIC_QUIZES, authHeader())
        .then(response => {
            //console.log(response.data)
            return response;
        })
}

function updateQuizAccess(data) {
    return APIHelper.post(APIURLConstants.UPDATE_DOCUMENT_ACCESS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}

function deleteQuizAccess(data) {
    return APIHelper.post(APIURLConstants.Delete_DOCUMENT_ACCESS, data, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}

function saveQuizStatus(data) {
    return APIHelper.post(APIURLConstants.SAVE_QUIZ, data, authHeader())
        .then(response => {
            return response;
        }).catch(handleError)
}

function saveQuizQuestionAnswer(data) {
    return APIHelper.post(APIURLConstants.SAVE_QUESTION_ANSWER, data, authHeader())
        .then(response => {
            return response.data;
        }).catch(handleError)
}

function getQuizUserAndScore(quizUid) {
    return APIHelper.get(APIURLConstants.GET_QUIZ_USER_SCORE + quizUid, authHeader())
        .then(data => {
            return data;
        }).catch(handleError)
}

function getUserScores(officialUID) {
    return APIHelper.get(APIURLConstants.GET_USER_SCORES + officialUID, authHeader())
        .then(data => {
            return data;
        })
        .catch(handleError)
}


function getQuizAccess(quizid) {
    return APIHelper.get(APIURLConstants.GET_QUIZ_USER_ACCESS + quizid, authHeader())
    .then(data => {
        return data;
    })
    .catch(handleError)
}