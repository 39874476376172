import React, { useContext } from 'react';
import { Route, Redirect, generatePath } from 'react-router-dom';
import RouteConstants, { PERMISSIONS_CONSTANTS } from '../common/RouteConstants';
import SectionLoader from './Loader/SectionLoader';
import { userService } from '../services'
import { Can, AbilityContext } from '../common/Can';
import { PERMISSION_VERBS } from '../common/RouteConstants'
import AppConstants from '../common/AppConstants';


export const PrivateRoute = ({ component: Component, layout: Layout, subject, roles, ...rest }) => {
    const ability = useContext(AbilityContext)
    const lazyLoadComponent = (user, sub, props) => {
        // if (ability.can(PERMISSION_VERBS.READ, sub)) {     //roleAccess.includes(userService.getUserRole())
        return <React.Suspense fallback={<SectionLoader />}>
            <Component {...props} />
        </React.Suspense>
        //}
    }
    return <Route {...rest} render={props => {
        //const currentUser = authenticationService.currentUserValue;

        var jwtDecode = require('jwt-decode');
        let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));

        if (!currentUserLocalStorage) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        if (currentUserLocalStorage && currentUserLocalStorage.role === 'User' && !currentUserLocalStorage.isEmailVerified && !window.location.pathname.includes(RouteConstants.EMAIL_VERIFY)) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: RouteConstants.EMAIL_VERIFY, state: { from: props.location } }} />
        }
        if (currentUserLocalStorage && currentUserLocalStorage.lastLoginDate === null && !window.location.pathname.includes(RouteConstants.NEW_USER_CHANGE_PASSWORD)) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: RouteConstants.NEW_USER_CHANGE_PASSWORD, state: { from: props.location } }} />
        }

        let jwt = currentUserLocalStorage && currentUserLocalStorage.token ? currentUserLocalStorage.token : '';
        const currentUser = jwtDecode(jwt);
        if (currentUser) {
            if (currentUser.exp < Date.now() / 1000) {
                localStorage.clear();
                return <Redirect to={{ pathname: RouteConstants.OFFICIAL_LOGOUT }} />
            }
        }
        // console.log(!ability.can(PERMISSION_VERBS.READ, subject));
        /// check if route is restricted by role,,, roles && roles.indexOf(currentUser.role) === -1
        if (!ability.can(PERMISSION_VERBS.READ, subject)) {
            // role not authorised so redirect to home page
            let path = ''
            if (currentUser.role === "Admin" || currentUser.role === 'Scout' || currentUser.role === 'AdminScout') {
                path = ability.can(PERMISSION_VERBS.READ, PERMISSIONS_CONSTANTS.SCOUTING_DASHBOARD.subject) ?
                    RouteConstants.DASHBOARD : ''
            }
            else if (currentUser.role === "EventAdmin") {
                path = ability.can(PERMISSION_VERBS.READ, PERMISSIONS_CONSTANTS.EVENT_DASHBOARD.subject) ?
                    RouteConstants.EVENT_DASHBOARD : ''
            }
            else if (currentUser.role === "Partner") {
                path = ability.can(PERMISSION_VERBS.READ, PERMISSIONS_CONSTANTS.EVENT_DASHBOARD.subject) ?
                    RouteConstants.ORG_MEMBER_DASHBOARD : ''
            }
            else if (currentUser.role === "User") {
                path = ability.can(PERMISSION_VERBS.READ, PERMISSIONS_CONSTANTS.MY_PROFILE.subject) ?
                    generatePath(RouteConstants.PROSPECT_MY_PROFILE, { section: 'schedule' }) : ''
            }
            return <Redirect to={{ pathname: path !== '' ? path : '/' }} />
        }

        // authorised so return component
        return Layout ? <Layout {...props}>{lazyLoadComponent(currentUser, subject, props)}</Layout> : <Component  {...props} />
    }} />
}