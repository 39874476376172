import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

export const eventInvitationService = {
    getInvitations,
    getInvitationByID,
    saveInvitation,
    deleteInvitation,
    saveNotificationParticipants,
    deleteNotificationParticipants,
    sendNotification,

};

function getInvitations(data) {
    return APIHelper.post(APIURLConstants.GET_NOTIFICATION_LIST, data, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function getInvitationByID(id) {
    let postData = { "invitationID" : id}
    return APIHelper.post(APIURLConstants.GET_EVENTNOTIFICATION_BY_ID , postData, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function saveInvitation(data) {
    return APIHelper.post(APIURLConstants.SAVE_EVENT_NOTIFICATION, data, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function deleteInvitation(id) {
    let postData = { "notificationID" : id}
    return APIHelper.post(APIURLConstants.DELETE_NOTIFICATION, postData, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function saveNotificationParticipants(data) {
    return APIHelper.post(APIURLConstants.SAVE_NOTIFICATION_PARTICIPANTS, data, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
function deleteNotificationParticipants(data) {
    return APIHelper.post(APIURLConstants.DELETE_NOTIFICATION_PARTICIPANTS, data, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function sendNotification(notificationID) {
    let postData = {"notificationID" : notificationID}
    return APIHelper.post(APIURLConstants.SEND_NOTIFICATION, postData, authHeader())
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}
