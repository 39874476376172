import { authHeader, handleResponse, handleError } from '../helper';
import { authenticationService } from '../services';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";
import axios from 'axios';

export const userService = {
    changeEmail,
    changePassword,
    sendChangeEmailOTP,
    registerUser,
    getUserFullName,
    getUserRole,
    getUsername,
    getAllUser,
    changeStatus,
    updateUser,
    validateToken,
    acceptTerms,
    getSitekeyForRecaptcha,
    getTrainingVideoSignedUrl,
    getUserProfile,
    getProspectPDF,
    saveLinkOfficial,
    // New code
    getContactDetails,
    getOfficiatingSchedule,
    getEducationDetail,
    getEmploymentHistory,
    getOfficiatingExperience,
    getOfficiatingEvents,
    getGeneralInfo,
    getSelfAssessment,
    getSocialMedia,
    getMediaProfiles,
    getProfileMasterData,
    savePrimaryScout,
    linkPool,
    poolHistory,
    saveMandatoryContactInfo,
    getProfileCompleteness,
    sendReminderEmail,
    getInCompleteProfiles,
    saveAboutUsInfo,
    getAboutUsInfo,
    getGuestProfileRequest,
    getAllASMSUsers,
    UpdateUserRole
};

function registerUser(userDetails) {
    return APIHelper.post(APIURLConstants.REGISTER_USER, userDetails)
        //.then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}

function validateToken(token) {
    return APIHelper.get(APIURLConstants.VALIDATE_TOKEN + '/' + token)
        .then(handleResponse)
        .then(response => {
            return response;
        }).catch(handleError);
}


function updateUser(userDetails) {
    return APIHelper.post(APIURLConstants.UPADTE_USER_DETAIL, userDetails, authHeader())
        .then(handleResponse)
        .then(user => {
            return user;
        }).catch(handleError);
}


function changePassword(currentPassword, newPassword, confirmPassword) {
    let postData = {
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
        NewPasswordConfirm: confirmPassword
    };

    return APIHelper.post(APIURLConstants.CHANGE_PASSWORD, postData, authHeader())
        .then(handleResponse)
        .then(user => {
            return user.data;
        }).catch(handleError);
}



function sendChangeEmailOTP(postdata) {
    return APIHelper.post(APIURLConstants.SEND_CHANGE_EMAIL_OTP, postdata, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function changeEmail(postdata) {
    return APIHelper.post(APIURLConstants.CHANGE_EMAIL, postdata, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}

function getUserFullName() {
    const currentUser = authenticationService && authenticationService.currentUserValue;
    return currentUser ? currentUser.firstName + " " + currentUser.lastName : "";
}

function getUsername() {
    var jwtDecode = require('jwt-decode');
    let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUserLocalStorage && currentUserLocalStorage.token) {
        let jwt = currentUserLocalStorage && currentUserLocalStorage.token ? currentUserLocalStorage.token : '';
        const currentUser = jwtDecode(jwt);
        return currentUser.role ? currentUser.unique_name : "";
    }
    return "";
}

function getUserRole() {

    var jwtDecode = require('jwt-decode');
    let currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUserLocalStorage && currentUserLocalStorage.token) {
        let jwt = currentUserLocalStorage && currentUserLocalStorage.token ? currentUserLocalStorage.token : '';
        const currentUser = jwtDecode(jwt);
        return currentUser.role ? currentUser.role : 'User';
    }
    return 'User';
}

function getAllUser() {
    return APIHelper.get(APIURLConstants.GET_ALL_USERS, authHeader())
        .then(handleResponse)
        .then(lstuser => {
            return lstuser.data;
        }).catch(handleError);
}

function getSitekeyForRecaptcha() {
    return APIHelper.get(APIURLConstants.GET_SITEKEY)
        .then(data => {
            return data;
        }).catch(handleError);
}

function changeStatus(postData) {
    return APIHelper.post(APIURLConstants.UPADTE_USER_STATUS, postData, authHeader())
        .then(handleResponse)
        .then(user => {
            return user;
        })
        .catch(handleError);
}

function acceptTerms() {

    return APIHelper.post(APIURLConstants.ACCEPT_TERMS_CONDITIONS, {}, authHeader())
        .then(res => {
            return res;
        });
}

function getTrainingVideoSignedUrl(fileName) {
    return APIHelper.get(APIURLConstants.GET_TRAINING_VIDEO + fileName, authHeader())
        .then(trainingVideoUrl => {
            return trainingVideoUrl;
        })
        .catch(handleError);
}

function getUserProfile(postData) {
    return APIHelper.post(APIURLConstants.GET_PROSPECT_PROFILE, postData, authHeader())
        .then(profile => {
            return profile.data;
        })
        .catch(handleError);
}

const webAPIURL = () => {
    //const { REACT_APP_PUBLIC_URL } = process.env;    
    let url = `${new URL(window.location.origin)}api`;
    return url
};

function getProspectPDF(userName) {
    // return APIHelper.get(APIURLConstants.GET_PROSPECT_PDF + userName,
    //    authHeader())
    //     .then(profile => {
    //         return profile;
    //     })
    //     .catch(handleError);


    let headers = authHeader();
    headers.responseType = 'blob';
    let apiURL = webAPIURL();


    var postdata = { "userName": userName };

    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: apiURL + APIURLConstants.GET_PROSPECT_PDF,
            data: postdata,
            headers: headers
        }).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err)
        })
    })
}


function saveLinkOfficial(postData) {
    return APIHelper.post(APIURLConstants.UPDATE_LINK_OFFICIAL, postData, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

// New Code
function getContactDetails(username, fetchMedia) {
    let apiURL = fetchMedia === true ? APIURLConstants.GET_BASIC_CONTACT_INFO : APIURLConstants.GET_PROFILE_DETAILS
    return APIHelper.post(apiURL, { username: username }, authHeader())
        //.then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return user.data;
        }).catch(handleError);
}


function getOfficiatingSchedule(username) {
    return APIHelper.post(APIURLConstants.GET_USER_OFFICIATING_SCHEDULE, { username: username }, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function getEducationDetail(username) {
    return APIHelper.post(APIURLConstants.GET_USER_EDUCATION_DETAIL, { username: username }, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function getEmploymentHistory(username) {
    return APIHelper.post(APIURLConstants.GET_USER_EMPLOYMENT_HISTORY, { username: username }, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function getOfficiatingExperience(username) {
    return APIHelper.post(APIURLConstants.GET_USER_OFFICIATING_EXPERIENCE, { username: username }, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function getOfficiatingEvents(username) {
    return APIHelper.post(APIURLConstants.GET_USER_OFFICIATING_EVENTS, { username: username }, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function getGeneralInfo(username) {
    return APIHelper.post(APIURLConstants.GET_USER_GENERAL_INFO, { username: username }, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function getSelfAssessment(username) {
    return APIHelper.post(APIURLConstants.GET_USER_SELF_ASSESSMENT, { username: username }, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function getSocialMedia(username) {
    return APIHelper.post(APIURLConstants.GET_USER_SOCIAL_MEDIA, { username: username }, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function getMediaProfiles(username) {
    return APIHelper.post(APIURLConstants.GET_USER_MEDIA_PROFILES, { username: username }, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function getProfileMasterData() {
    return APIHelper.get(APIURLConstants.GET_PROFILE_MASTER_DATA, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function savePrimaryScout(postData) {
    return APIHelper.post(APIURLConstants.UPDATE_PRIMARY_SCOUT, postData, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function linkPool(postData) {
    return APIHelper.post(APIURLConstants.LINK_POOL, postData, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function poolHistory(postData) {
    return APIHelper.post(APIURLConstants.POOL_HISTORY, postData, authHeader())
        .then(result => {
            return result.data
        })
        .catch(handleError);
}

function saveMandatoryContactInfo(payload) {
    return APIHelper.post(APIURLConstants.SAVE_MANDATORY_CONTACT_INFO, payload, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function getProfileCompleteness() {
    return APIHelper.get(APIURLConstants.GET_PROFILE_COMPLETENESS, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}

function sendReminderEmail(data) {
    return APIHelper.post(APIURLConstants.SEND_REMINDER_EMAIL, data, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function getInCompleteProfiles(data) {
    return APIHelper.post(APIURLConstants.GET_INCOMPLETE_PROFILES, data, authHeader())
        .then(result => {
            return result.data;
        })
        .catch(handleError);
}


function saveAboutUsInfo(payload) {
    return APIHelper.post(APIURLConstants.SAVE_ABOUT_US_INFO, payload, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function getAboutUsInfo(username) {
    return APIHelper.post(APIURLConstants.GET_ABOUT_US_INFO, { username: username }, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function getGuestProfileRequest(requestID) {
    return APIHelper.post(APIURLConstants.GET_GUEST_PROFILE_REQUEST, { requestID: requestID }, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function getAllASMSUsers() {
    return APIHelper.get(APIURLConstants.GET_ALL_ASMS_USERS, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}

function UpdateUserRole(data) {
    return APIHelper.post(APIURLConstants.UPDATE_USER_ROLE, data, authHeader())
        .then(result => {
            return result;
        })
        .catch(handleError);
}



