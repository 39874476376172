import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthLayoutContainer from "./AuthLayoutContainer";
import OrgMemberRegistrationComponent from "./components/OrgMemberRegistrationComponent";
import UserRegistrationComponent from "./components/UserRegistrationComponent";

const useStyles = makeStyles((theme) => ({

}));

const UserRegistration = (props) => {
    const classes = useStyles();
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");

    useEffect(() => {
    }, [])

    return <>
        <AuthLayoutContainer children={(token) ? <OrgMemberRegistrationComponent token={token} /> : <UserRegistrationComponent />} />
    </>

}

export default UserRegistration;